import { useEffect, useMemo, useState } from 'react'
import { QueryObserverResult, useQuery } from 'react-query'
import { QueryStatus } from 'react-query/types/core/types'

import useDebounced from '@hooks/useDebounced'
import { ApiError } from '@lib/api'

export type LoaderFunction<TParams, TData> = (params: TParams) => Promise<TData>

export type OnSuccessFunction<TParams, TData> = (data: TData, params: TParams) => void

export type OnErrorFunction<TParams, TError> = (error: TError, params: TParams) => void

export interface InitLoaderOptions<TParams, TData> {
  key: string
  loader: LoaderFunction<TParams, TData>
  keepPreviousData?: boolean
  enabled?: (params: TParams) => boolean
  debounce?: number
  cacheTime?: number
  staleTime?: number
}

export interface CallLoaderOptions<TParams, TData, TError = ApiError> {
  onSuccess?: OnSuccessFunction<TParams, TData>
  onError?: OnErrorFunction<TParams, TError>
  isLoading?: () => boolean
  refetchInterval?: number
  enabled?: boolean
}

export interface LoaderHook<TData, TError = ApiError> {
  isLoading: boolean
  data: TData | null
  errorCode: ErrorCode | null
  error: TError | null
  refetch: () => Promise<QueryObserverResult<TData, TError>>
  status: QueryStatus
  isInitialLoading: boolean
}

export const makeLoader = <TParams, TData, TError = ApiError>(initOptions: InitLoaderOptions<TParams, TData>) => {
  return (
    parameters: TParams,
    callOptions: CallLoaderOptions<TParams, TData, TError> = {},
  ): LoaderHook<TData, TError> => {
    const { key, loader, keepPreviousData, cacheTime = 100 } = initOptions
    const [apiError, setApiError] = useState<ApiError | null>(null)

    const params = useDebounced<TParams>(parameters, initOptions.debounce ?? 0)
    const enabled = initOptions.enabled ? initOptions.enabled(params) : true

    const {
      isLoading,
      data = null,
      error,
      isSuccess,
      refetch,
      isFetching,
      status,
    } = useQuery<TData, TError>({
      queryKey: [key, params],
      keepPreviousData: keepPreviousData ?? true,
      cacheTime,
      staleTime: initOptions.staleTime,
      enabled: enabled && callOptions.enabled,
      refetchInterval: callOptions.refetchInterval,
      queryFn: async () => await loader(params),
      onError: error => {
        callOptions.onError?.(error, params)
      },
      onSuccess: data => {
        callOptions.onSuccess?.(data, params)
      },
    })

    useEffect(() => {
      if (error instanceof ApiError) setApiError(error)
      else setApiError(null)
    }, [error])

    const errorCode = useMemo(() => apiError?.code ?? null, [apiError])
    const loading =
      isLoading ||
      (() => {
        if (isSuccess) return callOptions.isLoading?.() ?? false

        return false
      })()

    return {
      isLoading: loading || isFetching,
      data,
      errorCode,
      refetch,
      error,
      status,
      isInitialLoading: loading,
    }
  }
}
