import { useEffect, useState } from 'react'

import { ApiError } from '@lib/api'
import seatSelectionUtils, { SeatsConnections } from '@lib/seatSelection'
import useSeatsQueries from '@queries/seats'

export type SeatsList = Record<number, Seat.Entry[]>

interface UseSeatSelectionResult {
  selectedSeats: SeatsList
  handleSelect: (seat: Seat.Entry) => void
  confirmedSeats: SelectedSeats
  handleClose: () => void
  handleConfirm: () => void
  handleNavigateBack: () => void
  isLoading: boolean
  layout: Seat.Data[]
  segmentIndex: number
  segmentType: ConnectionType
  reset: () => void
}

export interface SeatsLayout {
  outbound: Seat.Data[]
  inbound: Seat.Data[]
}

export interface SelectedSeats {
  outbound: SeatsList
  inbound: SeatsList
}

interface UseSeatSelectionProps {
  connections: SeatsConnections
  onSuccess?: () => void
  onError?: (error: ApiError) => void
  onConfirm: () => void
  enabled: boolean
}

const initialValue = { outbound: { 0: [] }, inbound: { 0: [] } }

const useSeatsController = ({
  connections,
  onSuccess,
  onConfirm,
  onError,
  enabled,
}: UseSeatSelectionProps): UseSeatSelectionResult => {
  const [layout, setLayout] = useState<SeatsLayout>({ outbound: [], inbound: [] })
  const [type, setType] = useState<ConnectionType>('outbound')
  const [selectedSeats, setSelectedSeats] = useState<SelectedSeats>(initialValue)
  const [confirmedSeats, setConfirmedSeats] = useState<SelectedSeats>(initialValue)
  const [index, setIndex] = useState<number>(0)
  const [data, meta] = useSeatsQueries({ connections, enabled })

  useEffect(() => {
    if (!connections.outbound) return
    if (meta.isLoading) return
    if (meta.error) {
      onError?.(meta.error)
      return
    }

    setLayout(seatSelectionUtils.getSeatsLayout(data, connections))
    onSuccess?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.isLoading])

  const handleSelect = (seat: Seat.Entry): void => {
    const isInArray = selectedSeats[type][index].some(item => item.code === seat.code)
    const isSameFare = selectedSeats[type][index].map(({ fareClass }) => fareClass).includes(seat.fareClass)
    const newSeats = isSameFare ? selectedSeats[type][index] : []
    const seats = isInArray ? selectedSeats[type][index].filter(item => item.code !== seat.code) : [...newSeats, seat]

    setSelectedSeats({ ...selectedSeats, [type]: { ...selectedSeats[type], [index]: seats } })
  }

  const changeType = (type: ConnectionType): void => {
    setType(type)
    setIndex(0)
  }

  /* istanbul ignore next */
  const handleClose = (): void => {
    window.addEventListener(
      'animationend',
      () => {
        changeType('outbound')
        setSelectedSeats(confirmedSeats)
      },
      { once: true },
    )
  }

  const handleConfirm = (): void => {
    setConfirmedSeats({ ...confirmedSeats, [type]: { ...confirmedSeats[type], [index]: selectedSeats[type][index] } })

    if (layout[type][index + 1] !== undefined) {
      setIndex(index + 1)
      selectedSeats[type][index + 1] === undefined &&
        setSelectedSeats({ ...selectedSeats, [type]: { ...selectedSeats[type], [index + 1]: [] } })

      return
    }

    if (layout.inbound.length && type === 'outbound') {
      changeType('inbound')

      return
    }

    window.addEventListener(
      'animationend',
      () => {
        changeType('outbound')
      },
      { once: true },
    )
    onConfirm()
  }

  const handleNavigateBack = (): void => {
    setIndex(index - 1)
  }

  /* istanbul ignore next:  */
  const reset = (): void => {
    setSelectedSeats(initialValue)
    setConfirmedSeats(initialValue)
    changeType('outbound')
  }

  return {
    selectedSeats: selectedSeats[type],
    layout: layout[type],
    isLoading: meta.isLoading,
    segmentIndex: index,
    segmentType: type,
    confirmedSeats,
    handleSelect,
    handleClose,
    handleConfirm,
    handleNavigateBack,
    reset,
  }
}

export default useSeatsController
