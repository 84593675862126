import paramsUtils from '@lib/params'
import qs, { ArrayFormat } from '@lib/queryString'
import booking from '@lib/url/booking'
import checkout from '@lib/url/checkout'
import results from '@lib/url/results'
import search from '@lib/url/search'
import utils from '@lib/utils'
import { ParamsStore } from '@stores/params'

const build = (parts: string[], params: Object = {}, arrayFormat?: ArrayFormat): string => {
  const path = parts.map(p => utils.string.trim(p, '/')).join('/')
  const query = qs.stringify(params, { arrayFormat })

  if (query.length > 0) return `${path}?${query}`

  return path
}

const appendParams = (uri: string, params: Object): string => {
  const query = qs.stringify(params)

  return uri.includes('?')
    ? `${uri}&${query}`
    : /* istanbul ignore next: we do not have such cases yet */ `${uri}?${query}`
}

const buildInternal = (uri: string, appParams: ParamsStore, params: Object = {}): string => {
  const requiredParams = paramsUtils.getPersistentUrlParams(appParams)

  return build(['/', uri], { ...requiredParams, ...params })
}

// In case if in further development we faced situation where we should identify domain
// or similar functionality we will use 'ldts' library and replace this function
// https://github.com/distribusion/owl-frontend/pull/813
const getDomain = (url: string): string => {
  const split = new URL(url).hostname.split('.')

  return `${split.at(-2)}.${split.at(-1)}`
}

const toTranslationsPath = (path: string): string => {
  const key = utils.string.trim(path, '/').replace('/', '-')
  return key.length > 0 ? key : 'index'
}

export default {
  build,
  buildInternal,
  appendParams,
  results,
  checkout,
  search,
  booking,
  getDomain,
  toTranslationsPath,
}
