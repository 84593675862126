import locationAPI from '@api/location'
import { makeLoader } from '@lib/loader'

interface UseLocationNameParams {
  location?: Location.Item | null
  locale: Locale
}

export const useLocationName = makeLoader<UseLocationNameParams, Location.NamedItem | null>({
  key: 'location-name',
  enabled: ({ location }) => !!location,
  loader: async ({ location, locale }) => {
    const { code, type } = location as Location.Item

    const params = { route: { code }, query: { type, locale } }
    const response = await locationAPI.getLocationNameData(params)

    return { type, ...response }
  },
  cacheTime: Infinity,
  staleTime: Infinity,
})
