import React from 'react'

import bem from '@lib/bem'

import '@ui/MediaCarousel/NavigateState/index.scss'

interface NavigateStateProps {
  media: string[]
  currentIndex: number
}

const NavigateState = (props: NavigateStateProps) => {
  const { currentIndex, media } = props

  return (
    <div className={bem('media-carousel', 'navigate-dots')}>
      {media.map((url, index) => (
        <div key={url} className={bem('media-carousel', 'navigate-dot', { active: index === currentIndex })} />
      ))}
    </div>
  )
}

export default NavigateState
