import React, { ReactElement } from 'react'

import Money from '@components/Money'
import { useTranslation } from '@lib/i18n'

interface FeesProps {
  fees: Fee[]
}

const Fees = ({ fees }: FeesProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <div className="column gap-2">
      {fees.map(({ code, amount }) => (
        <div className="cell row space-between booking-details__info" key={code} data-tag={`fee-${code}`}>
          <div>{t(`checkout.fees.${code}`)}</div>
          <div className="booking-details__amount">
            <Money fractional={amount.fractional} currency={amount.currency} />
          </div>
        </div>
      ))}
    </div>
  )
}

export default Fees
