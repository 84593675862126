import { useFormikContext } from 'formik'
import React, { ReactElement, useMemo } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import { Trans, useTranslation } from '@lib/i18n'
import Controls from '@pages/Checkout/Accommodation/Elements/Contorls'
import Info from '@pages/Checkout/Accommodation/Elements/Info'
import Card from '@pages/Checkout/Extras/Card'
import Details from '@pages/Checkout/Extras/Card/Details'
import Footer from '@pages/Checkout/Extras/Card/Footer'
import useAncillaries from '@pages/Checkout/hooks/useAncillaries'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useCheckout } from '@stores/checkout'
import { Icon, Modal } from '@ui'

interface ItemProps {
  accommodation: Ancillary.Item[]
  index: number
  grouped: boolean
}

const Item = ({ accommodation, index, grouped }: ItemProps): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const {
    values: { ancillaries },
  } = useFormikContext<CheckoutFormData>()
  const [{ outbound, inbound }] = useCheckout()
  const { opened, handleToggle, handleCancel, handleSelect } = useAncillaries({
    name: 'ACCOMMODATION',
    outbound,
    inbound,
  })

  const segment = [...(outbound?.segments ?? /* istanbul ignore next */ []), ...(inbound?.segments ?? [])][index]

  const controls = (
    <div className={bem('extras', 'card-icon', { opened })}>
      <Icon name="chevron-right" size="large" />
    </div>
  )

  const details = useMemo(
    () =>
      accommodation.map(item => (
        <Details
          key={item.code}
          ancillary={item}
          description={<Info ancillary={item} />}
          controls={
            <Controls
              selected={ancillaries.ACCOMMODATION.some(el => el.code === item.code && el.segmentIndex === index)}
              onClick={handleSelect}
              ancillary={item}
            />
          }
        />
      )),
    [accommodation, ancillaries.ACCOMMODATION, handleSelect, index],
  )

  const description = (
    <div className="checkout__accommodation-group mr-2">
      <Trans
        i18nKey="extras.ancillary.accommodation.segment"
        components={{ b: <b /> }}
        values={{
          departureStation: segment?.departureStation.name,
          arrivalStation: segment?.arrivalStation.name,
          index: index + 1,
        }}
      />
    </div>
  )

  return (
    <>
      {segment && (
        <div className="mb-5">
          <Card
            description={grouped && description}
            controls={grouped && controls}
            onClick={handleToggle}
            details={opened && details}
            grouped={grouped}
          />
          {isMobile && (
            <Modal
              opened={opened}
              title={t('extras.ancillary.accommodation.title')}
              onClose={handleCancel}
              className={bem('extras', 'card-modal')}
              footer={<Footer onClick={handleToggle} onClose={handleCancel} />}
            >
              <div className={bem('extras', 'card-modal-content')}>{details}</div>
            </Modal>
          )}
        </div>
      )}
    </>
  )
}

export default Item
