import React, { ReactElement } from 'react'

import bem from '@lib/bem'
import { Icon } from '@ui'

import '@components/SeatSelection/Header/index.scss'

interface HeaderProps {
  segment: Segment
  connection: Connection | null
  onNavigateBack?: () => void
  segmentIndex: number
  segmentsCount: number
}

const Header = ({ segment, segmentsCount, onNavigateBack, connection, segmentIndex }: HeaderProps): ReactElement => {
  const { departureStation, arrivalStation } = segment

  const getName = (stationCode: string): string | undefined => {
    const stations = connection?.segments.flatMap(({ departureStation, arrivalStation }) => [
      departureStation,
      arrivalStation,
    ])

    return stations?.find(({ code }) => stationCode === code)?.name
  }

  const departureStationName = getName(departureStation.code)
  const arrivalStationName = getName(arrivalStation.code)

  return (
    <div className="seat-selection__header-stations">
      {segmentIndex >= 1 && <Icon onClick={onNavigateBack} name="back-arrow" size="medium" />}
      {segmentsCount > 1 && (
        <span className="cell-1-sm">
          <b>{segmentIndex + 1}</b>
          <span>/</span>
          <span className={bem('seat-selection', 'header-stations-leg')}>{segmentsCount}</span>
        </span>
      )}
      {departureStationName && arrivalStationName && (
        <>
          <span className="seat-selection__header-stations-name cell-4-sm">{departureStationName}</span>
          <Icon name="arrow-right" size="medium" />
          <span className="seat-selection__header-stations-name cell-4-sm">{arrivalStationName}</span>
        </>
      )}
    </div>
  )
}

export default Header
