import bookingAPI, { BookingCreateResponse, BookingFinalizeRequest } from '@api/booking'
import reservationAPI, {
  FinalizeRequest as ReservationFinalizeRequest,
  FinalizeResponse as ReservationFinalizeResponse,
} from '@api/reservation'
import { makeMutation } from '@lib/mutation'
import settingsStore from '@stores/settings'

interface BookingFinalizeLoader extends BookingFinalizeRequest {
  bookingFormId: number
}

interface ReservationFinalizeLoader extends ReservationFinalizeRequest {
  bookingFormId: number
}

type Response = ReservationFinalizeResponse | BookingCreateResponse
type Request = ReservationFinalizeLoader | BookingFinalizeLoader

export const useFinalizeBooking = makeMutation<Request, Response>({
  key: 'bookingFinalize',
  loader: async params => {
    const settings = settingsStore.get()
    const isReservation = settings.reservation.enabled || settings.quickReservation.enabled
    const fn = isReservation ? reservationAPI.finalize : bookingAPI.finalize
    const { bookingFormId, ...body } = params

    return await fn(bookingFormId, body)
  },
})
