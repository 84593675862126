import onetrust from '@lib/cookies/onetrust'

const consentInitialization = (provider?: CookiesConsentProvider) => {
  switch (provider) {
    case 'onetrust':
      return onetrust.insertScripts()
    default:
      return
  }
}

const cookies = {
  consentInitialization,
  consent: {
    get: () => document.cookie.includes('cookiesConsent'),
    set: () => (document.cookie = `cookiesConsent=true; max-age=${60 * 60 * 24 * 90}`), // 90 days
  },
}

export default cookies
