import React, { ReactElement } from 'react'

import Station from '@components/Station'
import date from '@lib/date'

export interface ArrivalDateProps {
  connection: Connection
}

const ArrivalDate = ({ connection }: ArrivalDateProps): ReactElement => {
  return <Station.Date date={date.parse(connection.arrivalTime, 'UTC')} />
}

export default ArrivalDate
