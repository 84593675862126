import { Field } from 'formik'
import React, { ReactElement, useMemo } from 'react'

import { Validator } from '@hooks/useValidators'
import sortUtils from '@lib/sorting'
import { useStates } from '@loaders/states'
import { useParams } from '@stores/params'
import DropdownField from '@ui/Dropdown/Field'

const { sortBy } = sortUtils

interface StateFieldProps {
  name: string
  label: string
  validate?: Validator
  required?: boolean
  countryCode?: string | null
  whitelist?: string[]
}

const Index = ({ countryCode, whitelist, ...props }: StateFieldProps): ReactElement => {
  const [{ locale }] = useParams()
  const { data: states } = useStates({ locale, countryCode })
  const availableStates = useMemo(
    () => (whitelist ? states?.filter(({ code }) => whitelist.includes(code)) : states),
    [states, whitelist],
  )
  const options = useMemo(
    () =>
      sortBy(availableStates ?? [], 'name').map(({ code, name }) => ({
        label: `${name} (${code})`,
        value: code,
        searchValue: name,
      })),
    [availableStates],
  )

  return <Field component={DropdownField} searchable items={options} {...props} />
}

export default Index
