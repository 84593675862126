import cn from 'classnames'
import React from 'react'

import Section, { SectionProps } from '@components/Section'
import bem from '@lib/bem'

interface BlockSectionProps extends SectionProps {
  onBackground?: boolean
}

const BlockSection = ({
  children,
  onBackground,
  contentClassName,
  appearance = 'elevated',
  ...rest
}: BlockSectionProps) => {
  return (
    <Section
      {...rest}
      appearance={appearance}
      contentClassName={cn(contentClassName, bem('custom-blocks', 'item', { onBackground }))}
    >
      {children}
    </Section>
  )
}

export default BlockSection
