import discountCardsAPI, { type DiscountCardsRequest, type DiscountCardsResponse } from '@api/discountCards'
import { makeLoader } from '@lib/loader'

export const useDiscountCardsLoader = makeLoader<DiscountCardsRequest, DiscountCardsResponse>({
  key: 'discount-cards',
  loader: discountCardsAPI.load,
  enabled: ({ retailerPartnerNumber }) => retailerPartnerNumber > 0,
  staleTime: 1000 * 60 * 10,
  cacheTime: 1000 * 60,
})
