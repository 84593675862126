import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { OrganizationSetting, SiteNameSetting } from '@api/settings'
import utils from '@lib/utils'
import { useSettings } from '@queries/settings'

const buildSiteName = (settings: SiteNameSetting) =>
  utils.object.compact({
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: window.location.origin,
    ...settings,
  })

const buildOrganization = (organization: OrganizationSetting) => ({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  url: window.location.origin,
  ...organization,
})

const StructuredData = () => {
  const [{ title }] = useSettings()
  const { pathname } = useLocation()

  const data = useMemo(() => {
    const data: any[] = []

    if (pathname === '/') {
      if (title.organization) {
        data.push(buildOrganization({ logo: title.favicon, ...title.organization }))
      } else if (title.siteName) {
        data.push(buildSiteName(title.siteName))
      }
    }

    return data.length > 1 ? /* istanbul ignore next */ data : data[0]
  }, [pathname, title.favicon, title.organization, title.siteName])

  if (!data) return null

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}></script>
}

export default StructuredData
