import cn from 'classnames'
import React, { Suspense, UIEvent, useRef } from 'react'

import Footer from '@components/Footer'
import Header from '@components/Header'
import JourneyList from '@components/JourneyList'
import SearchBar from '@components/SearchBar'
import TripPreview from '@components/TripPreview'
import useIsMobile from '@hooks/useIsMobile'
import useIsTablet from '@hooks/useIsTablet'
import { Observable } from '@lib/observable'
import ScrollContext from '@pages/SearchResult/ScrollContext/scrollContext'

interface Props {
  header: boolean
  footer: boolean
  trips: boolean
}

const SearchResultsLayout = ({ header, footer, trips }: Props) => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const pageRef = useRef<HTMLDivElement>(null)

  const scrollObservable = useRef(new Observable<UIEvent>())
  const isDesktop = !isMobile && !isTablet

  const onScroll = (event: UIEvent) => {
    scrollObservable.current.set(event)
  }

  return (
    <Suspense>
      <ScrollContext.Provider value={{ scrollObservable: scrollObservable.current, scrollRef: pageRef }}>
        <div className="result-page" onScroll={onScroll} ref={pageRef}>
          <div id="page" className="column">
            <div className="cell result-page__header">
              <div id="bg-line" />
              <div className="column result-page__section">
                <div className="cell-12 mb-2 mb-lg-2">
                  {header && <Header />}
                  {isMobile && <TripPreview />}
                </div>
                {!isMobile && (
                  <div className="cell-12">
                    <SearchBar />
                  </div>
                )}
              </div>
            </div>
            <div className={cn('cell result-page__content result-page__section', { 'pt-7': !isMobile })}>
              {trips && <JourneyList>{!isDesktop && <Footer />}</JourneyList>}
            </div>
          </div>
          {isDesktop && footer && (
            <div className="cell">
              <Footer />
            </div>
          )}
        </div>
      </ScrollContext.Provider>
    </Suspense>
  )
}

export default SearchResultsLayout
