import currency from 'currency.js'
import { useFormikContext } from 'formik'
import React, { ReactElement, ReactNode, useMemo } from 'react'

import bem from '@lib/bem'
import currencyUtils from '@lib/currency'
import fareUtils from '@lib/fare'
import { useTranslation } from '@lib/i18n'
import { UpdatedFareClass } from '@pages/Checkout/FareClassesSelection'
import FareClassMedia from '@pages/Checkout/FareClassesSelection/Media'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useParams } from '@stores/params'
import { Icon, Skeleton } from '@ui'

import '@pages/Checkout/FareClassesSelection/FareClassItem/index.scss'

interface FareClassProps {
  excludeFeatures: string[]
  fareClass: UpdatedFareClass
  selected: boolean
  onClick: () => void
  media?: string[] | null
}

const FareClassItem = ({ fareClass, excludeFeatures, selected, onClick, media }: FareClassProps): ReactElement => {
  const { t } = useTranslation()
  const [{ currency, bookingId }] = useParams()
  const {
    values: { vacancy, vacancies, isVacancyLoading },
  } = useFormikContext<CheckoutFormData>()
  const features = fareClass.fareFeatures.filter(({ code }) => !excludeFeatures.includes(code))
  const renderFareFeature = ({ code, name }: FareFeature): ReactNode => (
    <div className="row gap-1" key={code}>
      <Icon className={bem('fare-class', 'icon')} name={fareUtils.getFareFeatureIcon(code)} size="medium" />
      <div>{name}</div>
    </div>
  )

  const currentVacancy = useMemo(() => vacancies.find(v => v.fareClass === fareClass.code), [fareClass, vacancies])
  const price = currentVacancy?.price ?? fareClass.price
  const isVacant = currentVacancy?.vacant ?? true
  const priceDiff = useMemo(() => {
    if (!vacancy?.vacant || isVacancyLoading || !isVacant) return

    const currency = currencyUtils.create(price.fractional, price.currency)

    return bookingId ? currency : currency.subtract(vacancy.price.fractional)
  }, [price, vacancy, isVacancyLoading, isVacant, bookingId])

  const handleSelectFareClass = (): void => {
    if (isVacancyLoading || !isVacant) return

    onClick()
  }
  const formatPrice = (price: currency): string => `${price.value >= 0 ? '+' : ''}${price.format()}`
  const pricePlaceholder = formatPrice(currencyUtils.create(3800, currency))

  return (
    <div className={bem('fare-class', { selected })} onClick={handleSelectFareClass}>
      <div className="column cell-12">
        {!!media?.length && <FareClassMedia media={media} />}
        <div className="fare-class__card-content column gap-3 p-3">
          <h4 className={bem('fare-class', 'title')}>{fareClass.name}</h4>
          <div className="column gap-2 cell grow">{features.map(renderFareFeature)}</div>
          <div className={bem('fare-class', 'price')}>
            <Skeleton.Text placeholder={pricePlaceholder} loading={isVacant ? isVacancyLoading : false}>
              {priceDiff && formatPrice(priceDiff)}
            </Skeleton.Text>
          </div>
        </div>
      </div>
      {!isVacant && (
        <div className={bem('fare-class', 'card', { disabled: true })}>
          <div>{t('card.booked')}</div>
        </div>
      )}
    </div>
  )
}

export default FareClassItem
