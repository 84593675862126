import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import url from '@lib/url'
import { useSettings } from '@queries/settings'

const initialFavicon = document.querySelector('[rel=icon]')?.getAttribute('href') ?? /* istanbul ignore next */ ''
const initialTitle = document.title

const Essentials = () => {
  const [settings] = useSettings()
  const { pathname } = useLocation()
  const { favicon = initialFavicon } = settings.title
  const { t } = useTranslation()
  const meta = useMemo(
    () => t(`meta.${url.toTranslationsPath(pathname)}`, { returnObjects: true }) as PageMetadata | null,
    [pathname, t],
  )
  const title = meta?.title ?? initialTitle

  useEffect(() => {
    document.querySelector('[rel=icon]')?.setAttribute('href', favicon)
    document.title = title
  }, [favicon, title])

  return (
    <>
      {!settings.seo.enabled && <meta name="robots" content="noindex,nofollow" />}
      <meta property="og:title" content={title}></meta>
      <meta name="description" content={meta?.description} />
      <meta property="og:description" content={meta?.description} />
    </>
  )
}

export default Essentials
