import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import PriceInput from '@components/PriceInput'
import bem from '@lib/bem'
import { FilterConfig, FilterFunction, FilterRenderProps, FilterTitleProps } from '@lib/connections/filters/types'
import currencyUtils from '@lib/currency'
import { useTranslation } from '@lib/i18n'
import utils from '@lib/utils'
import { PriceFilterValue } from '@stores/connectionFilters'
import { useParams } from '@stores/params'
import Slider, { SliderValue } from '@ui/Slider'

import '@lib/connections/filters/price.scss'

const getConnectionPrices = (connection: Connection, byCheapest?: boolean): number[] =>
  byCheapest
    ? [connection.cheapestTotalAdultPrice.fractional]
    : connection.fares.flatMap(({ price }) => price.fractional)
const isBetween = (value: number, [min, max]: [number, number]): boolean => value >= min && value <= max

const filter: FilterFunction<PriceFilterValue> = (connection, value, { byCheapest }) => {
  /* istanbul ignore if: we already have !value?.length check inside applyFilters */
  if (value == null) return true

  const prices = getConnectionPrices(connection, byCheapest)

  return prices.some(amount => isBetween(amount, value))
}

const PriceComponent = ({
  value,
  onChange,
  data,
  setVisibility,
  options,
}: FilterRenderProps<PriceFilterValue>): ReactElement => {
  const { t } = useTranslation()
  const [{ currency }] = useParams()
  const bounds = useMemo<[number, number]>(() => {
    const prices = data.flatMap(connection => getConnectionPrices(connection, options.byCheapest))
    if (prices.length === 0) return [0, 0]

    return [Math.min(...prices), Math.max(...prices)]
  }, [data, options.byCheapest])
  const [min, max] = bounds
  const [sliderValue, setSliderValue] = useState<SliderValue>(value ?? bounds)

  const currencyMultiplier = useMemo(() => currencyUtils.getMultiplier(currency), [currency])
  const minDistance = useMemo(() => 100 * currencyMultiplier, [currencyMultiplier])

  useEffect(() => {
    setVisibility(min !== max)
    setSliderValue(value ?? bounds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds, value])

  const clampStart = (amount?: number) => utils.number.clamp(amount || min, min, sliderValue[1] - minDistance)
  const clampEnd = (amount?: number) => utils.number.clamp(amount || max, sliderValue[0] + minDistance, max)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useMemo(() => utils.function.debounce(onChange, 80), [])
  const onSliderChange = useCallback(
    (value: SliderValue) => {
      debouncedOnChange(value)
      setSliderValue(value)
    },
    [debouncedOnChange],
  )

  return (
    <div className="column gap-4 price-filter" data-tag="price-filter">
      <div className="cell">
        <div className="row gap-1">
          <div className="column">
            <PriceInput
              transformOnSubmit={clampStart}
              value={sliderValue[0]}
              onSubmit={value => onChange([value!, sliderValue[1]])}
              label={t('journeyList.filters.price.from')}
            />
            <div className={bem('price-filter', 'bound')}>
              {t('journeyList.filters.price.min', { value: currencyUtils.create(min, currency).format() })}
            </div>
          </div>
          <div className={bem('price-filter', 'divider')}>—</div>
          <div className="column">
            <PriceInput
              transformOnSubmit={clampEnd}
              value={sliderValue[1]}
              onSubmit={value => onChange([sliderValue[0], value!])}
              label={t('journeyList.filters.price.to')}
            />
            <div className={bem('price-filter', 'bound')}>
              {t('journeyList.filters.price.max', { value: currencyUtils.create(max, currency).format() })}
            </div>
          </div>
        </div>
      </div>
      <div className="cell">
        <Slider value={sliderValue} onChange={onSliderChange} min={min} max={max} minDistance={minDistance} />
      </div>
    </div>
  )
}

const getTitle = ({ tripDirection }: FilterTitleProps): string => {
  const keys = {
    inbound: 'price.title.inbound',
    outbound: 'price.title.outbound',
    oneWay: 'price.title.oneWay',
  }
  return keys[tripDirection]
}

const priceFilter: FilterConfig<PriceFilterValue> = {
  title: getTitle,
  filter,
  Component: PriceComponent,
}

export default priceFilter
