import React, { ReactElement, ReactNode, useRef, useState } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import DatePickerInput, { DatePickerInputProps } from '@ui/DatePicker/Input'
import DatePickerMenuDesktop, { DatePickerMenuDesktopProps } from '@ui/DatePicker/MenuDesktop'
import DatePickerMenuMobile, { DatePickerMenuMobileProps } from '@ui/DatePicker/MenuMobile'
import Icon from '@ui/Icon'
import { Tooltip } from '@ui/index'

import '@ui/DatePicker/index.scss'

export interface DatePickerProps {
  value: Date | null
  initialValue?: Date
  onChange: (value: Date) => void
  onToggle?: (opened: boolean) => void
  minDate: Date
  maxDate: Date
  withIcon?: boolean
  label: string
  getTileText?: (date: Date) => ReactNode
  isTileDisabled?: (date: Date) => boolean
  errorMessage?: string | null
  prices?: PriceCalendar
  isPriceLoading?: boolean
  required?: boolean
}
type DatePickerMenuProps = DatePickerMenuMobileProps & DatePickerMenuDesktopProps

const DatePicker = ({ value, onChange, errorMessage, label, ...calendarProps }: DatePickerProps): ReactElement => {
  const wrapperRef = useRef<HTMLInputElement>(null)
  const [isCalendarOpened, setIsCalendarOpened] = useState<boolean>(false)
  const isMobile = useIsMobile()

  const toggleCalendar = (): void => {
    setIsCalendarOpened(prevState => !prevState)
    calendarProps.onToggle?.(!isCalendarOpened)
  }

  const inputProps: DatePickerInputProps = {
    errorMessage,
    value,
    label,
    active: isCalendarOpened,
    ...calendarProps,
  }

  const menuProps: DatePickerMenuProps = {
    opened: isCalendarOpened,
    onClose: () => {
      setIsCalendarOpened(false)
    },
    value: value,
    title: label,
    onChange: onChange,
    ...calendarProps,
  }

  return (
    <div className="ui-date-picker" ref={wrapperRef}>
      {!isMobile && (
        <Tooltip
          popperClassName="ui-date-picker__tooltip"
          action="click"
          content={<DatePickerMenuDesktop {...menuProps} />}
          position="bottom-start"
          onOpenChanged={(isOpened: boolean): void => {
            setIsCalendarOpened(isOpened)
            calendarProps.onToggle?.(isOpened)
          }}
          opened={isCalendarOpened}
        >
          <DatePickerInput {...inputProps} />
        </Tooltip>
      )}
      {isMobile && (
        <>
          <div onClick={toggleCalendar}>
            <DatePickerInput {...inputProps} />
          </div>
          <DatePickerMenuMobile {...menuProps} />
        </>
      )}
      {errorMessage && (
        <div className="ui-date-picker__error-message row">
          <Icon name="alert" size="small" />
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  )
}

export default DatePicker
