import { useFormikContext } from 'formik'
import React, { ReactElement } from 'react'

import IsReturnTrip from '@components/IsReturnTrip'
import fareUtils from '@lib/fare'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useCheckout } from '@stores/checkout'

const DirectionField = (): ReactElement => {
  const [{ outbound }] = useCheckout()
  const { values, setFieldValue } = useFormikContext<CheckoutFormData>()
  const { fareClass } = values
  const isReturn = fareUtils.getFareByCode(fareClass, outbound)?.fareClass.journeyType === 'open_return'

  const onTripTypeChange = (isReturn: boolean): void => {
    const journeyType = isReturn ? 'open_return' : 'single'
    const fare = fareUtils.getByJourneyType(journeyType, outbound)
    setFieldValue('fareClass', fare?.fareClass.code)
  }

  return <IsReturnTrip layout="row" value={isReturn} onChange={onTripTypeChange} />
}

export default DirectionField
