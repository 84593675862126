import { zonedTimeToUtc } from 'date-fns-tz'
import React, { ReactElement } from 'react'

import date from '@lib/date'

export interface DurationProps {
  departureTime: string
  arrivalTime: string
  departureTimezone?: string
  arrivalTimezone?: string
}

const Duration = (props: DurationProps): ReactElement => {
  const { departureTime, departureTimezone, arrivalTime, arrivalTimezone } = props

  const departure = zonedTimeToUtc(date.parse(departureTime, 'UTC'), departureTimezone ?? '')
  const arrival = zonedTimeToUtc(date.parse(arrivalTime, 'UTC'), arrivalTimezone ?? '')

  const string = date.durationToString(departure, arrival, ['hours', 'minutes'])

  return <>{string}</>
}

export default Duration
