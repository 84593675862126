import cn from 'classnames'
import React, { ReactElement, ReactNode } from 'react'

import { Icon } from '@ui'

import '@ui/Notification/index.scss'

interface NotificationProps {
  type: NotificationStatus
  children: string | ReactNode
  size: 'small' | 'medium' | 'large'
  onClose?: () => void
}

const Notification = ({ type, children, size, onClose }: NotificationProps): ReactElement => {
  const name = {
    success: 'checkout-circle',
    error: 'cross-circle',
    warning: 'alert',
    disabled: 'cross-circle',
    info: 'alert',
  }

  return (
    <div className={cn('ui-notification row items-center', type)}>
      <Icon name={name[type]} size={size} />
      <span className="body-14 ui-notification__content">{children}</span>
      {onClose && <Icon onClick={onClose} name="cross" size="medium" />}
    </div>
  )
}

export default Notification
