import React, { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import useSearchParams from '@hooks/useSearchParams'
import { ConnectionsLoaderParams } from '@loaders/connections'
import { useParams } from '@stores/params'
import { Notification } from '@ui'

interface Props {
  altParams: Partial<ConnectionsLoaderParams>
  departureLocationName: string | undefined
  arrivalLocationName: string | undefined
}

const AlternativeTrip = ({ altParams, departureLocationName, arrivalLocationName }: Props): ReactElement | null => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [{ departureLocation, arrivalLocation }] = useParams()
  const initialDeparture: string =
    searchParams.departureStation ?? searchParams.departureArea ?? searchParams.departureCity
  const initialArrival: string = searchParams.arrivalStation ?? searchParams.arrivalArea ?? searchParams.arrivalCity

  // istanbul ignore next
  if (!departureLocation || !arrivalLocation) return null

  const isLocationsSwapped =
    initialDeparture.includes(arrivalLocation.code) && initialArrival.includes(departureLocation.code)
  const isLocationsAltered = initialDeparture !== departureLocation.code || initialArrival !== arrivalLocation.code

  return (
    <div className="pt-sm-3 pb-lg-3 column gap-2">
      {isLocationsSwapped && (
        <div className="cell">
          <Notification type="info" size="medium">
            <div className="column cell">
              <span className="body-16 bolder">{t('journeyList.alternativeTrip.title')}</span>
              <div>{t('journeyList.alternativeTrip.locationsSwapped')}</div>
            </div>
          </Notification>
        </div>
      )}
      {!isLocationsSwapped && isLocationsAltered && (
        <div className="cell">
          <Notification type="info" size="medium">
            <div className="column cell">
              <span className="body-16 bolder">{t('journeyList.alternativeTrip.title')}</span>
              <div>
                <Trans
                  i18nKey="journeyList.alternativeTrip.description"
                  components={{ b: <b /> }}
                  values={{
                    departureLocationName,
                    arrivalLocationName,
                  }}
                />
              </div>
            </div>
          </Notification>
        </div>
      )}
      {!isLocationsSwapped && 'returnDate' in altParams && (
        <div className="cell">
          <Notification type="info" size="medium">
            <span className="bolder">{t('journeyList.alternativeTrip.oneWay')}</span>
          </Notification>
        </div>
      )}
    </div>
  )
}

export default AlternativeTrip
