import React, { ReactElement } from 'react'

import poweredByDistribusionImage from '@images/common/powered-by-distribusion.svg'

import '@components/Logo/index.scss'

const PoweredByDistribusion = (): ReactElement | null => {
  return <img className="powered-by-distribusion-image" src={poweredByDistribusionImage} alt="Distribusion" />
}

export default PoweredByDistribusion
