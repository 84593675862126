import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import SkeletonCard from '@components/JourneyCard/Skeleton'
import Loader from '@components/Loader'
import useIsMobile from '@hooks/useIsMobile'
import { useSettings } from '@queries/settings'
import { Skeleton } from '@ui'

const RetrySkeleton = (): ReactElement => {
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const [{ filter }] = useSettings()

  const mobileFilters = (
    <div className="row space-between mb-5">
      <div className="cell-4">
        <Skeleton.Item height={24} />
      </div>
      <div className="cell-2">
        <Skeleton.Item height={24} />
      </div>
    </div>
  )

  return (
    <div className="column cell">
      <div className="pb-5">
        <Loader loading title={t('journeyList.loading')} width={44} height={44} />
      </div>
      {isMobile && filter.enabled && mobileFilters}
      <SkeletonCard />
    </div>
  )
}

export default RetrySkeleton
