import { useMemo } from 'react'

import carrierUtils, { SegmentedData } from '@lib/carrier'
import { useTranslation } from '@lib/i18n'
import { getValidity } from '@pages/Checkout/BookingDetails/TripDetails/ConnectionDetails/utils'

interface UseValidityHook {
  outboundValidity: string | null
  inboundValidity: string | null
}

interface UseValidityProps {
  fareClass?: string
  carriers: MarketingCarrier[]
  outbound: SegmentedData | null
  inbound: SegmentedData | null
}

const useValidity = ({ fareClass, carriers, inbound, outbound }: UseValidityProps): UseValidityHook => {
  const { t } = useTranslation()

  const outboundCarriers = useMemo(() => carrierUtils.getCarriers(outbound, carriers), [carriers, outbound])
  const inboundCarriers = useMemo(() => carrierUtils.getCarriers(inbound, carriers), [carriers, inbound])

  const outboundValidity = useMemo(
    () => getValidity(outboundCarriers, fareClass, 'outbound'),
    [fareClass, outboundCarriers],
  )
  const inboundValidity = useMemo(
    () => getValidity(inboundCarriers, fareClass, 'inbound'),
    [fareClass, inboundCarriers],
  )

  const isBothValid = inbound ? !!outboundValidity && outboundValidity === inboundValidity : false

  return {
    outboundValidity: isBothValid ? null : outboundValidity,
    inboundValidity: isBothValid
      ? t('checkout.tripDetails.availableBoth', { message: inboundValidity })
      : inboundValidity,
  }
}

export default useValidity
