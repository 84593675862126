import Color from 'color'

import utils from '@lib/utils'

export interface Colors {
  primary: string
  primaryText: string
  secondary: string
  secondaryText: string
  background: string
  backgroundText: string
  headerText: string
  outlineText: string
}

const getMixColor = (color: string, mixinColor: string, weight: number): string =>
  Color(color).mix(Color(mixinColor), weight).hex().toLowerCase()

const getColorKey = (name: string, shade?: number): string => {
  const suffix = shade != null ? `-${shade}` : ''

  return `--${utils.string.toKebabCase(name)}${suffix}`
}

export const getStyleMap = (type: string, color: string): Map<string, string> => {
  const black = '#000'
  const white = '#fff'

  return new Map<string, string>([
    [getColorKey(type), getMixColor(color, color, 1)],
    [getColorKey(type, 100), getMixColor(color, black, 0.6)],
    [getColorKey(type, 90), getMixColor(color, black, 0.4)],
    [getColorKey(type, 80), getMixColor(color, black, 0.2)],
    [getColorKey(type, 70), getMixColor(color, color, 1)],
    [getColorKey(type, 60), getMixColor(color, white, 0.15)],
    [getColorKey(type, 50), getMixColor(color, white, 0.3)],
    [getColorKey(type, 40), getMixColor(color, white, 0.5)],
    [getColorKey(type, 30), getMixColor(color, white, 0.7)],
    [getColorKey(type, 20), getMixColor(color, white, 0.9)],
    [getColorKey(type, 10), getMixColor(color, white, 0.95)],
  ])
}

export const initialColors: Colors = {
  primary: '#039b24',
  primaryText: '#ffffff',
  secondary: '#3329aa',
  secondaryText: '#ffffff',
  background: '#3329aa',
  backgroundText: '#ffffff',
  headerText: '#ffffff',
  outlineText: '#3329aa',
}

const embedBackgroundColor: Pick<Colors, 'background'> = {
  background: '#fafafa',
}

const applyCustomColors = (colors: Colors): void => {
  const root = document.documentElement.style
  const { primary, secondary, outlineText, ...restColors } = colors

  getStyleMap('primary', primary).forEach((color, key) => {
    root.setProperty(key, color)
  })
  getStyleMap('secondary', secondary).forEach((color, key) => {
    root.setProperty(key, color)
  })
  getStyleMap('outlineText', outlineText).forEach((color, key) => {
    root.setProperty(key, color)
  })

  Object.entries(restColors).forEach(([key, color]) => {
    root.setProperty(getColorKey(key), color)
  })
}

export default {
  applyCustomColors,
  getEmbedBackgroundColor: () => embedBackgroundColor,
}
