import { useField } from 'formik'
import React, { useContext } from 'react'

import Counter from '@components/Counter'
import amplitude from '@lib/analytics/amplitude'
import AncillaryContext from '@pages/Checkout/Extras/Ancillary/Context'
import usePassengersCount from '@pages/Checkout/hooks/usePassengersCount'

const AncillaryCounter = () => {
  const { ancillary } = useContext(AncillaryContext)
  const [{ value }, , { setValue }] = useField<Ancillary.Item[]>(`ancillaries.${ancillary.category}`)
  const maxCount = usePassengersCount()

  const handleChange = (count: number): void => {
    const ancillaries = [...Array(count)].map((_, index) => ({
      ...ancillary,
      passengerIndex: index + 1,
    }))
    setValue(ancillaries)
    amplitude.checkout.changeAncillary(ancillary.name, count)
  }

  return <Counter min={0} max={maxCount} value={value.length} onChange={handleChange} />
}

export default AncillaryCounter
