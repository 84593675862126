import { useFormikContext } from 'formik'

import ancillaryUtils from '@lib/ancillary'
import checkoutUtils from '@lib/checkout'
import seatUtils from '@lib/seatSelection'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'

export const useTotalPrice = (): Money | null => {
  const { values } = useFormikContext<CheckoutFormData>()
  const { price, fees, ancillaries, reservationInfo, seats } = values

  if (price == null || !Object.keys(price).length) return reservationInfo?.totalPrice ?? null

  const ancillariesAmount = ancillaryUtils.getTotalAmount(ancillaries)
  const { fractional: totlaPriceWithFees, currency } = checkoutUtils.totalPriceWithFees(price, fees)
  const outboundSeats = seatUtils.sumPrice(seatUtils.flatten(seats?.outbound))
  const inboundSeats = seatUtils.sumPrice(seatUtils.flatten(seats?.inbound))

  return {
    currency,
    fractional: totlaPriceWithFees + ancillariesAmount + outboundSeats + inboundSeats,
  }
}

export const useFinalPrice = (): Money | null => {
  const { values } = useFormikContext<CheckoutFormData>()
  const { installment } = values
  const totalPrice = useTotalPrice()

  return installment ? installment.total : totalPrice
}
