import { confirm, Request as ConfirmRequest, Response as ConfirmResponse } from '@api/reservation/confirm'
import { create, Request as CreateRequest, Response as CreateResponse } from '@api/reservation/create'
import { finalize, Request as FinalizeRequest, Response as FinalizeResponse } from '@api/reservation/finalize'
import { load, Request as LoadRequest, Response as LoadResponse } from '@api/reservation/load'
import { pay, Request as PayRequest } from '@api/reservation/pay'
import { status, Response as StatusResponse } from '@api/reservation/status'
import { update, Request as UpdateRequest } from '@api/reservation/update'

interface Reservation {
  createdAt: Date
  expiresAt: Date
  period: number
  id: number
  bookingFormId: number
  price: Money
  fees: Fee[]
}

export default {
  create,
  confirm,
  load,
  pay,
  update,
  finalize,
  status,
}

export type {
  Reservation,
  CreateRequest,
  CreateResponse,
  ConfirmRequest,
  ConfirmResponse,
  LoadResponse,
  LoadRequest,
  PayRequest,
  UpdateRequest,
  FinalizeRequest,
  FinalizeResponse,
  StatusResponse,
}
