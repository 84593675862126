import { useEffect, useMemo, useState } from 'react'

const useScreenIsLess = (screenWidth: number): boolean => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = (): void => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return useMemo(() => width <= screenWidth, [width, screenWidth])
}

export default useScreenIsLess
