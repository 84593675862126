import { Field, useFormikContext } from 'formik'
import React, { useState } from 'react'

import { FormBlockData } from '@components/Blocks/Form/useInitialFormValues'
import TermsModal from '@components/TermsAndPrivacy/TermsModal'
import useValidators from '@hooks/useValidators'
import { Trans, useTranslation } from '@lib/i18n'
import CheckboxField from '@ui/Checkbox/Field'
import FileUpload from '@ui/FileUpload'
import InputField from '@ui/Input/Field'

import '@components/Blocks/Form/Fields/Additional/index.scss'

interface Props {
  terms: string
}

const AdditionalInformation = ({ terms }: Props) => {
  const { t } = useTranslation()
  const [openedTerms, setOpenedTerms] = useState<boolean>(false)
  const { required } = useValidators()

  const { values, setFieldValue } = useFormikContext<FormBlockData>()

  return (
    <div className="column stretch form-block-additional">
      <div className="cell-12 mb-4">
        <Field
          component={InputField}
          label={t('customBlocksCommon.form.message')}
          name="message"
          multiline
          maxLength={500}
          validate={required}
          required
        />
      </div>
      <div className="cell-12 mb-5">
        <FileUpload
          fileTypes={['.png', '.jpg', '.jpeg', '.pdf', '.docx']}
          name="attachments"
          maxFiles={5}
          maxFileSize={2}
          value={values.attachments}
          onChange={files => {
            setFieldValue('attachments', files)
          }}
        />
      </div>
      <Field
        component={CheckboxField}
        label={
          <Trans
            i18nKey="customBlocksCommon.form.terms"
            components={{
              termsLink: (
                <span
                  className="form-block-additional__terms"
                  onClick={e => {
                    setOpenedTerms(true)
                    e.preventDefault()
                  }}
                />
              ),
            }}
          />
        }
        name="termAccepted"
        validate={required}
      />
      <TermsModal opened={openedTerms} onClose={() => setOpenedTerms(false)} text={terms} />
    </div>
  )
}

export default AdditionalInformation
