import { addHours, isWithinInterval, startOfDay, subSeconds } from 'date-fns'
import React, { useMemo } from 'react'

import { FilterConfig, FilterFunction, FilterRenderProps } from '@lib/connections/filters/types'
import dateUtils from '@lib/date'
import { useTranslation } from '@lib/i18n'
import { DayInterval } from '@stores/connectionFilters'
import CheckboxGroup, { CheckboxOption } from '@ui/CheckboxGroup'

interface TimeInterval {
  start: Date
  end: Date
}

const buildDateRange = (baseDate: Date, intervalName: DayInterval): TimeInterval => {
  const dayStart = startOfDay(baseDate)
  switch (intervalName) {
    case 'dawn':
      return { start: dayStart, end: addHours(dayStart, 6) }
    case 'morning':
      return { start: addHours(dayStart, 6), end: addHours(dayStart, 12) }
    case 'afternoon':
      return { start: addHours(dayStart, 12), end: addHours(dayStart, 18) }
    case 'night':
      return { start: addHours(dayStart, 18), end: subSeconds(addHours(dayStart, 24), 1) }
  }
}

const filter: FilterFunction = (connection, value) => {
  const departureTime = dateUtils.parse(connection.departureTime, 'UTC')

  return value.some(interval => isWithinInterval(departureTime, buildDateRange(departureTime, interval as DayInterval)))
}

const FilterComponent = ({ value, onChange }: FilterRenderProps<DayInterval[]>) => {
  const { t } = useTranslation()
  const options: CheckboxOption<DayInterval>[] = useMemo(
    () => [
      { value: 'dawn', label: `${t('journeyList.filters.dawn')} (00:00 - 06:00)` },
      { value: 'morning', label: `${t('journeyList.filters.morning')} (06:00 - 12:00)` },
      { value: 'afternoon', label: `${t('journeyList.filters.afternoon')} (12:00 - 18:00)` },
      { value: 'night', label: `${t('journeyList.filters.night')} (18:00 - 23:59)` },
    ],
    [t],
  )

  return <CheckboxGroup value={value} onChange={onChange} options={options} />
}

const departureTimeFilter: FilterConfig<DayInterval[]> = {
  filter,
  title: 'departureTime',
  Component: FilterComponent,
}

export default departureTimeFilter
