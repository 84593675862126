import cn from 'classnames'
import React, { CSSProperties, ReactElement } from 'react'

import Card from '@components/Blocks/Cards/Card'
import BlockSection from '@components/Blocks/common/BlockSection'
import TransFromHtml from '@components/TransFromHtml'
import useIsMobile from '@hooks/useIsMobile'
import { useTranslation } from '@lib/i18n'

import '@components/Blocks/Cards/index.scss'

const CardsBlock = (props: PageBlocks.CardsBlock['data']): ReactElement => {
  const { translationKey, cards, alignText = 'center', imageSize = 'fullWidth', columns, ...rest } = props
  const { t } = useTranslation()
  const { title, description } = t<any, PageBlocks.CardTranslation>(`customBlocks.${translationKey}`, {
    returnObjects: true,
    defaultValue: {},
  })
  const isMobile = useIsMobile()
  const columnSize = `calc(100% / ${columns})`
  const cellStyles: CSSProperties | undefined =
    isMobile || !columns ? undefined : { flexBasis: columnSize, maxWidth: columnSize }

  return (
    <BlockSection {...rest}>
      <div className={cn('cards-block', 'column', alignText, `image-size-${imageSize}`)}>
        {title && <h2 className="cell mb-3">{title}</h2>}
        {description && (
          <div className="body-16 mb-5 cards-block__description">
            <TransFromHtml i18nKey={`customBlocks.${translationKey}.description`} />
          </div>
        )}
        <div className="cell">
          <div className={cn('row-lg row-md wrap gap-6 column', { center: !!columns })}>
            {cards.map(props => (
              <div className={cn('cell-lg-4 cell-md-6 grow')} key={props.translations} style={cellStyles}>
                <Card {...props} translations={`${translationKey}.cards.${props.translations}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </BlockSection>
  )
}

export default CardsBlock
