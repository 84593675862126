import { PassengersTypesResponse } from '@api/passengerTypes'
import { createStore, useStore, UseStoreHook } from '@lib/store'

interface PassengerTypesStore {
  types: PassengersTypesResponse
}

const DEFAULT_PASSENGER_TYPE = [{ name: 'adult', code: 'PNOS', minAge: 16, maxAge: 65 }]

const store = createStore<PassengerTypesStore>({ types: DEFAULT_PASSENGER_TYPE })

export const usePassengerTypes = (): UseStoreHook<PassengerTypesStore> => useStore(store)

export default store
