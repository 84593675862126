import React, { useEffect } from 'react'

import { FilterRenderProps } from '@lib/connections/filters/types'
import { CheckboxGroup } from '@ui'
import { CheckboxOption } from '@ui/CheckboxGroup'

interface Props extends Pick<FilterRenderProps, 'value' | 'onChange' | 'setVisibility'> {
  options: CheckboxOption<string>[]
}

const CheckboxFilter = (props: Props) => {
  const { value, onChange, setVisibility, options } = props

  useEffect(() => {
    setVisibility(options.length > 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.length])

  return options.length ? <CheckboxGroup options={options} value={value} onChange={onChange} /> : null
}

export default CheckboxFilter
