import React, { ReactElement } from 'react'

import Page, { PageProps } from '@components/SidebarMenu/pages/Page'
import useSearchParams from '@hooks/useSearchParams'
import bem from '@lib/bem'
import currencyUtils from '@lib/currency'
import { useTranslation } from '@lib/i18n'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

import '@components/SidebarMenu/pages/page.scss'

interface CurrencyPageProps {
  onClose: () => void
}

const CurrencyPage = ({ onClose }: CurrencyPageProps): ReactElement => {
  const [{ currency: current }] = useParams()
  const [_, setSearchParams] = useSearchParams()
  const [
    {
      currency: { supported },
    },
  ] = useSettings()

  const setCurrency = (currency: Currency): void => {
    setSearchParams(current => ({ ...current, currency }))
  }
  const { t } = useTranslation()

  const pageProps: PageProps<Currency> = {
    title: t('header.currencySelectLabel'),
    list: currencyUtils.sort(supported),
    current,
    getIcon: (code: Currency) => currencyUtils.getSymbol(code),
    getName: (code: Currency) => currencyUtils.getName(code),
    onChange: (code: Currency) => {
      setCurrency(code)
      onClose()
    },
    getClass: (item: Currency, { selected }: { selected?: boolean }): string =>
      bem('currency-list', 'item', { selected, separated: currencyUtils.isTopLast(supported, item) }),
    onClose,
  }

  return <Page {...pageProps} />
}

export default CurrencyPage
