import React from 'react'
import { useLocation } from 'react-router-dom'

import url from '@lib/url'
import { useSettings } from '@queries/settings'

const Locales = () => {
  const [settings] = useSettings()
  const { pathname } = useLocation()

  return (
    <>
      {settings.locale.supported.map(key => (
        <link key={key} rel="alternate" hrefLang={key} href={url.build([location.origin, pathname], { locale: key })} />
      ))}
    </>
  )
}

export default Locales
