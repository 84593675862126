import React, { ReactElement, ReactNode } from 'react'

import { Modal } from '@ui'

import '@pages/Checkout/ReservationsErrorModal/index.scss'

interface ReservationsModalInterface {
  iconPath: string
  title: string
  body: string
  isOpened: boolean
  footer: ReactNode
}

const ReservationsModal = ({ iconPath, title, body, isOpened, footer }: ReservationsModalInterface): ReactElement => {
  return (
    <Modal maxWidth="md" opened={isOpened}>
      <div className="reservations-modal__content">
        <div className="reservations-modal__expired">
          <div className="reservations-modal__icon">
            <img src={iconPath} />
          </div>
          <div className="reservations-modal__message-title">{title}</div>
          <div className="reservations-modal__message-content">{body}</div>
        </div>
        <div className="reservations-modal__buttons">{footer}</div>
      </div>
    </Modal>
  )
}

export default ReservationsModal
