import PixElement from '@adyen/adyen-web/components/Pix'
import React, { ReactElement, useEffect, useRef } from 'react'

import '@pages/Checkout/Payment/PixForm/index.scss'

interface PixFormProps {
  instance?: PixElement | null
}
const PixForm = ({ instance }: PixFormProps): ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null)

  /* istanbul ignore next */
  useEffect(() => {
    if (containerRef.current && instance != null) {
      instance.mount(containerRef.current)
      containerRef.current.scrollIntoView()

      return () => {
        instance.unmount()
      }
    }
  }, [instance])

  return <div hidden={instance == null} ref={containerRef} />
}

export default PixForm
