import React, { ReactElement } from 'react'

import { Icon } from '@ui'

interface BurgerButtonProps {
  onClick: () => void
}

const BurgerButton = ({ onClick }: BurgerButtonProps): ReactElement => {
  return <Icon name="burger" size="large" onClick={onClick} />
}

export default BurgerButton
