export interface ConnectionResultsEvent {
  event: 'connection_results'
  site_language: Locale
  retailer_partner_number: RetailerNumber
  currency: Currency
  pax: number
  connections_count: number
  marketing_carrier_code?: CarrierCode
  arrival_area_code?: string
  arrival_city_code?: string
  arrival_station_code?: string
  departure_area_code?: string
  departure_city_code?: string
  departure_station_code?: string
}

export interface ConnectionResultsEventParams {
  locale: Locale
  currency: Currency
  pax: number
  connectionsCount: number
  retailerPartnerNumber: RetailerNumber
  marketingCarrierCode: CarrierCode | null
  arrivalLocation: Location.Item | null
  departureLocation: Location.Item | null
}

export const buildConnectionResultsEvent = (params: ConnectionResultsEventParams): ConnectionResultsEvent => {
  const { marketingCarrierCode, departureLocation, arrivalLocation } = params

  return {
    event: 'connection_results',
    site_language: params.locale,
    retailer_partner_number: params.retailerPartnerNumber,
    currency: params.currency,
    pax: params.pax,
    connections_count: params.connectionsCount,
    ...(marketingCarrierCode && { marketing_carrier_code: marketingCarrierCode }),
    ...(departureLocation && { [`departure_${departureLocation.type}_code`]: departureLocation.code }),
    ...(arrivalLocation && { [`arrival_${arrivalLocation.type}_code`]: arrivalLocation.code }),
  }
}
