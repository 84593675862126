import initAlienUtils from '@wasm/alien_utils'
import React from 'react'
import { createRoot } from 'react-dom/client'

import wasmUtils from '@lib/wasm'
import App from '@pages/Index/App'

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import '../../index.scss'
import '@pages/Index/index.scss'

const initializeApp = (): void => {
  const root = document.getElementById('root') as HTMLElement

  createRoot(root).render(<App />)
}

wasmUtils.withWasmSupport(initAlienUtils, initializeApp)
