import { useState, useEffect } from 'react'
import * as ReactGA from 'react-ga'

/* istanbul ignore next */
export interface GAParams {
  _ga: string | null
  _gac: string | null
}

/* istanbul ignore next */
export const useGAParams = (): GAParams => {
  const [params, setParams] = useState<GAParams>({ _ga: null, _gac: null })

  useEffect(() => {
    ReactGA.ga((tracker: any): void => {
      const linker = tracker.get('linkerParam')
      const gaParams = new URLSearchParams(decodeURIComponent(linker))
      setParams({ _ga: gaParams.get('_ga'), _gac: gaParams.get('_gac') })
    })
  }, [])

  return params
}
