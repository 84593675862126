import { Field } from 'formik'
import React, { ReactElement } from 'react'

import useValidators from '@hooks/useValidators'
import dateUtils from '@lib/date'
import { useTranslation } from '@lib/i18n'
import InputField from '@ui/Input/Field'

interface BirthdateProps {
  name: string
}

const Birthdate = ({ name }: BirthdateProps): ReactElement => {
  const { t } = useTranslation()
  const { birthDate } = useValidators()

  return (
    <Field
      component={InputField}
      type="date"
      label={t('checkout.passengerDetails.birthdate')}
      name={`${name}.birthdate`}
      validate={birthDate}
      max={dateUtils.formatDate(new Date())}
      required
    />
  )
}

export default Birthdate
