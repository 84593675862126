import React, { ReactElement, ReactNode, useCallback, useState, useEffect } from 'react'

import { Checkbox } from '@ui'

import '@ui/CheckboxGroup/index.scss'

export interface CheckboxOption<T> {
  value: T
  label: ReactNode
  disabled?: boolean
}

export interface CheckboxGroupProps<T> {
  value: T[]
  options: CheckboxOption<T>[]
  onChange?: (value: T[]) => void
  disabled?: boolean
}

const CheckboxGroup = <TValue extends string | boolean>({
  value,
  options,
  onChange,
  disabled,
  direction = 'column',
}: CheckboxGroupProps<TValue> & Directional): ReactElement => {
  const [groupValue, setGroupValue] = useState(value)

  useEffect(() => {
    setGroupValue(value)
  }, [value, setGroupValue])

  const onValueChange = useCallback(
    (inputValue: TValue): void => {
      const newValue = !groupValue.includes(inputValue)
        ? groupValue.concat([inputValue])
        : groupValue.filter(value => value !== inputValue)

      setGroupValue(newValue)

      onChange?.(newValue)
    },
    [groupValue, setGroupValue, onChange],
  )

  return (
    <div className={`ui-checkbox-group ${direction}`}>
      {options.map(option => (
        <Checkbox
          key={option.value.toString()}
          checked={groupValue.includes(option.value)}
          onChange={() => {
            onValueChange(option.value)
          }}
          disabled={disabled ?? option.disabled}
        >
          {option.label}
        </Checkbox>
      ))}
    </div>
  )
}

export default CheckboxGroup
