import React from 'react'
import { createPortal } from 'react-dom'

import Essentials from '@pages/Index/Meta/Essentials'
import Locales from '@pages/Index/Meta/Locales'
import StructuredData from '@pages/Index/Meta/StructuredData'

const Metadata = () => {
  return createPortal(
    <>
      <Essentials />
      <Locales />
      <StructuredData />
    </>,
    document.head,
  )
}

export default Metadata
