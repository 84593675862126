import { BookingPassenger } from '@api/booking'
import makeApi from '@lib/api'

export interface Request {
  id: string
}

export interface Response {
  passengers: BookingPassenger[]
  totalPrice: Money
  state: string
  cancelledAt: string | null
  termsVersion: string | null
  bookingFormId: number
  reservationId: string
  retailerPartnerNumber: number
  marketingCarrierCode: string
  departureTime: string
  arrivalTime: string
  returnDepartureTime: string
  returnArrivalTime: string
  arrivalStationCode: string
  departureStationCode: string
  fees: Fee[]
}

export const load = async (id: string): Promise<Response> => {
  const callApi = makeApi.get<{}, Response>({
    type: 'reservations',
    old: `/reservations/${id}`,
    new: `/reservations/${id}`,
  })

  return await callApi({})
}
