import makeApi from '@lib/api'

interface PassengersTypesRequest {
  marketingCarrierCode: CarrierCode
}

interface PassengerType {
  name: string
  code: string
  minAge: number
  maxAge: number
}

export type PassengersTypesResponse = PassengerType[]

export default {
  load: async (params: PassengersTypesRequest): Promise<PassengersTypesResponse> => {
    const callApi = makeApi.get<{}, PassengersTypesResponse>(
      `/marketing_carriers/${params.marketingCarrierCode}/passenger_types`,
    )

    return await callApi({})
  },
}
