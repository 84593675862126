import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

import amplitude from '@lib/analytics/amplitude'
import { useTranslation } from '@lib/i18n'
import paramsUtils from '@lib/params'
import urlUtils from '@lib/url'
import { useParams } from '@stores/params'

interface MenuLinkProps {
  url: string
  popup?: boolean
  onLinkClick?: () => void
  translationKey: string
  className?: string
  keyPrefix?: string
}

const MenuLink = (props: MenuLinkProps) => {
  const { url, translationKey, className = 'header-menu__link', keyPrefix = 'header', onLinkClick, popup } = props
  const [params] = useParams()
  const { t } = useTranslation(undefined)
  const key = `${keyPrefix}.${translationKey}`
  const isInternal = !url.startsWith('http')

  const handleClick = (url: string): void => {
    amplitude.common.clickMenuLink(url)
    onLinkClick?.()
  }

  const getInternalLink = useCallback(() => {
    return url.includes('?')
      ? urlUtils.appendParams(url, paramsUtils.getPersistentUrlParams(params))
      : urlUtils.buildInternal(url, params)
  }, [params, url])

  if (popup) {
    const onClick = (event: React.MouseEvent) => {
      const linkUrl = isInternal ? location.origin + getInternalLink() : url
      handleClick(url)
      window.open(linkUrl, 'popup', 'popup')
      event.preventDefault()
    }

    return (
      <a href={url} target="popup" className={className} onClick={onClick}>
        {t(key)}
      </a>
    )
  }

  if (!isInternal) {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
        onClick={() => {
          handleClick(url)
        }}
      >
        {t(key)}
      </a>
    )
  }

  return (
    <Link
      className={className}
      to={getInternalLink()}
      onClick={() => {
        handleClick(getInternalLink())
      }}
    >
      {t(key)}
    </Link>
  )
}

export default MenuLink
