import { createStore, useStore, UseStoreHook } from '@lib/store'

export type Directions = 'directOnly'
export type DayInterval = 'dawn' | 'morning' | 'afternoon' | 'night'
export type TrainType = 'acela' | 'conventional'
export type PriceFilterValue = [number, number] | null

export interface ConnectionFiltersStore {
  direction: Directions[]
  departureTime: DayInterval[]
  fareClass: string[]
  services: string[]
  departureStations: string[]
  arrivalStations: string[]
  trainType: TrainType[]
  price: PriceFilterValue
  marketingCarriers: string[]
  transportMode: Lowercase<Vehicle.Code>[]
}

const store = createStore<ConnectionFiltersStore>({
  direction: [],
  departureTime: [],
  fareClass: [],
  services: [],
  departureStations: [],
  arrivalStations: [],
  trainType: [],
  price: null,
  marketingCarriers: [],
  transportMode: [],
})

export const useConnectionFilters = (): UseStoreHook<ConnectionFiltersStore> => useStore(store)

export default store
