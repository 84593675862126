import React, { ReactElement } from 'react'

import Station from '@components/Station'
import date from '@lib/date'

export interface ArrivalTimeProps {
  date: string
}

const ArrivalTime = (props: ArrivalTimeProps): ReactElement => {
  return <Station.Time date={date.parse(props.date, 'UTC')} />
}

export default ArrivalTime
