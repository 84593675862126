import React, { ReactElement } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import fareUtils from '@lib/fare'
import { Icon, Tooltip } from '@ui'

import '@components/JourneyCard/Footer/index.scss'

interface AmenityProps {
  fareFeature: FareFeature
  tooltipPosition?: 'top' | 'bottom' | 'right' | 'left'
}

const Amenity = ({ fareFeature, tooltipPosition = 'top' }: AmenityProps): ReactElement => {
  const isMobile = useIsMobile()

  return (
    <Tooltip
      action={isMobile ? 'click' : 'hover'}
      content={<div className="journey-card__footer-amenities-description">{fareFeature.name}</div>}
      position={tooltipPosition}
    >
      <span className="journey-card__footer-amenities-icon">
        <Icon name={fareUtils.getFareFeatureIcon(fareFeature.code)} size={isMobile ? 'medium' : 'large'} />
      </span>
    </Tooltip>
  )
}

export default Amenity
