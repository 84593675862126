import PixElement from '@adyen/adyen-web/components/Pix'
import Core from '@adyen/adyen-web/core'
import React, { useCallback, useMemo, useState } from 'react'

import { BookingFinalizeResponse, BookingStatusResponse } from '@api/booking'
import { PaymentMethodStatus } from '@enums'
import { ConfirmPaymentParams, FinishPaymentParams } from '@hooks/useBookingFlow'
import { useBookingStatusPolling } from '@loaders/bookingStatusPolling'
import PaymentLabel from '@pages/Checkout/Payment/Label'
import PixForm from '@pages/Checkout/Payment/PixForm'

export const usePix = (finishPayment: (params: FinishPaymentParams) => void, adyen?: Core): AdyenTypes.Method => {
  const [pixInstance, setPixInstance] = useState<PixElement>()

  const getOption = useCallback(
    () => ({
      value: 'pix',
      label: <PaymentLabel type="pix" />,
      content: <PixForm instance={pixInstance} />,
    }),
    [pixInstance],
  )

  const statusOptions = useMemo(
    () => ({
      onSuccess: (params: BookingStatusResponse) => {
        finishPayment({ ...params, action: 'redirect' })
      },
    }),
    [finishPayment],
  )
  const statusPolling = useBookingStatusPolling(statusOptions)

  const onFinalize = useCallback(
    (params: BookingFinalizeResponse) => {
      statusPolling.mutate(params.bookingFormId)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [statusPolling.mutate],
  )

  const confirmPayment = useCallback(
    (params: ConfirmPaymentParams) => {
      if (adyen != null && params.action === 'pix') {
        const pix = adyen.createFromAction(params.details.action) as PixElement

        setPixInstance(pix)
      }
    },
    [adyen],
  )

  return useMemo(
    () => ({
      status: adyen == null ? PaymentMethodStatus.Pending : PaymentMethodStatus.Ready,
      getOption,
      error: statusPolling.error,
      isLoading: statusPolling.isLoading,
      on: {
        confirmPayment,
        adyenFinalize: onFinalize,
      },
    }),
    [adyen, getOption, statusPolling.error, statusPolling.isLoading, confirmPayment, onFinalize],
  )
}
