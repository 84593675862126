import { FieldProps } from 'formik'
import React, { ReactElement, ReactNode } from 'react'

import { Checkbox } from '@ui'
import { CheckboxProps } from '@ui/Checkbox'

type CheckboxFieldProps = FieldProps<boolean[]> & CheckboxProps & { label: ReactNode }

const CheckboxField = (props: CheckboxFieldProps): ReactElement => {
  const {
    field,
    form: { setFieldValue, getFieldMeta },
    ...rest
  } = props
  const { touched, error, value } = getFieldMeta<boolean>(field.name)

  return (
    <Checkbox
      {...rest}
      {...field}
      error={touched && !!error}
      onChange={() => {
        setFieldValue(field.name, !value)
      }}
    >
      {rest.label}
    </Checkbox>
  )
}

export default CheckboxField
