import helpers from '@lib/analytics/amplitude/helpers'

const { sendEvent } = helpers

export interface ResellingData {
  retailerPartnerNumber: number
  marketingCarrierCode: string
  departureCode: string
  arrivalCode: string
  returnTrip: boolean
  url: string | null
}

const clickReselling = (data: ResellingData): void => {
  sendEvent('click-reselling', { data })
}

export default {
  clickReselling,
}
