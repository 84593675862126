import React, { useCallback, useMemo } from 'react'

import { PaymentMethodStatus } from '@enums'
import { ConfirmPaymentParams } from '@hooks/useBookingFlow'
import PaymentLabel from '@pages/Checkout/Payment/Label'

const usePaypal = (): AdyenTypes.Method => {
  const getOption = useCallback(
    () => ({
      value: 'paypal',
      label: <PaymentLabel type="paypal" brands={[{ type: 'paypal' }]} />,
    }),
    [],
  )

  const submitForm = useCallback(() => ({ paymentMethodData: { type: 'paypal' } }), [])
  const confirmPayment = useCallback(({ action, details }: ConfirmPaymentParams) => {
    /* istanbul ignore else */
    if (action === 'paypal' && details.action.url != null) {
      window.location.href = details.action.url
    }
  }, [])

  return useMemo(
    () => ({
      getOption,
      status: PaymentMethodStatus.Ready,
      on: {
        submitForm,
        confirmPayment,
      },
    }),
    [confirmPayment, getOption, submitForm],
  )
}

export default usePaypal
