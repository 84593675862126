import { useQueries } from '@tanstack/react-query'
import { useMemo } from 'react'

import seatsAPI from '@api/seats'
import { ApiError } from '@lib/api'
import seatSelectionUtils, { BuildSeatsProps, SeatsConnections, SeatsData } from '@lib/seatSelection'
import { useParams } from '@stores/params'

interface UseSeatsQueriesProps {
  connections: SeatsConnections
  options?: BuildSeatsProps
  enabled?: boolean
}

type Refetch = () => void
interface Meta {
  isLoading: boolean
  error: ApiError | null | undefined
  status: string[]
}

type UseSeatsQueriesHook = [SeatsData, Meta, Refetch]

const useSeatsQueries = ({ connections, enabled = true, options }: UseSeatsQueriesProps): UseSeatsQueriesHook => {
  const [params] = useParams()
  const isMultiSegmentAllowed = useMemo(() => connections.outbound?.offerBundle, [connections])

  const segments = useMemo(
    () =>
      Object.entries(connections).reduce<(Segment | Connection)[]>((acc, [_, value]) => {
        if (!value) return acc
        const data = isMultiSegmentAllowed ? value.segments.map((item: Segment) => item) : value

        return [...acc, data].flat()
      }, []),
    [connections, isMultiSegmentAllowed],
  )

  const combinedQuery = useQueries({
    queries: segments.map(value => {
      const parameters = { ...seatSelectionUtils.buildSeatsParams(value, params, options) }

      return {
        queryKey: ['seats', value?.id],
        queryFn: () => seatsAPI.load(parameters),
        enabled: enabled && Object.values(parameters).every(item => item),
        staleTime: Infinity,
      }
    }),
    combine: results => {
      return {
        data: results.map(({ data }) => data).filter(Boolean),
        isLoading: results.some(({ isLoading }) => isLoading),
        error: results.find(Boolean)?.error,
        status: results.map(({ status }) => status),
        refetch: () => results.forEach(result => result.refetch()),
      }
    },
  })

  const meta = {
    isLoading: combinedQuery.isLoading,
    status: combinedQuery.status,
    error: combinedQuery.error as ApiError,
  }

  return [combinedQuery.data, meta, combinedQuery.refetch]
}

export default useSeatsQueries
