import { useFormikContext } from 'formik'
import React, { ReactElement, useMemo } from 'react'

import amplitude from '@lib/analytics/amplitude'
import fare from '@lib/fare'
import fareClassUtils from '@lib/fareClass'
import sorting from '@lib/sorting'
import FareClassItem from '@pages/Checkout/FareClassesSelection/FareClassItem'
import FareClassSkeleton from '@pages/Checkout/FareClassesSelection/FareClassSkeleton'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { UpdatedMediaData } from '@queries/media'
import { Skeleton } from '@ui'

export interface UpdatedFareClass extends FareClass {
  price: Money
}

interface FareClassesSelectionProps {
  fareClassList: UpdatedFareClass[]
  isLoading?: boolean
  media?: UpdatedMediaData[]
}

const FareClassesSelection = ({ fareClassList, isLoading, media }: FareClassesSelectionProps): ReactElement => {
  const {
    values: { fareClass: selectedClass },
    setFieldValue,
  } = useFormikContext<CheckoutFormData>()
  const commonFareFeatures = useMemo(() => fare.getCommonFareFeatureCodes(fareClassList), [fareClassList])
  const selectFareClass = ({ code }: FareClass): void => {
    setFieldValue('fareClass', code)
    setFieldValue('seats', null)
    amplitude.checkout.changeFareClass({ fromFareClass: selectedClass, toFareClass: code })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sortedFareClassList = useMemo(() => sorting.sortBy(fareClassList, 'code', selectedClass), [fareClassList])

  return (
    <div className="fare-class__container row gap-2 items-stretch">
      <Skeleton.List Skeleton={FareClassSkeleton} loading={!!isLoading} amount={2}>
        <>
          {sortedFareClassList.map(fareClass => (
            <div className="cell-11 cell-lg-6" key={fareClass.code}>
              <FareClassItem
                excludeFeatures={commonFareFeatures}
                fareClass={fareClass}
                selected={selectedClass === fareClass.code}
                onClick={() => {
                  selectFareClass(fareClass)
                }}
                media={media?.flatMap(item => fareClassUtils.getMedia(item, fareClass.code))}
              />
            </div>
          ))}
        </>
      </Skeleton.List>
    </div>
  )
}

export default FareClassesSelection
