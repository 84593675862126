import bookingAPI, {
  BookingByIdRequest,
  FindBookingRequest,
  BookingResponse,
  BookingConfirmationResponse,
  BookingCancellationRequest,
  BookingCancellationResponse,
  BookingQrResponse,
  BookingWalletResponse,
  BookingConfirmationRequest,
} from '@api/booking'
import { makeLoader } from '@lib/loader'

interface UseBookingByIdLoaderParams {
  bookingId: string
  params: BookingByIdRequest
}

interface UseBookingConfirmationLoaderParams {
  bookingId: string
  params: BookingConfirmationRequest
}

interface UseBookingQrLoaderParams {
  enabled: boolean
  bookingId: string
}

interface UseBookingQrLoaderParams {
  enabled: boolean
  bookingId: string
}

export const useBookingByIdLoader = makeLoader<UseBookingByIdLoaderParams, BookingResponse>({
  key: 'bookingById',
  enabled: ({ bookingId }) => !!bookingId,
  loader: async ({ bookingId, params }) => await bookingAPI.loadById(bookingId, params),
})

export const useConfirmationLoader = makeLoader<UseBookingConfirmationLoaderParams, BookingConfirmationResponse>({
  key: 'bookingConfirmation',
  enabled: ({ bookingId }) => !!bookingId,
  loader: async ({ bookingId, params }) => await bookingAPI.confirmation(bookingId, params),
})

export const useBookingQrLoader = makeLoader<UseBookingQrLoaderParams, BookingQrResponse>({
  key: 'bookingQr',
  enabled: ({ enabled, bookingId }) => enabled && !!bookingId,
  loader: async ({ bookingId }) => await bookingAPI.loadQrCodes(bookingId),
})

export const useBookingWalletLoader = makeLoader<UseBookingQrLoaderParams, BookingWalletResponse>({
  key: 'bookingWallet',
  enabled: ({ enabled, bookingId }) => enabled && !!bookingId,
  loader: async ({ bookingId }) => await bookingAPI.loadGoogleWallet(bookingId),
})

export const useFindBookingLoader = makeLoader<FindBookingRequest, BookingResponse>({
  key: 'bookingFind',
  enabled: ({ email, distribusionBookingNumber, lastName }) => !!email && !!distribusionBookingNumber && !!lastName,
  loader: async params => await bookingAPI.find(params),
})

export const useBookingCancelLoader = makeLoader<BookingCancellationRequest, BookingCancellationResponse>({
  key: 'bookingCancel',
  enabled: () => false,
  loader: async ({ bookingId }) => await bookingAPI.cancel(bookingId),
})
