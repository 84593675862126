import { addMonths, endOfMonth, isAfter, isBefore, startOfDay } from 'date-fns'
import { Field, useFormikContext } from 'formik'
import React, { ReactElement } from 'react'

import { SearchFormState } from '@components/SearchForm'
import usePriceCalendar from '@components/SearchForm/hooks/usePriceCalendar'
import config from '@config'
import useValidators from '@hooks/useValidators'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import DatePickerField from '@ui/DatePicker/Field'

interface DatesFieldSetProps {
  isDepartureDateDisabled?: (date: Date) => boolean
}

const minDate = new Date()
const maxDate = addMonths(minDate, config.searchBarDateRange)

const DatesFieldSet = (props: DatesFieldSetProps): ReactElement => {
  const { t } = useTranslation()
  const { required } = useValidators()
  const {
    values: { departureDate, returnDate, isReturnTrip },
    setFieldValue,
  } = useFormikContext<SearchFormState>()
  const isOutboundDisabled = (date: Date): boolean =>
    isBefore(date, startOfDay(new Date())) || isAfter(date, endOfMonth(maxDate))
  const isReturnDisabled = (date: Date): boolean =>
    isBefore(date, startOfDay(departureDate)) || isAfter(date, endOfMonth(maxDate))

  const { isDepartureDateDisabled = isOutboundDisabled } = props
  const { outbound, inbound } = usePriceCalendar()

  const resetReturnDate = (departureDate: Date): void => {
    if (returnDate && isBefore(returnDate, startOfDay(departureDate))) {
      setFieldValue('returnDate', departureDate)
    }
  }

  return (
    <div className={bem('search-form', 'dates')}>
      {!isReturnTrip ? (
        <Field
          component={DatePickerField}
          name="departureDate"
          isTileDisabled={isDepartureDateDisabled}
          label={t('searchBar.departureDateLabel')}
          minDate={minDate}
          maxDate={maxDate}
          prices={outbound.loader.data}
          isPriceLoading={outbound.loader.isLoading}
          onToggle={outbound.setEnabled}
        />
      ) : (
        <div className="search-form__compound-date-select">
          <Field
            component={DatePickerField}
            name="departureDate"
            isTileDisabled={isDepartureDateDisabled}
            label={t('searchBar.departureDateLabel')}
            minDate={minDate}
            maxDate={maxDate}
            onChange={resetReturnDate}
            prices={outbound.loader.data}
            isPriceLoading={outbound.loader.isLoading}
            onToggle={outbound.setEnabled}
          />
          <Field
            component={DatePickerField}
            validate={required}
            name="returnDate"
            withIcon={false}
            initialValue={departureDate}
            isTileDisabled={isReturnDisabled}
            label={t('searchBar.returnDateLabel')}
            minDate={minDate}
            maxDate={maxDate}
            prices={inbound.loader.data}
            isPriceLoading={inbound.loader.isLoading}
            onToggle={inbound.setEnabled}
          />
        </div>
      )}
    </div>
  )
}

export default DatesFieldSet
