import { useFormikContext } from 'formik'
import React, { ReactElement } from 'react'

import { useTranslation } from '@lib/i18n'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { Button } from '@ui'

interface HeaderProps {
  label: string
  onRemove?: () => void
  removeDisabled: boolean
}

const Header = ({ label, onRemove, removeDisabled }: HeaderProps): ReactElement => {
  const { t } = useTranslation()
  const {
    values: { passengers },
  } = useFormikContext<CheckoutFormData>()

  return (
    <div className="row space-between ">
      <h4 className="mb-0">{label}</h4>
      {passengers.length > 1 && !removeDisabled && (
        <Button variant="text" color="secondary" onClick={onRemove}>
          {t('checkout.passengerDetails.remove')}
        </Button>
      )}
    </div>
  )
}

export default Header
