import GooglePayElement from '@adyen/adyen-web/components/GooglePay'
import Core from '@adyen/adyen-web/core'
import React, { useCallback, useMemo } from 'react'

import utils from '@lib/utils'
import useAdyenPayButtonMethod, {
  PayButtonInstanceParams,
} from '@pages/Checkout/hooks/Payment/Adyen/useAdyenPayButtonMethod'
import PaymentLabel from '@pages/Checkout/Payment/Label'

export const useGooglePay = (adyen?: Core): AdyenTypes.Method => {
  const buildInstance = useCallback(({ validate, onSubmit, adyen }: PayButtonInstanceParams) => {
    return adyen?.create('googlepay', {
      buttonType: 'pay',
      buttonSizeMode: 'fill',
      buttonColor: 'black',
      configuration: {
        gatewayMerchantId: 'DistribusionTechnologies',
        merchantId: 'BCR2DN4T5TY3D3CF',
      },
      onSubmit,
      onClick: utils.function.toPromiseLike(validate),
    }) as GooglePayElement
  }, [])

  const props = useAdyenPayButtonMethod(buildInstance, adyen)

  const getOption = useCallback(
    () => ({
      value: 'google_pay',
      label: <PaymentLabel type="googlePay" />,
      content: <div style={{ margin: 0 }} id="three-d-secure-iframe-portal"></div>,
    }),
    [],
  )

  return useMemo(() => ({ ...props, getOption }), [getOption, props])
}
