import { useState } from 'react'

import utils from '@lib/utils'

interface Result {
  selectedSeats: Seat.Entry[][]
  toggleSeat: (seat: Seat.Entry, segment: number) => void
  reset: () => void
}

export const useSeatsSelection = (): Result => {
  const [selectedSeats, setSelectedSeats] = useState<Seat.Entry[][]>([])

  const toggleSeat = (seat: Seat.Entry, segment: number) => {
    setSelectedSeats({ ...selectedSeats, [segment]: utils.array.toggle(seat, selectedSeats[segment] ?? []) })
  }

  return { selectedSeats, toggleSeat, reset: () => setSelectedSeats([]) }
}
