import { isBefore } from 'date-fns'

import { Sorting } from '@enums'
import helpers, { RouteEvent } from '@lib/analytics/amplitude/helpers'
import paramsStore from '@stores/params'

const { sendEvent, getRouteParams, getConnectionParams } = helpers

export interface SearchEvent extends RouteEvent {
  departureDate: string
  returnDate: string | null
  departureTime: string | null
  pax: number
}

export interface SearchProps {
  departure: Location.NamedItem
  arrival: Location.NamedItem
}

const getSearchParams = ({ departure, arrival }: SearchProps): SearchEvent => {
  const params = paramsStore.get()

  return {
    ...getRouteParams(departure, arrival),
    departureDate: params.departureDate,
    returnDate: params.returnDate,
    departureTime: params.departureTime,
    pax: params.pax,
  }
}

export interface LoadResultsProps extends SearchProps {
  resultCount: number
  isInitialSearch: boolean
}

const load = ({ isInitialSearch, resultCount, ...rest }: LoadResultsProps): void => {
  const params = getSearchParams(rest)
  sendEvent('load-search-results', { ...params, isInitialSearch, resultCount }, { measurePerformance: 'page' })
}

const getConnectionPosition = (connection: Connection, connections: Connection[]): Record<string, number> => ({
  position: connections.findIndex(item => item.id === connection.id) + 1,
})

const clickDetails = (connection: Connection, connections: Connection[]): void => {
  sendEvent('click-details', {
    ...getConnectionParams(connection, connection.cheapestFareClassCode),
    ...getConnectionPosition(connection, connections),
  })
}

const clickTrip = (connection: Connection, fareClass: string, connections: Connection[]): void => {
  sendEvent('click-trip', {
    ...getConnectionParams(connection, fareClass),
    ...getConnectionPosition(connection, connections),
  })
}

const clickCalendar = (currentDate: Date, selectedDate: Date): void => {
  sendEvent('use-calendar', { daySelected: isBefore(currentDate, selectedDate) ? '-1' : '+1' })
}

const clickSort = (sortingOption: Sorting): void => {
  sendEvent('sort-by', { sortingOption })
}

const clickFilter = (filter: Record<string, string[]>): void => {
  const [[key, value]] = Object.entries(filter)

  sendEvent('apply-filter', { filterName: key, filterOption: value })
}

export default {
  load,
  clickDetails,
  clickTrip,
  clickCalendar,
  clickSort,
  clickFilter,
}
