import date from '@lib/date'
import paramsUtils from '@lib/params'
import url from '@lib/url'
import { FullParams } from '@lib/url/checkout'
import utils from '@lib/utils'

const payment = (params: FullParams): string => {
  const parameters = utils.object.compact({
    ...paramsUtils.getPersistentUrlParams(params),
    departureDate: date.formatDate(params.departureTime),
    departureTime: date.formatTime(params.departureTime),
    departureStation: params.departureStationCode,
    arrivalDate: date.formatDate(params.arrivalTime),
    arrivalTime: date.formatTime(params.arrivalTime),
    arrivalStation: params.arrivalStationCode,
    fareClass: params.fareClass,
    passengers: params.passengers,
    pax: params.pax,
    currency: params.currency,
    returnDepartureDate: params.returnDepartureTime ? date.formatDate(params.returnDepartureTime) : null,
    returnDepartureTime: params.returnDepartureTime ? date.formatTime(params.returnDepartureTime) : null,
    returnArrivalDate: params.returnArrivalTime ? date.formatDate(params.returnArrivalTime) : null,
    returnArrivalTime: params.returnArrivalTime ? date.formatTime(params.returnArrivalTime) : null,
    seats: params.seats && utils.object.compact(params.seats),
    deviceId: params.deviceId,
    mode: params.mode,
    parentDomain: params.parentDomain,
  })

  return url.build(['/', 'booking', `${params.bookingId as string}`, 'payment'], parameters)
}

export default {
  payment,
}
