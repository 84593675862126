import React, { ReactElement, useEffect } from 'react'

import { useCarrierLoader } from '@loaders/carrier'
import { useParams } from '@stores/params'

interface CarrierLoaderProps {
  marketingCarrierCode: string
  onLoading: (code: string, loading: boolean) => void
  onSuccess: (carrier: MarketingCarrier) => void
}

const CarrierLoader = ({ marketingCarrierCode, onLoading, onSuccess }: CarrierLoaderProps): ReactElement | null => {
  const [{ locale, currency }] = useParams()

  const { isLoading } = useCarrierLoader(
    { marketingCarrierCode, params: { currency, locale } },
    {
      onSuccess: data => {
        onSuccess(data)
      },
    },
  )

  useEffect(() => {
    onLoading(marketingCarrierCode, isLoading)
  }, [marketingCarrierCode, isLoading, onLoading])

  return <></>
}

export default CarrierLoader
