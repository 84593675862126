import parse from 'html-react-parser'
import { ReactNode } from 'react'

const URL_REGEX = /((?:(?:https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|$!:,.;]+[A-Z0-9+&@#/%=~_|$]))(?![^<]*<\/a>)/gim

const format = (text: string): ReactNode => {
  const createLink = (link: string): string => `<a rel='noreferrer' target='_blank' href=${link}>${link}</a>`

  return parse(text.replace(URL_REGEX, createLink))
}

export default {
  format,
}
