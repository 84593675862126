const DEFAULT_ICON = 'percent'

const ICONS: Record<string, string> = {
  PROMOCODE: 'gift',
  CORPORATECODE: 'percent',
  default: DEFAULT_ICON,
}

const getIcon = (code: string): string => ICONS[code] ?? ICONS.default

// ToDo: Remove this hardcoded logic inn the future
const ALTERNATIVE: Record<string, string> = {
  ANTT_Senior_100: 'ANTT_Senior_50',
}

const discountUtils = {
  getIcon,
  ALTERNATIVE,
}

export default discountUtils
