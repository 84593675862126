import { eachDayOfInterval, endOfMonth, getDate, getDay, isSameDay, isToday, startOfMonth, isSameMonth } from 'date-fns'
import React, { ReactElement, ReactNode, useEffect, useMemo, useRef } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import currencyUtils from '@lib/currency'
import dateUtils from '@lib/date'
import { useTranslation } from '@lib/i18n'
import { Skeleton } from '@ui/index'

import '@ui/Calendar/index.scss'

export interface CalendarProps {
  calendarDate: Date
  selectedDate: Date | null
  onDateChange: (date: Date) => void
  renderHeader?: (date: Date, title: string) => ReactNode
  getTileText?: (date: Date) => ReactNode
  isTileDisabled?: (date: Date) => boolean
  prices?: PriceCalendar
  isPriceLoading?: boolean
}

const Calendar = ({
  calendarDate,
  selectedDate,
  onDateChange,
  renderHeader,
  isTileDisabled,
  getTileText,
  prices,
  isPriceLoading = false,
}: CalendarProps): ReactElement => {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement | null>(null)
  const isMobile = useIsMobile()

  const placeholder = currencyUtils.create(760, 'EUR').format()
  const weekDays: string[] = t('calendar.days', { returnObjects: true, defaultValue: [] })
  const monthTitle = useMemo(() => dateUtils.formatYearMonth(calendarDate), [calendarDate])
  const availableDays = useMemo(() => {
    const interval = { start: startOfMonth(calendarDate), end: endOfMonth(calendarDate) }

    return eachDayOfInterval(interval)
  }, [calendarDate])

  const previousMonthOffset = useMemo(() => {
    const dayOfWeek = getDay(startOfMonth(calendarDate)) || 7

    return dayOfWeek - 1
  }, [calendarDate])

  const emptyTiles = useMemo(
    () =>
      [...Array(previousMonthOffset).keys()].map(key => <div key={key} className={bem('tile', { disabled: true })} />),
    [previousMonthOffset],
  )

  const getTileClasses = (date: Date): string => {
    const today = isToday(date)
    const selected = selectedDate != null ? isSameDay(date, selectedDate) : false

    return bem('tile', { selected, today })
  }

  useEffect(() => {
    const date = selectedDate ?? new Date()

    isSameMonth(date, calendarDate) && isMobile && ref.current?.scrollIntoView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPrice = (date: Date): Money | null => prices?.[dateUtils.formatDate(date)] ?? null
  const isBetween = (price: number, range: number[]): boolean => price >= range[0] && price < range[1]

  const formatPrice = (price: Money): string | number => {
    const currency = currencyUtils.create(price.fractional, price.currency)
    const value = currency.dollars()
    const divider = 1000

    if (isBetween(value, [0, 1])) return currency.format()
    if (isBetween(value, [1, 1000])) return value
    if (isBetween(value, [1000, 10000])) return (value / divider).toFixed(1).concat('k')

    return (value / divider).toFixed(0).concat('k')
  }

  return (
    <div className={bem('ui-calendar')} ref={ref}>
      <div className={bem('ui-calendar', 'header')}>
        {renderHeader != null ? renderHeader(calendarDate, monthTitle) : monthTitle}
      </div>
      <div className={bem('ui-calendar', 'tiles')}>
        {weekDays.map(day => (
          <div key={day} className={bem('ui-calendar', 'week-day')}>
            {day}
          </div>
        ))}
        {emptyTiles}
        {availableDays.map((date: Date) => {
          const price = getPrice(date)
          return (
            <button
              type="button"
              key={date.getTime()}
              className={getTileClasses(date)}
              onClick={() => {
                onDateChange(date)
              }}
              disabled={isTileDisabled?.(date)}
            >
              <div className={bem('tile', 'day')}>{getDate(date)}</div>
              <div className={bem('tile', 'text')}>{getTileText?.(date)}</div>
              <Skeleton.Text placeholder={placeholder} loading={isPriceLoading}>
                {price && (
                  <div className={bem('tile', 'price')}>
                    <span className={bem('tile', 'price-symbol')}>{currencyUtils.getSymbol(price.currency)}</span>
                    <span>{formatPrice(price)}</span>
                  </div>
                )}
              </Skeleton.Text>
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default Calendar
