import { FormikErrors } from 'formik'
import React, { ReactElement, useState } from 'react'
import { UseQueryResult } from 'react-query'

import { DiscountCardsResponse } from '@api/discountCards'
import PassengersDiscounts from '@components/PassengersDropdown/PassengersDiscounts'
import PassengersList from '@components/PassengersList'
import { useTranslation } from '@lib/i18n'
import { Tabs } from '@ui'

interface PassengersTabsProps {
  label?: string
  value: Passenger.Param[]
  availableTypes?: Passenger.Type[] | null
  onChange: (value: Passenger.Param[]) => void
  getMaxCount?: ((totalCount: number) => number) | null
  errors?: FormikErrors<{ passengers: string }>
  discounts?: UseQueryResult<DiscountCardsResponse>
  showDiscounts?: boolean
  showAge?: boolean
}

const PassengersTabs = (props: PassengersTabsProps): ReactElement => {
  const { t } = useTranslation()
  const { discounts, showDiscounts, showAge, ...rest } = props
  const [activeIndex, setActiveIndex] = useState<number>(1)

  const options = [
    { key: 1, label: t('searchBar.passengers.label') },
    { key: 2, label: t('searchBar.passengers.discounts') },
  ]

  return (
    <div className="passenger-dropdown__tabs">
      {showDiscounts && <Tabs options={options} value={activeIndex} onClick={setActiveIndex} />}
      {activeIndex === 1 && <PassengersList {...props} />}
      {activeIndex === 2 && <PassengersDiscounts passengers={rest.value} discounts={discounts} showAge={showAge} />}
    </div>
  )
}

export default PassengersTabs
