import ArrivalDate from '@components/Connection/ArrivalDate'
import ArrivalDateTime from '@components/Connection/ArrivalDateTime'
import ArrivalTime from '@components/Connection/ArrivalTime'
import DayShift from '@components/Connection/DayShift'
import DepartureDate from '@components/Connection/DepartureDate'
import DepartureDateTime from '@components/Connection/DepartureDateTime'
import DepartureTime from '@components/Connection/DepartureTime'
import Duration from '@components/Connection/Duration'

export default {
  DepartureTime,
  DepartureDate,
  DepartureDateTime,
  ArrivalTime,
  ArrivalDate,
  ArrivalDateTime,
  Duration,
  DayShift,
}
