import React, { ReactElement, useState } from 'react'

import CurrencyDropdown from '@components/CurrencyDropdown'
import BurgerButton from '@components/Header/BurgerButton'
import MenuItem from '@components/Header/Item'
import ManageBookingButton from '@components/Header/ManageBookingButton'
import TrackMyTripButton from '@components/Header/TrackMyTripButton'
import LocaleDropdown from '@components/LocaleDropdown'
import Logo from '@components/Logo'
import SidebarMenu from '@components/SidebarMenu'
import useDistribusionBranding from '@hooks/useDistribusionBranding'
import useIsMobile from '@hooks/useIsMobile'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

import '@components/Header/index.scss'

const isVisible = <T,>(data: T[], mode: PageMode = 'page'): boolean => data.length > 1 && mode === 'page'

interface HeaderProps {
  currencyChangeAllowed?: boolean
}

const MobileHeader = ({ currencyChangeAllowed = true }: HeaderProps): ReactElement => {
  const [sidebarOpened, setSidebarOpened] = useState(false)
  const [{ currency, locale, manageBookings }] = useSettings()
  const showCurrency = isVisible(currency.supported) && currencyChangeAllowed
  const showLocale = isVisible(locale.supported)
  const isBurgerVisible = showLocale || showCurrency
  const showDistribusionBranding = useDistribusionBranding()

  return (
    <>
      <SidebarMenu
        opened={sidebarOpened}
        onClose={() => {
          setSidebarOpened(false)
        }}
        showCurrency={showCurrency}
        showLocale={showLocale}
        showManageBookingsLink={manageBookings.enabled}
      />
      <div id="header" className="row items-center header__content">
        {isBurgerVisible && (
          <div className="cell-1 burger-button">
            <BurgerButton
              onClick={() => {
                setSidebarOpened(true)
              }}
            />
          </div>
        )}
        <div className="cell-11">
          <Logo showDistribusionBranding={showDistribusionBranding} />
        </div>
      </div>
    </>
  )
}

const DesktopHeader = ({ currencyChangeAllowed = true }: HeaderProps): ReactElement => {
  const [{ mode }] = useParams()
  const [{ currency, locale, manageBookings, menu, trackTrip }] = useSettings()
  const showCurrency = isVisible(currency.supported, mode) && currencyChangeAllowed
  const showLocale = isVisible(locale.supported, mode)
  const showDistribusionBranding = useDistribusionBranding()

  return (
    <div id="header" className="row items-center">
      {mode === 'page' && (
        <>
          <div className="cell-2">
            <Logo showDistribusionBranding={showDistribusionBranding} />
          </div>
          <div className="header-menu-items">
            {trackTrip.enabled && <TrackMyTripButton />}
            {menu.items.map(item => (
              <div className="cell header-menu" key={item.translationKey}>
                <MenuItem {...item} level={0} />
              </div>
            ))}
          </div>
          <div className="cell"></div>
          {showCurrency && (
            <div className="cell-1">
              <CurrencyDropdown />
            </div>
          )}
          {showLocale && (
            <div className="cell-1">
              <LocaleDropdown />
            </div>
          )}
          {manageBookings.enabled && <ManageBookingButton />}
        </>
      )}
    </div>
  )
}

const Header = (props: HeaderProps): ReactElement => {
  const isMobile = useIsMobile()

  return isMobile ? <MobileHeader {...props} /> : <DesktopHeader {...props} />
}

export default Header
