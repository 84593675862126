import React from 'react'

import { Icon } from '@ui'

interface Props {
  file: File
  onRemove: () => void
}

const AttachedFile = ({ file, onRemove }: Props) => {
  return (
    <div className="row gap-2">
      <div className="cell no-grow">
        <Icon name="attachment" size="large" />
      </div>
      <div className="cell ui-file-upload__file-name">{file.name}</div>
      <Icon className="ui-file-upload__remove" name="cross" size="large" onClick={onRemove} />
    </div>
  )
}

export default AttachedFile
