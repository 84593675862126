export enum Sorting {
  DepartureTime = 'departure_time',
  ArrivalTime = 'arrival_time',
  Price = 'price',
  Transfers = 'transfers',
  Duration = 'duration',
}

export enum Filter {
  DirectOnly = 'direct_only',
  DepartureTime = 'departure_time',
  FareClass = 'fare_classes',
  Services = 'services',
  DepartureStation = 'departure_stations',
  ArrivalStation = 'arrival_stations',
  TrainType = 'train_type',
  Price = 'price',
  Carriers = 'marketing_carriers',
}

export enum IdFields {
  Type = 'document_type',
  Citizenship = 'citizenship',
  Location = 'location_of_issue',
  ExpirationDate = 'expiration_date',
}

export enum PaymentMethodStatus {
  Pending,
  Ready,
  Rejected,
}
