import React, { ReactElement, useEffect } from 'react'
import { useTimer } from 'react-timer-hook'

import Loader from '@components/Loader'
import { useTranslation } from '@lib/i18n'

import '@pages/Checkout/Timer/index.scss'

export interface TimerConfig {
  isStopped?: boolean
  onExpire?: () => void
  expiryTimestamp: Date
}

const Timer = ({ isStopped, onExpire, expiryTimestamp }: TimerConfig): ReactElement => {
  const { t } = useTranslation()

  const { seconds, minutes, pause } = useTimer({
    expiryTimestamp,
    onExpire,
  })

  useEffect(() => {
    if (isStopped) pause()
  }, [isStopped, pause])

  return (
    <div className="timer-message">
      <div className="timer-message__loader">
        <Loader loading={true} />
      </div>
      {t('checkout.reservationModal.expiresAt', {
        minutes: ('0' + String(minutes)).slice(-2),
        seconds: ('0' + String(seconds)).slice(-2),
      })}
    </div>
  )
}

export default Timer
