import makeApi from '@lib/api'

export interface PopularConnectionsResponse {
  connections: PopularConnection[]
}

export interface PopularConnectionsRequest {
  retailerPartnerNumber: number | null
  marketingCarrierCode: string | null
  locale?: Locale
  currency?: Currency
}

export default {
  load: makeApi.get<PopularConnectionsRequest, PopularConnectionsResponse>('/popular_connections'),
}
