import cn from 'classnames'
import React, { ReactElement } from 'react'

import bem from '@lib/bem'
import Icon from '@ui/Icon'

interface IconTextProps {
  label: string
  iconName: string
  className?: string
}

const IconText = ({ label, iconName, className }: IconTextProps): ReactElement => (
  <span className={cn('ui-icon-text', 'row', className)}>
    <Icon name={iconName} size="medium" className="pr-2" />
    <span className={bem('ui-icon-text', 'label')}>{label}</span>
  </span>
)

export default IconText
