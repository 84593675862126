import { Sorting } from '@enums'

type SortingFunction = (a: Connection, b: Connection) => number

const SORTING_MAP: Record<Sorting, SortingFunction> = {
  [Sorting.Price]: (a, b) => a.cheapestTotalAdultPrice.fractional - b.cheapestTotalAdultPrice.fractional,
  [Sorting.ArrivalTime]: (a, b) => Number(new Date(a.arrivalTime)) - Number(new Date(b.arrivalTime)),
  [Sorting.DepartureTime]: (a, b) => Number(new Date(a.departureTime)) - Number(new Date(b.departureTime)),
  [Sorting.Transfers]: (a, b) =>
    a.segments.length !== b.segments.length
      ? a.segments.length - b.segments.length
      : SORTING_MAP[Sorting.DepartureTime](a, b),
  [Sorting.Duration]: (a, b) => a.duration - b.duration,
}

const sortConnectionList = (list: Connection[], value: Sorting | null): Connection[] => {
  if (!value) return list

  /* istanbul ignore next default value returned */
  const sortingFn = SORTING_MAP[value] ?? (() => 0)

  return [...list].sort(sortingFn)
}

const sortBy = <T>(items: T[], key: keyof T, target?: string): T[] =>
  items.sort((a, b) =>
    target ? (a[key] === target && b[key] !== target ? -1 : 1) : (a[key] as string).localeCompare(b[key] as string),
  )

export default {
  sortConnectionList,
  sortBy,
}
