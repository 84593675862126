import script from '@lib/script'

declare global {
  interface Window {
    dftp: {
      init: (options: Record<string, unknown>) => void
      validateIntegration: () => void
      profileCompleted: () => Promise<void>
    }
  }
}

const SCRIPT_URL = 'https://d3fgm6ry1r2mlx.cloudfront.net/s/847368/dIMvFo.js'

const init = async (reference: string): Promise<void> => {
  const options = {
    attemptReference: reference,
    sensitiveFields: ['cvv', 'cnn'],
  }

  void script
    .reload(SCRIPT_URL, tag => {
      tag.crossOrigin = 'use-credentials'
    })
    .then(() => {
      window.dftp.init(options)
      window.dftp.validateIntegration()
    })
}

const profile = async (): Promise<void> => {
  if (window.dftp == null) {
    return
  }

  try {
    await window.dftp.profileCompleted()
  } catch {
    // just ignore
  }
}

const nethone = {
  init,
  profile,
}

export default nethone
