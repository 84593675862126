const createExternalScript = () => {
  const script = document.createElement('script')
  script.src = 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js'
  script.type = 'text/javascript'
  script.charset = 'UTF-8'
  script.setAttribute('data-domain-script', 'bbbb1a91-9eb5-4701-a6f0-a1650628c5e6')

  return script
}

const createInternalScript = () => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.text = 'function OptanonWrapper() { }'

  return script
}

const insertScripts = () => {
  const external = createExternalScript()
  const internal = createInternalScript()

  document.head.prepend(external)
  document.head.insertBefore(internal, external.nextSibling)
}

const onetrust = {
  insertScripts,
}

export default onetrust
