import { setNestedObjectValues, useFormikContext } from 'formik'
import { useCallback } from 'react'

const useRunFormValidation = () => {
  const { validateForm, setTouched, setSubmitting } = useFormikContext()

  return useCallback(async (): Promise<void> => {
    setSubmitting(true)
    const errors = await validateForm()
    const isValid = Object.keys(errors).length === 0
    // added setTimeout in order to have at least 1 render with isSubmitting === true
    setTimeout(() => setSubmitting(false))

    if (!isValid) {
      setTouched(setNestedObjectValues(errors, true))
      await Promise.reject(new Error('invalid form fields'))
    }
    await Promise.resolve()
  }, [setSubmitting, setTouched, validateForm])
}

export default useRunFormValidation
