import React, { ReactElement } from 'react'

import { VGS } from '@lib/vgs'
import VGSField from '@pages/Checkout/Payment/CreditCardForm/VGS/Field'

interface CVCFieldProps {
  form: VGS.Form
  name: string
  placeholder: string
  touched?: boolean
}

const CVCField = ({ form, name, placeholder, touched }: CVCFieldProps): ReactElement => {
  return (
    <VGSField
      id="vgs-cvc"
      form={form}
      touched={touched}
      options={{
        name,
        placeholder,
        type: 'card-security-code',
        showCardIcon: true,
        validations: ['required', 'validCardSecurityCode'],
        tokenization: { format: 'NUM_LENGTH_PRESERVING', storage: 'VOLATILE' },
      }}
    />
  )
}

export default CVCField
