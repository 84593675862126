import { eachMonthOfInterval } from 'date-fns'
import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import { useTranslation } from '@lib/i18n'
import { Button, Calendar, Modal } from '@ui'

import '@ui/DatePicker/MenuMobile/index.scss'

export interface DatePickerMenuMobileProps {
  opened: boolean
  onClose: () => void
  title: ReactNode
  value: Date | null
  onChange: (value: Date) => void
  minDate: Date
  maxDate: Date
  getTileText?: (date: Date) => ReactNode
  isTileDisabled?: (date: Date) => boolean
  prices?: PriceCalendar
  isPriceLoading?: boolean
}

const DatePickerMenuMobile = ({
  title,
  value,
  onChange,
  minDate,
  maxDate,
  opened,
  onClose,
  ...calendarProps
}: DatePickerMenuMobileProps): ReactElement => {
  const { t } = useTranslation()
  const [selectedDate, setSelectedDate] = useState<Date | null>(value)

  useEffect(() => {
    setSelectedDate(value)
  }, [value])

  const submitDate = useCallback(
    (date: Date): void => {
      onChange(date)
      onClose()
    },
    [onChange, onClose],
  )

  const footer = useMemo(
    () =>
      selectedDate && (
        <div className="ui-date-picker-modal__footer">
          <Button
            onClick={() => {
              submitDate(selectedDate)
            }}
          >
            {t('ui.datePicker.select')}
          </Button>
        </div>
      ),
    [selectedDate, submitDate, t],
  )

  return (
    <Modal className="ui-date-picker-modal" opened={opened} onClose={onClose} title={title} footer={footer} fullScreen>
      <div className="ui-date-picker-modal__content">
        {eachMonthOfInterval({ start: minDate, end: maxDate }).map((date: Date) => (
          <Calendar
            calendarDate={date}
            selectedDate={selectedDate}
            onDateChange={setSelectedDate}
            key={date.getTime()}
            {...calendarProps}
          />
        ))}
      </div>
    </Modal>
  )
}

export default DatePickerMenuMobile
