import { useFormikContext } from 'formik'
import React, { ReactElement } from 'react'

import Price from '@pages/Checkout/BookingDetails/Price'
import DefaultPayButton from '@pages/Checkout/BookingDetails/SubmitSection/PayButton/Default'
import Voucher from '@pages/Checkout/BookingDetails/Voucher'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useFinalPrice } from '@pages/Checkout/hooks/usePrice'
import { useSettings } from '@queries/settings'
import { useCheckout } from '@stores/checkout'

import '@pages/Checkout/BookingDetails/SubmitSection/index.scss'

const SubmitSection = (): ReactElement => {
  const [{ paymentInstance }] = useCheckout()
  const [{ discountVoucher }] = useSettings()
  const {
    values: { isReservationLoading, isVacancyLoading },
  } = useFormikContext<CheckoutFormData>()
  const isPriceLoading = isReservationLoading || isVacancyLoading
  const price = useFinalPrice()
  const showPayButton = !isPriceLoading && price && price.fractional > 0
  const showConfirmButton = !isPriceLoading && price && price.fractional <= 0

  return (
    <div className="submit-section">
      {discountVoucher.enabled && (
        <div className="mb-3">
          <Voucher />
        </div>
      )}
      <Price price={price} />
      <div data-tag="pay">
        {isPriceLoading && <DefaultPayButton disabled />}
        {showPayButton && (paymentInstance?.getPayButton?.() ?? <DefaultPayButton />)}
        {showConfirmButton && <DefaultPayButton confirm />}
      </div>
    </div>
  )
}

export default SubmitSection
