import makeApi from '@lib/api'

export interface DiscountCardsRequest {
  locale: Locale
  retailerPartnerNumber: RetailerNumber
  marketingCarrierCodes?: CarrierCode[]
  types?: DiscountCard.Type[]
}

export type DiscountCardsResponse = DiscountCard.Item[]

export default {
  load: makeApi.get<DiscountCardsRequest, DiscountCardsResponse>({
    type: 'discountCards',
    old: '/cards',
    new: '/discount_cards',
  }),
}
