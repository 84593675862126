import { FieldArray, useFormikContext } from 'formik'
import React, { ReactElement, useMemo } from 'react'

import useValidators from '@hooks/useValidators'
import amplitude from '@lib/analytics/amplitude'
import { useTranslation } from '@lib/i18n'
import passengerUtils from '@lib/passengers'
import { useDiscountCardsLoader } from '@loaders/discountCards'
import { useInitialCheckoutPassenger } from '@pages/Checkout/hooks/useInitialCheckoutPassenger'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import PassengerFieldset from '@pages/Checkout/PassengerDetails/PassengerFieldset'
import { useSettings } from '@queries/settings'
import { PassengerData, useCheckout } from '@stores/checkout'
import { useParams } from '@stores/params'
import { Button, Icon } from '@ui'

const getMaxAge = (type: string, supported?: Passenger.Type[]): number | undefined =>
  supported?.find(({ code }) => code === type)?.maxAge

const PassengerDetails = (): ReactElement => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<CheckoutFormData>()
  const [{ outbound }] = useCheckout()
  const [
    {
      reservation,
      passengerTypesList,
      fareClasses: { displayOn },
      paxDiscountCodes,
    },
  ] = useSettings()
  const [{ express, retailerPartnerNumber, marketingCarrierCode, locale }, setParams] = useParams()
  const getInitialPassenger = useInitialCheckoutPassenger()
  const { passengers } = useValidators()
  const isConnectionsLoading = !outbound

  const passengerTypes = passengerUtils.sortPassengers(
    outbound?.marketingCarrier.passengerTypes ?? /* istanbul ignore next */ [],
  )
  const passengerList = useMemo(() => passengerUtils.getPassengerList(values.passengers), [values.passengers])
  const isFareClassesEnabled = displayOn === 'checkout' || displayOn === 'everywhere'
  const showDiscounts =
    paxDiscountCodes.enabled &&
    (paxDiscountCodes.displayOn === 'checkout' || paxDiscountCodes.displayOn === 'everywhere')

  const { isLoading, data: cards } = useDiscountCardsLoader(
    {
      locale,
      retailerPartnerNumber,
      types: ['discount_card', 'loyalty'],
      ...(marketingCarrierCode && { marketingCarrierCodes: [marketingCarrierCode] }),
    },
    { enabled: showDiscounts },
  )

  const handleParams = (data: PassengerData[]): void => {
    if (!isFareClassesEnabled) return

    const supportedList = outbound?.marketingCarrier.passengerTypes
    const passengers = data.map(({ pax, maxAge, cards, type }) => ({
      type,
      pax,
      cards,
      maxAge: maxAge ?? getMaxAge(type, supportedList),
    }))
    const pax = passengerUtils.getPaxCount(passengers)

    setParams({ passengers, pax })
    setFieldValue('vacancies', [])
  }

  const addPassenger = (push: (params: PassengerData) => void): void => {
    const initialPassenger = getInitialPassenger()

    push(initialPassenger)
    amplitude.checkout.addPassenger(express)
    handleParams([...values.passengers, initialPassenger])
  }

  const removePassenger = (remove: (index: number) => void, index: number): void => {
    remove(index)
    amplitude.checkout.removePassenger(express)

    const passengers = [...values.passengers]
    passengers.splice(index, 1)
    handleParams(passengers)
  }

  const handleTypeChange = (type: string, index: number): void => {
    const { maxAge, minAge } = { ...passengerTypes.find(p => p.code === type) }
    handleParams(values.passengers.map((pax, i) => (index === i ? { ...pax, type, maxAge, minAge, cards: [] } : pax)))
  }

  const isRemovePassengerDisabled = reservation.enabled || passengerTypesList.enabled || isConnectionsLoading
  const hideTypes = reservation.enabled && passengerTypes.length === 1

  return (
    <div className="passenger-details">
      <h3 className="mb-5">{t('checkout.passengerDetails.title')}</h3>
      <FieldArray
        name="passengers"
        render={({ push, remove }) => (
          <>
            <div className="mb-5 column gap-5">
              {values.passengers.map(({ id }, index) => (
                <div className="cell passenger-form" key={id}>
                  <PassengerFieldset
                    cards={cards}
                    disableTypes={reservation.enabled}
                    hideTypes={hideTypes}
                    isCardLoading={isLoading}
                    isTypeLoading={isConnectionsLoading}
                    label={t('checkout.passengerDetails.passengerLabel', { index: index + 1 })}
                    name={`passengers.${index}`}
                    onRemove={() => removePassenger(remove, index)}
                    onTypeChange={type => handleTypeChange(type, index)}
                    outbound={outbound}
                    removeDisabled={isRemovePassengerDisabled}
                    types={passengerTypes}
                    validate={() => passengers(passengerList, retailerPartnerNumber)}
                    showDiscounts={showDiscounts}
                  />
                </div>
              ))}
            </div>
            {!reservation.enabled && (
              <Button
                color="secondary"
                variant="text"
                onClick={() => {
                  addPassenger(push)
                }}
                disabled={isConnectionsLoading}
              >
                <div className="row gap-1">
                  <Icon name="plus" size="large" />
                  <span>{t('checkout.passengerDetails.add')}</span>
                </div>
              </Button>
            )}
          </>
        )}
      />
    </div>
  )
}

export default PassengerDetails
