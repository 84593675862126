import React, { ReactElement } from 'react'

import { VGS } from '@lib/vgs'
import VGSField from '@pages/Checkout/Payment/CreditCardForm/VGS/Field'

interface NumberFieldProps {
  form: VGS.Form
  name: string
  placeholder: string
  touched?: boolean
}

const NumberField = ({ form, name, placeholder, touched }: NumberFieldProps): ReactElement => {
  return (
    <VGSField
      id="vgs-number"
      form={form}
      touched={touched}
      options={{
        name,
        placeholder,
        type: 'card-number',
        showCardIcon: true,
        validations: ['required', 'validCardNumber'],
        tokenization: { format: 'NUM_LENGTH_PRESERVING', storage: 'PERSISTENT' },
        addCardBrands: [{ type: 'unionpay', pattern: /^(62|81)/ }],
      }}
    />
  )
}

export default NumberField
