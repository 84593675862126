import { eachHourOfInterval, endOfDay, startOfDay } from 'date-fns'
import { Field, FieldProps } from 'formik'
import React, { ReactElement } from 'react'

import date from '@lib/date'
import { Dropdown } from '@ui'
import { DropdownItem } from '@ui/Dropdown'

const dayInterval = { start: startOfDay(new Date()), end: endOfDay(new Date()) }
const hoursInterval = eachHourOfInterval(dayInterval)

const timeOptions = hoursInterval.map(hour => ({
  value: date.formatTime(hour),
  selectedLabel: date.formatTime(hour),
}))

interface DepartureTimeFieldProps {
  name: string
  label: string
  options?: DropdownItem<string>[]
  forcedInitialValue?: string | null
}

const DepartureTimeField = ({ name, label, options, forcedInitialValue }: DepartureTimeFieldProps): ReactElement => (
  <Field name={name}>
    {({ field: { value, name }, meta: { error, touched }, form: { setFieldValue } }: FieldProps) => (
      <Dropdown
        value={value ?? forcedInitialValue}
        onChange={updatedValue => {
          setFieldValue(name, updatedValue)
        }}
        checkedMark
        label={label}
        items={options ?? timeOptions}
        errorMessage={touched ? error : undefined}
      />
    )}
  </Field>
)

export default DepartureTimeField
