import React, { ReactElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import faqImage from '@images/common/faq.jpg'
import bem from '@lib/bem'
import { Trans, useTranslation } from '@lib/i18n'
import url from '@lib/url'
import { useParams } from '@stores/params'
import { Button, Modal } from '@ui'

import '@components/Footer/Help/index.scss'

const Help = (): ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [params] = useParams()
  const [helpOpened, setHelpOpened] = useState(false)
  const [contactOpened, setContactOpened] = useState<boolean>(false)
  const email = t('footer.contactEmail', { defaultValue: '' }).split(/<email>|<\/email>/)[1]
  const closeModal = (): void => {
    setHelpOpened(false)
  }

  const redirectToLogin = (): void => {
    const { retailerPartnerNumber, marketingCarrierCode, locale } = params

    closeModal()
    navigate(url.build(['/', 'booking', 'login'], { retailerPartnerNumber, marketingCarrierCode, locale }))
  }

  return (
    <div className="column gap-3">
      <div className="column gap-2">
        <h4 className="mb-0">{t('footer.anyQuestions')}</h4>
        <div className="body-14">{t('footer.findAnswer')}</div>
      </div>
      <Button
        variant="outline"
        onClick={() => {
          setHelpOpened(true)
        }}
      >
        {t('footer.helpCenter')}
      </Button>
      <Modal
        opened={helpOpened}
        maxWidth="md"
        className={bem('page-footer', 'help-modal')}
        onClose={closeModal}
        title={<img src={faqImage} alt="questions" />}
      >
        <div className="column p-6 items-center">
          <h3 className="mb-2">{t('footer.needHelp')}</h3>
          <div className="help-modal__description body-16 mb-5">{t('footer.helpDescription')}</div>
          <div className="row space-between gap-4 column-sm gap-sm-2">
            <div className="cell-6 cell-sm-12">
              <Button
                onClick={() => {
                  setContactOpened(true)
                }}
                variant="outline"
              >
                {t('footer.iDontHaveBooking')}
              </Button>
            </div>
            <div className="cell-6 cell-sm-12">
              <Button onClick={redirectToLogin}>{t('footer.iHaveBooking')}</Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        opened={contactOpened}
        maxWidth="md"
        className={bem('page-footer', 'help-modal-contact')}
        title
        onClose={() => {
          setContactOpened(false)
        }}
      >
        <div className="p-6">
          <Trans i18nKey="footer.contactEmail" components={{ email: <a href={`mailto:${email}`} /> }} />
        </div>
      </Modal>
    </div>
  )
}

export default Help
