import cn from 'classnames'
import React, { ReactElement, ReactNode } from 'react'

import bem from '@lib/bem'
import { Icon } from '@ui'

import '@components/SidebarMenu/pages/page.scss'

export interface PageProps<T> {
  title: string
  list: T[]
  current: T
  getIcon: (item: T) => ReactNode
  getName: (item: T) => ReactNode
  getClass?: (item: T, { selected }: { selected?: boolean }) => string
  onChange: (item: T) => void
  onClose: () => void
}

const Page = <T extends string>(props: PageProps<T>): ReactElement => {
  const { title, list, current, getIcon, getName, onChange, onClose, getClass } = props

  return (
    <div className="page">
      <div className="page__header">
        <Icon name="chevron-left" size="medium" onClick={onClose} />
        <div className="header__title">{title}</div>
      </div>
      <div className="page__content">
        {list.map(item => {
          const isCurrent = current === item
          const listItemClasses = bem('list', 'item', { selected: isCurrent })
          const valueClasses = getClass?.(item, { selected: isCurrent }) ?? ''

          return (
            <div
              key={item}
              className={cn(listItemClasses, valueClasses)}
              onClick={() => {
                onChange(item)
              }}
            >
              <div className="item__icon">{getIcon(item)}</div>
              <div className="item__name">{getName(item)}</div>
              {isCurrent && <Icon name="checkout" size="large" />}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Page
