import { BookingCreateRequest, BookingResponse } from '@api/booking'
import config from '@config'
import ancillaryUtils from '@lib/ancillary'
import checkoutUtils from '@lib/checkout'
import dateUtils from '@lib/date'
import passengersUtils from '@lib/passengers'
import utils from '@lib/utils'
import { CheckoutFormData, Seats } from '@pages/Checkout/hooks/useInitialFormValues'
import { PassengerData } from '@stores/checkout'
import { ParamsStore } from '@stores/params'

const calculateAmount = ({ ancillaries, price }: CheckoutFormData): number =>
  Number(price?.fractional) + ancillaryUtils.getTotalAmount(ancillaries)

const getSeatsPrice = (seats: Seats, passengers: PassengerData[]): number =>
  passengers.reduce((acc, _, index) => {
    const ancillaries = ancillaryUtils.buildSeatAncillary(index, seats)
    return acc + ancillaries.reduce((acc, { price }) => acc + price, 0)
  }, 0)

const buildCreateParams = (
  data: CheckoutFormData,
  params: ParamsStore,
  inbound: Connection,
  outbound: Connection,
): BookingCreateRequest => {
  const validPassengers = passengersUtils.filterPassengers(data.passengers)
  const seatsPrice = data.seats ? getSeatsPrice(data.seats, validPassengers) : 0
  const cards = data.meta.cards.length ? data.meta.cards : null

  return utils.object.compact<BookingCreateRequest>({
    paymentMethod: data.paymentMethod as string,
    paymentProvider: data.paymentProvider as string,
    passengers: checkoutUtils.assignSeatsToPassengers({
      passengers: validPassengers,
      data,
      seatAncillaries: false,
    }),
    description: config.adyen.description,
    email: data.contact.email || null,
    phone: data.contact.phone,
    termsAccepted: data.termsAndPrivacy,
    sendMarketingEmails: data.marketing,
    pax: passengersUtils.filterPassengers(data.passengers).length,
    fareClass: data.fareClass,
    cardHolderName: data.holderName,
    express: false,
    numberOfInstallments: data.numberOfInstallments,
    cpf: data.cpf,
    cardData: data.cardData,
    paymentMethodData: data.paymentMethod === 'pix' ? { type: 'pix' } : data.paymentMethodData,
    browserInfo: data.browserInfo,
    arrivalStationCode: outbound.arrivalStation.code,
    departureStationCode: outbound.departureStation.code,
    marketingCarrierCode: params.marketingCarrierCode as string,
    arrivalTime: outbound.arrivalTime,
    departureTime: outbound.departureTime,
    currency: params.currency,
    locale: params.locale,
    returnDepartureTime: inbound?.departureTime,
    returnArrivalTime: inbound?.arrivalTime,
    retailerPartnerNumber: params.retailerPartnerNumber,
    amount: calculateAmount(data) + seatsPrice,
    ancillaries: ancillaryUtils.getBookingAncillariesParams(data.ancillaries),
    markupFee: checkoutUtils.calculateTotalFees(data.vacancy?.fees),
    deviceFingerprint: data.deviceFingerprint,
    retailerBookingNumber: params.retailerBookingNumber,
    paymentAttemptReference: data.paymentAttemptReference,
    countryCode: data.countryCode,
    originalPrice: cards ? data.originalPrice?.fractional : null,
    cards,
  })
}

const formatDateTime = (date: string | undefined, formatTime: boolean = false): string | null => {
  if (!date) return null
  if (formatTime) return dateUtils.formatTime(dateUtils.parse(date, 'UTC'))

  return dateUtils.formatDate(dateUtils.parse(date, 'UTC'))
}

const getSegmentPassengers = (connection?: Connection | null): Booking.Passengers[] =>
  connection?.segments[0].segmentPassengers?.map(({ passenger }) => passenger) ?? /* istanbul ignore next */ []

const getParamsFromBooking = (data: BookingResponse): ParamsStore => {
  const { outboundConnection, inboundConnection, booking, passengers, marketingCarrier } = data

  return utils.object.compact({
    departureLocation: { type: 'station', code: outboundConnection.departureStation.code },
    arrivalLocation: { type: 'station', code: outboundConnection.arrivalStation.code },
    departureDate: formatDateTime(outboundConnection.departureTime),
    departureTime: formatDateTime(outboundConnection.departureTime, true),
    arrivalDate: formatDateTime(outboundConnection.arrivalTime),
    arrivalTime: formatDateTime(outboundConnection.arrivalTime, true),
    returnDepartureDate: formatDateTime(inboundConnection?.departureTime),
    returnDepartureTime: formatDateTime(inboundConnection?.departureTime, true),
    returnArrivalDate: formatDateTime(inboundConnection?.arrivalTime),
    returnArrivalTime: formatDateTime(inboundConnection?.arrivalTime, true),
    fareClass: booking.fareClass.code,
    marketingCarrierCode: marketingCarrier.code,
    pax: passengers.length,
    currency: booking.totalPrice.currency,
    retailerPartnerNumber: booking.retailerPartnerNumber,
  })
}

export default {
  buildCreateParams,
  calculateAmount,
  getParamsFromBooking,
  getSegmentPassengers,
}
