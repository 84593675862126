import { Placement } from '@popperjs/core'
import cn from 'classnames'
import React, { HTMLAttributes, useCallback, useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'

import bem from '@lib/bem'
import PopupContainerContext from '@ui/Tooltip/ContainerContext'

import '@ui/Popper/index.scss'

export interface PopperProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  anchorElement: HTMLElement | null
  position?: Placement
  onRendered?: (element: HTMLDivElement) => void
  anchorWidth?: boolean
  maxWidth?: Exclude<Size, 'lg'> | 'none'
  maxHeight?: Size
  fullWidth?: boolean
  className?: string
  opened?: boolean
}

const Popper = (props: PopperProps) => {
  const {
    children,
    opened,
    anchorElement,
    onRendered,
    position = 'bottom-start',
    anchorWidth,
    className,
    maxWidth = 'sm',
    maxHeight = 'sm',
    fullWidth,
    ...divProps
  } = props
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const popperProps = usePopper(anchorElement, popperElement, {
    placement: position,
    modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
  })
  const { styles, attributes, state } = popperProps
  const popperAlignment = state?.placement.split('-')[0] ?? 'bottom'
  const container = useContext(PopupContainerContext)

  const handleElementRender = useCallback(
    (element: HTMLDivElement) => {
      setPopperElement(element)
      onRendered?.(element)
    },
    [onRendered],
  )

  if (!opened) return null

  const minWidth = anchorWidth ? anchorElement?.offsetWidth : undefined
  const popperClasses = bem('ui-popper', {
    [`max-w-${maxWidth}`]: true,
    [`max-h-${maxHeight}`]: true,
    'full-width': fullWidth,
  })

  return createPortal(
    <div
      data-tag="popper"
      ref={handleElementRender}
      className={cn(popperClasses, className, { [popperAlignment]: true })}
      style={{ ...styles.popper, minWidth }}
      {...attributes.popper}
      {...divProps}
    >
      {children}
    </div>,
    container.current,
  )
}

export default Popper
