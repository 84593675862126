import makeApi from '@lib/api'

interface PriceCalendarParams {
  departureStation?: string | null
  departureCity?: string | null
  departureArea?: string | null
  arrivalStation?: string | null
  arrivalCity?: string | null
  arrivalArea?: string | null
  currency: Currency
  retailerPartnerNumber: number
  marketingCarrierCodes?: CarrierCode[] | null
}

export interface PriceCalendarOutboundRequest extends PriceCalendarParams {
  departureStartTime?: string | null
  departureDateStart: string
  departureDateEnd: string
}

export type PriceCalendarResponse = PriceCalendar

export interface PriceCalendarInboundRequest extends PriceCalendarParams {
  returnDateStart: string
  returnDateEnd: string
  departureDate: string
}

export default {
  outbound: {
    load: makeApi.get<PriceCalendarOutboundRequest, PriceCalendarResponse>('/connections/price_calendar'),
  },
  inbound: {
    load: makeApi.get<PriceCalendarInboundRequest, PriceCalendarResponse>('/connections/price_calendar/return'),
  },
}
