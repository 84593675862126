import helpers from '@lib/analytics/amplitude/helpers'

const { sendEvent } = helpers

const clickMenu = (name: string): void => {
  sendEvent('click-header-menu', { name })
}

const clickMenuLink = (url: string): void => {
  sendEvent('click-menu-link', { url })
}

export default {
  clickMenu,
  clickMenuLink,
}
