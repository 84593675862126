import React, { ReactElement, ReactNode, useState } from 'react'

import bem from '@lib/bem'
import Icon from '@ui/Icon'

import '@components/ConnectionFilters/Section/index.scss'

interface SectionProps {
  title?: string | null
  collapsible?: boolean
  hidden?: boolean
  children: ReactNode
}

const Section = ({ title, children, collapsible = true, hidden }: SectionProps): ReactElement => {
  const [collapsed, setCollapsed] = useState<boolean>(false)

  return (
    <div className={bem('connection-filters', 'section', { hidden })}>
      {title && (
        <div
          className={bem('connection-filters', 'section-header', { collapsed })}
          onClick={() => {
            setCollapsed(!collapsed)
          }}
        >
          <div className={bem('connection-filters', 'section-header-title')}>{title}</div>
          {collapsible && <Icon name={collapsed ? 'chevron-up' : 'chevron-down'} size="medium" />}
        </div>
      )}
      <div className={bem('connection-filters', 'section-content', { collapsed })}>{children}</div>
    </div>
  )
}

export default Section
