import { ApiError } from '@lib/api'

interface Params<TParams, TResponse> {
  fn: (params: TParams) => Promise<TResponse>
  validate: (result: TResponse) => boolean
  interval: number
  maxAttempts: number
}

export class MaxAttemptsError extends Error {
  constructor() {
    super('Max attempts exceeded')
  }
}

export type PollingApiError = ApiError | MaxAttemptsError

const polling = async <TParams, TResponse>({ fn, validate, interval, maxAttempts }: Params<TParams, TResponse>) => {
  return async (params: TParams): Promise<TResponse> => {
    let attempts = 0

    const executePoll = (resolve: any, reject: any): void => {
      fn(params)
        .then(result => {
          attempts++

          if (validate(result)) return resolve(result)
          if (maxAttempts && attempts === maxAttempts) return reject(new MaxAttemptsError())

          setTimeout(executePoll, interval, resolve, reject)
        })
        .catch(err => reject(err))
    }

    return await new Promise(executePoll)
  }
}

export default polling
