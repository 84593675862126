import React, { ReactElement } from 'react'

import timeImage from '@images/reservations/time.png'
import { useTranslation } from '@lib/i18n'
import ReservationModal from '@pages/Checkout/ReservationsErrorModal'
import { Button } from '@ui'

interface ReservationsExpiredModalProps {
  opened: boolean
  period?: number | null
}

const ExpressExpiredModal = ({ opened, period }: ReservationsExpiredModalProps): ReactElement => {
  const { t } = useTranslation()
  const refreshPage = (): void => {
    document.location.reload()
  }

  return (
    <ReservationModal
      isOpened={opened}
      body={t('checkout.reservationModal.reservationExpiredBody', { period })}
      title={t('checkout.reservationModal.reservationExpiredTitle')}
      footer={
        <div className="row center cell">
          <div className="cell-5 cell-sm-12">
            <Button onClick={refreshPage}>{t('checkout.expressModal.refreshPage')}</Button>
          </div>
        </div>
      }
      iconPath={timeImage}
    />
  )
}

export default ExpressExpiredModal
