import React, { ReactElement, ReactNode } from 'react'

import Money from '@components/Money'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import Description from '@pages/Checkout/Extras/Card/Description'

interface DescriptionProps {
  ancillary: Ancillary.Item
}

const Info = ({ ancillary }: DescriptionProps): ReactElement => {
  const isMobile = useIsMobile()

  const description = (ancillary: Ancillary.Item): ReactNode => (
    <Description title={ancillary.name} description={ancillary.description} />
  )

  return (
    <>
      {isMobile && ancillary.description && description(ancillary)}
      {!isMobile && (
        <span className={bem('checkout', 'accommodation-description')}>
          <Money fractional={ancillary.price.fractional} currency={ancillary.price.currency} />
        </span>
      )}
    </>
  )
}

export default Info
