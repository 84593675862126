import makeApi from '@lib/api'

export interface LocationNameDataResponse {
  code: string
  name: string
  countryCode: string
}

export interface LocationNameDataRequest {
  route: {
    code: string
  }
  query: LocationNameDataRequestParams
}

export interface LocationNameDataRequestParams {
  type: LocationType
  locale: string
}

export default {
  getLocationNameData: async ({ route, query }: LocationNameDataRequest) => {
    const callApi = makeApi.get<LocationNameDataRequestParams, LocationNameDataResponse>({
      type: 'locations',
      old: `/locations/${route.code}/name`,
      new: `/locations/${route.code}`,
    })

    return await callApi(query)
  },
}
