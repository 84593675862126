import cn from 'classnames'
import React, { ReactElement } from 'react'

import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'

import '@ui/ErrorMessage/index.scss'

interface ErrorMessageProps {
  code?: ErrorCode
  className?: string
  fallbackMessage?: string | null
}

const ErrorMessage = ({ code, fallbackMessage, className }: ErrorMessageProps): ReactElement => {
  const { t } = useTranslation()
  const defaultError = fallbackMessage ?? 'errors.internalError'

  return (
    <div className={cn('message', className)}>
      <p className={bem('message', 'text')}>{t([`errors.${code as string}`, defaultError])}</p>
    </div>
  )
}

export default ErrorMessage
