import cn from 'classnames'
import React, { ButtonHTMLAttributes, MouseEventHandler, ReactElement, ReactNode } from 'react'

import Loader from '@components/Loader'

import '@ui/Button/index.scss'

export interface ButtonProps {
  children: ReactNode
  buttonType?: ButtonHTMLAttributes<any>['type']
  color?: 'primary' | 'secondary' | 'grey' | 'white' | 'backgroundText'
  variant?: 'filled' | 'outline' | 'text'
  round?: boolean
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  fullWidth?: boolean
  loading?: boolean
}

const Button = ({
  onClick,
  color = 'primary',
  variant = 'filled',
  round,
  children,
  disabled: disabledProp,
  buttonType = 'button',
  fullWidth = true,
  loading,
  ...rest
}: ButtonProps): ReactElement => {
  const buttonWrapperClasses = cn('ui-button-wrapper', color, variant, { round, fullWidth })
  const disabled = disabledProp || loading
  const isShadowVisible = variant === 'filled' && !disabled && !round

  return (
    <div className={buttonWrapperClasses} {...rest}>
      {loading && <Loader loading={loading} />}
      {isShadowVisible && <div className="ui-button-shadow" />}
      <button type={buttonType} className="ui-button" disabled={disabled} onClick={onClick}>
        {children}
      </button>
    </div>
  )
}

export default Button
