import cn from 'classnames'
import React, { ReactElement } from 'react'

import bem from '@lib/bem'
import { Button, Icon } from '@ui'

import '@components/Counter/index.scss'

interface CounterProps {
  label?: string
  description?: string | null
  value: number
  min: number
  max: number
  onChange: (count: number, type: CounterOperation) => void
}

const Counter = ({ label, description, value, onChange, min, max }: CounterProps): ReactElement => {
  const decreaseDisabled = value <= min
  const increaseDisabled = value >= max

  const decrease = (): void => {
    onChange(value - 1, 'decrease')
  }
  const increase = (): void => {
    onChange(value + 1, 'increase')
  }

  return (
    <div className="row items-center space-between counter">
      <div className="cell column space-between">
        <div className={cn('cell', bem('counter', 'label'))}>{label}</div>
        <div className={cn('cell', bem('counter', 'description'))}>{description}</div>
      </div>
      <div className="cell no-grow">
        <div className="row items-center gap-1">
          <Button
            color="grey"
            disabled={decreaseDisabled}
            variant="outline"
            round
            onClick={decrease}
            data-tag="counter-decrease"
          >
            <Icon name="minus" size="small" />
          </Button>
          <div className={bem('counter', 'amount')}>{value}</div>
          <Button
            color="grey"
            disabled={increaseDisabled}
            variant="outline"
            round
            onClick={increase}
            data-tag="counter-increase"
          >
            <Icon name="plus" size="small" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Counter
