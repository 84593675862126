/* eslint-disable @typescript-eslint/no-namespace */
// @see https://www.verygoodsecurity.com/docs/api/collect/

import { loadVGSCollect } from '@vgs/collect-js'

import config from '@config'

export namespace VGS {
  type TokenizeSuccessHandler<T> = (status: number, result: T) => void
  type TokenizeErrorHandler = (errors: Record<string, unknown>) => void

  export namespace Field {
    export interface Error {
      code: number
      message: string
      desciption: string
    }

    export interface UpdateData {
      errors: Error[]
      isTouched: boolean
      isFocused: boolean
      isDirty: boolean
    }

    export type Event = 'update'

    export type EventListener = (data: UpdateData) => void

    export interface Item {
      name: string
      on: (event: Event, cb: EventListener) => void
      off: (event: Event, cb: EventListener) => void
      delete: () => void
    }

    namespace Serializer {
      export interface Replace {
        name: 'replace'
        options: { old: string; new: string; count?: string }
      }

      export type Item = Replace
    }

    interface CardBrand {
      type: string
      pattern: RegExp
    }

    export interface Options {
      type: 'card-number' | 'card-expiration-date' | 'card-security-code' | 'text'
      name: string
      tokenization: { format: string; storage: string }
      css?: Record<string, string>
      placeholder?: string
      showCardIcon?: boolean
      validations?: string[]
      serializers?: Serializer.Item[]
      addCardBrands?: CardBrand[]
      classes?: {
        invalid?: string
        empty?: string
        focused?: string
        dirty?: string
        touched?: string
      }
    }
  }

  interface StateItem {
    errorMessages: string[]
    errors: Field.Error[]
    isDirty: boolean
    isEmpty: boolean
    isFocused: boolean
    isTouched: boolean
    isValid: boolean
    name: string
    bin?: string
    cardType?: string
    last4?: string
  }

  type State = Record<string, StateItem>

  export interface Form {
    setRouteId: (id: string) => void
    field: (selector: string, options: Field.Options) => Field.Item
    fields: Field.Item[]
    tokenize: <T>(success?: TokenizeSuccessHandler<T>, error?: TokenizeErrorHandler) => void
    state: State
  }

  export type StateListenerFunction = (state: State) => void

  export interface Collect {
    init: (listener?: StateListenerFunction) => Form
  }
}

const VERSION = '2.19'

const init = async (): Promise<VGS.Form> => {
  const collect = (await loadVGSCollect({
    vaultId: config.vgs.vaultId,
    environment: config.vgs.env,
    version: VERSION,
  })) as VGS.Collect

  const form = collect.init()

  form.setRouteId(config.vgs.routeId)

  return form
}

interface PromisedTokenizeResult<T> {
  status: number
  result: T
}

/* istanbul ignore next */
const promisedTokenize = <T>(form: VGS.Form): Promise<PromisedTokenizeResult<T>> =>
  new Promise((resolve, reject) => {
    form.tokenize<T>(
      (status, result) => {
        resolve({ status, result })
      },
      errors => {
        reject(errors)
      },
    )
  })

const vgs = {
  init,
  promisedTokenize,
}

export default vgs
