import cn from 'classnames'
import React, { ReactElement, useState } from 'react'
import { useNavigate, createSearchParams } from 'react-router-dom'

import * as Reservation from '@api/reservation'
import config from '@config'
import success from '@images/checkout/onHold.png'
import bem from '@lib/bem'
import date from '@lib/date'
import { Trans, useTranslation } from '@lib/i18n'
import url from '@lib/url'
import utils from '@lib/utils'
import { useParams } from '@stores/params'
import { Button, Icon, Modal } from '@ui'

import '@pages/Checkout/Form/SubmitReservation/ReservationHoldModal/index.scss'

interface ReservationHoldModalProps {
  reservationData: Reservation.CreateResponse
}

const ReservationHoldModal = ({ reservationData }: ReservationHoldModalProps): ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [{ mode, retailerPartnerNumber, locale, currency }] = useParams()
  const [copied, setCopied] = useState<boolean>(false)

  const { expiresAt, id } = reservationData
  const expireDate = expiresAt && date.format(date.parse(`${expiresAt}+00:00`), 'MMMM d, yyyy HH:mm')

  const handleBookNewTrip = (): void => {
    const params = utils.object.compact<Record<string, string>>({ retailerPartnerNumber, locale, mode, currency })

    const pathname = mode === 'embed' ? '/frame/result' : '/'
    const search = createSearchParams({ ...params }).toString()

    navigate({ pathname, search })
  }

  const handleCopyToReservation = (): void => {
    const link = url.build([config.publicPath, 'reservation', 'pay', `${id}`])
    window.navigator.clipboard.writeText(link).then(() => {
      setCopied(true)
    })
  }

  return (
    <Modal opened>
      <div className="column gap-3 items-center on-hold-reservation">
        <img src={success} alt="success" />
        <h3 className={bem('payment-error', 'title')}>{t('checkout.onHold.title')}</h3>
        <div className="cell stretch">
          <div className={cn('column', 'gap-1', bem('on-hold-reservation', 'data'))}>
            <div>
              <Trans i18nKey="checkout.onHold.holdLimit" values={{ expireDate }} />
            </div>
          </div>
        </div>
        <div className="cell ">
          <div className={bem('on-hold-reservation', 'controls')}>
            <Button variant="outline" onClick={handleBookNewTrip}>
              {t('checkout.onHold.bookNewTrip')}
            </Button>
            {mode === 'embed' && (
              <Button onClick={handleCopyToReservation}>
                <div className="row items-center center">
                  <span className="mr-2">{t('checkout.onHold.copyReservation')}</span>
                  {copied && <Icon name="checkout" size="medium" />}
                </div>
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ReservationHoldModal
