import React, { ReactElement, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Header from '@components/Header'
import useSearchParams from '@hooks/useSearchParams'
import notFound from '@images/404/not-found.png'
import amplitude from '@lib/analytics/amplitude'
import { PageNotFoundError, ViewErrorPageEvent } from '@lib/analytics/amplitude/error'
import { useTranslation } from '@lib/i18n'
import url from '@lib/url'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'
import { Button } from '@ui'

import '@pages/NotFound/index.scss'

const getBrowserLocale = (): Locale => window.navigator.language.slice(0, 2) as Locale

interface NotFoundPageProps {
  error?: ViewErrorPageEvent | null
}

const NotFoundPage = ({ error: propsError }: NotFoundPageProps): ReactElement => {
  const { t } = useTranslation()
  const [settings] = useSettings()
  const [params] = useParams()
  const [_, setParams] = useSearchParams()
  const pageLocale = getBrowserLocale()
  const { state } = useLocation()
  const errorData = propsError ?? state

  useEffect(() => {
    if (settings.locale.supported.includes(pageLocale)) {
      setParams(
        current => ({
          ...current,
          locale: pageLocale,
        }),
        { state },
      )
    }
  }, [settings.locale.supported, pageLocale, setParams, state])

  useEffect(() => {
    const error = errorData == null ? amplitude.error.buildParams(new PageNotFoundError()) : errorData

    amplitude.error.viewPage(error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGoBack = (): void => {
    window.location.href = settings.logo.redirectionUrl ?? url.search(params)
  }

  return (
    <div className="not-found-page">
      <div id="bg-line" />
      <div id="page" className="column">
        <div className="mb-2 mb-lg-2">
          <Header />
        </div>
        <div className="column items-center center not-found-page__info">
          <img src={notFound} alt="not found" />
          <h3>{t('notFound.title')}</h3>
          <p>{t('notFound.description')}</p>
          {!!params.retailerPartnerNumber && <Button onClick={handleGoBack}>{t('notFound.back')}</Button>}
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage
