import React from 'react'

import { useTranslation } from '@lib/i18n'
import { Icon } from '@ui'

import '@components/Blocks/Banner/index.scss'

type Props = PageBlocks.BannerBlock['data']

const Banner = (props: Props) => {
  const { translationKey } = props
  const { t } = useTranslation()

  return (
    <div className="banner-block">
      <div className="banner-block__content">
        <Icon name="calendar" size="large" />
        <div>{t(`customBlocks.${translationKey}.title`)}</div>
      </div>
    </div>
  )
}

export default Banner
