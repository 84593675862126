import React, { useMemo, useState } from 'react'

import PriceInput from '@components/PriceInput'
import currencyUtils from '@lib/currency'
import { useTranslation } from '@lib/i18n'
import { useParams } from '@stores/params'
import { Button } from '@ui'

interface Props {
  total: number
  onSubmit: () => void
}

const ChangeCalculator = ({ total, onSubmit }: Props) => {
  const [{ currency }] = useParams()
  const [amount, setAmount] = useState<number>()
  const { t } = useTranslation()

  const totalPrice = useMemo(() => currencyUtils.create(total, currency), [currency, total])
  const isValidAmount = useMemo(() => amount != null && amount >= total, [amount, total])
  const changeAmount = useMemo(
    () => (isValidAmount ? totalPrice.subtract(amount!).format().slice(1) : '-'),
    [amount, isValidAmount, totalPrice],
  )

  return (
    <>
      <div className="mb-4">
        <div className="mb-0.5">{t('changeCalculator.total')}</div>
        <div className="bolder body-20">{totalPrice.format()}</div>
      </div>
      <div className="row wrap gap-4">
        <div className="cell-6">
          <div className="mb-1">{t('changeCalculator.givenAmount')}</div>
          <PriceInput onSubmit={setAmount} onChange={setAmount} label={t('changeCalculator.amountInput')} />
        </div>
        <div className="cell-6">
          <div className="column h-100">
            <div className="mb-1 cell">{t('changeCalculator.change')}</div>
            <div className="bolder body-20 cell" data-tag="changeCalc_change">
              {changeAmount}
            </div>
          </div>
        </div>
        <div className="cell-12 mt-1" data-tag="changeCalc_approve">
          <Button disabled={!isValidAmount} onClick={onSubmit}>
            {t('changeCalculator.approve')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default ChangeCalculator
