import { useFormikContext } from 'formik'
import React, { ReactElement, useMemo } from 'react'

import { FareClassesResponse } from '@api/fareClasses'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { LoaderHook } from '@lib/loader'
import Accommodation from '@pages/Checkout/Accommodation'
import FareClassList from '@pages/Checkout/FareClassesSelection/SplitFareClasses/FareClassList'
import FareFamilyList from '@pages/Checkout/FareClassesSelection/SplitFareClasses/FareFamilyList'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { UpdatedMediaData } from '@queries/media'
import { ErrorMessage } from '@ui'

import '@pages/Checkout/FareClassesSelection/SplitFareClasses/index.scss'

interface FareClassItemProps {
  connection: Connection | null
  isConnectionsLoading: boolean
  accommodations: Ancillary.Item[]
  faresLoader: LoaderHook<FareClassesResponse>
  selectedFare?: Fare
  isReturnTrip?: boolean
  media?: UpdatedMediaData[]
}

const FareClassItem = (props: FareClassItemProps): ReactElement => {
  const {
    faresLoader: { data: faresList, isLoading, errorCode },
    connection,
    isConnectionsLoading,
    accommodations,
    isReturnTrip,
    selectedFare,
    media,
  } = props
  const { t } = useTranslation()
  const {
    values: { isVacancyLoading, travelClass, returnTravelClass, fareClass, returnFareClass },
  } = useFormikContext<CheckoutFormData>()

  const currentFareClass = useMemo(
    () => (isReturnTrip ? returnFareClass : fareClass),
    [fareClass, isReturnTrip, returnFareClass],
  )
  const currentTravelClass = useMemo(
    () => (isReturnTrip ? returnTravelClass : travelClass),
    [isReturnTrip, returnTravelClass, travelClass],
  )

  const fareClassList = useMemo(
    () => connection?.fares.map(({ fareClass, price }) => ({ ...fareClass, price })) ?? /* istanbul ignore next */ [],
    [connection],
  )

  const showAccommodation = (): boolean => {
    const manyInSegment = [...new Set(accommodations.map(item => item.segmentIndex))].length !== accommodations.length

    return (
      (manyInSegment || accommodations.some(item => item.price.fractional > 0)) &&
      !isVacancyLoading &&
      !isConnectionsLoading
    )
  }

  const segmentCountList = useMemo(
    () => [...new Set(accommodations.map(item => item.segmentIndex))] as number[],
    [accommodations],
  )

  const filteredFaresList = useMemo(() => {
    const availableFareClasses = fareClassList.map(f => f.code)

    return (
      faresList
        ?.flatMap(item => ({
          ...item,
          fares: item.fares.filter(el => availableFareClasses.includes(el.fareClassCode)),
        }))
        .filter(item => !!item.fares.length) ?? []
    )
  }, [fareClassList, faresList])

  const showFareFamilyList = !errorCode && faresList && fareClassList.length > 0 && !isConnectionsLoading

  return (
    <>
      <div className="cell">
        <div className={bem('checkout', 'section')}>
          <h3 className="mb-5">{t('checkout.travel_class.title')}</h3>
          {!errorCode && (
            <>
              <FareClassList
                faresList={filteredFaresList}
                fareClassList={fareClassList}
                isLoading={isLoading || isConnectionsLoading}
                travelClass={currentTravelClass}
                isReturnTrip={isReturnTrip}
                selectedFare={selectedFare}
              />
              {showAccommodation() && (
                <Accommodation accommodations={accommodations} segmentCountList={segmentCountList} />
              )}
            </>
          )}
          {errorCode && <ErrorMessage code={errorCode} />}
        </div>
      </div>
      {showFareFamilyList && (
        <div className="cell">
          <div className={bem('checkout', 'section')}>
            <h3 className="mb-5">{t('checkout.travel_family.title')}</h3>
            <FareFamilyList
              faresList={filteredFaresList?.filter(fareClass => fareClass.class === currentTravelClass)}
              fareClassList={fareClassList}
              fareClass={currentFareClass}
              travelClass={currentTravelClass}
              isReturnTrip={isReturnTrip}
              selectedFare={selectedFare}
              media={media}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default FareClassItem
