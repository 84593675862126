import cn from 'classnames'
import React, { ReactElement } from 'react'

import { Icon } from '@ui/index'

import '@ui/Alert/index.scss'

interface AlertProps {
  message: string
  type: 'error'
  onClose?: () => void
}

const Alert = ({ message, onClose, type }: AlertProps): ReactElement => {
  return (
    <div className={cn('row space-between ui-alert center', type)}>
      <div className="cell">
        <div className="row gap-2 items-center">
          {type === 'error' && <Icon className="ui-alert__type-icon" name="cross-circle" size="large" />}
          <div className="body-14">{message}</div>
        </div>
      </div>
      <div className="cell no-grow">
        {onClose && <Icon className="ui-alert__close" name="cross" size="medium" onClick={onClose} />}
      </div>
    </div>
  )
}

export default Alert
