import { useFormikContext } from 'formik'
import React, { ReactElement } from 'react'

import Loader from '@components/Loader'
import Money from '@components/Money'
import ancillaryUtils from '@lib/ancillary'
import { useTranslation } from '@lib/i18n'
import Ancillaries from '@pages/Checkout/BookingDetails/Ancillaries'
import TripBreakdown from '@pages/Checkout/BookingDetails/TripBreakdown'
import TripDetails from '@pages/Checkout/BookingDetails/TripDetails'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useCheckout } from '@stores/checkout'
import { Button, Skeleton } from '@ui'

import '@pages/Checkout/BookingDetails/index.scss'

interface BookingDetailsProps {
  isCarrierLoading: boolean
  isBookingLoading: boolean
  carriers: MarketingCarrier[]
  totalPrice?: Money
}

const Details = ({ isCarrierLoading, carriers, isBookingLoading, totalPrice }: BookingDetailsProps): ReactElement => {
  const [{ outbound, inbound }] = useCheckout()
  const { t } = useTranslation()
  const {
    values: { ancillaries, fareClass, vacancy },
  } = useFormikContext<CheckoutFormData>()

  const ancillariesInfo = ancillaryUtils.getPriceInfo(ancillaries, { includeReservation: true })

  return (
    <div className="booking-details">
      <h3>{t('checkout.bookingDetails.title')}</h3>
      <Loader loading={isCarrierLoading}>
        <TripBreakdown
          isCarriersLoading={isCarrierLoading}
          carriers={carriers}
          outbound={outbound}
          inbound={inbound}
          fareClass={fareClass}
        />
      </Loader>
      <hr />
      <TripDetails fareClass={fareClass} carriers={carriers} />
      <hr />
      {totalPrice && (
        <div className="row space-between mb-2 booking-details__trip">
          <div className="cell basis-auto no-grow">{t('booking.payment.previousTrip')}</div>
          <b className="cell basis-auto no-grow">
            <Money {...totalPrice} />
          </b>
        </div>
      )}
      <div className="mb-lg-4">
        {isBookingLoading && <Skeleton.Item height={25} width={150} />}
        {!!ancillariesInfo.length && <Ancillaries priceInfo={ancillariesInfo} />}
      </div>
      <div className="submit-section">
        <Button disabled={!vacancy?.vacant} buttonType="submit">
          {t('booking.continue')}
        </Button>
      </div>
    </div>
  )
}

export default Details
