import { useEffect } from 'react'

import style, { Colors } from '@lib/style'

const useCustomStyles = (colors: Colors): void => {
  useEffect(() => {
    style.applyCustomColors(colors)
  }, [colors])
}

export default useCustomStyles
