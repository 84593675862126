import { Field, useFormikContext } from 'formik'
import React, { ReactElement, useMemo } from 'react'

import TermsAndPrivacy from '@components/TermsAndPrivacy'
import TermsAndPrivacySkeleton from '@components/TermsAndPrivacy/Skeleton'
import useValidators from '@hooks/useValidators'
import ancillaryUtils from '@lib/ancillary'
import checkoutUtils from '@lib/checkout'
import feeUtils from '@lib/fee'
import { useTranslation } from '@lib/i18n'
import seatUtils from '@lib/seatSelection'
import vacancyUtils from '@lib/vacancy'
import Ancillaries from '@pages/Checkout/BookingDetails/Ancillaries'
import Discount from '@pages/Checkout/BookingDetails/Discount'
import Fees from '@pages/Checkout/BookingDetails/Fees'
import Seats from '@pages/Checkout/BookingDetails/Seats'
import DetailsSkeleton from '@pages/Checkout/BookingDetails/Skeleton'
import TripBreakdown from '@pages/Checkout/BookingDetails/TripBreakdown'
import TripBreakdownSkeleton from '@pages/Checkout/BookingDetails/TripBreakdown/Skeleton'
import TripDetails from '@pages/Checkout/BookingDetails/TripDetails'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useSettings } from '@queries/settings'
import { useCheckout } from '@stores/checkout'
import { useParams } from '@stores/params'
import { Skeleton } from '@ui'
import CheckboxField from '@ui/Checkbox/Field'

import '@pages/Checkout/BookingDetails/index.scss'

interface BookingDetailsProps {
  express?: boolean
  isCarriersLoading: boolean
  carriers: MarketingCarrier[]
}

const BookingDetails = ({ express, isCarriersLoading, carriers }: BookingDetailsProps): ReactElement => {
  const [{ reservation, marketingMaterials, termsAndPrivacy }] = useSettings()
  const [{ currency, hideLocations }] = useParams()
  const [{ outbound, inbound }] = useCheckout()
  const { t } = useTranslation()
  const { required } = useValidators()
  const { values } = useFormikContext<CheckoutFormData>()
  const { vacancy, ancillaries, reservationData, fareClass, isReservationLoading, isVacancyLoading, seats } = values
  const isLoading = !outbound

  const vacancyFees = vacancy?.vacant ? checkoutUtils.filterFees(vacancy.fees) : []
  const reservationFees = reservationData?.fees ? checkoutUtils.filterFees(reservationData.fees) : []

  const fees = reservation.enabled ? reservationFees : vacancyFees
  const priceInitialized = vacancy != null || reservationData != null
  const bookingFee = useMemo(() => {
    const outboundBookingFee = outbound?.marketingCarrier.bookingFee.fractional ?? /* istanbul ignore next */ 0
    const inboundBookingFee = inbound?.marketingCarrier.bookingFee.fractional ?? 0
    const sum = outboundBookingFee + inboundBookingFee

    if (sum > 0) return { fractional: sum, currency }
    return null
  }, [outbound, inbound, currency])

  if (bookingFee) {
    fees.push(feeUtils.buildBookingFee(bookingFee))
  }

  const isPriceLoading = (reservation.enabled ? isReservationLoading : isVacancyLoading) && !priceInitialized

  const seatPrice = useMemo(() => {
    const outbound = seatUtils.sumPrice(seatUtils.flatten(seats?.outbound))
    const inbound = seatUtils.sumPrice(seatUtils.flatten(seats?.inbound))

    return { fractional: outbound + inbound, currency }
  }, [currency, seats])

  const ancillariesInfo = ancillaryUtils.getPriceInfo(ancillaries, { includeReservation: true })
  const isVisibleLine = !!fees.length || !!ancillariesInfo.length || !!seatPrice.fractional || isPriceLoading
  const showSeatPrice = !!seatPrice.fractional
  const showAncillariesInfo = !!ancillariesInfo.length
  const discount = vacancy?.vacant ? vacancyUtils.getDiscount(vacancy) : 0
  const showTerms = marketingMaterials.enabled || termsAndPrivacy.enabled

  return (
    <div className="booking-details mb-lg-2">
      <h3>{t('checkout.bookingDetails.title')}</h3>
      <Skeleton.List Skeleton={TripBreakdownSkeleton} amount={1} loading={isLoading}>
        <TripBreakdown
          express={express}
          carriers={carriers}
          isCarriersLoading={isCarriersLoading}
          outbound={outbound}
          inbound={inbound}
          fareClass={fareClass}
        />
      </Skeleton.List>
      {!hideLocations && <hr />}
      {!hideLocations && (
        <Skeleton.List Skeleton={DetailsSkeleton} amount={1} loading={isLoading}>
          <TripDetails fareClass={fareClass} carriers={carriers} />
        </Skeleton.List>
      )}
      <hr />
      {showSeatPrice && <Seats price={seatPrice} />}
      {showAncillariesInfo && <Ancillaries priceInfo={ancillariesInfo} />}
      <Skeleton.List Skeleton={DetailsSkeleton} amount={1} loading={isPriceLoading}>
        <div className="mb-4 column gap-2">
          {fees.length > 0 && <Fees fees={fees} />}
          {discount > 0 && <Discount amount={discount} />}
        </div>
      </Skeleton.List>
      {isVisibleLine && <hr />}
      {showTerms && (
        <div className="column gap-3 booking-details__terms">
          {termsAndPrivacy.enabled && (
            <Skeleton.List Skeleton={TermsAndPrivacySkeleton} amount={1} loading={isLoading || isCarriersLoading}>
              <Field
                component={CheckboxField}
                label={<TermsAndPrivacy carriers={carriers} />}
                name={'termsAndPrivacy'}
                validate={required}
              />
            </Skeleton.List>
          )}
          {marketingMaterials.enabled && (
            <Field component={CheckboxField} name="marketing" label={t('checkout.bookingDetails.marketingMaterials')} />
          )}
        </div>
      )}
    </div>
  )
}

export default BookingDetails
