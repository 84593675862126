import React, { ReactElement } from 'react'

import config from '@config'
import { Icon } from '@ui'

interface RouteProps {
  connection: Connection | null
  isReturn: boolean
}

const Route = ({ connection, isReturn }: RouteProps): ReactElement => {
  const iconName = (): string => {
    const carriersWithAnyDirectionTrips = config.expressCheckout.carriersWithAnyDirectionTrips

    const marketingCarrierCode = connection?.marketingCarrier.id

    if (carriersWithAnyDirectionTrips.includes(marketingCarrierCode ?? /* istanbul ignore next */ '')) return 'return'

    return isReturn ? 'return' : 'arrow-right'
  }

  return (
    <h3 className="row wrap gap-1 mb-2">
      <span>{connection?.departureStation.name}</span>
      <Icon name={iconName()} size="large" />
      <span>{connection?.arrivalStation.name}</span>
    </h3>
  )
}

export default Route
