import React from 'react'

import Section from '@components/Section'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import utils from '@lib/utils'
import SearchBar from '@pages/Search/Bar'
import { useSettings } from '@queries/settings'

import '@components/Blocks/TripSearch/index.scss'

type TripSearchProps = PageBlocks.BlockComponentProps<PageBlocks.TripSearchBlock>

const TripSearch = (props: TripSearchProps) => {
  const [settings] = useSettings()
  const { layout = settings.searchBar.layout, titleKey, subTitleKey, color, onBackground, departure, arrival } = props
  const { t } = useTranslation(undefined, { keyPrefix: 'customBlocks' })
  const omitOffset = onBackground && (layout === 'column' || !!subTitleKey || !!titleKey)
  const defaultColor = onBackground ? settings.colors.headerText : settings.colors.backgroundText
  const searchParams = utils.object.compact({ departureLocation: departure, arrivalLocation: arrival })

  // ToDo: Remove trip-search-block__wrapper and use popover library for autocomplete
  return (
    <Section
      appearance="flat"
      background={{ fill: 'transparent' }}
      className="trip-search-block__wrapper"
      contentClassName={bem('trip-search-block', { omitOffset })}
    >
      <SearchBar
        layout={layout}
        subTitle={subTitleKey && t(subTitleKey)}
        title={titleKey && t(titleKey)}
        textColor={color ?? defaultColor}
        params={searchParams}
      />
    </Section>
  )
}

export default TripSearch
