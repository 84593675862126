import { useFormikContext } from 'formik'
import React, { ReactElement, useMemo, useState } from 'react'

import SeatSelection from '@components/SeatSelection'
import config from '@config'
import useSeatsController from '@hooks/useSeatsController'
import seat from '@images/extras/seat.png'
import amplitude from '@lib/analytics/amplitude'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import seatsUtils from '@lib/seatSelection'
import Card from '@pages/Checkout/Extras/Card'
import Info from '@pages/Checkout/Extras/Seats/Info'
import ExtrasSkeleton from '@pages/Checkout/Extras/Skeleton'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useSettings } from '@queries/settings'
import { useCheckout } from '@stores/checkout'
import { useParams } from '@stores/params'
import { Divider, Icon, Skeleton } from '@ui'

import '@pages/Checkout/Extras/Seats/index.scss'

interface SeatsProps {
  onError: (error: ErrorCode | null) => void
  isConnectionsLoading: boolean
}

const Seats = ({ onError, isConnectionsLoading }: SeatsProps): ReactElement => {
  const { t } = useTranslation()
  const [opened, setOpened] = useState<boolean>(false)
  const [touched, setTouched] = useState<boolean>(false)
  const [{ returnDepartureDate, retailerPartnerNumber }] = useParams()
  const [{ outbound, inbound }] = useCheckout()
  const [
    {
      reservation,
      seatSelection: { enabledOnCheckout },
    },
  ] = useSettings()

  const {
    values: { seats, passengers, fareClass },
    setFieldValue,
  } = useFormikContext<CheckoutFormData>()

  const {
    isLoading,
    layout,
    selectedSeats,
    segmentIndex,
    segmentType,
    handleSelect,
    handleNavigateBack,
    handleClose,
    handleConfirm,
  } = useSeatsController({
    connections: { outbound, inbound },
    onConfirm: () => {
      setOpened(false)
    },
    onError: error => {
      onError(error.code)
    },
    enabled: enabledOnCheckout,
  })

  const selectedSeatsCount = seatsUtils.getSeatsCount(seats?.outbound) + seatsUtils.getSeatsCount(seats?.inbound)
  const isAvailable = !config.forcedExtras?.[retailerPartnerNumber]?.seatSelection?.enabled

  const handleConfirmSelection = (): void => {
    const confirmed = {
      ...seats,
      [segmentType]: {
        ...seats?.[segmentType],
        [segmentIndex]: selectedSeats[segmentIndex].map(item => item),
      },
    }

    setFieldValue('seats', confirmed)
    handleConfirm()
  }

  const handleOpenModal = (): void => {
    if (reservation.enabled) return
    if (!isAvailable) {
      setTouched(true)
      amplitude.checkout.changeAncillary('Seat', passengers.length)
      return
    }

    setOpened(true)
  }

  const handleCloseModal = (): void => {
    setOpened(false)
    handleClose()
  }

  const showHeader = layout.length > 1 || !!returnDepartureDate

  const filteredLayout = useMemo(() => seatsUtils.filterLayoutByFareClass(layout, fareClass), [fareClass, layout])

  const controls = !reservation.enabled && (
    <div className={bem('extras', 'seats-icon')}>
      <Icon name="chevron-right" size="large" />
    </div>
  )

  const seatsLoading = isLoading || isConnectionsLoading
  const warning = (
    <div className="extras__seats-warning-message row gap-1">
      <Icon name="alert" size="small" />
      <span>{t('seats.warning.unavailable')}</span>
    </div>
  )
  const SeatsSkeleton = (
    <>
      <ExtrasSkeleton />
      <Divider className="mb-3 mt-3" />
    </>
  )

  return (
    <Skeleton.List Skeleton={() => SeatsSkeleton} amount={1} loading={seatsLoading}>
      <div className={bem('extras', 'section')}>
        <Card
          icon={seat}
          title={t('extras.seats.selected', { count: selectedSeatsCount })}
          description={<Info seats={seats} />}
          controls={controls}
          onClick={handleOpenModal}
          warning={touched && warning}
        />
        {layout.length > 0 && (
          <SeatSelection
            layout={filteredLayout}
            showHeader={showHeader}
            segmentIndex={segmentIndex}
            selected={selectedSeats[segmentIndex]}
            connection={segmentType === 'outbound' ? outbound : inbound}
            reservedSeatsCount={passengers.length}
            selectedFareClassCode={fareClass}
            opened={opened}
            onSelect={handleSelect}
            onClose={handleCloseModal}
            onSubmit={handleConfirmSelection}
            onNavigateBack={handleNavigateBack}
          />
        )}
      </div>
    </Skeleton.List>
  )
}

export default Seats
