import React, { ReactElement } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import { useTranslation } from '@lib/i18n'
import Item from '@pages/Checkout/Accommodation/Elements/Item'

import '@pages/Checkout/Accommodation/index.scss'

interface AccommodationProps {
  accommodations: Ancillary.Item[]
  segmentCountList: number[]
}

const Accommodation = ({ accommodations, segmentCountList }: AccommodationProps): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  return (
    <div className="checkout__accommodation-wrapper cell-12 column">
      <h4 className="mb-1 mt-6">{t('extras.ancillary.accommodation.title')}</h4>
      <p className="checkout__accommodation-subtitle mb-3 mt-1">
        {t('extras.ancillary.accommodation.subtitle', { count: segmentCountList.length })}
      </p>
      {segmentCountList.map(segment => (
        <Item
          key={segment}
          index={segment}
          accommodation={accommodations.filter(acc => acc.segmentIndex === segment)}
          grouped={segmentCountList.length > 1 || isMobile}
        />
      ))}
    </div>
  )
}

export default Accommodation
