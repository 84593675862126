/* istanbul ignore next */
const cdnLink = (): string => '//service.maxymiser.net/cdn/nationalexpress/js/mmcore.js'

/* istanbul ignore next */
const init = ({ enabled }: Analytics.Settings['maxymiser']): void => {
  if (!enabled) return

  const maxymiserScript = document.createElement('script')
  maxymiserScript.src = cdnLink()

  document.head.appendChild(maxymiserScript)
}

export default {
  init,
}
