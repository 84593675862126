import React, { ReactElement, ReactNode } from 'react'

import bem from '@lib/bem'
import { Radio } from '@ui'

import '@ui/RadioGroup/index.scss'

export type RadioValue = string | boolean

export interface RadioOption<T> {
  value: T
  label: ReactNode
  centered?: boolean
  disabled?: boolean
}

export interface RadioGroupProps<TValue, TOption> {
  value: TValue | null | undefined
  options: TOption[]
  onChange: (value: TValue) => void
  renderRadio?: (option: RadioOption<TValue>, radioElement: ReactElement, index: number) => ReactElement
  disabled?: boolean
  label?: string
  divider?: boolean
}

const RadioGroup = <TValue extends RadioValue, TOption extends RadioOption<TValue>>({
  value,
  options,
  onChange,
  disabled,
  renderRadio,
  label,
  direction = 'column',
}: RadioGroupProps<TValue, TOption> & Directional): ReactElement => {
  const renderOption = (option: RadioOption<TValue>, index: number): ReactElement => {
    const radioElement = (
      <Radio
        checked={option.value === value}
        onChange={() => {
          onChange(option.value)
        }}
        disabled={disabled ?? option.disabled}
        key={String(option.value)}
        centered={option.centered}
      >
        {option.label}
      </Radio>
    )

    return renderRadio ? renderRadio(option, radioElement, index) : radioElement
  }

  return (
    <div className="column">
      {label != null && <div className={bem('ui-radio-group', 'label')}>{label}</div>}
      <div className={`ui-radio-group ${direction}`}>{options.map(renderOption)}</div>
    </div>
  )
}

export default RadioGroup
