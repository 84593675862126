import React, { ReactElement } from 'react'

import Connection from '@components/Connection'
import TripInfo from '@components/TripInfo'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'

import '@pages/Checkout/BookingDetails/TripDetails/ConnectionDetails/index.scss'

interface ConnectionDetailsProps {
  connection: Connection
  type: ConnectionType
  fareClass: string
  carriers: MarketingCarrier[]
  hideTime?: boolean
}

const ConnectionDetails = ({
  connection,
  type,
  fareClass,
  carriers,
  hideTime,
}: ConnectionDetailsProps): ReactElement => {
  const { t } = useTranslation()

  const titleKey = type === 'outbound' ? 'outboundTrip' : 'returnTrip'

  return (
    <>
      <h4 className={bem('trip-connection-details', 'heading')}>{t(`checkout.bookingDetails.${titleKey}`)}</h4>
      {!hideTime && (
        <div className={bem('trip-connection-details', 'subheading')}>
          <Connection.DepartureDate connection={connection} />
          {' – '}
          <Connection.ArrivalDate connection={connection} />
        </div>
      )}
      <TripInfo connection={connection} fareClass={fareClass} carriers={carriers} type={type} hideTime={hideTime} />
    </>
  )
}

export default ConnectionDetails
