import React, { ReactElement, useEffect, useState } from 'react'

import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import utils from '@lib/utils'
import { VGS } from '@lib/vgs'
import Icon from '@ui/Icon'

import '@pages/Checkout/Payment/CreditCardForm/VGS/Field/index.scss'

interface IVGSFieldProps {
  id: string
  form: VGS.Form
  options: VGS.Field.Options
  touched?: boolean
}

const COMMON_FIELD_OPTIONS = {
  css: {
    fontSize: '16px',
    fontFamily: 'Poppins, Inter, sans-serif',
  },
}

const ERROR_CODES: Record<string, string> = {
  1001: 'errors.required',
  default: 'errors.invalid_value',
}

const VGSField = ({ id, form, options, touched: formTouched }: IVGSFieldProps): ReactElement => {
  const [errorCode, setErrorCode] = useState<number | null>(null)
  const [field, setField] = useState<VGS.Field.Item>()
  const hasError = errorCode != null
  const { t } = useTranslation()

  useEffect(() => {
    if (formTouched && field) {
      setErrorCode(form.state[field.name]?.errors?.[0]?.code)
    }
  }, [field, form.state, formTouched])

  useEffect(() => {
    /* istanbul ignore next */
    const listener: VGS.Field.EventListener = ({ errors, isTouched }) => {
      isTouched ? setErrorCode(errors[0]?.code) : setErrorCode(null)
    }

    const field = form.field(`#${id}`, { ...COMMON_FIELD_OPTIONS, ...options })
    setField(field)

    field.on('update', listener)

    /* istanbul ignore next */
    return () => {
      field.off('update', listener)
      field.delete()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, form, utils.common.hash(options)])

  /* istanbul ignore next */
  return (
    <div className={bem('vgs-field', 'wrapper')}>
      <div id={id} className={bem('vgs-field', 'field', { invalid: hasError })} data-tag={id}></div>
      {hasError && (
        <div className={bem('vgs-field', 'error-message')}>
          <Icon name="alert" size="small" />
          <span>{t(ERROR_CODES[errorCode] ?? ERROR_CODES.default)}</span>
        </div>
      )}
    </div>
  )
}

export default VGSField
