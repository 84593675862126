import { useFormikContext } from 'formik'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import config from '@config'
import useIsMobile from '@hooks/useIsMobile'
import fareClassUtils from '@lib/fareClass'
import { useTranslation } from '@lib/i18n'
import vacancy from '@lib/vacancy'
import { UpdatedFareClass } from '@pages/Checkout/FareClassesSelection'
import Card from '@pages/Checkout/FareClassesSelection/SplitFareClasses/Card'
import Navigation from '@pages/Checkout/FareClassesSelection/SplitFareClasses/Navigation'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { UpdatedMediaData } from '@queries/media'
import { useParams } from '@stores/params'

interface FareFamilyProps {
  faresList: FareClassMap.Entity[]
  fareClassList: UpdatedFareClass[]
  fareClass: string | null
  travelClass?: string
  isReturnTrip?: boolean
  selectedFare?: Fare
  media?: UpdatedMediaData[]
}

const FareFamilyList = (props: FareFamilyProps): ReactElement => {
  const { faresList, fareClassList, fareClass, travelClass, isReturnTrip, selectedFare, media } = props
  const [{ currency }] = useParams()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const {
    values: {
      isVacancyLoading,
      vacancies,
      price: totalPrice,
      fareClass: currentFareClass,
      returnFareClass: currentReturnFareClass,
    },
    setFieldValue,
  } = useFormikContext<CheckoutFormData>()
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const { fares } = { ...faresList[0] }
  const fareFormName = isReturnTrip ? 'returnFareClass' : 'fareClass'
  const emptyPrice = { fractional: 0, currency }

  const getCode = useCallback(
    (code: string): string => (isReturnTrip ? `${currentFareClass}${code}` : `${code}${currentReturnFareClass ?? ''}`),
    [currentFareClass, currentReturnFareClass, isReturnTrip],
  )

  const sorted = useMemo(
    () =>
      fares
        ?.map(item => ({
          ...item,
          price: vacancy.getPrice(fareClassList, item.fareClassCode),
          vacant: vacancy.getVacant(vacancies, getCode(item.fareClassCode)),
        }))
        .sort((a, b) => Number(a.price?.fractional) - Number(b.price?.fractional)) ?? /* istanbul ignore next */ [],
    [fareClassList, fares, getCode, vacancies],
  )

  const list = useMemo(
    () => (isMobile ? sorted : sorted.slice(currentIndex, currentIndex + config.fareSplitViewLength)),
    [currentIndex, sorted, isMobile],
  )

  useEffect(() => {
    const index = sorted.findIndex(item => item.fareClassCode === fareClass) / config.fareSplitViewLength
    setCurrentIndex(Math.floor(index) * config.fareSplitViewLength)
  }, [fareClass, sorted, isVacancyLoading, travelClass, vacancies.length])

  const selectFareFamily = (fareClass: string): void => {
    setFieldValue(fareFormName, fareClass)
    setFieldValue('seats', null)
  }

  return (
    <div className="fare-class__family-section row gap-3">
      {list.map(({ fareClassCode, id, price, vacant }) => (
        <div key={id + fareClassCode} className="cell-11 cell-lg-4">
          <Card
            title={t(`checkout.travel_family.${id}.title`, { defaultValue: id })}
            selected={fareClassCode === fareClass}
            onClick={() => {
              selectFareFamily(fareClassCode)
            }}
            description={t(`checkout.travel_family.${id}.description`, { returnObjects: true, defaultValue: [] })}
            vacant={vacant}
            price={
              fareClass === fareClassCode || !price
                ? emptyPrice
                : vacancy.calculatePrice(totalPrice, price, selectedFare?.price)
            }
            fareClassCode={fareClassCode}
            media={media?.flatMap(item => fareClassUtils.getMedia(item, fareClassCode))}
          />
        </div>
      ))}
      {!isMobile && fares && (
        <Navigation currentIndex={currentIndex} maxIndex={fares.length} onNavigate={setCurrentIndex} />
      )}
    </div>
  )
}

export default FareFamilyList
