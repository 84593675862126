import React, { ReactElement, ReactNode, useCallback } from 'react'

import fareClassUtils from '@lib/fareClass'

interface ItemProps {
  url: string
  className?: string
  config?: {
    image?: React.ImgHTMLAttributes<HTMLImageElement>
    video?: React.VideoHTMLAttributes<HTMLVideoElement>
    iframe?: React.IframeHTMLAttributes<HTMLIFrameElement>
  }
}

const Item = ({ url, className, config }: ItemProps): ReactElement => {
  const renderMedia = useCallback(
    (url: string, className?: string): ReactNode => {
      const youtubeVideoId = fareClassUtils.getYoutubeId(url)
      const previewPostfix = '#t=0.1'

      if (fareClassUtils.validateVideoUrl(url)) {
        return (
          <video
            data-tag="activeMedia"
            className={className}
            key={url}
            src={url.concat(previewPostfix)}
            controls
            {...config?.video}
          />
        )
      }

      if (youtubeVideoId) {
        return (
          <iframe
            key={url}
            data-tag="activeMedia"
            className={className}
            src={`https://www.youtube.com/embed/${youtubeVideoId}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            {...config?.iframe}
          />
        )
      }

      return <img data-tag="activeMedia" className={className} key={url} src={url} alt="" {...config?.image} />
    },
    [config],
  )

  return <>{renderMedia(url, className)}</>
}

export default Item
