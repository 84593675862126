import { addMonths, isBefore, startOfDay } from 'date-fns'
import React, { ReactElement, useState } from 'react'

import PassengersDropdown from '@components/PassengersDropdown'
import config from '@config'
import { useTranslation } from '@lib/i18n'
import { Button, DatePicker } from '@ui'

import '@pages/Search/PopularDirectionCard/Body.scss'

export interface PopularDirectionParams {
  passengers: Passenger.Param[]
  departureDate: Date
  departureLocation: Location.NamedItem
  arrivalLocation: Location.NamedItem
}

interface BodyProps {
  connection: PopularConnection
  onClick: (params: PopularDirectionParams) => void
}

const minDate = new Date()
const maxDate = addMonths(minDate, config.searchBarDateRange)

const Body = ({ connection, onClick }: BodyProps): ReactElement => {
  const { t } = useTranslation()
  const { departureDate: datePicker, passengerTypes, departureLocation, arrivalLocation } = connection
  const [departureDate, setDepartureDate] = useState(new Date())
  const [passengers, setPassengers] = useState([{ type: 'passengers', pax: 1 }])
  const isDayInPast = (date: Date): boolean => isBefore(date, startOfDay(new Date()))

  const isFieldsEnabled = datePicker?.enabled || passengerTypes?.enabled
  const onBookButtonClick = (): void => {
    onClick({ passengers, departureDate, departureLocation, arrivalLocation })
  }

  return (
    <div className="column gap-5 popular-direction-body">
      {isFieldsEnabled && (
        <div className="cell-12">
          <div className="column gap-3">
            {datePicker?.enabled && (
              <div className="cell-12">
                <DatePicker
                  value={departureDate}
                  onChange={setDepartureDate}
                  minDate={minDate}
                  maxDate={maxDate}
                  isTileDisabled={isDayInPast}
                  label={t('popularDirections.departureDate')}
                />
              </div>
            )}
            {passengerTypes?.enabled && (
              <div className="cell-12">
                <PassengersDropdown
                  label={t('searchBar.passengers.label')}
                  value={passengers}
                  onChange={setPassengers}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="cell-12">
        <Button onClick={onBookButtonClick}>{t('popularDirections.bookNow')}</Button>
      </div>
    </div>
  )
}

export default Body
