import React, { ReactElement } from 'react'

import Station from '@components/Station'
import connection from '@lib/connection'
import date from '@lib/date'
import filterUtils from '@lib/filter'
import { Icon } from '@ui'

import '@components/TripInfo/StationInfo/index.scss'

interface StationProps {
  type: Location.Direction
  station: Station
  time?: string
  vehicle?: Vehicle.Code
  duration?: ReactElement
  showAddress?: boolean
}

/*
 * @deprecated Use RouteBreakdown instead
 */
const StationInfo = ({ type, station, time, duration, showAddress = true, vehicle }: StationProps): ReactElement => {
  return (
    <div className="stations mb-1" data-tag="route-breakdown">
      {(time || duration) && (
        <div className="stations__time column items-center">
          <div>{time && <Station.Time date={date.parse(time, 'UTC')} />}</div>
          {type === 'departure' && <div className="stations__time-duration">{duration}</div>}
        </div>
      )}
      <div className="stations__transport cell-1">
        <Icon name={connection.getVehicleIcon(vehicle)} size="medium" />
        {type !== 'arrival' && <div className="stations__transport-divider" />}
      </div>
      <div className="stations__term">
        <div className="row space-between items-center">
          <div>
            <div className={`stations__term-${type}`}>{station.name}</div>
            {showAddress && (
              <a
                href={filterUtils.buildStationMapURL(station)}
                target="_blank"
                className="stations__term-description"
                rel="noreferrer"
              >{`${station.streetAndNumber}, ${station.city.name}`}</a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StationInfo
