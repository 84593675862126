import { useQueries, useQuery } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'

import carrierAPI from '@api/carrier'
import settingsApi from '@api/settings'
import { useAppContext } from '@context/app'
import utils from '@lib/utils'
import { carrierInitialData, settingsInitialData } from '@queries/settings/initialData'
import { Settings } from '@queries/settings/types'
import { merge } from '@queries/settings/utils'
import { useParams } from '@stores/params'
import store from '@stores/settings'

interface SettingsQuery {
  isFetching: ReturnType<typeof useQuery<Settings, Error>>['isFetching']
  error: ReturnType<typeof useQuery<Settings, Error>>['error']
}

type UseSettingsHook = [Settings, SettingsQuery]

export const useSettings = (): UseSettingsHook => {
  const { retailerPartnerNumber, marketingCarrierCode } = useAppContext()
  const loadCarrierConfiguration = marketingCarrierCode != null
  const [{ passengersCards }] = useParams()

  const [settingsQuery, carrierQuery] = useQueries({
    queries: [
      {
        queryKey: ['settings', retailerPartnerNumber, marketingCarrierCode],
        queryFn: () => settingsApi.load({ retailerPartnerNumber, marketingCarrierCode }),
        enabled: retailerPartnerNumber > 0,
        staleTime: Infinity,
        gcTime: Infinity,
        placeholderData: settingsInitialData,
      },
      {
        queryKey: ['carrier', 'config', marketingCarrierCode, passengersCards],
        queryFn: () => carrierAPI.config(marketingCarrierCode as CarrierCode, { discounts: passengersCards }),
        enabled: loadCarrierConfiguration,
        staleTime: Infinity,
        gcTime: Infinity,
        placeholderData: carrierInitialData,
      },
    ],
  })

  const data = useMemo(() => {
    const settingsData = { ...settingsInitialData, ...settingsQuery.data }
    const carrierData = { ...carrierInitialData, ...carrierQuery.data }

    return merge(settingsData, carrierData)
  }, [settingsQuery.data, carrierQuery.data])

  const meta = {
    isFetching: settingsQuery.isFetching,
    error: settingsQuery.error || carrierQuery.error,
  }

  // Backward compability
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => store.set(data), [utils.common.hash(data)])

  return [data, meta]
}
