import React, { ReactElement } from 'react'
import { Trans } from 'react-i18next'

import { Notification } from '@ui'

const Banner = (): ReactElement => (
  <Notification type="warning" size="small">
    <Trans
      i18nKey="checkout.banner.text"
      components={{
        h2: <h2 />,
        h3: <h3 />,
        h4: <h4 />,
        p: <p />,
        a: <a />,
        li: <li />,
        ul: <ul />,
        u: <u />,
        b: <b />,
      }}
    />
  </Notification>
)

export default Banner
