import cn from 'classnames'
import React, { MouseEvent, ReactElement, useState } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { Button, Modal } from '@ui'
import DropdownList, { DropdownListProps } from '@ui/Dropdown/List'
import Popper, { PopperProps } from '@ui/Popper'

interface DropdownMenuProps<T> extends DropdownListProps<T> {
  placeholder?: string | null
  opened: boolean
  onClose: () => void
  anchorElement: HTMLElement | null
  popperProps?: Partial<PopperProps>
}

const DropdownMenu = <T extends string>({
  opened,
  onClose,
  onChange,
  onReset,
  placeholder,
  value,
  activeItem,
  anchorElement,
  popperProps,
  ...listProps
}: DropdownMenuProps<T>): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const [modalValue, setModalValue] = useState<T | null>(null)
  const selectItem = (item: T, event?: MouseEvent): void => {
    onChange(item, event)
    onClose()
  }

  const reset = (event?: MouseEvent): void => {
    onReset?.(event)
    setModalValue(null)
    confirmModal()
    onClose()
  }

  const closeModal = (): void => {
    setModalValue(null)
    onClose()
  }
  const confirmModal = (): void => {
    /* istanbul ignore else */
    if (modalValue) onChange(modalValue)
    closeModal()
  }

  return (
    <>
      {isMobile && (
        <Modal
          className={bem('ui-dropdown', 'modal')}
          opened={opened}
          onClose={onClose}
          title={placeholder}
          fullScreen
          footer={modalValue && <Button onClick={confirmModal}>{t('ui.dropdown.confirm')}</Button>}
        >
          <div className="column cell grow" data-tag="dropdown-menu">
            <DropdownList onChange={setModalValue} onReset={reset} value={modalValue ?? value} {...listProps} />
          </div>
        </Modal>
      )}
      {!isMobile && (
        <Popper
          opened={opened}
          anchorElement={anchorElement}
          anchorWidth
          maxWidth="none"
          {...popperProps}
          className={cn('p-0', popperProps?.className)}
        >
          <div className="ui-dropdown__list" data-tag="dropdown-menu">
            <DropdownList onChange={selectItem} onReset={reset} value={value} activeItem={activeItem} {...listProps} />
          </div>
        </Popper>
      )}
    </>
  )
}

export default DropdownMenu
