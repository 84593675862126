import { useFormikContext } from 'formik'
import { useMemo } from 'react'

import ancillaryUtils from '@lib/ancillary'
import { PASSENGERS_CODE } from '@lib/passengers'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'

const usePassengersCount = (): number => {
  const {
    values: { passengers },
  } = useFormikContext<CheckoutFormData>()

  return useMemo(() => ancillaryUtils.getMaxCount(passengers, [PASSENGERS_CODE.infant]), [passengers])
}

export default usePassengersCount
