import { Field, getIn, useFormikContext } from 'formik'
import { FieldProps } from 'formik/dist/Field'
import React, { ReactElement, useEffect, useMemo } from 'react'

import { useTranslation } from '@lib/i18n'
import { RadioGroup } from '@ui'

const GENDERS = ['F', 'M']

interface GenderProps {
  name: string
}

const Gender = ({ name }: GenderProps): ReactElement => {
  const { t } = useTranslation()
  const genderOptions = useMemo(
    () =>
      GENDERS.map(value => ({
        value,
        label: t(`checkout.passengerDetails.gender.${value}`),
      })),
    [t],
  )
  const { setFieldValue, values } = useFormikContext()
  useEffect(() => {
    if (getIn(values, name) == null) {
      setFieldValue(name, GENDERS[0])
    }
  }, [name, setFieldValue, values])

  return (
    <Field name={name}>
      {({ field, form }: FieldProps<string>) => (
        <RadioGroup
          label={t('checkout.passengerDetails.gender.label')}
          value={field.value}
          options={genderOptions}
          onChange={value => {
            form.setFieldValue(name, value)
          }}
          direction="row"
        />
      )}
    </Field>
  )
}

export default Gender
