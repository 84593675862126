import React, { ReactElement } from 'react'

import Station from '@components/Station'
import date from '@lib/date'

export interface ArrivalTimeProps {
  connection: Connection
}

const ArrivalDateTime = ({ connection }: ArrivalTimeProps): ReactElement => {
  return <Station.DateTime date={date.parse(connection.arrivalTime, 'UTC')} />
}

export default ArrivalDateTime
