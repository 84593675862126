import cn from 'classnames'
import React, { FocusEvent, MouseEvent, ReactElement, useState } from 'react'

import useValidators from '@hooks/useValidators'
import { Dropdown, Input } from '@ui'
import { DropdownItem } from '@ui/Dropdown'

interface PassengerDiscountCodeProps {
  availableCards: DiscountCard.Item[]
  label: string
  onChange: (card: DiscountCode.Card | null) => void
  setError: (error: string | undefined) => void
  error: string | undefined | null
  fullWidth?: boolean
  initialValues?: DiscountCode.Card
  disabled?: boolean
}

interface InitialCardValues {
  name: DiscountCode.Type | null
  code: string
}

const cardToOption = ({ name, marketingName }: DiscountCard.Item): DropdownItem<DiscountCode.Type> => ({
  value: name,
  label: marketingName,
})

const findCard = (cards: DiscountCard.Item[], name?: DiscountCode.Type): DiscountCard.Item | undefined =>
  cards?.find(card => card.name === name)

const getCardValue = (list: DropdownItem<string>[], card?: DiscountCode.Card): InitialCardValues => {
  if (!card) return { name: null, code: '' }
  if (!list.some(item => item.value === card.name)) return { name: null, code: '' }

  return { name: card.name, code: card.code ?? '' }
}

const DiscountSelect = (props: PassengerDiscountCodeProps): ReactElement => {
  const { availableCards, setError, onChange, error, fullWidth, disabled, label, initialValues } = props
  const { required } = useValidators()
  const options = availableCards?.map(cardToOption)
  const classNames = cn(fullWidth ? 'cell-12' : 'cell-6 cell-sm-12')

  const [selectedCardName, setSelectedCardName] = useState<DiscountCode.Type | null>(
    () => getCardValue(options, initialValues).name,
  )
  const [selectedCardCode, setSelectedCardCode] = useState<string>(() => getCardValue(options, initialValues).code)
  const [selectedCard, setSelectedCard] = useState<DiscountCard.Item | null | undefined>(() =>
    findCard(availableCards, initialValues?.name),
  )
  const [errorVisible, setErrorVisible] = useState<boolean>(false)

  const updateCard = (name: DiscountCode.Type, code: string, card?: DiscountCard.Item): void => {
    if (card?.requiresCode) {
      onChange({ name, code })
    } else {
      setSelectedCardCode('')
      onChange({ name })
    }
  }

  const onCardDataChange = (name: DiscountCode.Type, code: string): void => {
    const card = findCard(availableCards, name)
    const error = card?.requiresCode ? required(selectedCardCode) : undefined
    setSelectedCard(card)
    setError(error)

    if (!error) updateCard(name, code, card)
  }

  const handleReset = (event?: MouseEvent): void => {
    event && event.stopPropagation()
    setSelectedCardCode('')
    setSelectedCardName(null)
    setSelectedCard(null)
    setError(undefined)
    onChange(null)
  }
  const handleCodeBlur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setErrorVisible(true)
    onCardDataChange(selectedCardName as DiscountCode.Type, e.target.value)
  }
  const handleNameChange = (name: DiscountCode.Type, event?: MouseEvent): void => {
    event && event.stopPropagation()
    setSelectedCardName(name)
    onCardDataChange(name, selectedCardCode)
  }

  return (
    <>
      <div className={classNames}>
        <Dropdown
          items={options}
          label={label}
          value={selectedCardName}
          onChange={(item, event) => handleNameChange(item, event)}
          onReset={handleReset}
          resettable
          disabled={disabled}
        />
      </div>
      {selectedCard?.requiresCode && (
        <div className={classNames}>
          <Input
            label={selectedCard.description}
            required
            value={selectedCardCode}
            onChange={setSelectedCardCode}
            onBlur={handleCodeBlur}
            errorMessage={errorVisible ? error : null}
            disabled={disabled}
          />
        </div>
      )}
    </>
  )
}

export default DiscountSelect
