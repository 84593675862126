import React, { ReactElement } from 'react'

import Station from '@components/Station'
import date from '@lib/date'

export interface DepartureTimeProps {
  date: string
}

const DepartureTime = (props: DepartureTimeProps): ReactElement => {
  return <Station.Time date={date.parse(props.date, 'UTC')} />
}

export default DepartureTime
