import React, { ReactElement } from 'react'

import { VGS } from '@lib/vgs'
import VGSField from '@pages/Checkout/Payment/CreditCardForm/VGS/Field'

interface HolderFieldProps {
  form: VGS.Form
  name: string
  placeholder: string
  touched?: boolean
}

const HolderField = ({ form, name, placeholder, touched }: HolderFieldProps): ReactElement => {
  return (
    <VGSField
      id="vgs-holder"
      form={form}
      touched={touched}
      options={{
        name,
        placeholder,
        type: 'text',
        validations: ['required'],
        tokenization: { format: 'NUM_LENGTH_PRESERVING', storage: 'PERSISTENT' },
      }}
    />
  )
}

export default HolderField
