interface Product {
  id: string
  name: string
  category: 'single' | 'round'
  variant: 'inbound' | 'outbound'
  brand: CarrierCode
  dimension7: RetailerNumber
  quantity: number
  price: number | null
}

export interface CheckoutEvent {
  event: 'eec.checkout'
  ecommerce: {
    currencyCode: Currency
    checkout: {
      actionField: {
        step: number
        affiliation: RetailerNumber
        revenue: number | null
        tax: number | null
      }
      products: Product[]
    }
  }
}

export interface CheckoutEventParams {
  step: number
  currency: Currency
  retailerPartnerNumber: RetailerNumber
  fareClass: string
  pax: number
  outbound: Connection | null
  inbound: Connection | null
  fees?: Fee[]
  price?: Money
}

export const buildCheckoutEvent = (params: CheckoutEventParams): CheckoutEvent => {
  const trips = [params.outbound, params.inbound].filter(x => x != null) as Connection[]
  const price = (() => {
    if (params.price?.fractional == null) return null

    return params.price.fractional / 100
  })()

  const fees = (() => {
    if (params.fees == null) return 0

    return params.fees?.reduce((acc, fee) => acc + fee.amount.fractional, 0) / 100
  })()

  const totalPrice = (() => {
    if (price == null) return null

    return price + fees
  })()

  const passengerTripPrice = (() => {
    if (totalPrice == null) return null

    return totalPrice / params.pax / trips.length
  })()

  return {
    event: 'eec.checkout',
    ecommerce: {
      currencyCode: params.currency,
      checkout: {
        actionField: {
          step: params.step,
          affiliation: params.retailerPartnerNumber,
          revenue: totalPrice,
          tax: fees,
        },
        products: trips.map((trip, index) => {
          const idParams = [
            trip.marketingCarrier.code,
            trip.departureStation.code,
            trip.arrivalStation.code,
            trip.departureTime.substring(0, 16),
            trip.arrivalTime.substring(0, 16),
            params.fareClass,
          ]
          const id = idParams.filter(x => x != null).join('-')

          return {
            id,
            name: `${trip.departureStation.code}-${trip.arrivalStation.code}`,
            category: trips.length > 1 ? 'round' : 'single',
            variant: index > 0 ? 'inbound' : 'outbound',
            brand: trip.marketingCarrier.code,
            dimension7: params.retailerPartnerNumber,
            quantity: params.pax,
            price: passengerTripPrice,
          }
        }),
      },
    },
  }
}
