import { addDays, max, parseISO, subWeeks } from 'date-fns'
import { useFormikContext } from 'formik'
import { useMemo, useState } from 'react'

import {
  PriceCalendarInboundRequest,
  PriceCalendarOutboundRequest,
  type PriceCalendarResponse,
} from '@api/priceCalendar'
import { SearchFormState } from '@components/SearchForm'
import config from '@config'
import dateUtils from '@lib/date'
import { LoaderHook } from '@lib/loader'
import paramsUtils from '@lib/params'
import utils from '@lib/utils'
import { usePriceCalendarLoader } from '@loaders/priceCalendar'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

type RangeDates = {
  start: string
  end: string
}

interface TripPricesResult {
  loader: LoaderHook<PriceCalendarResponse>
  setEnabled: (enabled: boolean) => void
}

interface UsePriceCalendarHook {
  outbound: TripPricesResult
  inbound: TripPricesResult
}

const today = parseISO(dateUtils.formatDate(new Date()))

const buildRange = (selectedDate: Date, earliestDate: Date): RangeDates => {
  const weekAgo = subWeeks(selectedDate, 1)
  const startDay = max([earliestDate, weekAgo])

  return {
    start: dateUtils.formatDate(startDay),
    end: dateUtils.formatDate(addDays(startDay, config.priceCalendarDateRange)),
  }
}

const usePriceCalendar = (): UsePriceCalendarHook => {
  const [{ currency, retailerPartnerNumber, marketingCarrierCode }] = useParams()
  const [{ priceCalendar }] = useSettings()
  const [outboundEnabled, setOutboundEnabled] = useState<boolean>(false)
  const [inboundEnabled, setInboundEnabled] = useState<boolean>(false)
  const { values } = useFormikContext<SearchFormState>()
  const { departureLocation, arrivalLocation, departureDate, departureTime, returnDate } = values

  const calendarParams = useMemo(
    () => ({
      ...(departureLocation && paramsUtils.flatLocation(departureLocation, 'departure')),
      ...(arrivalLocation && paramsUtils.flatLocation(arrivalLocation, 'arrival')),
      retailerPartnerNumber,
      currency,
      marketingCarrierCodes: marketingCarrierCode ? [marketingCarrierCode] : null,
    }),
    [arrivalLocation, currency, departureLocation, marketingCarrierCode, retailerPartnerNumber],
  )

  const outboundParams = useMemo<PriceCalendarOutboundRequest>(() => {
    const { start, end } = buildRange(departureDate, today)
    return { ...calendarParams, departureDateStart: start, departureDateEnd: end, departureStartTime: departureTime }
  }, [calendarParams, departureDate, departureTime])

  const inboundParams = useMemo<PriceCalendarInboundRequest>(() => {
    const { start, end } = buildRange(returnDate ?? departureDate, departureDate)
    return {
      ...calendarParams,
      returnDateStart: start,
      returnDateEnd: end,
      departureDate: dateUtils.formatDate(departureDate),
    }
  }, [calendarParams, departureDate, returnDate])

  const outboundLoader = usePriceCalendarLoader(
    { direction: 'outbound', params: utils.object.compact(outboundParams) },
    { enabled: priceCalendar.enabled && outboundEnabled },
  )

  const inboundLoader = usePriceCalendarLoader(
    { direction: 'inbound', params: utils.object.compact(inboundParams) },
    { enabled: priceCalendar.enabled && inboundEnabled },
  )

  return {
    outbound: {
      loader: outboundLoader,
      setEnabled: setOutboundEnabled,
    },
    inbound: {
      loader: inboundLoader,
      setEnabled: setInboundEnabled,
    },
  }
}

export default usePriceCalendar
