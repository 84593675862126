import React, { ReactElement } from 'react'

interface InputLabelProps {
  text: string
  required?: boolean
  className?: string
}

const InputLabel = ({ text, required, className }: InputLabelProps): ReactElement => {
  return <label className={className} data-nosnippet="true">{`${text}${required ? '*' : ''}`}</label>
}

export default InputLabel
