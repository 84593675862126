import makeApi from '@lib/api'

export interface Request {
  retailerPartnerNumber: number
}

export type Response = CreditCard.Saved[]

export default {
  load: async ({ retailerPartnerNumber }: Request) =>
    await makeApi.get<{}, Response>(`/retailer/${retailerPartnerNumber}/cards`)({}),
}
