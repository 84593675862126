import React, { ReactElement } from 'react'

import TransFromHtml from '@components/TransFromHtml'
import { useTranslation } from '@lib/i18n'

interface MessageProps {
  isReturn: boolean
  outbound: Connection
}

const Message = ({ isReturn, outbound }: MessageProps): ReactElement => {
  const { i18n } = useTranslation()
  const { departureStation, arrivalStation } = outbound

  const directionKey = isReturn ? 'return' : 'oneWay'
  const routeKey = `${departureStation.code}_${arrivalStation.code}`

  const translationKey = (() => {
    const byRoute = `expressCheckout.message.route.${routeKey}`
    const byDirection = `expressCheckout.message.direction.${directionKey}`
    const defaults = `expressCheckout.message.text`

    return [byRoute, byDirection, defaults].find(key => i18n.exists(key))
  })()

  /* istanbul ignore if */
  if (!translationKey) return <></>

  return <TransFromHtml i18nKey={translationKey} />
}

export default Message
