import React, { ReactElement, ReactNode } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import Card from '@pages/Checkout/Extras/Card/index'

interface DetailsProps {
  ancillary: Ancillary.Item
  description?: ReactNode | string
  controls: ReactNode
}

const Details = ({ ancillary, description, controls }: DetailsProps): ReactElement => {
  const { name, price } = ancillary
  const isMobile = useIsMobile()

  return (
    <div className={bem('extras', 'section-details')}>
      <Card title={name} description={description} controls={controls} price={isMobile ? price : []} />
    </div>
  )
}

export default Details
