import { useCallback } from 'react'

import { PaymentEvents } from '@hooks/useBookingFlow'
import { VGS } from '@lib/vgs'

export const useVgsValidate = (form?: VGS.Form): PaymentEvents['validate'] => {
  /* istanbul ignore next */
  return useCallback(async () => {
    if (!form) return { paymentMethodData: 'VGS is not ready' }

    const isValid = Object.values(form.state).every(({ isValid }) => isValid)
    if (!isValid) return { paymentMethodData: 'Invalid VGS fields' }
  }, [form])
}
