import React, { ReactElement } from 'react'

import { Skeleton } from '@ui'

const ExtrasSkeleton = (): ReactElement => {
  return (
    <div className="row gap-4">
      <div className="cell no-grow">
        <Skeleton.Item height={56} width={56} />
      </div>
      <div className="cell">
        <div className="column gap-1">
          <div className="cell-6">
            <Skeleton.Item height={24} />
          </div>
          <div className="cell-10">
            <Skeleton.Item height={20} />
          </div>
          <div className="cell-3">
            <Skeleton.Item height={20} />
          </div>
        </div>
      </div>
      <div className="cell-1">
        <Skeleton.Item height={20} />
      </div>
    </div>
  )
}

export default ExtrasSkeleton
