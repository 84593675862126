import { Field } from 'formik'
import React, { ReactElement } from 'react'

import useValidators from '@hooks/useValidators'
import { useTranslation } from '@lib/i18n'
import { useSettings } from '@queries/settings'
import InputField from '@ui/Input/Field'

const ContactDetails = (): ReactElement => {
  const { t } = useTranslation()
  const { required } = useValidators()
  const [{ email }] = useSettings()

  return (
    <div>
      <h3 className="mb-1">{t('checkout.express.sendTicket')}</h3>
      <div className="mb-4">{t('checkout.express.contactDescription')}</div>
      <div className="row column-sm">
        <div className="cell-6 cell-sm-12">
          <Field
            component={InputField}
            label={t('checkout.contactDetails.email')}
            name="contact.email"
            validate={!email.optional && required}
            required={!email.optional}
          />
        </div>
      </div>
    </div>
  )
}

export default ContactDetails
