import installmentsUtils from '@lib/installments'
import { useInstallmentRates } from '@loaders/installmentRates'

interface UseInstallmentsParams {
  price: Money | null
  marketingCarrierCode: CarrierCode
  retailerPartnerNumber: RetailerNumber
}

interface UseInstallmentsHook {
  isLoading: boolean
  data: Installment.Item[]
}

const useInstallments = ({ price, ...params }: UseInstallmentsParams): UseInstallmentsHook => {
  const { isLoading, data: rates } = useInstallmentRates(params)

  const data = price ? installmentsUtils.buildFromRates(price, rates ?? []) : []

  return { isLoading, data }
}

export default useInstallments
