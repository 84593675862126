import { VacancyRequest, VacancyResponse } from '@api/vacancy'
import ancillaryUtils from '@lib/ancillary'

interface TripTimes {
  departureDateTime: string
  arrivalDateTime: string
}

const DATE_TIME_PATTERN = '(\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2})'
const TRIP_TIMES_REGEX = new RegExp(`${DATE_TIME_PATTERN}-${DATE_TIME_PATTERN}`)

const formatDateTime = (dateTimeString: string): string => `${dateTimeString}+00:00`

const getCategory = (ancillary: Ancillary.Item): AncillaryCategory => ancillaryUtils.getCategoryFromCode(ancillary.code)

const getAncillaries = (vacancy: any): Ancillary.Item[] => {
  const ancillaries = vacancy.ancillaryOptions ?? /* istanbul ignore next */ []

  return ancillaries.map((ancillary: Ancillary.Item) => ({ ...ancillary, category: getCategory(ancillary) }))
}

const getDateTimeString = (date?: string | null, time?: string | null): string | null => {
  if (!date || !time) return null

  return formatDateTime(`${date}T${time}`)
}

const getRequiredTripDateTimes = (vacancyId: string, requestParams: VacancyRequest): TripTimes => {
  let departureDateTime = getDateTimeString(requestParams.departureDate, requestParams.departureTime)
  let arrivalDateTime = getDateTimeString(requestParams.arrivalDate, requestParams.arrivalTime)

  // This logic is required for the cases when a Vacancy is called from a widget and a user gets redirected
  // directly to a checkout page where departure and arrival dateTimes are required. For not it's the only way
  // to get these dateTimes as `vacancy` response does not contain this information directly
  if (!departureDateTime || !arrivalDateTime) {
    const match = vacancyId.match(TRIP_TIMES_REGEX)

    /* istanbul ignore else */
    if (match) {
      departureDateTime = departureDateTime ?? formatDateTime(match[1])
      arrivalDateTime = arrivalDateTime ?? formatDateTime(match[2])
    }
  }

  return {
    departureDateTime: departureDateTime ?? /* istanbul ignore next */ '',
    arrivalDateTime: arrivalDateTime ?? /* istanbul ignore next */ '',
  }
}

const transformResponse = (response: any, rawResponse: any, requestParams: VacancyRequest): VacancyResponse => {
  if (rawResponse?.jsonapi?.version == null) return rawResponse

  const { departureDateTime, arrivalDateTime } = getRequiredTripDateTimes(response.id, requestParams)

  return {
    originalPrice: response.originalPrice,
    vacant: response.vacant,
    price: response.totalPrice,
    fees: response.fees,
    reason: response.reason,
    ancillaries: getAncillaries(response),
    departureTime: departureDateTime,
    arrivalTime: arrivalDateTime,
    returnDepartureTime: getDateTimeString(requestParams.returnDepartureDate, requestParams.returnDepartureTime),
    returnArrivalTime: getDateTimeString(requestParams.returnArrivalDate, requestParams.returnArrivalTime),
    appliedCards: response.appliedCards,
    termsAndConditions: rawResponse.meta?.terms_and_conditions ?? /* istanbul ignore next */ {},
  }
}

export { transformResponse }
