import cn from 'classnames'
import React, { useState } from 'react'

import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { Icon } from '@ui'
import AttachedFile from '@ui/FileUpload/AttachedFile'

import '@ui/FileUpload/index.scss'

interface Props {
  maxFiles: number
  maxFileSize: number
  fileTypes: string[]
  name: string
  value: File[]
  onChange: (files: File[]) => void
}

const getExtension = (file: File): string => {
  const parts = file.name.split('.')
  return `.${parts[parts.length - 1]}`
}

const FileUpload = (props: Props) => {
  const { name, maxFiles, maxFileSize, fileTypes, value, onChange } = props
  const { t } = useTranslation()
  const [invalidFiles, setInvalidFiles] = useState<File[]>([])

  const formats = fileTypes.join(', ').toUpperCase()
  const id = `ui-file-upload-${name}-${Math.random()}`
  const filesizeInBytes = maxFileSize * 1024 * 1024

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    /* istanbul ignore else */
    if (files) {
      const [validFiles, invalidFiles] = Array.from(files).reduce<File[][]>(
        ([validFiles, invalidFiles], file) => {
          if (file.size > filesizeInBytes || !fileTypes.includes(getExtension(file))) {
            invalidFiles.push(file)
          } else {
            validFiles.push(file)
          }
          return [validFiles, invalidFiles]
        },
        [[], []],
      )

      onChange([...value, ...validFiles])
      setInvalidFiles(prev => [...prev, ...invalidFiles])
    }
  }

  const disabled = value.length >= maxFiles

  return (
    <div className={cn('column', bem('ui-file-upload', { disabled }))}>
      <h4 className="cell mb-1">{t('components.fileUpload.title')}</h4>
      <div className="cell mb-3 body-14">
        {t('components.fileUpload.description', { maxFiles, maxFileSize, formats })}
      </div>
      <div className="cell mb-3">
        <div className="column gap-2">
          {value.map((file, index) => (
            <div className="cell" key={file.name}>
              <AttachedFile file={file} onRemove={() => onChange(value.filter((_, i) => i !== index))} />
            </div>
          ))}
          {invalidFiles.map((file, index) => (
            <>
              <div className="cell" key={file.name}>
                <AttachedFile
                  file={file}
                  onRemove={() => setInvalidFiles(files => files.filter((_, i) => i !== index))}
                />
              </div>
              <div className={cn('cell', bem('ui-file-upload', 'invalid'))}>
                <div className="row items-center gap-1">
                  <Icon name="alert" size="small" />
                  <div className="body-12">{t('components.fileUpload.invalidFile', { maxFileSize, formats })}</div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <label className={bem('ui-file-upload', 'button')} htmlFor={id}>
        <Icon name="upload" size="large" />
        <div className="bolder">{t('components.fileUpload.upload')}</div>
        <input
          accept={fileTypes.join(',')}
          id={id}
          onChange={handleFileInput}
          className={bem('ui-file-upload', 'input')}
          type="file"
          value=""
          disabled={disabled}
        />
      </label>
    </div>
  )
}

export default FileUpload
