import React from 'react'

import Sentry from '@lib/sentry'

interface Props {
  children: React.ReactNode
  fallback?: (data: { error: Error }) => React.ReactElement
}

/* istanbul ignore next */
const DEFAULT_FALLBACK = <></>

const ErrorBoundary = ({ children, fallback }: Props) => {
  return <Sentry.ErrorBoundary fallback={fallback || DEFAULT_FALLBACK}>{children}</Sentry.ErrorBoundary>
}

export default ErrorBoundary
