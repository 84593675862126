import React, { ReactElement } from 'react'

import ListItem, { ItemProps } from '@components/RouteBreakdown/Item'
import Station from '@components/Station'
import bem from '@lib/bem'
import connection from '@lib/connection'
import date from '@lib/date'
import { useTranslation, TFunction } from '@lib/i18n'

interface StationItem {
  type: 'station'
  station: string
  time: Date | null
  vehicle?: Vehicle.Code
}

interface TransferItem {
  type: 'transfers'
  count: number
}

interface DurationItem {
  type: 'duration'
  start: Date
  end: Date
}

export type RouteItem = StationItem | TransferItem | DurationItem

interface RouteBreakdownProps {
  route: RouteItem[]
}

interface ToListItemPropsOptions {
  t: TFunction
}

const toListItemProps = (routeItem: RouteItem, { t }: ToListItemPropsOptions): ItemProps => {
  switch (routeItem.type) {
    case 'station':
      return {
        left: routeItem.time && <Station.Time date={routeItem.time} />,
        icon: connection.getVehicleIcon(routeItem.vehicle),
        right: routeItem.station,
      }

    case 'transfers':
      return {
        icon: 'transfer',
        right: t('components.routeBreakdown.transfers', { count: routeItem.count }),
      }

    case 'duration':
      return {
        right: date.durationToString(routeItem.start, routeItem.end, ['hours', 'minutes']),
        type: 'duration',
      }
  }
}

const RouteBreakdown = ({ route }: RouteBreakdownProps): ReactElement => {
  const { t } = useTranslation()
  const listProps = route.map(item => toListItemProps(item, { t }))
  const hideLeft = listProps.every(({ left }) => left == null)

  return (
    <div className={bem('route-breakdown', { 'hide-left': hideLeft })} data-tag="route-breakdown">
      {listProps.map((props, index) => (
        <ListItem key={String(index)} {...props} />
      ))}
    </div>
  )
}

export default RouteBreakdown
