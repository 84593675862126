import React, { ReactElement, useMemo, useState } from 'react'

import Section from '@components/ConnectionFilters/Section'
import useIsMobile from '@hooks/useIsMobile'
import useIsTablet from '@hooks/useIsTablet'
import amplitude from '@lib/analytics/amplitude'
import CONNECTION_FILTERS from '@lib/connections/filters/all'
import { FilterOptions, TripDirection } from '@lib/connections/filters/types'
import filterUtils from '@lib/filter'
import { useTranslation } from '@lib/i18n'
import { useSettings } from '@queries/settings'
import { useConnectionFilters } from '@stores/connectionFilters'
import { Button, IconText } from '@ui'

export interface InlineFiltersProps {
  connections: Connection[]
  tripDirection: TripDirection
  options: FilterOptions
}

const initialVisibility = Object.keys(CONNECTION_FILTERS).reduce((acc, key) => ({ ...acc, [key]: true }), {})

const InlineFilters = ({ connections, options, tripDirection }: InlineFiltersProps): ReactElement => {
  const [filterValues, setFilterValues, { reset: resetFilters }] = useConnectionFilters()
  const [{ filter }] = useSettings()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const { t } = useTranslation()
  const [visibleFilters, setVisibleFilters] = useState<Record<string, boolean>>(initialVisibility)

  const handleChange = (value: Record<string, string[]>): void => {
    amplitude.results.clickFilter(value)

    setFilterValues(value)
  }

  const showButtons = useMemo(() => !(isMobile || isTablet), [isMobile, isTablet])

  const getTitle = (key: string): string => t(`journeyList.filters.${key}`, { defaultValue: '' })

  return (
    <div className="connection-filters">
      {filter.supported.map(item => {
        const key = filterUtils.getFilterKey(item)
        const { Component, title } = CONNECTION_FILTERS[key]
        const titleKey = typeof title === 'function' ? title({ tripDirection }) : title

        return (
          <Section key={key} title={titleKey && getTitle(titleKey)} hidden={!visibleFilters[key]}>
            <Component
              options={options}
              value={filterValues[key]}
              onChange={value => {
                handleChange({ [key]: value })
              }}
              data={connections}
              setVisibility={visible => setVisibleFilters(prev => ({ ...prev, [key]: visible }))}
            />
          </Section>
        )
      })}
      {showButtons && (
        <Section>
          <Button variant="text" color="secondary" onClick={() => resetFilters()}>
            <IconText label={t('journeyList.filters.clearAllFilters')} iconName="cross" />
          </Button>
        </Section>
      )}
    </div>
  )
}

export default InlineFilters
