const hasFeature = (feature: FareFeature, code: string): boolean => feature.code === code

const isFullyRefundable = (feature: FareFeature): boolean => hasFeature(feature, 'REFU')

const isPartiallyRefundable = (feature: FareFeature): boolean => hasFeature(feature, 'PREF')

const isNotRefundable = (feature: FareFeature): boolean => hasFeature(feature, 'NREF')

const isVoucherRefund = (feature: FareFeature): boolean => hasFeature(feature, 'VREF')

const isAmendable = (feature: FareFeature): boolean => hasFeature(feature, 'AMEN')

const isNonAmendable = (feature: FareFeature): boolean => hasFeature(feature, 'NAME')

const hasRefundFeatures = (feature: FareFeature): boolean => {
  return [
    isFullyRefundable(feature),
    isPartiallyRefundable(feature),
    isNotRefundable(feature),
    isVoucherRefund(feature),
    isAmendable(feature),
    isNonAmendable(feature),
  ].some(item => item)
}

export default {
  isFullyRefundable,
  isPartiallyRefundable,
  isNotRefundable,
  isVoucherRefund,
  hasRefundFeatures,
  isAmendable,
  isNonAmendable,
}
