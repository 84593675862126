import { QueryClient, QueryClientProvider as OriginalQueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import {
  QueryClient as DeprecatedQueryClient,
  QueryClientProvider as OriginalDeprecatedQueryClientProvider,
} from 'react-query'

const deprecatedQueryClient = new DeprecatedQueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

export const QueryClientProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <OriginalQueryClientProvider client={queryClient}>
      <OriginalDeprecatedQueryClientProvider client={deprecatedQueryClient}>
        {children}
      </OriginalDeprecatedQueryClientProvider>
    </OriginalQueryClientProvider>
  )
}
