import React, { ReactElement, useMemo, useRef } from 'react'

import Thumb from '@ui/Slider/Thumb'

import '@ui/Slider/index.scss'

export type SliderValue = [number, number]

interface Props {
  value: SliderValue
  onChange: (value: SliderValue) => void
  min: number
  max: number
  minDistance: number
}

interface Range {
  start: number
  size: number
}

const Slider = (props: Props): ReactElement => {
  const { value, onChange, min, max, minDistance } = props
  const sliderRef = useRef<HTMLDivElement>(null)

  const { start, size } = useMemo((): Range => {
    return {
      start: ((value[0] - min) / (max - min)) * 100,
      size: ((value[1] - value[0]) / (max - min)) * 100,
    }
  }, [max, min, value])
  const sliderBounds = useMemo(() => ({ min, max }), [min, max])

  return (
    <div className="ui-slider" ref={sliderRef}>
      <div className="ui-slider__rail" />
      <div className="ui-slider__track" style={{ left: `${start}%`, width: `${size}%` }} />
      <Thumb
        sliderRef={sliderRef}
        value={start}
        sliderBounds={sliderBounds}
        thumbBounds={{ min, max: value[1] - minDistance }}
        onChange={thumb => onChange([thumb, value[1]])}
      />
      <Thumb
        sliderRef={sliderRef}
        value={start + size}
        sliderBounds={sliderBounds}
        thumbBounds={{ min: value[0] + minDistance, max }}
        onChange={thumb => onChange([value[0], thumb])}
      />
    </div>
  )
}

export default Slider
