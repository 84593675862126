import { FieldProps, useField } from 'formik'
import React, { ReactElement } from 'react'

import { DatePicker } from '@ui'
import { DatePickerProps } from '@ui/DatePicker'

type DatePickerFieldProps = FieldProps<Date> & DatePickerProps

const DatePickerField = ({ field, form: { setFieldValue }, onChange, ...rest }: DatePickerFieldProps): ReactElement => {
  const [_, { touched, error }] = useField(field.name)
  const errorMessage = touched ? error : null
  const handleChange = (value: Date): void => {
    setFieldValue(field.name, value)
    onChange?.(value)
  }

  return <DatePicker {...field} {...rest} errorMessage={errorMessage} onChange={handleChange} />
}

export default DatePickerField
