import makeApi from '@lib/api'

export interface BillingAddressRequest {
  retailerPartnerNumber: string
}

export interface BillingAddressResponse {
  id: string
  retailerPartnerNumber: number
  street: string
  city: string
  country: string
  state: string
  postalCode: string
}

export default {
  load: async (retailerPartnerNumber: string): Promise<BillingAddressResponse> => {
    const callApi = makeApi.get<{}, BillingAddressResponse>(`/retailer/${retailerPartnerNumber}/billing_address`)

    return await callApi({})
  },
}
