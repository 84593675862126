import React, { ReactElement, ReactNode, useMemo } from 'react'

import CheckoutSkeleton from '@components/Initializer/Skeletons/Checkout'
import LandingSkeleton from '@components/Initializer/Skeletons/Landing'
import ResultSkeleton from '@components/Initializer/Skeletons/Result'
import SearchSkeleton from '@components/Initializer/Skeletons/Search'
import Loader from '@components/Loader'

import '@components/Initializer/Skeletons/index.scss'

const PageSkeleton = (): ReactElement => {
  const skeletons: Record<string, ReactNode> = useMemo(
    () => ({
      result: <ResultSkeleton />,
      search: <SearchSkeleton />,
      landing: <LandingSkeleton />,
      checkout: <CheckoutSkeleton />,
      default: <Loader loading />,
    }),
    [],
  )

  const getSkeleton = (): ReactNode => {
    const key = location.pathname.split('/')[1]

    if (key === '') return skeletons.search
    if (!skeletons[key]) return skeletons.default

    return skeletons[key]
  }

  return <>{getSkeleton()}</>
}

export default PageSkeleton
