import React, { ReactElement } from 'react'

import useSearchForm from '@components/SearchBar/useSearchForm'
import SearchForm from '@components/SearchForm'

import '@components/SearchBar/index.scss'

const SearchBar = (): ReactElement => {
  const { settings, submit, initialValues } = useSearchForm()

  return (
    <div className="search-bar">
      <SearchForm settings={settings} initialValues={initialValues} onSubmit={submit} />
    </div>
  )
}

export default SearchBar
