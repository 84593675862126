import React, { ReactElement } from 'react'

import bem from '@lib/bem'
import Icon from '@ui/Icon'

interface ItemProps {
  icon: string
  title: string | null
}

const Item = ({ icon, title }: ItemProps): ReactElement => {
  return (
    <div key={title}>
      <span className={bem('trip-conditions', 'wrapper')}>
        <span className={bem('trip-conditions', 'icon')}>
          <Icon name={icon} size="medium" />
        </span>
        <span className={bem('trip-conditions', 'title')}>{title}</span>
      </span>
    </div>
  )
}

export default Item
