import typesAPI, { PassengersTypesResponse } from '@api/passengerTypes'
import { makeLoader } from '@lib/loader'

interface PassengersTypesLoaderParams {
  enabled: boolean
  marketingCarrierCode: CarrierCode | null
}

export const usePassengersTypesLoader = makeLoader<PassengersTypesLoaderParams, PassengersTypesResponse>({
  key: 'passengersType',
  enabled: params => params.enabled && !!params.marketingCarrierCode,
  loader: async params => await typesAPI.load({ marketingCarrierCode: params.marketingCarrierCode as CarrierCode }),
})
