import priceCalendarAPI, {
  type PriceCalendarOutboundRequest,
  type PriceCalendarResponse,
  PriceCalendarInboundRequest,
} from '@api/priceCalendar'
import { makeLoader } from '@lib/loader'

interface UsePriceCalendarOutboundLoader {
  direction: 'outbound'
  params: PriceCalendarOutboundRequest
}

interface UsePriceCalendarReturnLoader {
  direction: 'inbound'
  params: PriceCalendarInboundRequest
}

export type UsePriceCalendarLoader = UsePriceCalendarOutboundLoader | UsePriceCalendarReturnLoader

export const usePriceCalendarLoader = makeLoader<UsePriceCalendarLoader, PriceCalendarResponse>({
  key: 'priceCalendar',
  loader: ({ params, direction }) =>
    direction === 'outbound' ? priceCalendarAPI.outbound.load(params) : priceCalendarAPI.inbound.load(params),
  enabled: ({ params }) =>
    [params.departureStation, params.departureArea, params.departureCity].some(i => i && i.length > 0) &&
    [params.arrivalStation, params.arrivalArea, params.arrivalCity].some(i => i && i.length > 0),
  staleTime: 1000 * 60 * 10,
  cacheTime: 1000 * 60 * 10,
})
