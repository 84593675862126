import React, { ReactElement, useMemo } from 'react'

import InlineFilters from '@components/ConnectionFilters/InlineFilters'
import ModalFilters, { ModalFiltersProps } from '@components/ConnectionFilters/ModalFilters'
import useIsMobile from '@hooks/useIsMobile'
import useIsTablet from '@hooks/useIsTablet'

import '@components/ConnectionFilters/index.scss'

const ConnectionFilters = (props: ModalFiltersProps): ReactElement => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  const showModal = useMemo(() => isMobile || isTablet, [isMobile, isTablet])

  return showModal ? (
    <ModalFilters {...props} />
  ) : (
    <InlineFilters tripDirection={props.tripDirection} connections={props.connections} options={props.options} />
  )
}

export default ConnectionFilters
