import React, { Fragment, ReactElement, ReactNode } from 'react'

import { Divider, RadioGroup } from '@ui'
import { RadioGroupProps, RadioOption, RadioValue } from '@ui/RadioGroup'

export interface ExpandableRadioOption<T> extends RadioOption<T> {
  content?: ReactNode
}

type Props<T> = RadioGroupProps<T, ExpandableRadioOption<T>>

const ExpandableRadioGroup = <TValue extends RadioValue>(props: Props<TValue>): ReactElement => {
  const renderRadio = (option: ExpandableRadioOption<TValue>, radio: ReactElement, index: number): ReactElement => {
    const lastElement = props.options.length === index + 1
    return (
      <Fragment key={option.value.toString()}>
        {radio}
        {option.value === props.value && option.content}
        {props.divider && !lastElement && <Divider />}
      </Fragment>
    )
  }

  return <RadioGroup {...props} renderRadio={renderRadio} />
}

export default ExpandableRadioGroup
