import React, { MouseEvent, ReactElement, useCallback, useMemo } from 'react'

import { Option } from '@ui'
import { DropdownItem } from '@ui/Dropdown'
import SearchableList from '@ui/Dropdown/List/SearchableList'

export interface DropdownListProps<T> {
  value: T | null
  activeItem?: T | null
  items: DropdownItem<T>[]
  pinnedValues?: T[]
  onChange: (item: T, event?: MouseEvent) => void
  onReset?: (event?: MouseEvent) => void
  checkedMark?: boolean
  searchable?: boolean
  resettable?: boolean
  focus?: boolean
}

const DropdownList = <T extends string>({
  items,
  value,
  checkedMark,
  onChange,
  onReset,
  activeItem,
  searchable,
  resettable,
  focus,
  pinnedValues = [],
}: DropdownListProps<T>): ReactElement => {
  const pinnedItems = useMemo(
    () =>
      pinnedValues
        .map(value => items.find(item => item.value === value))
        .filter(item => item != null) as DropdownItem<T>[],
    [items, pinnedValues],
  )

  const allItems = useMemo(() => {
    const restItems = items.filter(({ value }) => !pinnedValues?.includes(value))

    return [...pinnedItems, ...restItems]
  }, [items, pinnedItems, pinnedValues])

  const isLastPinnedItem = useCallback(
    (value: T): boolean => value === pinnedItems[pinnedItems.length - 1].value,
    [pinnedItems],
  )

  const renderOption = useCallback(
    ({ value: itemValue, label, getClass }: DropdownItem<T>): ReactElement => (
      <Option
        key={itemValue + crypto.randomUUID()}
        value={itemValue}
        onSelect={onChange}
        checkedMark={checkedMark}
        selected={value === itemValue}
        active={activeItem === itemValue}
        className={getClass?.({ selected: activeItem === itemValue })}
        scroll={!searchable}
        separated={pinnedItems.length ? isLastPinnedItem(itemValue) : false}
        focus={focus}
      >
        {label ?? itemValue}
      </Option>
    ),
    [activeItem, checkedMark, isLastPinnedItem, onChange, pinnedItems.length, searchable, value, focus],
  )

  const renderResetOption = useCallback(
    ({ label }: { label: string }): ReactElement => (
      <Option
        key="no-value"
        value=""
        onSelect={(_, event) => {
          onReset?.(event)
        }}
        checkedMark={checkedMark}
      >
        {label}
      </Option>
    ),
    [checkedMark, onReset],
  )

  return (
    <>
      {resettable && value != null && renderResetOption({ label: ' – ' })}
      {searchable ? (
        <SearchableList value={value} items={allItems} renderItem={renderOption} />
      ) : (
        allItems.map(renderOption)
      )}
    </>
  )
}

export default DropdownList
