import { useMemo } from 'react'

import { SearchFormSettings } from '@components/SearchForm'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

const useSearchBarSettings = (): SearchFormSettings => {
  const [settings] = useSettings()
  const [params] = useParams()

  return useMemo(() => {
    const { returnTrip, passengerType, tripTime, passengerTypesList, discountCodes, paxDiscountCodes } = settings
    return {
      returnTrip: returnTrip.enabled,
      passengerType: params.bookingId ? false : passengerType.enabled,
      tripTime: tripTime.enabled,
      discountCodes: discountCodes.enabled && discountCodes.displayOn === 'everywhere',
      paxDiscountCodes:
        paxDiscountCodes.enabled &&
        passengerTypesList.enabled &&
        (paxDiscountCodes.displayOn === 'everywhere' || paxDiscountCodes.displayOn === 'search_results'),
    }
  }, [params.bookingId, settings])
}

export default useSearchBarSettings
