import { track } from '@amplitude/analytics-browser'
import { differenceInMinutes } from 'date-fns'

import cuurencyUtils from '@lib/currency'
import date from '@lib/date'
import fareUtils from '@lib/fare'
import sdk from '@lib/sdk'
import utils from '@lib/utils'
import paramsStore from '@stores/params'

const { formatPrice, getPriceInEuro } = cuurencyUtils

interface PerformanceProps {
  timeToLoad?: number | null
  timeToEvent: number
}

interface AnalyticsCommonProps {
  retailerPartnerNumber: number
  marketingCarrierCode: string | null
  express: boolean
  language: Locale
  currency: Currency
  url: string
  performance?: PerformanceProps
}

type PerformanceType = 'page' | 'widget'

interface EventOptions {
  measurePerformance: 'page' | 'widget'
}

const measureTime = (start?: string | PerformanceMeasureOptions): number =>
  Math.round(performance.measure('amplitudeEvent', start).duration)

const getPageLoadPerformance = (): PerformanceProps => ({
  timeToLoad: performance.getEntriesByType('navigation')[0].duration,
  timeToEvent: measureTime(),
})

const getInternalRedirectPerformance = (): PerformanceProps => ({
  timeToLoad: null,
  timeToEvent: measureTime('redirect'),
})

const getWidgetPerformance = (): PerformanceProps => {
  const scriptPerformance = performance.getEntriesByName(sdk.getSourcePath('js'))[0]

  return {
    timeToLoad: scriptPerformance?.duration,
    timeToEvent: measureTime({ start: scriptPerformance?.startTime }),
  }
}

const getPerformanceParams = (type: PerformanceType): PerformanceProps => {
  if (type === 'widget') return getWidgetPerformance()
  if (performance.getEntriesByName('redirect')[0] == null) return getPageLoadPerformance()

  return getInternalRedirectPerformance()
}

const getCommonParams = (options?: EventOptions): AnalyticsCommonProps => {
  const params = paramsStore.get()

  return utils.object.compact({
    retailerPartnerNumber: params.retailerPartnerNumber,
    marketingCarrierCode: params.marketingCarrierCode,
    express: false,
    language: params.locale,
    currency: params.currency,
    url: window.location.toString(),
    performance: options?.measurePerformance ? getPerformanceParams(options.measurePerformance) : undefined,
  })
}

const sendEvent = (key: string, params?: any, options?: EventOptions): void => {
  track(key, { ...getCommonParams(options), ...params })
}

export interface RouteEvent {
  departure: Location.Item
  arrival: Location.Item
  route: string
}

const getRouteParams = (
  { name: departureName, ...departure }: Location.NamedItem,
  { name: arrivalName, ...arrival }: Location.NamedItem,
): RouteEvent => ({
  departure: { code: departure.code, type: departure.type },
  arrival: { code: arrival.code, type: arrival.type },
  route: `${departureName} - ${arrivalName}`,
})

interface PriceEvent {
  priceInCurrency: number
  currency: Currency
  priceInEuro: number
}

const getPriceParams = (money: Money): PriceEvent => ({
  priceInCurrency: formatPrice(money.fractional),
  currency: money.currency,
  priceInEuro: money.currency === 'EUR' ? formatPrice(money.fractional) : getPriceInEuro(money),
})

const getConnectionParams = (connection: Connection, fareClassCode: string): any => {
  const { departureStation, arrivalStation } = connection
  const routeEventData = getRouteParams(
    { code: departureStation.code, name: departureStation.name, type: 'station' },
    { code: arrivalStation.code, name: arrivalStation.name, type: 'station' },
  )
  const departureTime = date.parse(connection.departureTime)
  const arrivalTime = date.parse(connection.arrivalTime)
  const fare: Fare | undefined = fareUtils.getFareByCode(fareClassCode, connection)

  return {
    ...routeEventData,
    ...(fare && getPriceParams(fare.price)),
    departureDateTime: connection.departureTime,
    duration: differenceInMinutes(arrivalTime, departureTime),
    fareClass: fareClassCode,
    fareFeatures: fare?.fareClass.fareFeatures.map(({ code }) => code),
  }
}

export default {
  sendEvent,
  getRouteParams,
  getPriceParams,
  getConnectionParams,
}
