/* istanbul ignore next */
const gtmScriptTemplate = (containerId: string | number): string =>
  `(function (w, d, s, l, i) {
    w[l] = w[l] || []
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    })
    const f = d.getElementsByTagName(s)[0]
    const j = d.createElement(s)
    const dl = l !== 'dataLayer' ? '&l=' + l : ''
    j.async = true
    j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window, document, 'script', 'dataLayer', '${containerId}')`

/* istanbul ignore next */
const init = ({ container_id }: Analytics.Settings['gtm']): void => {
  if (!container_id) return
  const gtm = document.createElement('script')
  gtm.innerHTML = gtmScriptTemplate(container_id)

  document.head.appendChild(gtm)

  const noscript = document.createElement('noscript')
  const iframe = document.createElement('iframe')
  const root = document.getElementById('root')
  iframe.height = '0'
  iframe.width = '0'
  iframe.style.display = 'none'
  iframe.style.visibility = 'hidden'
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${container_id}`

  noscript.appendChild(iframe)
  document.body.insertBefore(noscript, root)
}

export default {
  init,
}
