import React, { ReactElement, ReactNode } from 'react'

interface OptionsProps {
  options: ReactNode
  listClassNames: string
  errorMessage?: string | null
}

const Options = ({ options, listClassNames, errorMessage }: OptionsProps): ReactElement => {
  return (
    <div className={listClassNames} data-tag="autocomplete-options">
      {options}
      {errorMessage && <div className="ui-autocomplete__error-message">{errorMessage}</div>}
    </div>
  )
}

export default Options
