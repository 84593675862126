import { WebAssembly } from 'polywasm'

const isWasmSupported = (): boolean => typeof WebAssembly !== 'undefined'

const withWasmSupport = (wasmInitializer: () => Promise<unknown>, callback: () => void): void => {
  /* istanbul ignore if: impossible to test */
  if (!isWasmSupported()) {
    window.WebAssembly = WebAssembly
  }

  void wasmInitializer().then(callback)
}

export default {
  isWasmSupported,
  withWasmSupport,
}
