import React, { ReactElement, useEffect, useRef } from 'react'

import '@pages/Checkout/BookingDetails/SubmitSection/PayButton/Adyen.scss'

interface AdyenPayButtonProps {
  instance?: AdyenTypes.PayButtonElement | null
}

const AdyenPayButton = ({ instance }: AdyenPayButtonProps): ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    /* istanbul ignore else */
    if (instance != null && containerRef.current) {
      instance.mount(containerRef.current)

      return () => {
        instance?.unmount?.()
      }
    }
  }, [instance])

  return <div className="custom-pay-button" ref={containerRef} />
}

export default AdyenPayButton
