import React, { ReactElement } from 'react'

import currencyUtils from '@lib/currency'

interface MoneyProps {
  fractional: number
  currency: Currency
  signed?: boolean
}

const Money = ({ fractional, currency, signed }: MoneyProps): ReactElement => (
  <>
    {signed && fractional >= 0 && '+'}
    {currencyUtils.create(fractional, currency).format()}
  </>
)

export default Money
