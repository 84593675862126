import React, { ReactElement } from 'react'

import Station from '@components/Station'
import date from '@lib/date'

export interface DepartureDateProps {
  connection: Connection
}

const DepartureDateTime = ({ connection }: DepartureDateProps): ReactElement => {
  return <Station.DateTime date={date.parse(connection.departureTime, 'UTC')} />
}

export default DepartureDateTime
