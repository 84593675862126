import React, { ReactElement } from 'react'

import { Skeleton } from '@ui'

interface LineSkeletonProps {
  height?: number
}

const LineSkeleton = ({ height }: LineSkeletonProps): ReactElement => {
  return (
    <div className="cell-12 page-skeleton__bg-line">
      <Skeleton.Item height={height} />
    </div>
  )
}

export default LineSkeleton
