import { differenceInCalendarDays } from 'date-fns'
import React, { ReactElement } from 'react'

import date from '@lib/date'

export interface DayShiftProps {
  departureTime: string
  arrivalTime: string
}

const DayShift = (props: DayShiftProps): ReactElement => {
  const departureTime = date.parse(props.departureTime, 'UTC')
  const arrivalTime = date.parse(props.arrivalTime, 'UTC')

  const days: string = date.formatDuration({ days: differenceInCalendarDays(arrivalTime, departureTime) })
  const string = days ? `+${days}` : null

  return <>{string}</>
}

export default DayShift
