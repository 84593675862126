import CardElement from '@adyen/adyen-web/components/Card'
import { CardElementProps } from '@adyen/adyen-web/components/Card/types'
import Core from '@adyen/adyen-web/core'
import { useFormikContext } from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { PaymentMethodStatus } from '@enums'
import { ConfirmPaymentParams } from '@hooks/useBookingFlow'
import testingUtils from '@lib/testing'
import AdyenCardForm from '@pages/Checkout/Payment/CreditCardForm/Adyen'
import PaymentLabel from '@pages/Checkout/Payment/Label'

export const useCard = (adyen?: Core): AdyenTypes.Method => {
  const [cardInstance, setCardInstance] = useState<CardElement>()
  const { setFieldValue } = useFormikContext()

  const initCard = useCallback(
    (checkout: Core): void => {
      const configuration: CardElementProps = {
        hasHolderName: false,
        holderNameRequired: false,
        billingAddressRequired: false,
        showBrandsUnderCardNumber: false,
        hideCVC: false,
        onBrand: ({ brand }) => {
          setFieldValue('cardData.brand', brand)
        },
        onBinValue: ({ binValue }) => {
          setFieldValue('cardData.bin', binValue)
        },
        onBinLookup: /* istanbul ignore next */ ({ issuingCountryCode }) => {
          setFieldValue('cardData.issuingCountryCode', issuingCountryCode)
        },
        onChange: ({ data }) => {
          setFieldValue('browserInfo', data.browserInfo)
          setFieldValue('paymentMethodData', data.paymentMethod)
        },
      }
      const card = checkout.create('card', configuration)
      testingUtils.expose('card', card)
      setCardInstance(card)
    },
    [setFieldValue],
  )

  useEffect(() => {
    if (adyen != null) initCard(adyen)
  }, [adyen, initCard])

  const validate = useCallback(async () => {
    if (!cardInstance?.isValid) {
      cardInstance?.showValidation()

      await Promise.reject(new Error('Invalid card fields'))
    }
  }, [cardInstance])

  const confirmPayment = useCallback(
    (params: ConfirmPaymentParams) => {
      if (params.action === 'three_d_secure') {
        cardInstance?.handleAction(params.details.action)
      }
    },
    [cardInstance],
  )

  const getOption = useCallback(() => {
    const brands = cardInstance?.brands.map(({ name, icon }) => ({ type: name, icon }))

    return {
      value: 'credit_card',
      label: <PaymentLabel type="credit_card" brands={brands} showName />,
      content: <AdyenCardForm loading={cardInstance == null} card={cardInstance} />,
    }
  }, [cardInstance])

  return useMemo(
    () => ({
      status: cardInstance == null ? PaymentMethodStatus.Pending : PaymentMethodStatus.Ready,
      getOption,
      on: {
        submitForm: validate,
        confirmPayment,
      },
    }),
    [cardInstance, confirmPayment, getOption, validate],
  )
}
