import React, { ReactElement } from 'react'

import Date from '@components/Station/Date'
import Time from '@components/Station/Time'

export interface DateTimeProps {
  date: Date
}

const DateTime = ({ date }: DateTimeProps): ReactElement => {
  return (
    <>
      <Date date={date} />
      {', '}
      <Time date={date} />
    </>
  )
}

export default DateTime
