import billingAddress, { BillingAddressResponse, BillingAddressRequest } from '@api/billingAddress'
import { makeLoader } from '@lib/loader'

interface UseBillingAddressLoaderProps extends BillingAddressRequest {
  enabled: boolean
}

export const useBillingAddressLoadLoader = makeLoader<UseBillingAddressLoaderProps, BillingAddressResponse>({
  key: 'billingAddressLoad',
  enabled: ({ retailerPartnerNumber, enabled }) => !!retailerPartnerNumber && enabled,
  loader: async ({ retailerPartnerNumber }) => await billingAddress.load(retailerPartnerNumber),
})
