import React, { ReactElement } from 'react'

import { Skeleton } from '@ui'

const DiscountsSkeleton = (): ReactElement => {
  return (
    <div className="column row-lg gap-2">
      <div className="cell-12 cell-lg-6 ">
        <div className="ui-input p-2">
          <Skeleton.Item height={32} />
        </div>
      </div>
      <div className="cell-12 cell-lg-6 ">
        <div className="ui-input p-2">
          <Skeleton.Item height={32} />
        </div>
      </div>
    </div>
  )
}

export default DiscountsSkeleton
