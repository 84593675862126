import React from 'react'

import errorIcon from '@images/common/error.svg'
import successIcon from '@images/common/success.svg'
import { useTranslation } from '@lib/i18n'
import { Button, Modal } from '@ui'

import '@components/Blocks/common/SubmitModal/index.scss'

interface Props {
  blockType: string
  opened: boolean
  onClose: () => void
  type: 'success' | 'error'
}

interface ModalTranslations {
  title: string
  description: string
  buttonText: string
}

const SubmitModal = (props: Props) => {
  const { blockType, opened, onClose, type } = props
  const { t } = useTranslation()

  const { title, description, buttonText } = t(`customBlocksCommon.${blockType}.modal.${type}`, {
    returnObjects: true,
  }) as ModalTranslations

  return (
    <Modal maxWidth="md" opened={opened} onClose={onClose}>
      <div className="submit-modal column items-center">
        <div className="submit-modal__icon mb-5">
          {type === 'success' && <img src={successIcon} alt="success" />}
          {type === 'error' && <img src={errorIcon} alt="error" />}
        </div>
        <h3 className="mb-3">{title}</h3>
        <div className="mb-5">{description}</div>
        <Button onClick={onClose}>{buttonText}</Button>
      </div>
    </Modal>
  )
}

export default SubmitModal
