import React, { Fragment, FunctionComponent, ReactElement } from 'react'

import { Divider } from '@ui'

export interface SkeletonListProps {
  amount: number
  Skeleton: FunctionComponent
  children: ReactElement
  loading: boolean
  direction?: Direction
  divider?: boolean
}

const List = (props: SkeletonListProps): ReactElement => {
  const { amount, loading, children, Skeleton, direction = 'row', divider } = props
  if (!loading) return children

  return (
    <div className={`${direction} gap-3`}>
      {new Array(amount).fill(true).map((_, index) => (
        <Fragment key={index}>
          <div className="cell">
            <Skeleton />
          </div>
          {divider && index < amount - 1 && (
            <div className="cell">
              <Divider />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  )
}

export default List
