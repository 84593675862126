import { useCallback } from 'react'
import { useNavigate as useStandardNavigate, createSearchParams } from 'react-router-dom'

import params from '@lib/params'
import qs from '@lib/queryString'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

const useNavigate = (): ((url: string) => void) => {
  const navigate = useStandardNavigate()
  const [_, setParams] = useParams()
  const [settings] = useSettings()

  return useCallback(
    (url: string) => {
      const urlParams = createSearchParams(url.split('?')[1])
      const parsedParams = qs.parse(Object.fromEntries([...urlParams.entries()]))

      const normalisedParams =
        parsedParams &&
        params.normaliseParams({
          retailerPartnerNumber: parseInt(parsedParams.retailerPartnerNumber),
          params: parsedParams,
          settings: settings,
        })
      setParams(normalisedParams)

      navigate(url)
    },
    [navigate, setParams, settings],
  )
}

export default useNavigate
