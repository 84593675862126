import { useFormikContext } from 'formik'
import React, { ReactElement, useEffect, useRef } from 'react'

import script from '@lib/script'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'

const SCRIPT = 'https://live.adyen.com/hpp/js/df.js'

const getScriptUrl = (): string => {
  const now = new Date()

  return `${SCRIPT}?v=${now.getFullYear()}${now.getMonth()}${now.getDate()}`
}

declare global {
  let dfSet: (el: string | HTMLInputElement) => any
}

const Fingerprint = (): ReactElement => {
  const ref = useRef<HTMLInputElement>(null)
  const { setFieldValue } = useFormikContext<CheckoutFormData>()

  useEffect(() => {
    const collect = async (): Promise<void> => {
      /* istanbul ignore if */
      if (!ref.current) return

      await script.load(getScriptUrl())
      dfSet(ref.current)
      setFieldValue('deviceFingerprint', ref.current?.value)
    }

    void collect()
  }, [ref, setFieldValue])

  return <input ref={ref} type="hidden" name="deviceFingerprint" id="deviceFingerprint" />
}

export default Fingerprint
