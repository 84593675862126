import { FieldProps } from 'formik'
import React, { ReactElement } from 'react'

import IsReturnTrip from '@components/IsReturnTrip'

const Field = ({ field, form }: FieldProps<boolean>): ReactElement => {
  return (
    <IsReturnTrip
      layout="row"
      value={field.value}
      onChange={value => {
        form.setFieldValue(field.name, value)
      }}
    />
  )
}

export default Field
