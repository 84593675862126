import { getIn, useFormikContext } from 'formik'
import React, { ReactElement, useEffect } from 'react'

import CountryField from '@components/CountryField'
import StateField from '@components/StateField'
import useValidators from '@hooks/useValidators'
import { useTranslation } from '@lib/i18n'

const STATE_GOVERNMENT_TYPES = ['drivers_license']
const AMTRAK_AVAILABLE_STATES = ['MI', 'MN', 'NY', 'VT', 'WA']

interface IssuingLocationProps {
  name: string
}

const IssuingLocation = ({ name }: IssuingLocationProps): ReactElement => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext()
  const { required } = useValidators()
  const type = getIn(values, `${name}.governmentIdType`)
  const showStateDropdown = STATE_GOVERNMENT_TYPES.includes(type)

  useEffect(() => {
    setFieldValue(`${name}.governmentIdIssuingState`, null)
    setFieldValue(`${name}.governmentIdIssuingCountry`, null)
  }, [showStateDropdown, name, setFieldValue])

  return showStateDropdown ? (
    <StateField
      label={t('checkout.passengerDetails.locationOfIssue')}
      countryCode="US"
      name={`${name}.governmentIdIssuingState`}
      validate={required}
      whitelist={AMTRAK_AVAILABLE_STATES}
      required
    />
  ) : (
    <CountryField
      label={t('checkout.passengerDetails.locationOfIssue')}
      name={`${name}.governmentIdIssuingCountry`}
      validate={required}
      required
    />
  )
}

export default IssuingLocation
