import makeApi, { ResponsePostprocessing } from '@lib/api'

export type ConnectionsResponse = Connection[]

export interface ConnectionPassengers {
  maxAge: number
  pax: number
  cards?: DiscountCode.Card[]
}

export interface ConnectionsRequest {
  departureCity?: CityCode
  departureArea?: AreaCode
  departureStation?: StationCode
  arrivalCity?: CityCode
  arrivalArea?: AreaCode
  arrivalStation?: StationCode
  retailerPartnerNumber: RetailerNumber
  pax: number
  locale: Locale
  currency: Currency
  marketingCarrierCode?: string
  passengers?: ConnectionPassengers[]
  cards?: DiscountCode.Card[]
  bookingId: string | null
  returnDate?: string
  departureDate: string
  departureStartTime?: string
  departureEndTime?: string
}

const config: ResponsePostprocessing = {
  convertValues: {
    cheapestTotalAdultPrice: 'Price',
    price: 'Price',
    bookingFee: 'Price',
    fee: 'Price',
    cancellationFee: 'Price',
    arrivalTime: 'DateTime',
    departureTime: 'DateTime',
  },
}

export default {
  load: makeApi.get<ConnectionsRequest, ConnectionsResponse>('connections', {
    responsePostprocessing: config,
  }),
}
