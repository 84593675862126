type BeforeFunction = (element: HTMLScriptElement) => void

type LoadFunction = (path: string, before?: BeforeFunction) => Promise<void>

const find = (path: string): HTMLScriptElement | null => document.querySelector<HTMLScriptElement>(`[src='${path}']`)

const load: LoadFunction = async (path, before) => {
  const tag = document.createElement('script')
  before?.(tag)
  tag.src = path

  const promise = new Promise<void>((resolve, reject) => {
    tag.addEventListener('load', () => resolve(), { once: true })
    tag.addEventListener('error', /* istanbul ignore next */ e => reject(e), { once: true })
  })

  document.body.appendChild(tag)

  await promise
}

const remove = (path: string): void => find(path)?.remove()

const reload: LoadFunction = async (path, before) => {
  remove(path)
  await load(path, before)
}

const script = {
  load,
  reload,
}

export default script
