// Timezone to Country mapping using https://github.com/moment/moment-timezone/blob/develop/data/meta/latest.json
// Country to Currency mapping using https://github.com/thiagodp/country-to-currency/blob/master/index.ts
export default {
  'Europe/Andorra': 'EUR',
  'Asia/Dubai': 'AED',
  'Asia/Kabul': 'AFN',
  'Europe/Tirane': 'ALL',
  'Asia/Yerevan': 'AMD',
  'Antarctica/Casey': 'USD',
  'Antarctica/Davis': 'USD',
  'Antarctica/Mawson': 'USD',
  'Antarctica/Palmer': 'USD',
  'Antarctica/Rothera': 'USD',
  'Antarctica/Troll': 'USD',
  'Antarctica/Vostok': 'USD',
  'America/Argentina/Buenos_Aires': 'ARS',
  'America/Argentina/Cordoba': 'ARS',
  'America/Argentina/Salta': 'ARS',
  'America/Argentina/Jujuy': 'ARS',
  'America/Argentina/Tucuman': 'ARS',
  'America/Argentina/Catamarca': 'ARS',
  'America/Argentina/La_Rioja': 'ARS',
  'America/Argentina/San_Juan': 'ARS',
  'America/Argentina/Mendoza': 'ARS',
  'America/Argentina/San_Luis': 'ARS',
  'America/Argentina/Rio_Gallegos': 'ARS',
  'America/Argentina/Ushuaia': 'ARS',
  'Pacific/Pago_Pago': 'USD',
  'Europe/Vienna': 'EUR',
  'Australia/Lord_Howe': 'AUD',
  'Antarctica/Macquarie': 'AUD',
  'Australia/Hobart': 'AUD',
  'Australia/Melbourne': 'AUD',
  'Australia/Sydney': 'AUD',
  'Australia/Broken_Hill': 'AUD',
  'Australia/Brisbane': 'AUD',
  'Australia/Lindeman': 'AUD',
  'Australia/Adelaide': 'AUD',
  'Australia/Darwin': 'AUD',
  'Australia/Perth': 'AUD',
  'Australia/Eucla': 'AUD',
  'Asia/Baku': 'AZN',
  'America/Barbados': 'BBD',
  'Asia/Dhaka': 'BDT',
  'Europe/Brussels': 'EUR',
  'Europe/Sofia': 'BGN',
  'Atlantic/Bermuda': 'BMD',
  'America/La_Paz': 'BOB',
  'America/Noronha': 'BRL',
  'America/Belem': 'BRL',
  'America/Fortaleza': 'BRL',
  'America/Recife': 'BRL',
  'America/Araguaina': 'BRL',
  'America/Maceio': 'BRL',
  'America/Bahia': 'BRL',
  'America/Sao_Paulo': 'BRL',
  'America/Campo_Grande': 'BRL',
  'America/Cuiaba': 'BRL',
  'America/Santarem': 'BRL',
  'America/Porto_Velho': 'BRL',
  'America/Boa_Vista': 'BRL',
  'America/Manaus': 'BRL',
  'America/Eirunepe': 'BRL',
  'America/Rio_Branco': 'BRL',
  'Asia/Thimphu': 'BTN',
  'Europe/Minsk': 'BYN',
  'America/Belize': 'BZD',
  'America/St_Johns': 'CAD',
  'America/Halifax': 'CAD',
  'America/Glace_Bay': 'CAD',
  'America/Moncton': 'CAD',
  'America/Goose_Bay': 'CAD',
  'America/Toronto': 'CAD',
  'America/Iqaluit': 'CAD',
  'America/Winnipeg': 'CAD',
  'America/Resolute': 'CAD',
  'America/Rankin_Inlet': 'CAD',
  'America/Regina': 'CAD',
  'America/Swift_Current': 'CAD',
  'America/Edmonton': 'CAD',
  'America/Cambridge_Bay': 'CAD',
  'America/Inuvik': 'CAD',
  'America/Dawson_Creek': 'CAD',
  'America/Fort_Nelson': 'CAD',
  'America/Whitehorse': 'CAD',
  'America/Dawson': 'CAD',
  'America/Vancouver': 'CAD',
  'Europe/Zurich': 'CHF',
  'Africa/Abidjan': 'XOF',
  'Pacific/Rarotonga': 'NZD',
  'America/Santiago': 'CLP',
  'America/Punta_Arenas': 'CLP',
  'Pacific/Easter': 'CLP',
  'Asia/Shanghai': 'CNY',
  'Asia/Urumqi': 'CNY',
  'America/Bogota': 'COP',
  'America/Costa_Rica': 'CRC',
  'America/Havana': 'CUP',
  'Atlantic/Cape_Verde': 'CVE',
  'Asia/Nicosia': 'EUR',
  'Asia/Famagusta': 'EUR',
  'Europe/Prague': 'CZK',
  'Europe/Berlin': 'EUR',
  'America/Santo_Domingo': 'DOP',
  'Africa/Algiers': 'DZD',
  'America/Guayaquil': 'USD',
  'Pacific/Galapagos': 'USD',
  'Europe/Tallinn': 'EUR',
  'Africa/Cairo': 'EGP',
  'Africa/El_Aaiun': 'MAD',
  'Europe/Madrid': 'EUR',
  'Africa/Ceuta': 'EUR',
  'Atlantic/Canary': 'EUR',
  'Europe/Helsinki': 'EUR',
  'Pacific/Fiji': 'FJD',
  'Atlantic/Stanley': 'FKP',
  'Pacific/Kosrae': 'USD',
  'Atlantic/Faroe': 'DKK',
  'Europe/Paris': 'EUR',
  'Europe/London': 'GBP',
  'Asia/Tbilisi': 'GEL',
  'America/Cayenne': 'EUR',
  'Europe/Gibraltar': 'GIP',
  'America/Nuuk': 'DKK',
  'America/Danmarkshavn': 'DKK',
  'America/Scoresbysund': 'DKK',
  'America/Thule': 'DKK',
  'Europe/Athens': 'EUR',
  'Atlantic/South_Georgia': 'FKP',
  'America/Guatemala': 'GTQ',
  'Pacific/Guam': 'USD',
  'Africa/Bissau': 'XOF',
  'America/Guyana': 'GYD',
  'Asia/Hong_Kong': 'HKD',
  'America/Tegucigalpa': 'HNL',
  'America/Port-au-Prince': 'HTG',
  'Europe/Budapest': 'HUF',
  'Asia/Jakarta': 'IDR',
  'Asia/Pontianak': 'IDR',
  'Asia/Makassar': 'IDR',
  'Asia/Jayapura': 'IDR',
  'Europe/Dublin': 'EUR',
  'Asia/Jerusalem': 'ILS',
  'Asia/Kolkata': 'INR',
  'Indian/Chagos': 'USD',
  'Asia/Baghdad': 'IQD',
  'Asia/Tehran': 'IRR',
  'Europe/Rome': 'EUR',
  'America/Jamaica': 'JMD',
  'Asia/Amman': 'JOD',
  'Asia/Tokyo': 'JPY',
  'Africa/Nairobi': 'KES',
  'Asia/Bishkek': 'KGS',
  'Pacific/Tarawa': 'AUD',
  'Pacific/Kanton': 'AUD',
  'Pacific/Kiritimati': 'AUD',
  'Asia/Pyongyang': 'KPW',
  'Asia/Seoul': 'KRW',
  'Asia/Almaty': 'KZT',
  'Asia/Qyzylorda': 'KZT',
  'Asia/Qostanay': 'KZT',
  'Asia/Aqtobe': 'KZT',
  'Asia/Aqtau': 'KZT',
  'Asia/Atyrau': 'KZT',
  'Asia/Oral': 'KZT',
  'Asia/Beirut': 'LBP',
  'Asia/Colombo': 'LKR',
  'Africa/Monrovia': 'LRD',
  'Europe/Vilnius': 'EUR',
  'Europe/Riga': 'EUR',
  'Africa/Tripoli': 'LYD',
  'Africa/Casablanca': 'MAD',
  'Europe/Chisinau': 'MDL',
  'Pacific/Kwajalein': 'USD',
  'Asia/Yangon': 'MMK',
  'Asia/Ulaanbaatar': 'MNT',
  'Asia/Hovd': 'MNT',
  'Asia/Choibalsan': 'MNT',
  'Asia/Macau': 'MOP',
  'America/Martinique': 'EUR',
  'Europe/Malta': 'EUR',
  'Indian/Mauritius': 'MUR',
  'Indian/Maldives': 'MVR',
  'America/Mexico_City': 'MXN',
  'America/Cancun': 'MXN',
  'America/Merida': 'MXN',
  'America/Monterrey': 'MXN',
  'America/Matamoros': 'MXN',
  'America/Chihuahua': 'MXN',
  'America/Ciudad_Juarez': 'MXN',
  'America/Ojinaga': 'MXN',
  'America/Mazatlan': 'MXN',
  'America/Bahia_Banderas': 'MXN',
  'America/Hermosillo': 'MXN',
  'America/Tijuana': 'MXN',
  'Asia/Kuching': 'MYR',
  'Africa/Maputo': 'MZN',
  'Africa/Windhoek': 'NAD',
  'Pacific/Noumea': 'XPF',
  'Pacific/Norfolk': 'AUD',
  'Africa/Lagos': 'NGN',
  'America/Managua': 'NIO',
  'Asia/Kathmandu': 'NPR',
  'Pacific/Nauru': 'AUD',
  'Pacific/Niue': 'NZD',
  'Pacific/Auckland': 'NZD',
  'Pacific/Chatham': 'NZD',
  'America/Panama': 'PAB',
  'America/Lima': 'PEN',
  'Pacific/Tahiti': 'XPF',
  'Pacific/Marquesas': 'XPF',
  'Pacific/Gambier': 'XPF',
  'Pacific/Port_Moresby': 'PGK',
  'Pacific/Bougainville': 'PGK',
  'Asia/Manila': 'PHP',
  'Asia/Karachi': 'PKR',
  'Europe/Warsaw': 'PLN',
  'America/Miquelon': 'EUR',
  'Pacific/Pitcairn': 'NZD',
  'America/Puerto_Rico': 'USD',
  'Asia/Gaza': 'ILS',
  'Asia/Hebron': 'ILS',
  'Europe/Lisbon': 'EUR',
  'Atlantic/Madeira': 'EUR',
  'Atlantic/Azores': 'EUR',
  'Pacific/Palau': 'USD',
  'America/Asuncion': 'PYG',
  'Asia/Qatar': 'QAR',
  'Europe/Bucharest': 'RON',
  'Europe/Belgrade': 'RSD',
  'Europe/Kaliningrad': 'RUB',
  'Europe/Moscow': 'RUB',
  'Europe/Simferopol': 'RUB',
  'Europe/Kirov': 'RUB',
  'Europe/Volgograd': 'RUB',
  'Europe/Astrakhan': 'RUB',
  'Europe/Saratov': 'RUB',
  'Europe/Ulyanovsk': 'RUB',
  'Europe/Samara': 'RUB',
  'Asia/Yekaterinburg': 'RUB',
  'Asia/Omsk': 'RUB',
  'Asia/Novosibirsk': 'RUB',
  'Asia/Barnaul': 'RUB',
  'Asia/Tomsk': 'RUB',
  'Asia/Novokuznetsk': 'RUB',
  'Asia/Krasnoyarsk': 'RUB',
  'Asia/Irkutsk': 'RUB',
  'Asia/Chita': 'RUB',
  'Asia/Yakutsk': 'RUB',
  'Asia/Khandyga': 'RUB',
  'Asia/Vladivostok': 'RUB',
  'Asia/Ust-Nera': 'RUB',
  'Asia/Magadan': 'RUB',
  'Asia/Sakhalin': 'RUB',
  'Asia/Srednekolymsk': 'RUB',
  'Asia/Kamchatka': 'RUB',
  'Asia/Anadyr': 'RUB',
  'Asia/Riyadh': 'SAR',
  'Pacific/Guadalcanal': 'SBD',
  'Africa/Khartoum': 'SDG',
  'Asia/Singapore': 'SGD',
  'America/Paramaribo': 'SRD',
  'Africa/Juba': 'SSP',
  'Africa/Sao_Tome': 'STN',
  'America/El_Salvador': 'USD',
  'Asia/Damascus': 'SYP',
  'America/Grand_Turk': 'USD',
  'Africa/Ndjamena': 'XAF',
  'Asia/Bangkok': 'THB',
  'Asia/Dushanbe': 'TJS',
  'Pacific/Fakaofo': 'NZD',
  'Asia/Dili': 'USD',
  'Asia/Ashgabat': 'TMT',
  'Africa/Tunis': 'TND',
  'Pacific/Tongatapu': 'TOP',
  'Europe/Istanbul': 'TRY',
  'Asia/Taipei': 'TWD',
  'Europe/Kyiv': 'UAH',
  'America/New_York': 'USD',
  'America/Detroit': 'USD',
  'America/Kentucky/Louisville': 'USD',
  'America/Kentucky/Monticello': 'USD',
  'America/Indiana/Indianapolis': 'USD',
  'America/Indiana/Vincennes': 'USD',
  'America/Indiana/Winamac': 'USD',
  'America/Indiana/Marengo': 'USD',
  'America/Indiana/Petersburg': 'USD',
  'America/Indiana/Vevay': 'USD',
  'America/Chicago': 'USD',
  'America/Indiana/Tell_City': 'USD',
  'America/Indiana/Knox': 'USD',
  'America/Menominee': 'USD',
  'America/North_Dakota/Center': 'USD',
  'America/North_Dakota/New_Salem': 'USD',
  'America/North_Dakota/Beulah': 'USD',
  'America/Denver': 'USD',
  'America/Boise': 'USD',
  'America/Phoenix': 'USD',
  'America/Los_Angeles': 'USD',
  'America/Anchorage': 'USD',
  'America/Juneau': 'USD',
  'America/Sitka': 'USD',
  'America/Metlakatla': 'USD',
  'America/Yakutat': 'USD',
  'America/Nome': 'USD',
  'America/Adak': 'USD',
  'Pacific/Honolulu': 'USD',
  'America/Montevideo': 'UYU',
  'Asia/Samarkand': 'UZS',
  'Asia/Tashkent': 'UZS',
  'America/Caracas': 'VES',
  'Asia/Ho_Chi_Minh': 'VND',
  'Pacific/Efate': 'VUV',
  'Pacific/Apia': 'WST',
  'Africa/Johannesburg': 'ZAR',
  'America/Antigua': 'XCD',
  'America/Anguilla': 'XCD',
  'Africa/Luanda': 'AOA',
  'Antarctica/McMurdo': 'USD',
  'Antarctica/DumontDUrville': 'USD',
  'Antarctica/Syowa': 'USD',
  'America/Aruba': 'AWG',
  'Europe/Mariehamn': 'EUR',
  'Europe/Sarajevo': 'BAM',
  'Africa/Ouagadougou': 'XOF',
  'Asia/Bahrain': 'BHD',
  'Africa/Bujumbura': 'BIF',
  'Africa/Porto-Novo': 'XOF',
  'America/St_Barthelemy': 'EUR',
  'Asia/Brunei': 'BND',
  'America/Kralendijk': 'USD',
  'America/Nassau': 'BSD',
  'Africa/Gaborone': 'BWP',
  'America/Blanc-Sablon': 'CAD',
  'America/Atikokan': 'CAD',
  'America/Creston': 'CAD',
  'Indian/Cocos': 'AUD',
  'Africa/Kinshasa': 'CDF',
  'Africa/Lubumbashi': 'CDF',
  'Africa/Bangui': 'XAF',
  'Africa/Brazzaville': 'XAF',
  'Africa/Douala': 'XAF',
  'America/Curacao': 'ANG',
  'Indian/Christmas': 'AUD',
  'Europe/Busingen': 'EUR',
  'Africa/Djibouti': 'DJF',
  'Europe/Copenhagen': 'DKK',
  'America/Dominica': 'XCD',
  'Africa/Asmara': 'ERN',
  'Africa/Addis_Ababa': 'ETB',
  'Pacific/Chuuk': 'USD',
  'Pacific/Pohnpei': 'USD',
  'Africa/Libreville': 'XAF',
  'America/Grenada': 'XCD',
  'Europe/Guernsey': 'GBP',
  'Africa/Accra': 'GHS',
  'Africa/Banjul': 'GMD',
  'Africa/Conakry': 'GNF',
  'America/Guadeloupe': 'EUR',
  'Africa/Malabo': 'XAF',
  'Europe/Zagreb': 'EUR',
  'Europe/Isle_of_Man': 'GBP',
  'Atlantic/Reykjavik': 'ISK',
  'Europe/Jersey': 'GBP',
  'Asia/Phnom_Penh': 'KHR',
  'Indian/Comoro': 'KMF',
  'America/St_Kitts': 'XCD',
  'Asia/Kuwait': 'KWD',
  'America/Cayman': 'KYD',
  'Asia/Vientiane': 'LAK',
  'America/St_Lucia': 'XCD',
  'Europe/Vaduz': 'CHF',
  'Africa/Maseru': 'LSL',
  'Europe/Luxembourg': 'EUR',
  'Europe/Monaco': 'EUR',
  'Europe/Podgorica': 'EUR',
  'America/Marigot': 'EUR',
  'Indian/Antananarivo': 'MGA',
  'Pacific/Majuro': 'USD',
  'Europe/Skopje': 'MKD',
  'Africa/Bamako': 'XOF',
  'Pacific/Saipan': 'USD',
  'Africa/Nouakchott': 'MRU',
  'America/Montserrat': 'XCD',
  'Africa/Blantyre': 'MWK',
  'Asia/Kuala_Lumpur': 'MYR',
  'Africa/Niamey': 'XOF',
  'Europe/Amsterdam': 'EUR',
  'Europe/Oslo': 'NOK',
  'Asia/Muscat': 'OMR',
  'Indian/Reunion': 'EUR',
  'Africa/Kigali': 'RWF',
  'Indian/Mahe': 'SCR',
  'Europe/Stockholm': 'SEK',
  'Atlantic/St_Helena': 'SHP',
  'Europe/Ljubljana': 'EUR',
  'Arctic/Longyearbyen': 'NOK',
  'Europe/Bratislava': 'EUR',
  'Africa/Freetown': 'SLE',
  'Europe/San_Marino': 'EUR',
  'Africa/Dakar': 'XOF',
  'Africa/Mogadishu': 'SOS',
  'America/Lower_Princes': 'ANG',
  'Africa/Mbabane': 'SZL',
  'Indian/Kerguelen': 'EUR',
  'Africa/Lome': 'XOF',
  'America/Port_of_Spain': 'TTD',
  'Pacific/Funafuti': 'AUD',
  'Africa/Dar_es_Salaam': 'TZS',
  'Africa/Kampala': 'UGX',
  'Pacific/Midway': 'USD',
  'Pacific/Wake': 'USD',
  'Europe/Vatican': 'EUR',
  'America/St_Vincent': 'XCD',
  'America/Tortola': 'USD',
  'America/St_Thomas': 'USD',
  'Pacific/Wallis': 'XPF',
  'Asia/Aden': 'YER',
  'Indian/Mayotte': 'EUR',
  'Africa/Lusaka': 'ZMW',
  'Africa/Harare': 'ZWL',
} as unknown as Record<string, Currency>
