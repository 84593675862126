import React, { ReactElement } from 'react'

import config from '@config'
import useSearchParams from '@hooks/useSearchParams'
import amplitude from '@lib/analytics/amplitude'
import bem from '@lib/bem'
import currencyUtils from '@lib/currency'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'
import { Dropdown } from '@ui'

import '@components/CurrencyDropdown/index.scss'

const CurrencyDropdown = (): ReactElement => {
  const [{ currency: current }] = useParams()
  const [
    {
      currency: { supported },
    },
  ] = useSettings()
  const [, setSearchParams] = useSearchParams()

  const setCurrency = (currency: Currency): void => {
    amplitude.changeCurrency({ from: current, to: currency })
    setSearchParams(current => ({ ...current, currency }))
  }

  const list = currencyUtils.sort(supported).map(code => ({
    value: code,
    label: (
      <div className={bem('currency-list', 'item')}>
        <div className={bem('item', 'code')}>{currencyUtils.getSymbol(code)}</div>
        <div className={bem('item', 'name')}>{currencyUtils.getName(code)}</div>
      </div>
    ),
    selectedLabel: currencyUtils.getSymbol(code),
    getClass: ({ selected }: { selected?: boolean }): string => bem('currency-list', 'item', { selected }),
  }))

  return (
    <Dropdown
      pinnedValues={config.settings.topCurrencies}
      items={list}
      value={current}
      checkedMark
      className={bem('currency-list')}
      onChange={setCurrency}
      popperProps={{ anchorWidth: false, maxHeight: 'md' }}
    />
  )
}

export default CurrencyDropdown
