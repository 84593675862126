import makeApi from '@lib/api'

export interface ExchangeRatesRequest {
  date: string
  currencies?: Currency[] | null
}

export type ExchangeRatesResponse = ExchangeRate[]

export default {
  load: makeApi.get<ExchangeRatesRequest, ExchangeRatesResponse>({
    type: 'exchange_rates',
    old: `/exchange_rates`,
    new: `/exchange_rates`,
  }),
}
