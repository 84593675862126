import { FieldProps } from 'formik'
import React, { ReactElement, useState, FocusEvent } from 'react'

import { Input } from '@ui'
import { InputElement, InputProps } from '@ui/Input/index'

type InputFieldProps = FieldProps &
  InputProps & {
    updateEvent?: 'onChange' | 'onBlur'
  }

const InputField = (props: InputFieldProps): ReactElement => {
  const {
    field,
    form: { getFieldMeta, submitCount, setFieldTouched, setFieldValue },
    updateEvent = 'onChange',
    ...rest
  } = props
  const { touched, error } = getFieldMeta(field.name)
  const [localValue, setLocalValue] = useState(field.value)
  const value = updateEvent === 'onBlur' ? localValue : field.value

  const onChange = (value: string) => {
    if (updateEvent === 'onChange') {
      setFieldValue(field.name, value)
      rest.onChange?.(value)
    } else {
      setLocalValue(value)
    }
  }

  const onBlur = (e: FocusEvent<InputElement>, value: string) => {
    if (updateEvent === 'onBlur') {
      setFieldValue(field.name, value)
      rest.onChange?.(value)

      setFieldTouched(field.name, true, false)
      rest.onBlur?.(e, value)
    } else {
      rest.onBlur?.(e, value)
      field.onBlur(e)
    }
  }

  return (
    <Input
      {...rest}
      {...field}
      value={value ?? ''}
      onChange={onChange}
      onBlur={onBlur}
      errorMessage={rest.errorMessage ?? (touched || submitCount > 0 ? error : null)}
    />
  )
}

export default InputField
