import React, { useCallback, useMemo } from 'react'

import { BookingStatusResponse } from '@api/booking'
import { PaymentMethodStatus } from '@enums'
import { ConfirmPaymentParams, FinishPaymentParams } from '@hooks/useBookingFlow'
import { useBookingStatusPolling } from '@loaders/bookingStatusPolling'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import BlikForm from '@pages/Checkout/Payment/BlikForm'
import PaymentLabel from '@pages/Checkout/Payment/Label'
import { useParams } from '@stores/params'

const useBlik = (finishPayment: (params: FinishPaymentParams) => void): AdyenTypes.Method => {
  const [{ currency }] = useParams()

  const getOption = useCallback(
    () => ({
      value: 'blik',
      label: <PaymentLabel type="blik" />,
      content: <BlikForm />,
    }),
    [],
  )

  const submitForm = useCallback(
    ({ blikCode }: CheckoutFormData) => ({ paymentMethodData: { type: 'blik', blikCode }, countryCode: 'PL' }),
    [],
  )

  const statusOptions = useMemo(
    () => ({
      onSuccess: (params: BookingStatusResponse) => {
        finishPayment({ ...params, action: 'redirect' })
      },
    }),
    [finishPayment],
  )
  const statusPolling = useBookingStatusPolling(statusOptions)

  const confirmPayment = useCallback(
    (params: ConfirmPaymentParams) => {
      params.action === 'blik' && statusPolling.mutate(params.bookingFormId)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [statusPolling.mutate],
  )

  return useMemo(
    () => ({
      getOption,
      status: currency === 'PLN' ? PaymentMethodStatus.Ready : PaymentMethodStatus.Rejected,
      error: statusPolling.error,
      isLoading: statusPolling.isLoading,
      on: {
        submitForm,
        confirmPayment,
      },
    }),
    [confirmPayment, currency, getOption, statusPolling.error, statusPolling.isLoading, submitForm],
  )
}

export default useBlik
