import React, { ReactElement } from 'react'

import Connection from '@components/Connection'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import Icon from '@ui/Icon'

import '@components/TripInfo/TransferTime/index.scss'

interface TransferTimeProps {
  departureTime: string
  arrivalTime: string
  departureTimezone: string
  arrivalTimezone: string
  selfTransfer?: boolean
}

const TransferTime = (props: TransferTimeProps): ReactElement => {
  const { departureTime, arrivalTime, selfTransfer, departureTimezone, arrivalTimezone } = props
  const { t } = useTranslation()
  const text = selfTransfer ? t('card.selfTransferTime') : t('card.transferTime')

  return (
    <div className={bem('trip-transfer-time')}>
      {selfTransfer && (
        <div className="trip-transfer-time__station-change">
          <Icon className={bem('trip-transfer-time', 'station-change-icon')} name="alert" size="small" />
          <div>{t('card.stationChange')}</div>
        </div>
      )}
      <Icon name="transfer" size="large" className={bem('trip-transfer-time', 'transfer-icon')} />
      <span>
        {`${text}: `}
        <b>
          <Connection.Duration
            departureTime={departureTime}
            arrivalTime={arrivalTime}
            departureTimezone={departureTimezone}
            arrivalTimezone={arrivalTimezone}
          />
        </b>
      </span>
    </div>
  )
}

export default TransferTime
