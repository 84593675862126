import { useMemo } from 'react'
import { useMutation, UseMutationResult } from 'react-query'

import { ApiError } from '@lib/api'
import { CallLoaderOptions, InitLoaderOptions } from '@lib/loader'

type InitMutationOptions<TParams, TData> = Pick<InitLoaderOptions<TParams, TData>, 'key' | 'loader'>

export type MutationResult<TData, TError, TParams> = UseMutationResult<TData, TError, TParams> & {
  errorCode: string | null
}

export const makeMutation = <TParams, TData, TError = ApiError>(initOptions: InitMutationOptions<TParams, TData>) => {
  return (callOptions: CallLoaderOptions<TParams, TData, TError> = {}): MutationResult<TData, TError, TParams> => {
    const { key, loader } = initOptions
    const { onSuccess, onError } = callOptions

    const result = useMutation({
      mutationKey: [key],
      mutationFn: loader,
      onSuccess,
      onError,
    })
    const { error } = result
    const errorCode = useMemo(() => (error instanceof ApiError ? error.code : null), [error])

    return {
      errorCode,
      ...result,
    }
  }
}
