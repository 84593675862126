import React, { ReactElement } from 'react'

import CountryField from '@components/CountryField'
import useValidators from '@hooks/useValidators'
import { useTranslation } from '@lib/i18n'

interface CitizenshipProps {
  name: string
}

const Citizenship = ({ name }: CitizenshipProps): ReactElement => {
  const { t } = useTranslation()
  const { required } = useValidators()

  return (
    <CountryField
      label={t('checkout.passengerDetails.countryCode')}
      name={`${name}.nationality`}
      validate={required}
      required
    />
  )
}

export default Citizenship
