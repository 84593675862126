import React, { FunctionComponent, ReactElement } from 'react'

import AccordionBlock from '@components/Blocks/Accordion'
import BackgroundBlock from '@components/Blocks/Background'
import CardsBlock from '@components/Blocks/Cards'
import FAQBlock from '@components/Blocks/FAQ'
import FormBlock from '@components/Blocks/Form'
import NavigateBlock from '@components/Blocks/Navigate'
import SubscribeBlock from '@components/Blocks/Subscribe'
import TripSearch from '@components/Blocks/TripSearch'

import '@components/Blocks/index.scss'

interface BlocksProps {
  blocks: PageBlocks.BlockAlias[]
}

type BlockType = Exclude<PageBlocks.BlockAlias['type'], 'banner'>
const blocksMapping: Record<BlockType, FunctionComponent<any>> = {
  navigate: NavigateBlock,
  cards: CardsBlock,
  tripSearch: TripSearch,
  background: BackgroundBlock,
  faq: FAQBlock,
  accordion: AccordionBlock,
  subscribe: SubscribeBlock,
  form: FormBlock,
}

const Blocks = ({ blocks }: BlocksProps): ReactElement => {
  const hasBackground = blocks.some(({ type }) => type === 'background')
  return (
    <div className="column gap-md-5 gap-lg-5 custom-blocks mb-7">
      {blocks.map(({ data, type }, index) => {
        const Component = blocksMapping[type as BlockType]
        if (!Component) return null

        const onBackground = (hasBackground && index === 0) || (blocks[0].type === 'background' && index === 1)

        return (
          <div className="cell" key={`${type}${index}${(data as any)?.translationKey}`}>
            <Component {...(data ?? {})} onBackground={onBackground} />
          </div>
        )
      })}
    </div>
  )
}

export default Blocks
