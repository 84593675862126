import { useFormikContext } from 'formik'
import React, { ReactElement, useEffect, useRef } from 'react'

import Loader from '@components/Loader'
import Money from '@components/Money'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import FareClassMedia from '@pages/Checkout/FareClassesSelection/Media'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'

interface CardProps {
  onClick: () => void
  selected: boolean
  title: string
  description: string[]
  vacant?: boolean
  fareClassCode?: string
  price?: Money | null
  media?: string[] | null
}

const NO_PRICE_VALUE = '-'

const Card = (props: CardProps): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const ref = useRef<HTMLDivElement>(null)
  const { onClick, selected, title, description, vacant, price, media } = props
  const {
    values: { isVacancyLoading },
  } = useFormikContext<CheckoutFormData>()

  useEffect(() => {
    selected &&
      isMobile &&
      !isVacancyLoading &&
      ref.current?.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'center' })
  }, [isMobile, selected, isVacancyLoading])

  const handleClick = (): void => {
    if (isVacancyLoading || !vacant) return

    onClick()
  }

  return (
    <div ref={ref} className={bem('fare-class', { selected })} onClick={handleClick}>
      <div className="column cell-12">
        {!!media?.length && <FareClassMedia media={media} />}
        <div className="fare-class__card-content column gap-3 p-4">
          <h4 className={bem('fare-class', 'title')}>{title}</h4>
          <div className="fare-class__description column gap-2 cell grow">
            <ul>
              {description.map((item: string) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
          <div className={bem('fare-class', 'price')}>
            <Loader loading={isVacancyLoading}>
              {price && vacant ? (
                <Money fractional={price.fractional} currency={price.currency} signed />
              ) : (
                NO_PRICE_VALUE
              )}
            </Loader>
          </div>
        </div>
      </div>
      {!vacant && (
        <div className={bem('fare-class', 'card', { disabled: true })}>
          <div>{t('card.booked')}</div>
        </div>
      )}
    </div>
  )
}

export default Card
