import cn from 'classnames'
import React, { ReactElement, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import { Icon } from '@ui'
import PopupContainerContext from '@ui/Tooltip/ContainerContext'

import '@ui/Modal/index.scss'

export interface ModalProps {
  children: ReactNode
  opened: boolean
  onClose?: (event?: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void
  fullScreen?: boolean
  className?: string
  title?: ReactNode | true
  header?: ReactElement | null
  footer?: ReactElement | null
  maxWidth?: Size
}

const Modal = ({
  children,
  fullScreen,
  header,
  footer,
  opened,
  onClose,
  title,
  className,
  maxWidth = 'lg',
}: ModalProps): ReactElement | null => {
  const desktop = !useIsMobile()
  const modalRef = useRef<HTMLDivElement>(null)
  const backdropRef = useRef<HTMLDivElement>(null)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(opened)
  const popupContainer = useContext(PopupContainerContext)

  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    onClose?.(event)
  }

  const updateBodyStyles = useCallback((isOpened: boolean): void => {
    document.body.style.overflow = isOpened ? 'hidden' : ''
    document.body.style.touchAction = isOpened ? 'none' : ''
  }, [])

  const openModal = useCallback(() => {
    updateBodyStyles(true)
    setIsModalVisible(true)
  }, [updateBodyStyles])

  const closeModal = useCallback(() => {
    const hideModal = (): void => {
      updateBodyStyles(false)
      setIsModalVisible(false)
    }

    if (modalRef.current) {
      modalRef.current.addEventListener('animationend', hideModal, { once: true })
    } else {
      hideModal()
    }
  }, [updateBodyStyles])

  useEffect(() => {
    if (opened) openModal()
    else closeModal()
  }, [opened, openModal, closeModal])
  useEffect(() => {
    return () => {
      updateBodyStyles(false)
    }
  }, [updateBodyStyles])

  if (!isModalVisible && !opened) return null

  const modalClassNames = bem('ui-modal', {
    opened,
    closed: !opened,
    'full-screen': fullScreen,
    [maxWidth]: true,
    desktop,
  })
  const classNames = cn(modalClassNames, className)

  return createPortal(
    <>
      <div
        data-tag="modal-overlay"
        ref={backdropRef}
        onClick={handleBackdropClick}
        className={bem('ui-modal', 'overlay')}
      />
      <div data-tag="modal-window" ref={modalRef} className={classNames}>
        {title != null && (
          <div className="ui-modal__header" data-tag="modal-header">
            <div className="row space-between">
              <div className="w-100">{title}</div>
              <button onClick={onClose} className="ui-modal__close-button">
                <Icon name="cross" size="large" data-tag="modal-close" />
              </button>
            </div>
            {header}
          </div>
        )}
        <div data-tag="modal-body" className="ui-modal__content">
          {children}
        </div>
        {footer && (
          <div data-tag="modal-footer" className="ui-modal__footer">
            {footer}
          </div>
        )}
      </div>
    </>,
    popupContainer.current ?? /* istanbul ignore next: not reachable */ document.body,
  )
}

export default Modal
