import React, { ReactElement } from 'react'

import { Skeleton } from '@ui'

const TermsAndPrivacySkeleton = (): ReactElement => {
  return (
    <div className="column">
      <div className="cell-12 pb-3">
        <Skeleton.Item height={60} />
      </div>
      <div className="cell-12">
        <Skeleton.Item height={21} />
      </div>
    </div>
  )
}

export default TermsAndPrivacySkeleton
