import makeApi from '@lib/api'

type DepartureDate = string

export interface CheapestPricesRequest {
  retailerPartnerNumber: RetailerNumber
  departureDates: DepartureDate[]
  currency: Currency
  departureCity?: CityCode
  departureArea?: AreaCode
  departureStation?: StationCode
  arrivalCity?: CityCode
  arrivalArea?: AreaCode
  arrivalStation?: StationCode
}

export type CheapestPricesResponse = Record<DepartureDate, Money>

export default {
  load: makeApi.get<CheapestPricesRequest, CheapestPricesResponse>('/connections/cheapest_prices'),
}
