import bookingAPI, { BookingStatusResponse } from '@api/booking'
import reservationAPI, { StatusResponse as ReservationStatusResponse } from '@api/reservation'
import config from '@config'
import { makeMutation } from '@lib/mutation'
import polling, { PollingApiError } from '@lib/polling'
import settingsStore from '@stores/settings'

type StatusResponse = BookingStatusResponse | ReservationStatusResponse

export const useBookingStatusPolling = makeMutation<number, StatusResponse, PollingApiError>({
  key: 'bookingStatusPolling',
  loader: async id => {
    const settings = settingsStore.get()
    const isReservation = settings.reservation.enabled || settings.quickReservation.enabled
    const fn = isReservation ? reservationAPI.status : bookingAPI.status
    const callPolling = await polling<number, StatusResponse>({
      fn: fn,
      validate: ({ bookingStatus }) => bookingStatus === 'success',
      interval: config.bookingStatus.timeout,
      maxAttempts: config.bookingStatus.maxAttempts,
    })

    return await callPolling(id)
  },
})
