import { useFormikContext } from 'formik'
import React, { ReactElement, useMemo, useState } from 'react'

import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import Extras from '@pages/Checkout/Extras'
import FareClassesSelection from '@pages/Checkout/FareClassesSelection'
import SplitFareClasses from '@pages/Checkout/FareClassesSelection/SplitFareClasses'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useShowExtras } from '@pages/Checkout/hooks/useShowExtras'
import { useSettings } from '@queries/settings'
import { useCheckout } from '@stores/checkout'

const Fields = (): ReactElement => {
  const { t } = useTranslation()
  const [{ outbound, inbound }] = useCheckout()
  const { values } = useFormikContext<CheckoutFormData>()
  const [seatsError, setSeatsError] = useState<ErrorCode | null>(null)
  const [{ fareClasses }] = useSettings()
  const { displayOn, split } = fareClasses
  const isLoading = !outbound

  const fareClassList = useMemo(
    () => outbound?.fares.map(({ fareClass, price }) => ({ ...fareClass, price })) ?? /* istanbul ignore next */ [],
    [outbound],
  )

  const isFareClassSelection = displayOn === 'checkout' || displayOn === 'everywhere' || displayOn === 'search_results'
  const showFareClassSplit = isFareClassSelection && split
  const showFareClassSelection = isFareClassSelection && !split

  const { showExtras } = useShowExtras({ seatsError, values, outbound, inbound })

  return (
    <>
      <div className="cell">
        {showFareClassSelection && (
          <div className={bem('checkout', 'section')}>
            <h3>{t('booking.fareClass.title')}</h3>
            <FareClassesSelection fareClassList={fareClassList} />
          </div>
        )}
        {showFareClassSplit && <SplitFareClasses />}
      </div>
      {showExtras && (
        <div className="cell">
          <div className={bem('checkout', 'section')}>
            <Extras
              isConnectionsLoading={isLoading}
              onSeatsError={setSeatsError}
              seatsError={seatsError}
              outbound={outbound}
              inbound={inbound}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default Fields
