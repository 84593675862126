import CONNECTION_FILTERS from '@lib/connections/filters/all'
import { FilterOptions, FilterType, FilterValues } from '@lib/connections/filters/types'

const applyFilters = (data: Connection[], filters: FilterValues, options: FilterOptions): Connection[] => {
  return data.filter(connection =>
    Object.entries(CONNECTION_FILTERS).every(([type, { filter }]) => {
      const value = filters[type as FilterType]

      return !value?.length || filter(connection, value, options)
    }),
  )
}

export default {
  applyFilters,
}
