import vacancyAPI, { VacancyRequest, VacancyResponse } from '@api/vacancy'
import { makeLoader } from '@lib/loader'

export const useVacancyLoader = makeLoader<VacancyRequest, VacancyResponse>({
  key: 'vacancy',
  loader: async params => await vacancyAPI.load(params),
  enabled: ({ fareClass, departureStationCode, arrivalStationCode }) =>
    !!fareClass && !!departureStationCode && !!arrivalStationCode,
  debounce: 200,
  keepPreviousData: false,
})
