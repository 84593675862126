import React, { ReactElement } from 'react'
import { Trans } from 'react-i18next'

import { useTranslation } from '@lib/i18n'
import { Button, Modal } from '@ui'

import '@components/TermsAndPrivacy/PrivacyModal/index.scss'

interface TermsModalProps {
  opened: boolean
  onClose: () => void
}

const PrivacyModal = ({ onClose, opened }: TermsModalProps): ReactElement => {
  const { t } = useTranslation()

  const privacyFooter = (
    <div className="row p-3 end">
      <Button onClick={onClose} color="secondary">
        {t('privacyPolicy.close')}
      </Button>
    </div>
  )

  return (
    <Modal opened={opened} onClose={onClose} title={t('privacyPolicy.title')} footer={privacyFooter}>
      <div className="p-6 privacy-content">
        <Trans
          i18nKey="privacyPolicy.html"
          components={{ h2: <h2 />, h3: <h3 />, h4: <h4 />, p: <p />, a: <a />, li: <li />, ul: <ul />, u: <u /> }}
        />
      </div>
    </Modal>
  )
}

export default PrivacyModal
