import { FormikErrors } from 'formik'

import { BookingPassenger } from '@api/booking'
import helpers from '@lib/analytics/amplitude/helpers'
import bookingUtils from '@lib/booking'
import checkoutUtils from '@lib/checkout'
import currency from '@lib/currency'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'

const { sendEvent, getConnectionParams, getPriceParams } = helpers

export interface CheckoutData {
  outbound: Connection
  inbound: Connection | null
  fareClass: string | undefined | null
  passengers: BookingPassenger[]
}

export type CheckoutAncillary = Pick<Ancillary.Item, 'code' | 'name' | 'price'>

const getPaxBreakdown = (passengers: BookingPassenger[]): BookingPassenger =>
  passengers.reduce(
    /* istanbul ignore next: ignore unaccessible default  */
    (acc, { type = 'unknown' }) => ({
      ...acc,
      [type]: ((acc[type as keyof object] as number) ?? 0) + 1,
    }),
    {},
  )

const getDiscrepancyData = (initialPrice: number, price: number): any => {
  const discrepancyAmount = Number((price - initialPrice).toFixed(2))

  return {
    discrepancy: discrepancyAmount !== 0,
    discrepancyAmount,
    discrepancyPercent: Number((price / initialPrice - 1).toFixed(2)),
  }
}

const getCheckoutData = (props: CheckoutData): Record<string, any> => {
  const { outbound, inbound, fareClass, passengers } = props
  const fareClassCode = fareClass ?? /* istanbul ignore next */ ''

  return {
    bookingOutbound: getConnectionParams(outbound, fareClassCode),
    bookingInbound: inbound ? getConnectionParams(inbound, fareClassCode) : null,
    pax: passengers.length,
    paxBreakdown: getPaxBreakdown(passengers),
  }
}

export interface ViewCheckoutPageData extends CheckoutData {
  vacant: boolean
  initialPrice?: number | null
  price?: Money | null
  reason?: string
  errorData?: any
  ancillaries?: CheckoutAncillary[]
}

const viewPage = (data: ViewCheckoutPageData): void => {
  const { initialPrice, price, vacant, reason, errorData, ancillaries } = data
  const currentPriceInEuro = price && currency.getPriceInEuro(price)
  const discrepancy =
    initialPrice != null && currentPriceInEuro != null ? getDiscrepancyData(initialPrice, currentPriceInEuro) : {}

  const eventData = {
    ...getCheckoutData({ ...data }),
    ...discrepancy,
    vacant,
    reason,
    errorData,
    ancillaries,
  }

  sendEvent('view-checkout-page', eventData)
}

export interface PurchaseData {
  connections: { outbound: Connection; inbound: Connection | null }
  formData: CheckoutFormData
  currency: Currency
  reselling: boolean
}

const purchaseTrip = ({ connections, formData, currency, reselling }: PurchaseData): void => {
  const { paymentMethod, fareClass, passengers, fees, ancillaries } = formData
  const amount = bookingUtils.calculateAmount(formData) + checkoutUtils.calculateTotalFees(fees)
  const data = {
    ...getCheckoutData({ ...connections, fareClass, passengers }),
    ...getPriceParams({ fractional: amount, currency }),
    paymentMethod,
    reselling,
    ancillaries: Object.entries(ancillaries).flatMap(([_, values]) =>
      values.map(({ code, price, name }) => ({ code, price, name })),
    ),
  }

  sendEvent('purchase', data)
}

const addPassenger = (express: boolean): void => {
  sendEvent('add-passenger', { express })
}

const removePassenger = (express: boolean): void => {
  sendEvent('remove-passenger', { express })
}

export interface ChangeFareClassData {
  fromFareClass: string | undefined | null
  toFareClass: string | undefined | null
}

const changeFareClass = (props: ChangeFareClassData): void => {
  sendEvent('change-fareclass', props)
}

const clickTripDetails = (): void => {
  sendEvent('trip-details')
}

const clickCarrierDetails = (): void => {
  sendEvent('carrier-details')
}

const clickPay = (submitted: boolean, errors: FormikErrors<CheckoutFormData>): void => {
  sendEvent('click-pay', { submitted, errors })
}

const viewAncillaries = (): void => {
  sendEvent('view-ancillaries')
}

const clickAncillaryDetails = (name: string): void => {
  sendEvent('click-learn-more', { name })
}

const changeAncillary = (name: string, count: number): void => {
  sendEvent('change-ancillary', { name, count })
}

export default {
  viewPage,
  addPassenger,
  removePassenger,
  changeFareClass,
  purchaseTrip,
  clickTripDetails,
  clickCarrierDetails,
  clickPay,
  viewAncillaries,
  clickAncillaryDetails,
  changeAncillary,
}
