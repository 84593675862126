import cn from 'classnames'
import React, { ReactElement, ReactNode } from 'react'

import '@ui/Radio/index.scss'

interface Props {
  children: ReactNode
  checked: boolean
  onChange: () => void
  centered?: boolean
  disabled?: boolean
}

const Radio = ({ children, onChange, checked, disabled, centered }: Props): ReactElement => (
  <label className={cn('ui-radio', { disabled, centered })}>
    <input
      className="ui-radio-input"
      type="radio"
      onChange={() => {
        onChange()
      }}
      disabled={disabled}
      checked={checked}
    />
    <span className="ui-radio-label">{children}</span>
  </label>
)

export default Radio
