/* istanbul ignore file */

import ga from '@lib/analytics/ga'

const get = (): string | null => {
  const linkedPage = ga?.linkedPage

  if (!linkedPage) return null

  const formNode = createFormWithRedirectionTo(linkedPage)
  const buttonNode = addButtonTo(formNode)

  document.body.append(formNode)

  buttonNode.click()

  return getLinkerParam(formNode)
}

const createFormWithRedirectionTo = (formAction: string): HTMLElement => {
  const formNode: any = document.createElement('FORM')

  formNode.action = formAction

  formNode.style.opacity = '0'
  formNode.addEventListener('submit', (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
  })

  return formNode
}

const addButtonTo = (formNode: HTMLElement): HTMLElement => {
  const buttonNode: any = document.createElement('BUTTON')

  buttonNode.type = 'submit'

  formNode.append(buttonNode)

  return buttonNode
}

const getLinkerParam = (formNode: HTMLElement): string | null => {
  const _glNode = formNode.querySelector('input[name="_gl"]')

  return _glNode ? (_glNode as any).value : null
}

export default {
  get,
}
