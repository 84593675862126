import { Field } from 'formik'
import React, { ReactElement } from 'react'

import useValidators from '@hooks/useValidators'
import { useTranslation } from '@lib/i18n'
import InputField from '@ui/Input/Field'

const BlikForm = (): ReactElement => {
  const { required, combine, validateLength } = useValidators()
  const { t } = useTranslation()
  const requiredCodeLength = 6

  return (
    <>
      <strong className="cell-12">{t('checkout.blik.title')}</strong>
      <Field
        type="number"
        validate={combine([required, validateLength(requiredCodeLength, t('checkout.blik.error'))])}
        component={InputField}
        name="blikCode"
        label={t('checkout.blik.placeholder')}
      />
    </>
  )
}

export default BlikForm
