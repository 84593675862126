import makeApi from '@lib/api'

export interface RatesRequest {
  retailerPartnerNumber: RetailerNumber
  marketingCarrierCode: CarrierCode
}

export type RatesResponse = Installment.Rate[]

export default {
  rates: async (params: RatesRequest) => {
    return await makeApi.get<RatesRequest, RatesResponse>(
      `/retailer/${params.retailerPartnerNumber}/installment_rates`,
    )(params)
  },
}
