import React, { useState } from 'react'

import TransFromHtml from '@components/TransFromHtml'
import { Trans, useTranslation } from '@lib/i18n'
import textUtils from '@lib/text'
import { Button, Modal } from '@ui'

import '@components/Blocks/Subscribe/PrivacyPolicy/index.scss'

interface Props {
  privacyPolicy?: string
}

const PrivacyPolicy = ({ privacyPolicy }: Props) => {
  const { t } = useTranslation()
  const [opened, setOpenedTerms] = useState<boolean>(false)
  const closed = () => setOpenedTerms(false)

  const footer = (
    <div className="row p-3 end">
      <Button onClick={closed} color="secondary">
        {t('privacyPolicy.close')}
      </Button>
    </div>
  )

  const renderCarrierPolicy = (privacyPolicy: string) => (
    <pre className="terms-content">{textUtils.format(privacyPolicy)}</pre>
  )

  return (
    <div className="body-12 subscribe-block__privacy-policy">
      <Trans
        i18nKey="customBlocksCommon.subscribe.privacyPolicy"
        components={{
          termsLink: (
            <span
              className="subscribe-block__link"
              onClick={e => {
                setOpenedTerms(true)
                e.preventDefault()
              }}
            />
          ),
        }}
      />
      <Modal
        opened={opened}
        onClose={closed}
        title={t('customBlocksCommon.subscribe.privacyPolicyModal')}
        footer={footer}
      >
        <div className="p-6">
          {privacyPolicy ? renderCarrierPolicy(privacyPolicy) : <TransFromHtml i18nKey="privacyPolicy.html" />}
        </div>
      </Modal>
    </div>
  )
}

export default PrivacyPolicy
