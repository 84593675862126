import { ApplePayElementProps } from '@adyen/adyen-web/components/ApplePay/types'
import Core from '@adyen/adyen-web/core'
import React, { useCallback, useMemo } from 'react'

import utils from '@lib/utils'
import useAdyenPayButtonMethod, {
  PayButtonInstanceParams,
} from '@pages/Checkout/hooks/Payment/Adyen/useAdyenPayButtonMethod'
import PaymentLabel from '@pages/Checkout/Payment/Label'

export const useApplePay = (adyen?: Core): AdyenTypes.Method => {
  const buildInstance = useCallback(({ validate, onSubmit, adyen }: PayButtonInstanceParams) => {
    const props: any = {
      buttonType: 'buy',
      buttonColor: 'black',
      onSubmit,
      onClick: utils.function.toPromiseLike(validate),
    }

    return adyen?.create('applepay', props as ApplePayElementProps)
  }, [])

  const props = useAdyenPayButtonMethod(buildInstance, adyen)

  const getOption = useCallback(
    () => ({
      value: 'apple_pay',
      label: <PaymentLabel type="applePay" />,
      content: <div style={{ margin: 0 }} id="three-d-secure-iframe-portal"></div>,
    }),
    [],
  )

  return useMemo(() => ({ ...props, getOption }), [getOption, props])
}
