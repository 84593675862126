const groupByLocationCodes = (list: Connection[]): Record<string, Connection[]> => {
  return list.reduce<Record<string, Connection[]>>((arr, connection) => {
    const from = connection.departureStation.code
    const to = connection.arrivalStation.code
    const key = `${from}-${to}`

    return { ...arr, [key]: [...(arr[key] ?? []), connection] }
  }, {})
}

const isDepartureFrom = (connection: Connection, departureLocation: Location.Item): boolean => {
  const departureCodes = [
    connection.departureStation.code,
    connection.departureStation.city.id,
    connection.departureStation.area?.id,
  ]

  return departureCodes.includes(departureLocation.code)
}

interface ConnectionGroupParams {
  departureLocation?: Location.Item | null
  arrivalLocation?: Location.Item | null
  returnDate?: string | null
}

interface SortedConnections {
  outbounds: Connection[]
  inbounds: Connection[]
}

const groupConnections = (list: Connection[], arg: ConnectionGroupParams): SortedConnections => {
  const { departureLocation, arrivalLocation, returnDate } = arg

  if (returnDate == null) return { outbounds: list, inbounds: [] }

  const map = groupByLocationCodes(list)

  const outbounds: Connection[] = []
  const inbounds: Connection[] = []

  while (Object.keys(map).length > 0) {
    const [[directKey, directConnections]] = Object.entries(map)
    const [codeFrom, codeTo] = directKey.split('-')
    const reversedKey = `${codeTo}-${codeFrom}`
    const reversedConnections = map[reversedKey]

    /* istanbul ignore else: TODO fix specs to cover all */
    if (departureLocation && arrivalLocation && reversedConnections?.length > 0) {
      const directIsOutbound = isDepartureFrom(directConnections[0], departureLocation)

      if (directIsOutbound) {
        outbounds.push(...directConnections)
        inbounds.push(...reversedConnections)
      } else if (isDepartureFrom(directConnections[0], arrivalLocation)) {
        inbounds.push(...directConnections)
        outbounds.push(...reversedConnections)
      }
    }

    /* eslint-disable @typescript-eslint/no-dynamic-delete */
    delete map[directKey]
    delete map[reversedKey]
    /* eslint-enable @typescript-eslint/no-dynamic-delete */
  }

  return { outbounds, inbounds }
}

export default groupConnections
