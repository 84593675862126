import React from 'react'

import MenuGroup from '@components/Header/Group'
import MenuLink from '@components/Header/Link'

const isGroup = (props: NavBar.Item): props is NavBar.Group => 'items' in props

type MenuItemProps = NavBar.Item & { level: number }

const MenuItem = (props: MenuItemProps) => {
  return isGroup(props) ? <MenuGroup {...props} /> : <MenuLink {...props} />
}

export default MenuItem
