import React, { ReactElement } from 'react'

import Money from '@components/Money'
import { useTranslation } from '@lib/i18n'

interface FeesProps {
  price: Money
}

const Seats = ({ price }: FeesProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <div className="column gap-2 mb-1">
      <div className="cell row space-between booking-details__info">
        <div>{t('extras.seats.seats')}</div>
        <div className="booking-details__amount">
          <Money {...price} />
        </div>
      </div>
    </div>
  )
}

export default Seats
