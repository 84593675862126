import cn from 'classnames'
import React, { ReactElement } from 'react'

import '@ui/Divider/index.scss'

interface DividerProps {
  className?: string
}

const Divider = ({ className }: DividerProps): ReactElement => {
  return <div className={cn('ui-divider', className)} />
}

export default Divider
