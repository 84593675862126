import { FormikProps } from 'formik'
import React, { ReactElement, useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useBookingFlow, { FinishPaymentParams } from '@hooks/useBookingFlow'
import amplitude from '@lib/analytics/amplitude'
import bookingUtils from '@lib/booking'
import checkoutUtils from '@lib/checkout'
import { useCreateBooking } from '@loaders/createBooking'
import CheckoutForm from '@pages/Checkout/Form/'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useSettings } from '@queries/settings'
import { useCheckout } from '@stores/checkout'
import { useParams } from '@stores/params'

const SubmitBooking = (): ReactElement | null => {
  const form = useRef<FormikProps<CheckoutFormData>>(null)
  const navigate = useNavigate()

  const [{ outbound, inbound }] = useCheckout()
  const [{ reselling }] = useSettings()
  const [pageParams] = useParams()
  const onSuccess = useCallback(
    ({ action, redirectPath }: FinishPaymentParams): void => {
      if (action === 'redirect' && redirectPath && outbound && form.current) {
        amplitude.checkout.purchaseTrip({
          connections: { outbound, inbound },
          formData: form.current.values,
          currency: pageParams.currency,
          reselling: reselling.enabled,
        })
        navigate(checkoutUtils.buildConfirmationPageUrl(pageParams, redirectPath))
      }
    },
    [inbound, navigate, outbound, pageParams, reselling.enabled],
  )

  const bookingHandlers = useBookingFlow({ form, onSuccess })
  const { mutate: createBooking, ...bookingCreate } = useCreateBooking({ onSuccess: bookingHandlers.confirmPayment })

  const submit = async (formData: CheckoutFormData): Promise<void> => {
    const data = await bookingHandlers.submitForm(formData)
    const createParams = bookingUtils.buildCreateParams(data, pageParams, inbound as Connection, outbound as Connection)

    createBooking(createParams)
  }

  return (
    <CheckoutForm
      finishPayment={onSuccess}
      innerRef={form}
      submit={submit}
      validate={bookingHandlers.validate}
      isLoading={bookingCreate.isLoading}
      error={bookingCreate.error}
    />
  )
}

export default SubmitBooking
