import React, { ReactElement } from 'react'

import { useTranslation } from '@lib/i18n'
import { Button } from '@ui'

interface CloseProps {
  onClick: () => void
}

const Close = ({ onClick }: CloseProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <div className="reservations-modal__buttons-home">
      <Button
        onClick={() => {
          onClick()
        }}
      >
        <div className="reservations-modal__homepage-text">{t('checkout.reservationModal.close')}</div>
      </Button>
    </div>
  )
}

export default Close
