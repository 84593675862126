import cheapestPricesAPI, { CheapestPricesRequest, CheapestPricesResponse } from '@api/connections/cheapestPrices'
import { LoaderFunction, makeLoader } from '@lib/loader'
import paramsUtils from '@lib/params'
import utils from '@lib/utils'

export interface CheapestPricesParams {
  retailerPartnerNumber: RetailerNumber
  departureDates: string[]
  currency: Currency
  arrivalLocation: Location.Item | null
  departureLocation: Location.Item | null
}

export const loadCheapestPrices: LoaderFunction<CheapestPricesParams, CheapestPricesResponse> = async params => {
  const { departureLocation, arrivalLocation, ...rest } = params

  const filteredParams = utils.object.compact<CheapestPricesRequest>({
    ...(departureLocation && paramsUtils.flatLocation(departureLocation, 'departure')),
    ...(arrivalLocation && paramsUtils.flatLocation(arrivalLocation, 'arrival')),
    ...rest,
  })

  return await cheapestPricesAPI.load(filteredParams)
}

export const useCheapestPricesLoader = makeLoader<CheapestPricesParams, CheapestPricesResponse>({
  key: 'cheapestPrices',
  keepPreviousData: false,
  cacheTime: 15 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
  enabled: ({ departureDates, departureLocation, arrivalLocation }) =>
    departureDates.length > 0 && departureLocation != null && arrivalLocation != null,
  loader: loadCheapestPrices,
})
