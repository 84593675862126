import React, { Fragment, ReactElement, useState } from 'react'

import { useTranslation } from '@lib/i18n'
import { Divider, Icon, Modal } from '@ui'

interface FareConditionsProps {
  conditions: Condition[]
}

const FareConditions = ({ conditions }: FareConditionsProps): ReactElement => {
  const { t } = useTranslation()
  const [opened, setOpened] = useState(false)
  const conditionContent = (
    <div className="column p-4 gap-2">
      {conditions.map(({ description }, index) => (
        <Fragment key={index}>
          <div className="cell body-14 fare-conditions__description">{description}</div>
          {index < conditions.length - 1 && <Divider />}
        </Fragment>
      ))}
    </div>
  )

  return (
    <>
      <Icon
        className="fare-conditions-info"
        name="info"
        size="large"
        onClick={() => {
          setOpened(true)
        }}
      />
      <Modal
        maxWidth="md"
        opened={opened}
        onClose={() => {
          setOpened(false)
        }}
        title={t('card.fareConditions')}
      >
        {conditionContent}
      </Modal>
    </>
  )
}

export default FareConditions
