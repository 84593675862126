import React, { ReactElement } from 'react'

import Section from '@components/ConnectionFilters/Section'
import { Skeleton } from '@ui'

const SkeletonFilter = (): ReactElement => {
  return (
    <div className="connection-filters cell">
      <Section>
        <div className="cell-7">
          <Skeleton.Item height={24} />
        </div>
      </Section>
    </div>
  )
}

export default SkeletonFilter
