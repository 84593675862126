import * as ReactGA from 'react-ga'

/* istanbul ignore next */
class GoogleAnalytics {
  linkedPage?: string

  init = ({ container_id, linked_page }: Analytics.Settings['ga']): void => {
    if (container_id) {
      ReactGA.initialize(container_id.toString())
    }
    this.linkedPage = linked_page
  }
}

export default new GoogleAnalytics()
