import { differenceInDays } from 'date-fns'

import { markupFee, priceWithOutFee, totalPrice } from '@lib/analytics/gtm/confirmation/helpers'
import date from '@lib/date'

export interface BookingDataEvent {
  tripPrice: number
  totalPrice: number
  feePrice: number
  origin_city: string
  destination_city: string
  origin_country: string
  destination_country: string
  name_station: string
  name: string
  connection_startdate: string
  connection_enddate: string
  connection_departure_time: string
  connection_arrival_time: string
  pax: number
  retailer_partner_number: number
  payment_method: string
  event: string
  site_language: string
  arrival_station_code: string
  departure_station_code: string
  marketing_carrier_code: string
  booking_id: string
  currency: string
  basket_days_to_departure: number
  provider_id: string
  multisegment: boolean
}

const EVENT_NAME = 'thank_you_page'
const EVENT_TYPE = 'thank_you_event'

export const buildBookingEvent = (bookingData: Booking.Confirmation, locale: Locale): BookingDataEvent => {
  const { booking, marketingCarrier, outboundConnection } = bookingData

  const event = {
    totalPrice: totalPrice(booking),
    tripPrice: priceWithOutFee(booking),
    feePrice: markupFee(booking),
    pax: booking.pax,
    retailer_partner_number: booking.retailerPartnerNumber,
    payment_method: booking.paymentMethod,
    event: EVENT_NAME,
    site_language: locale,
    arrival_station_code: booking.arrivalStation.code,
    departure_station_code: booking.departureStation.code,
    marketing_carrier_code: marketingCarrier.code,
    booking_id: booking.distribusionBookingNumber,
    currency: booking.totalPrice.currency,
    basket_days_to_departure: daysToDepartureLeft(outboundConnection),
    checkout_type: EVENT_TYPE,
    provider_id: marketingCarrier.id,
    multisegment: booking.segments.length > 1,
    origin_city: booking.departureStation.city.name,
    destination_city: booking.arrivalStation.city.name,
    origin_country: booking.departureStation.code.substring(0, 2),
    destination_country: booking.arrivalStation.code.substring(0, 2),
    name_station: tripStationNames(booking),
    name: tripCityNames(booking),
    connection_startdate: date.formatDate(departureDate(outboundConnection)),
    connection_enddate: date.formatDate(arrivalDate(outboundConnection)),
    connection_departure_time: date.formatTime(departureDate(outboundConnection)),
    connection_arrival_time: date.formatTime(arrivalDate(outboundConnection)),
  }

  return event
}

const departureDate = (connection: Connection): Date => date.parse(connection.departureTime, 'UTC')

const arrivalDate = (connection: Connection): Date => date.parse(connection.arrivalTime, 'UTC')

const tripStationNames = (booking: Booking.Info): string =>
  `${booking.departureStation.name.replace(/\s/g, '')}_${booking.arrivalStation.name.replace(/\s/g, '')}`

const tripCityNames = (booking: Booking.Info): string =>
  `${booking.departureStation.city.name}_${booking.arrivalStation.city.name}`

const daysToDepartureLeft = (connection: Connection): number => {
  const connectionDepartureDate = departureDate(connection)

  const diff = differenceInDays(connectionDepartureDate, date.today())

  return diff < 0 ? 0 : diff
}
