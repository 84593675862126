import cn from 'classnames'
import React, { ReactElement, useMemo } from 'react'

import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'

import '@pages/Checkout/Payment/Label/index.scss'

interface PaymentLabelProps {
  type: string
  text?: string
  brands?: BrandData[]
  showName?: boolean
}

interface BrandData {
  type: string
  icon?: string
}

const STORED_LOGOS = ['applePay', 'googlePay', 'mc', 'me', 'visa', 'pix', 'amex', 'paypal', 'blik']
const EXCLUDE_BRANDS = ['bcmc']

const PaymentLabel = (props: PaymentLabelProps): ReactElement => {
  const { type, text, brands = [{ type }], showName } = props
  const { t } = useTranslation()
  const availableBrands = useMemo(() => brands.filter(({ type }) => !EXCLUDE_BRANDS.includes(type)), [brands])

  const logos = useMemo(
    () =>
      availableBrands
        .map(({ type, icon }) => {
          const logo = STORED_LOGOS.includes(type) ? require(`@images/paymentBrands/${type}.svg`) : icon
          if (logo == null) return null

          return (
            <div key={type}>
              <img key={type} alt={type} className={cn(bem('payment-label', 'icon'), type)} src={logo} />
            </div>
          )
        })
        .filter(i => !!i),
    [availableBrands],
  )

  return (
    <div className="row gap-3 wrap" data-tag={type}>
      {showName && (
        <div className={cn(logos.length ? 'cell-3 cell-sm-6' : 'cell-12')}>
          {text ?? t(`checkout.paymentMethods.${type}`)}
        </div>
      )}
      {!!logos.length && <div className={bem('payment-label', 'brands')}>{logos}</div>}
    </div>
  )
}

export default PaymentLabel
