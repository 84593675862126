import React, { ReactElement } from 'react'

import bem from '@lib/bem'
import { Icon } from '@ui'

import '@pages/Search/PopularDirectionCard/Header.scss'

interface HeaderProps {
  connection: PopularConnection
}

const Header = ({ connection: { departureLocation, arrivalLocation, customStyling } }: HeaderProps): ReactElement => {
  return (
    <div className="popular-direction-header">
      <img
        alt={`${departureLocation.name} - ${arrivalLocation.name}`}
        src={customStyling?.backgroundImage}
        className={bem('popular-direction-header', 'image')}
      />
      <div className={bem('popular-direction-header', 'title')}>
        <div data-tag="departure" className={bem('popular-direction-header', 'locationName')}>
          {departureLocation.name}
        </div>
        <Icon name="arrow-right" size="large" />
        <div data-tag="arrival">{arrivalLocation.name}</div>
      </div>
    </div>
  )
}

export default Header
