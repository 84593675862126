import '@formatjs/intl-displaynames/polyfill'
import '@formatjs/intl-locale/polyfill'
// Needs to be imported after polyfill
// eslint-disable-next-line import/order
import '@formatjs/intl-displaynames/locale-data/en'
import cn from 'classnames'
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js/min'
import React, { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react'
import { FixedSizeList, ListChildComponentProps } from 'react-window'

import bem from '@lib/bem'

const ITEM_HEIGHT = 48

interface PhoneFieldMenuProps {
  countries: CountryCode[]
  onSelect: (country: CountryCode) => void
}

const PhoneFieldMenu = ({ countries, onSelect }: PhoneFieldMenuProps): ReactElement => {
  const displayCountryNames = useMemo(() => new Intl.DisplayNames(['en'], { type: 'region' }), [])
  const listRef = useRef<FixedSizeList>(null)
  const renderCountryOption = ({ index, style }: ListChildComponentProps): ReactElement => (
    <div
      className={cn('row', 'gap-1', 'items-center', bem('phone', 'option'))}
      style={style}
      onClick={() => {
        onSelect(countries[index])
      }}
    >
      <div className="row">
        <span className={`flag:${countries[index]}`} />
      </div>
      <span>{displayCountryNames.of(countries[index])}</span>
      <span>{`+${getCountryCallingCode(countries[index])}`}</span>
    </div>
  )

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      const key = e.key.toLowerCase()
      const index = countries.findIndex(code => displayCountryNames.of(code)?.toLowerCase().startsWith(key))

      /* istanbul ignore else */
      if (index >= 0) {
        listRef.current?.scrollToItem(index, 'start')
      }
    },
    [countries, displayCountryNames],
  )

  useEffect(() => {
    document.addEventListener('keypress', handleKeyPress)

    return () => {
      document.removeEventListener('keypress', handleKeyPress)
    }
  }, [handleKeyPress])

  return (
    <div className={bem('phone', 'countries')}>
      <FixedSizeList
        ref={listRef}
        itemSize={ITEM_HEIGHT}
        height={ITEM_HEIGHT * 4}
        itemCount={countries.length}
        width="100%"
      >
        {renderCountryOption}
      </FixedSizeList>
    </div>
  )
}

export default PhoneFieldMenu
