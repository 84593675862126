import React, { ReactElement, useState } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import amplitude from '@lib/analytics/amplitude'
import { useTranslation } from '@lib/i18n'
import textUtils from '@lib/text'
import { Icon, Modal } from '@ui'

interface DescriptionProps {
  title: string
  description: string
}

const Description = ({ title, description }: DescriptionProps): ReactElement => {
  const isMobile = useIsMobile()
  const [opened, setOpened] = useState<boolean>(false)
  const { t } = useTranslation()

  return (
    <>
      <div
        className="extras__card-learn row gap-1 items-center"
        onClick={() => {
          setOpened(true)
          amplitude.checkout.clickAncillaryDetails(title)
        }}
      >
        <Icon name="info" size={isMobile ? 'small' : 'medium'} />
        <span>{t('extras.learn_more')}</span>
      </div>
      <Modal
        opened={opened}
        title={title}
        onClose={() => {
          setOpened(false)
        }}
        fullScreen={isMobile}
      >
        <div className="p-3 extras__card-learn-description">{textUtils.format(description)}</div>
      </Modal>
    </>
  )
}

export default Description
