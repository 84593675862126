import React, { ReactElement, ReactNode } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import Description from '@pages/Checkout/Extras/Card/Description'
import { Icon } from '@ui'

interface ControlsProps {
  selected: boolean
  ancillary: Ancillary.Item
  onClick: (ancillary: Ancillary.Item) => void
}

const Controls = ({ selected, ancillary, onClick }: ControlsProps): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const description = (ancillary: Ancillary.Item): ReactNode => (
    <Description title={ancillary.name} description={ancillary.description} />
  )

  return (
    <div className="row items-center">
      {!isMobile && ancillary.description && <span className="mr-3">{description(ancillary)}</span>}
      <div className={bem('checkout', 'accommodation', { selected })}>
        {selected && (
          <>
            <Icon name="checkout" size="large" />
            <span>{t('extras.ancillary.accommodation.selected')}</span>
          </>
        )}
        {!selected && (
          <span
            onClick={() => {
              onClick(ancillary)
            }}
          >
            {t('extras.ancillary.accommodation.select')}
          </span>
        )}
      </div>
    </div>
  )
}

export default Controls
