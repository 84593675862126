import React, { ReactElement, useState } from 'react'
import { Trans } from 'react-i18next'

import PrivacyModal from '@components/TermsAndPrivacy/PrivacyModal'
import TermsModal from '@components/TermsAndPrivacy/TermsModal'
import { useTranslation } from '@lib/i18n'

interface TermsAndPrivacyProps {
  carriers: MarketingCarrier[]
}

const TermsAndPrivacy = ({ carriers }: TermsAndPrivacyProps): ReactElement => {
  const { t } = useTranslation()
  const [privacyOpened, setPrivacyOpened] = useState<boolean>(false)
  const [openedTerms, setOpenedTerms] = useState<string | null>(null)
  const closePrivacyModal = (): void => {
    setPrivacyOpened(false)
  }
  const closeTermsModal = (): void => {
    setOpenedTerms(null)
  }
  const getTerms = (terms: string): string => t('checkout.bookingDetails.terms.retailer', { defaultValue: terms })

  return (
    <>
      <Trans
        i18nKey="checkout.bookingDetails.terms.text"
        components={{
          carriers: (
            <>
              {carriers.map(({ tradeName, id, terms = '' }, i) => (
                <span key={id}>
                  <Trans
                    i18nKey="checkout.bookingDetails.terms.carrier"
                    values={{ name: tradeName }}
                    components={{
                      termsLink: (
                        <a
                          onClick={() => {
                            setOpenedTerms(getTerms(terms))
                          }}
                        />
                      ),
                    }}
                  />
                  {i < carriers.length - 1 && /* istanbul ignore next */ ', '}
                </span>
              ))}
            </>
          ),
          policyLink: (
            <a
              href="#"
              onClick={() => {
                setPrivacyOpened(true)
              }}
            />
          ),
          retailer: (
            <a
              href="#"
              onClick={() => {
                setOpenedTerms(t('checkout.bookingDetails.terms.retailerAdditional', { defaultValue: '' }))
              }}
            />
          ),
        }}
      />
      <TermsModal opened={openedTerms != null} onClose={closeTermsModal} text={openedTerms ?? ''} />
      <PrivacyModal opened={privacyOpened} onClose={closePrivacyModal} />
    </>
  )
}

export default TermsAndPrivacy
