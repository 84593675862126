import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { PaymentMethodStatus } from '@enums'
import { SubmitFormEvent } from '@hooks/useBookingFlow'
import vgs, { VGS } from '@lib/vgs'
import { useSavedCards } from '@loaders/retailers/cards'
import { PaymentMethod } from '@pages/Checkout/hooks/Payment/useBookingPayment'
import { useVgsValidate } from '@pages/Checkout/hooks/Payment/VGS/helpers/useValidate'
import { VGSCardData } from '@pages/Checkout/hooks/useInitialFormValues'
import PaymentLabel from '@pages/Checkout/Payment/Label'
import SavedCardForm from '@pages/Checkout/Payment/SavedCardForm'
import { useSettings } from '@queries/settings'
import { useCarrierSpecificParams } from '@stores/params'

const useSavedCreditCard = (): PaymentMethod => {
  const [{ retailerPartnerNumber }] = useCarrierSpecificParams()
  const [{ amtrackSavedCards }] = useSettings()
  const [status, setStatus] = useState(PaymentMethodStatus.Pending)
  const [vgsForm, setVgsForm] = useState<VGS.Form>()
  const { data: savedCards, error } = useSavedCards({ retailerPartnerNumber, enabled: amtrackSavedCards.enabled })
  const validate = useVgsValidate(vgsForm)

  useEffect(() => {
    if (!amtrackSavedCards.enabled || error != null) setStatus(PaymentMethodStatus.Rejected)
    else setStatus(savedCards != null && vgsForm != null ? PaymentMethodStatus.Ready : PaymentMethodStatus.Pending)
  }, [amtrackSavedCards.enabled, error, savedCards, vgsForm])

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      setVgsForm(await vgs.init())
    }
    void initialize()
  }, [])

  const getOption = useCallback(
    () =>
      savedCards?.map(card => ({
        value: `saved_credit_card:${card.lastDigits}`,
        label: (
          <PaymentLabel text={`•• ${card.lastDigits}`} type="credit_card" brands={[{ type: card.type }]} showName />
        ),
        content: <SavedCardForm card={card} vgsForm={vgsForm} />,
      })),
    [savedCards, vgsForm],
  )

  /* istanbul ignore next */
  const loadCvcData = useCallback<SubmitFormEvent>(
    async data => {
      if (!vgsForm) throw new Error('VGS is not ready')
      const { result } = await vgs.promisedTokenize<VGSCardData>(vgsForm)

      return {
        paymentMethod: 'credit_card',
        paymentProvider: 'vgs',
        paymentMethodData: { ...data.paymentMethodData, ...result },
      }
    },
    [vgsForm],
  )

  const submitForm = useCallback<SubmitFormEvent>(
    async data =>
      amtrackSavedCards.cvc ? /* istanbul ignore next */ await loadCvcData(data) : { paymentMethod: 'credit_card' },
    [amtrackSavedCards.cvc, loadCvcData],
  )

  return useMemo(
    () => ({
      status,
      getOption,
      on: {
        submitForm,
        validate,
      },
    }),
    [getOption, status, submitForm, validate],
  )
}

export default useSavedCreditCard
