import { Field, getIn, useFormikContext } from 'formik'
import React, { ReactElement } from 'react'

import config from '@config'
import useValidators from '@hooks/useValidators'
import { useTranslation } from '@lib/i18n'
import InputField from '@ui/Input/Field'

interface ExpirationDateProps {
  name: string
}

const ExpirationDate = ({ name }: ExpirationDateProps): ReactElement => {
  const { t } = useTranslation()
  const { required, combine, date } = useValidators()
  const { values } = useFormikContext()
  const type = getIn(values, `${name}.governmentIdType`)

  return (
    <>
      {!config.validityBlacklistedTypes.includes(type) && (
        <Field
          component={InputField}
          type="date"
          label={t('checkout.passengerDetails.validityExpiration')}
          name={`${name}.governmentIdValidityExpiration`}
          validate={combine([required, date])}
          required
        />
      )}
    </>
  )
}

export default ExpirationDate
