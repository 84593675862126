import React, { ReactElement, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Money from '@components/Money'
import config from '@config'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { useSettings } from '@queries/settings'
import { Icon, Tooltip } from '@ui'
import Button from '@ui/Button'

import '@components/SeatSelection/Footer/index.scss'

interface FooterProps {
  passengerCount: number
  reservedSeatsCount: number
  requiredSeats?: number | null
  outboundSeatsCount: number
  price: Money | null
  onClick: () => void
}

const Footer = (props: FooterProps): ReactElement => {
  const { passengerCount, onClick, reservedSeatsCount, requiredSeats, price, outboundSeatsCount } = props
  const { t } = useTranslation()
  const [{ seatSelection }] = useSettings()
  const isMobile = useIsMobile()
  const { pathname } = useLocation()
  const [isAnimationInProgress, setIsAnimationInProgress] = useState<boolean>(false)

  const selectionOnResult = seatSelection.enabledOnSearchResults && pathname === '/result'
  const selectionOnCheckout = seatSelection.enabledOnCheckout && pathname === '/checkout'

  const limitSeatsCount = outboundSeatsCount === 0 ? config.maxPassengers : outboundSeatsCount

  const enableWarning = selectionOnResult && passengerCount > limitSeatsCount
  const limitByMaxSeats = selectionOnResult && !outboundSeatsCount && !requiredSeats
  const limitByReservedSeats = selectionOnCheckout || !!outboundSeatsCount || requiredSeats

  const disableButton = useMemo(() => {
    if (selectionOnCheckout) return passengerCount > 0 && passengerCount !== reservedSeatsCount
    if (outboundSeatsCount) return passengerCount !== outboundSeatsCount
    if (requiredSeats) return passengerCount !== requiredSeats

    return passengerCount === 0 || passengerCount > limitSeatsCount
  }, [limitSeatsCount, outboundSeatsCount, passengerCount, requiredSeats, reservedSeatsCount, selectionOnCheckout])

  const disabledTooltip = (selectionOnResult && outboundSeatsCount === 0) || !disableButton || isAnimationInProgress
  const handleClick = (): void => {
    setIsAnimationInProgress(true)
    onClick()
  }
  const maxReservedSeats = requiredSeats ? requiredSeats : outboundSeatsCount || reservedSeatsCount

  return (
    <div className="column seat-selection__footer">
      <div className="row space-between">
        <span className="mb-1">
          <span className={bem('seat-selection', 'footer-title')}>{`${t('seats.selected_seats')}: `}</span>
          {limitByReservedSeats && (
            <span>
              {passengerCount}/{maxReservedSeats}
            </span>
          )}
          {limitByMaxSeats && (
            <span className={bem('seat-selection', 'footer-limit', { warning: passengerCount > limitSeatsCount })}>
              {passengerCount}
            </span>
          )}
        </span>
        {price && (
          <span className="seat-selection__footer-price mb-1">
            <Money {...price} />
          </span>
        )}
      </div>
      {enableWarning && (
        <div className="seat-selection__footer-alert row items-center mb-1">
          <span className="pr-1">
            <Icon name="alert" size="small" />
          </span>
          <span>{t('seats.warning.max_seats', { count: limitSeatsCount })}</span>
        </div>
      )}
      <Button color="secondary" disabled={disableButton} onClick={handleClick}>
        <Tooltip
          action={isMobile ? 'click' : 'hover'}
          content={t('seats.warning.selected_pax', { count: outboundSeatsCount || reservedSeatsCount })}
          position={isMobile ? 'top' : 'left'}
          disabled={disabledTooltip}
        >
          {t('seats.continue')}
        </Tooltip>
      </Button>
    </div>
  )
}

export default Footer
