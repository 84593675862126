import React, { ReactElement } from 'react'

import timeImage from '@images/reservations/time.png'
import { useTranslation } from '@lib/i18n'
import ReservationModal from '@pages/Checkout/ReservationsErrorModal'
import Default from '@pages/Checkout/ReservationsErrorModal/Footer/Default'

interface ReservationsExpiredModalProps {
  opened: boolean
  period?: number | null
}

const ReservationsExpiredModal = ({ opened, period }: ReservationsExpiredModalProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <ReservationModal
      isOpened={opened}
      body={t('checkout.reservationModal.reservationExpiredBody', { period })}
      title={t('checkout.reservationModal.reservationExpiredTitle')}
      footer={<Default />}
      iconPath={timeImage}
    />
  )
}

export default ReservationsExpiredModal
