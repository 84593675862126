import React, { ReactElement } from 'react'

import { DiscountCardsResponse } from '@api/discountCards'
import connectionUtils from '@lib/connection'
import Birthdate from '@pages/Checkout/PassengerDetails/PassengerFieldset/Birthdate'
import PassengerDiscounts from '@pages/Checkout/PassengerDetails/PassengerFieldset/Discounts'
import Gender from '@pages/Checkout/PassengerDetails/PassengerFieldset/Gender'
import GovernmentId from '@pages/Checkout/PassengerDetails/PassengerFieldset/GovernmentId'
import Header from '@pages/Checkout/PassengerDetails/PassengerFieldset/Header'
import Name from '@pages/Checkout/PassengerDetails/PassengerFieldset/Name'
import Type from '@pages/Checkout/PassengerDetails/PassengerFieldset/Type'
import { useSettings } from '@queries/settings'
import { Skeleton } from '@ui'

import '@pages/Checkout/PassengerDetails/PassengerFieldset/index.scss'

interface PassengerFieldsetProps {
  cards: DiscountCardsResponse | null
  disableTypes?: boolean
  hideTypes?: boolean
  isCardLoading: boolean
  isTypeLoading?: boolean
  label: string
  name: string
  onRemove?: () => void
  onTypeChange?: (type: string) => void
  outbound: Connection | null
  removeDisabled: boolean
  types: Passenger.Type[]
  validate?: () => void
  showDiscounts?: boolean
}

const PassengerFieldset = (props: PassengerFieldsetProps): ReactElement => {
  const {
    cards,
    disableTypes,
    hideTypes,
    isCardLoading,
    isTypeLoading,
    label,
    name,
    onRemove,
    onTypeChange,
    outbound,
    removeDisabled,
    types,
    validate,
    showDiscounts,
  } = props
  const [settings] = useSettings()
  const { governmentId, birthday, gender, passengerName } = settings
  const isInternational = connectionUtils.isInternational(outbound as Connection)
  const genderVisible = gender.enabled && gender.trips === 'only_international' ? isInternational : gender.enabled
  const governmentIdVisible = governmentId.trips === 'only_international' ? isInternational : governmentId.enabled

  const typeSkeleton = (
    <div className="ui-dropdown__label">
      <Skeleton.Item width="100%" height={32} />
    </div>
  )

  return (
    <div className="column gap-5">
      <div className="cell">
        <Header label={label} onRemove={onRemove} removeDisabled={removeDisabled} />
      </div>
      <div className="passenger-fieldset__section">
        {passengerName.enabled && <Name name={name} />}
        {birthday.enabled && <Birthdate name={name} />}
        {!hideTypes && (
          <Skeleton.List Skeleton={() => typeSkeleton} loading={!!isTypeLoading} amount={1}>
            <Type name={name} types={types} validate={validate} onChange={onTypeChange} disabled={disableTypes} />
          </Skeleton.List>
        )}
        {genderVisible && <Gender name={`${name}.gender`} />}
      </div>
      {governmentIdVisible && (
        <div className="passenger-fieldset__section">
          <GovernmentId name={name} />
        </div>
      )}
      {showDiscounts && cards && cards.length > 0 && (
        <PassengerDiscounts cards={cards} isLoading={isCardLoading || !!isTypeLoading} name={name} />
      )}
    </div>
  )
}

export default PassengerFieldset
