import React from 'react'

import Money from '@components/Money'
import { useTranslation } from '@lib/i18n'
import { useParams } from '@stores/params'

interface DiscountProps {
  amount: number
}

const Discount = ({ amount }: DiscountProps) => {
  const { t } = useTranslation()
  const [{ currency }] = useParams()

  return (
    <div className="row space-between">
      <div>{t('checkout.discount')}</div>
      <div className="bolder" data-tag="discount">
        <Money fractional={-amount} currency={currency} />
      </div>
    </div>
  )
}

export default Discount
