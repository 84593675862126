import helpers from '@lib/analytics/amplitude/helpers'
import date from '@lib/date'
const { sendEvent, getRouteParams, getPriceParams } = helpers

const viewPage = (): void => {
  sendEvent('view-landing-page', { express: true }, { measurePerformance: 'page' })
}

export interface ExpressTripProps {
  departureDate: string
  connection: Landing.Connection
  tripType: Landing.TripType
  price: Money | null
}

const clickTrip = (props: ExpressTripProps): void => {
  const { departureDate, connection, tripType, price } = props
  const eventParams = {
    ...getRouteParams(connection.departureLocation, connection.arrivalLocation),
    ...(price && getPriceParams(price)),
    departureDate: date.formatDate(date.parse(departureDate)),
    isReturn: tripType === 'two_way',
  }

  sendEvent('click-express-trip', eventParams)
}

export default {
  viewPage,
  clickTrip,
}
