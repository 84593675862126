import { useEffect, useState } from 'react'

import { Observable } from '@lib/observable'
import utils from '@lib/utils'

export interface SetFunction<T> {
  (fn: (current: T) => T): void
  (value: Partial<T>): void
}

export class Store<T extends Object> extends Observable<T> {
  __data: T
  __initialData: T

  constructor(data: T) {
    super()
    this.__data = data
    this.__initialData = data
  }

  get = (): T => this.__data

  set: SetFunction<T> = arg => {
    const currentValue = this.__data
    const nextValue = utils.function.isFunction(arg) ? arg(currentValue) : { ...this.__data, ...arg }

    if (utils.object.isEqual(currentValue, nextValue)) return

    this.__data = nextValue
    super.set(this.__data)
  }

  isInitialState = (): boolean => this.__data === this.__initialData

  reset = (excludes?: (keyof T)[]): void => {
    const ignore = excludes?.reduce((acc, curr) => ({ ...acc, [curr]: this.__data[curr] }), {})

    this.__data = { ...this.__initialData, ...ignore }
    this.__listeners.forEach(fn => {
      fn(this.__data)
    })
  }
}

export interface StoreMeta<T> {
  isInitialState: boolean
  reset: (exclude?: (keyof T)[]) => void
  get: () => T
}

export type UseStoreHook<T> = [T, SetFunction<T>, StoreMeta<T>]

export const createStore = <T extends Object>(data: T): Store<T> => new Store(data)

export const useStore = <T extends Object>(store: Store<T>): UseStoreHook<T> => {
  const [state, setState] = useState<T>(store.get())
  const meta = {
    isInitialState: store.isInitialState(),
    get: store.get,
    reset: store.reset,
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => store.subscribe(setState), [])

  return [state, store.set, meta]
}
