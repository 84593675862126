import React from 'react'

import bem from '@lib/bem'
import date from '@lib/date'
import Icon from '@ui/Icon'
import InputLabel from '@ui/Input/Label'

export interface DatePickerInputProps {
  label: string
  withIcon?: boolean
  errorMessage?: string | null
  value: Date | null
  active: boolean
  required?: boolean
}

const DatePickerInput = ({ label, withIcon = true, errorMessage, value, active, required }: DatePickerInputProps) => {
  const inputValue = value && date.format(value, 'E, d MMM')
  const inputWrapperClasses = bem('ui-date-picker', 'input-wrapper', {
    active,
    error: !!errorMessage,
  })

  return (
    <div className={inputWrapperClasses}>
      {withIcon && (
        <div className="ui-date-picker__icon">
          <Icon name="calendar" size="large" />
        </div>
      )}
      <div className="ui-date-picker__value">
        <InputLabel
          className={bem('ui-date-picker', 'label', { focused: inputValue })}
          text={label}
          required={required}
        />
        <span>{inputValue}</span>
      </div>
    </div>
  )
}

export default DatePickerInput
