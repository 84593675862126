import { isSameDay } from 'date-fns'
import React, { ReactElement, useMemo } from 'react'

import RouteBreakdown, { RouteItem } from '@components/RouteBreakdown'
import Station from '@components/Station'
import TripConditions from '@components/TripInfo/TripConditions'
import bem from '@lib/bem'
import connectionUtils from '@lib/connection'
import date from '@lib/date'
import featureUtils from '@lib/fareFeature'
import { useTranslation } from '@lib/i18n'
import CarrierLink from '@pages/Checkout/BookingDetails/CarrierLink'
import DetailsSkeleton from '@pages/Checkout/BookingDetails/Skeleton'
import { Skeleton } from '@ui'

interface StationWithName {
  name: string
}

export type ConnectionBreakdownData = Pick<Connection, 'departureTime' | 'arrivalTime' | 'segments'> & {
  departureStation: StationWithName
  arrivalStation: StationWithName
}

interface ConnectionStationsProps {
  connection: ConnectionBreakdownData
  carriers: MarketingCarrier[]
  isLoading: boolean
  title: string | null
  showTime?: boolean
  showDuration?: boolean
  showCarrier?: boolean
  fareClass?: string
  validity: string | null
  hideLocations?: boolean
}

const ConnectionBreakdown = (props: ConnectionStationsProps): ReactElement => {
  const { t } = useTranslation()
  const { connection, title, showCarrier, showDuration, showTime, carriers, validity, fareClass, isLoading } = props
  const { departureStation, departureTime, arrivalStation, arrivalTime, segments } = connection

  const fareClasses = useMemo(
    () => carriers.flatMap(carrier => carrier.fareClasses.filter(fare => fare.code === fareClass)),
    [carriers, fareClass],
  )

  const checkDisplayFeature = (feature: FareFeature) =>
    featureUtils.isFullyRefundable(feature) || featureUtils.isAmendable(feature)

  const showFeatures = fareClasses.every(fare => fare.fareFeatures.some(checkDisplayFeature))

  const filteredFareFeatures = showFeatures
    ? fareClasses.flatMap(item => item.fareFeatures.filter(checkDisplayFeature))
    : []

  const departureDate = date.parse(departureTime, 'UTC')
  const arrivalDate = date.parse(arrivalTime, 'UTC')

  const route: RouteItem[] = []
  const stationProps = { type: 'station' as const, vehicle: connection.segments[0]?.vehicle?.vehicleType?.code }
  const filteredSegments = segments.filter(segment => !connectionUtils.isSelfTransfer(segment))

  route.push({ ...stationProps, time: showTime ? departureDate : null, station: departureStation.name })
  showDuration && route.push({ type: 'duration', start: departureDate, end: arrivalDate })
  filteredSegments.length > 1 && route.push({ type: 'transfers', count: filteredSegments.length - 1 })
  route.push({ ...stationProps, time: showTime ? arrivalDate : null, station: arrivalStation.name })

  const isSingleDayTrip = isSameDay(departureDate, arrivalDate)

  return (
    <div className={bem('checkout-trip', 'connection')}>
      {title && <h4 className="mb-2">{title}</h4>}
      <div className={bem('checkout-trip', 'dates')}>
        <Station.Date date={date.parse(departureTime, 'UTC')} />
        {!isSingleDayTrip && (
          <>
            {' - '}
            <Station.Date date={date.parse(arrivalTime, 'UTC')} />
          </>
        )}
      </div>
      {!props.hideLocations && <RouteBreakdown route={route} />}
      {showCarrier && (
        <Skeleton.List Skeleton={() => <DetailsSkeleton className="pb-3" />} loading={isLoading} amount={1}>
          <div className={bem('checkout-trip', 'operated-by')}>
            <span>{t('checkout.bookingDetails.operatedBy')}</span>
            {carriers?.map(carrier => <CarrierLink key={carrier.id} carrier={carrier} />)}
          </div>
        </Skeleton.List>
      )}
      <Skeleton.List Skeleton={DetailsSkeleton} loading={isLoading} amount={1}>
        <div className={bem('checkout-trip', 'conditions')}>
          <TripConditions fareFeatures={filteredFareFeatures} validity={validity} description={false} />
        </div>
      </Skeleton.List>
    </div>
  )
}

export default ConnectionBreakdown
