import React, { ReactElement } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import { Card, Skeleton } from '@ui'

import '@components/JourneyCard/Skeleton/index.scss'

const SkeletonCard = (): ReactElement => {
  const isMobile = useIsMobile()

  return (
    <div className="journey-list__skeleton-card">
      {new Array(2).fill(true).map((_, index) => (
        <Card
          key={index}
          header={
            <div className="row space-between mb-2">
              <div className="cell-2">
                <Skeleton.Item height={32} />
              </div>
              <div className="cell-1">
                <Skeleton.Item height={32} />
              </div>
            </div>
          }
          body={
            <div>
              <div className="cell-8">
                <Skeleton.Item height={52} />
              </div>
            </div>
          }
          footer={
            <div className="row space-between items-center w-100">
              <div className="cell-3">
                <Skeleton.Item height={24} />
              </div>
              {!isMobile && (
                <div className="cell-1">
                  <Skeleton.Item height={24} />
                </div>
              )}
              <div className="cell-4 cell-lg-2">
                <Skeleton.Item height={72} />
              </div>
            </div>
          }
        />
      ))}
    </div>
  )
}

export default SkeletonCard
