import * as qs from 'qs'
import { IStringifyOptions } from 'qs'

export type ArrayFormat = 'indices' | 'brackets' | 'repeat' | 'comma'

const stringify = (params: Object, options?: IStringifyOptions): string => {
  return qs.stringify(params, {
    encodeValuesOnly: true,
    skipNulls: true,
    ...options,
    arrayFormat: options?.arrayFormat ?? 'indices',
  })
}

const parse = (params: string | Record<string, string>): Record<string, any> => qs.parse(params)

export default {
  stringify,
  parse,
}
