import React from 'react'

import { FilterConfig, FilterFunction } from '@lib/connections/filters/types'

const filter: FilterFunction = (connection, value) => {
  const types = connection.segments.flatMap(s => s.vehicle?.vehicleType?.code.toLowerCase())

  return value.some(code => types.includes(code as Lowercase<Vehicle.Code>))
}

const transportModeFilter: FilterConfig = {
  filter,
  Component: /* istanbul ignore next */ () => <></>,
}

export default transportModeFilter
