import 'country-flag-icons/3x2/flags.css'
import { FieldProps } from 'formik'
import {
  AsYouType,
  CountryCode,
  formatIncompletePhoneNumber,
  getCountries,
  getCountryCallingCode,
  isSupportedCountry,
} from 'libphonenumber-js/min'
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import PhoneFieldMenu from '@components/PhoneField/Menu'
import useClickOutside from '@hooks/useClickOutside'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { useParams } from '@stores/params'
import { Icon, Input } from '@ui'
import { InputProps } from '@ui/Input'
import InputLabel from '@ui/Input/Label'

import '@components/PhoneField/index.scss'

interface ValidateCountry {
  validateCountry?: (code: CountryCode, value?: string) => void
}

type InputFieldProps = FieldProps<string | null> & InputProps & ValidateCountry
const countryName = new Intl.DisplayNames(['en'], { type: 'region' })

const PhoneField = (props: InputFieldProps): ReactElement => {
  const { field, form, label, validateCountry, required, ...rest } = props
  const { setFieldValue, getFieldMeta } = form
  const { name, value } = field
  const [{ locale }] = useParams()
  const { touched, error } = getFieldMeta(name)
  const [menuOpened, setMenuOpened] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const countries = useMemo(
    () => getCountries().sort((a, b) => String(countryName.of(a)).localeCompare(String(countryName.of(b)))),
    [],
  )
  const [inputValue, setInputValue] = useState('')
  const { t } = useTranslation()

  useClickOutside(dropdownRef, () => {
    setMenuOpened(false)
  })

  const getInitialCountry = (): CountryCode => {
    const countryFromLocale = locale.slice(-2).toLocaleUpperCase() as CountryCode

    return isSupportedCountry(countryFromLocale) ? countryFromLocale : 'US'
  }
  const [selectedCountry, setSelectedCountry] = useState(() => getInitialCountry())
  const callingCode = useMemo(() => getCountryCallingCode(selectedCountry), [selectedCountry])
  const onChange = (value: string): void => {
    const digits = value.match(/\+|\d/g)?.join('') ?? /* istanbul ignore next */ ''
    const phone = !digits || value.startsWith('+') ? digits : `+${callingCode}${digits}`
    setInputValue(value)
    setFieldValue(name, phone)
    validateCountry?.(selectedCountry, value)
  }

  const detectCountry = useCallback(
    (input: string) => {
      const phoneNumber = new AsYouType(selectedCountry)
      phoneNumber.input(input)

      return phoneNumber.getCountry()
    },
    [selectedCountry],
  )

  useEffect(() => {
    if (!inputValue.startsWith('+')) return

    const country = detectCountry(inputValue)
    if (country && country !== selectedCountry) {
      setSelectedCountry(country)
    }
  }, [detectCountry, inputValue, selectedCountry, value])

  const formatWithoutCode = useCallback(
    (value?: string | null) => {
      const number = value != null && value.length > 0 ? formatIncompletePhoneNumber(value) : ''

      return number.replace(new RegExp(`\\+${callingCode}\\s?`), '')
    },
    [callingCode],
  )

  const formattedValue = useMemo(
    () => (inputValue.startsWith('+') ? formatIncompletePhoneNumber(inputValue) : formatWithoutCode(value)),
    [formatWithoutCode, inputValue, value],
  )

  const onCountrySelect = (country: CountryCode): void => {
    setFieldValue(name, '')
    setInputValue('')
    setSelectedCountry(country)
    setMenuOpened(false)
    validateCountry?.(country)
  }

  return (
    <div className="phone">
      <div ref={dropdownRef} className={bem('phone', 'dropdown')}>
        <div
          onClick={() => {
            setMenuOpened(!menuOpened)
          }}
        >
          {label && <InputLabel className={bem('phone', 'label')} text={label} required={required} />}
          <div
            className="row gap-1 items-center"
            onClick={() => {
              setMenuOpened(true)
            }}
          >
            <div>
              <span className={`flag:${selectedCountry}`} />
            </div>
            <span>{`+${callingCode}`}</span>
            <Icon name="chevron-down" size="small" />
          </div>
        </div>
      </div>
      {menuOpened && <PhoneFieldMenu countries={countries} onSelect={onCountrySelect} />}
      <Input
        {...rest}
        {...field}
        value={formattedValue}
        onChange={onChange}
        errorMessage={touched ? error : null}
        placeholder={t('checkout.contactDetails.phoneNumber')}
      />
    </div>
  )
}

export default PhoneField
