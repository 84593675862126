import { FormikProps } from 'formik'
import { RefObject, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { CreateResponse as ReservationCreateResponse, Reservation } from '@api/reservation'
import { ApiError } from '@lib/api'
import reservationUtils from '@lib/reservation'
import { useReservationCreate } from '@loaders/createReservation'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useCheckout } from '@stores/checkout'
import { useParams } from '@stores/params'
import { TimerConfig } from 'src/pages/Checkout/Timer'

interface ExpiredErrorData {
  period?: number
}

export interface HookResult {
  initializeReservation: () => void
  createError: ApiError | null
  expiredError: ExpiredErrorData | null
  timer?: TimerConfig | null
}

export const useReservationSession = (form: RefObject<FormikProps<CheckoutFormData>>): HookResult => {
  const [startTimer, setStartTimer] = useState<boolean>(false)
  const [expiredError, setExpiredError] = useState<ExpiredErrorData | null>(null)
  const [expiryTimestamp, setExpiryTimestamp] = useState<Date | null>(null)
  const [params] = useParams()
  const [{ inbound, outbound }] = useCheckout()
  const { pathname, search } = useLocation()

  const setReservationData = (data: Reservation): void => {
    form.current?.setFieldValue('reservationData', data)
    form.current?.setFieldValue('price', data.price)
    form.current?.setFieldValue('fees', data.fees)
    form.current?.setFieldValue('priceError', null)
    setStartTimer(true)
    setExpiryTimestamp(data.expiresAt)
  }

  const reservationCreate = useReservationCreate({
    onSuccess: (data: ReservationCreateResponse) => {
      const adjusted = reservationUtils.adjustTimeZones(data)
      setReservationData(adjusted)
      reservationUtils.setSessionStorage(adjusted, params)
      reservationUtils.setCheckoutPath(String(data.id), `${pathname}${search}`)
    },
    onError: error => {
      form.current?.setFieldValue('priceError', error)
    },
  })

  useEffect(() => {
    form.current?.setFieldValue('isReservationLoading', reservationCreate.isLoading)
  }, [form, reservationCreate.isLoading])
  const createReservation = (): void => {
    if (!outbound) return

    const data = form.current?.values as CheckoutFormData
    const createParams = reservationUtils.buildCreateParams({ data, params, inbound, outbound })
    reservationCreate.mutate(createParams)
  }

  const initializeReservation = (): void => {
    const reservation = reservationUtils.getSessionStorage(params)
    const reservationValid = reservation != null && new Date() < reservation.expiresAt

    if (reservationValid) {
      setReservationData(reservation)
    } else {
      reservationUtils.resetSessionStorage(params)
      createReservation()
    }
  }

  const onExpire = (): void => {
    setExpiredError({ period: form.current?.values.reservationData?.period })
  }

  return {
    initializeReservation,
    createError: reservationCreate.error,
    expiredError,
    timer: startTimer && expiryTimestamp != null ? { onExpire, expiryTimestamp } : null,
  }
}
