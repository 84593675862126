import React, { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'

import utils from '@lib/utils'
import { DropdownItem } from '@ui/Dropdown'

import '@ui/Dropdown/List/SearchableList.scss'

interface SearchableListProps<T> {
  value: T | null
  items: DropdownItem<T>[]
  renderItem: (item: DropdownItem<T>) => ReactElement
}

const ITEM_HEIGHT = 48

const SearchableList = <T extends string>({ items, renderItem, value }: SearchableListProps<T>): ReactElement => {
  const listRef = useRef<FixedSizeList>()
  const searchQuery = useRef<string | null>(null)

  const clearSearchDebounced = useMemo(
    () =>
      utils.function.debounce(() => {
        searchQuery.current = null
      }, 500),
    [],
  )

  const appendSearchValue = useCallback(
    (text: string): void => {
      searchQuery.current = (searchQuery.current ?? '') + text
      clearSearchDebounced()
    },
    [clearSearchDebounced],
  )

  const findItemIndex = useCallback(
    (searchQuery: string): number => {
      const regex = new RegExp(`^${searchQuery}`, 'i')

      return items.findIndex(({ searchValue, value }) => regex.test(searchValue ?? /* istanbul ignore next */ value))
    },
    [items],
  )

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      appendSearchValue(e.key)
      const index = findItemIndex(searchQuery.current as string)

      /* istanbul ignore else */
      if (index >= 0) {
        listRef.current?.scrollToItem(index, 'start')
      }
    },
    [appendSearchValue, findItemIndex],
  )

  useEffect(() => {
    document.addEventListener('keypress', handleKeyPress)

    return () => {
      document.removeEventListener('keypress', handleKeyPress)
    }
  }, [handleKeyPress])

  const onListMount = useCallback(
    (ref: FixedSizeList): void => {
      listRef.current = ref

      const index = items.findIndex(item => item.value === value)
      listRef.current?.scrollToItem(index, 'center')
    },
    [items, value],
  )

  return (
    <div className="ui-searchable-list">
      <AutoSizer>
        {({ width, height }) => (
          <FixedSizeList
            ref={onListMount}
            itemSize={ITEM_HEIGHT}
            itemCount={items.length}
            height={height}
            width={width}
          >
            {({ index, style }) => <div style={style}>{renderItem(items[index])}</div>}
          </FixedSizeList>
        )}
      </AutoSizer>
    </div>
  )
}

export default SearchableList
