import cn from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'

import bem from '@lib/bem'
import utils from '@lib/utils'
import { Button, Icon } from '@ui'
import Item from '@ui/MediaCarousel/Item'

import '@ui/MediaCarousel/Desktop/index.scss'

interface mediaCarouselProps {
  media: string[]
  className?: string
  onMediaChange?: (index: number) => void
}

const MediaCarouselDesktop = ({ media, className, onMediaChange }: mediaCarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [animationDirection, setAnimationDirection] = useState<DirectionalAction | null>(null)
  const changeIndex = (index: number) => {
    setCurrentIndex(index)
    onMediaChange?.(index)
  }

  const goForward = () => {
    setAnimationDirection('forward')
  }

  const goBack = () => {
    setAnimationDirection('back')
  }

  const focusedMediaIndex = useMemo(() => {
    return animationDirection != null ? utils.array.moveIndex(media, currentIndex, animationDirection) : null
  }, [animationDirection, media, currentIndex])

  const handleAnimationEnd = () => {
    changeIndex(focusedMediaIndex ?? /* istanbul ignore next: not possible */ 0)
    setAnimationDirection(null)
  }

  const collection = useCallback(
    (className?: string) => media.map(url => <Item key={url} url={url} className={className} />),
    [media],
  )

  return (
    <div className={cn('column', className, bem('media-carousel', { desktop: true }))}>
      <div className={bem('media-carousel', 'media')}>
        <div
          className={bem('media-carousel', 'media-container', {
            [animationDirection as string]: animationDirection != null,
          })}
          onAnimationEnd={handleAnimationEnd}
          onTransitionEnd={handleAnimationEnd}
        >
          {collection()[currentIndex]}
          {focusedMediaIndex != null && collection(bem('media-carousel', 'next-media'))[focusedMediaIndex]}
        </div>
        <div className={bem('media-carousel', 'navigate-arrows', { animating: animationDirection != null })}>
          <Button variant="outline" round onClick={() => goBack()}>
            <Icon name="chevron-left" size="medium" />
          </Button>
          <Button variant="outline" round onClick={() => goForward()}>
            <Icon name="chevron-right" size="medium" />
          </Button>
        </div>
      </div>
      <div className={bem('media-carousel', 'navigate-dots')}>
        {media.map((url, index) => (
          <div
            key={url}
            className={bem('media-carousel', 'navigate-dot', { active: index === (focusedMediaIndex ?? currentIndex) })}
          />
        ))}
      </div>
    </div>
  )
}

export default MediaCarouselDesktop
