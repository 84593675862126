import cn from 'classnames'
import React from 'react'
import { createPortal } from 'react-dom'

import bem from '@lib/bem'
import { useSettings } from '@queries/settings'

import '@components/Blocks/Background/index.scss'

const BackgroundBlock = (props: PageBlocks.BackgroundBlock['data']) => {
  const [{ backgroundImage }] = useSettings()
  const { url = backgroundImage.url, headerOffset = backgroundImage.headerOffset } = props

  return (
    <>
      <div className="background-offset" />
      {createPortal(
        <div
          style={{ backgroundImage: `url(${url})` }}
          className={cn('background-block', bem('background'), { 'header-offset': headerOffset })}
        />,
        document.body,
      )}
    </>
  )
}

export default BackgroundBlock
