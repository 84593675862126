import cn from 'classnames'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'

import useValidators from '@hooks/useValidators'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useParams } from '@stores/params'
import { Button, Icon, Input } from '@ui'

import '@pages/Checkout/BookingDetails/Voucher/index.scss'

enum VoucherStatus {
  Closed,
  Edit,
  Applied,
}

const Voucher = () => {
  const { t } = useTranslation()
  const [{ mode }] = useParams()
  const [status, setStatus] = useState<VoucherStatus>(() =>
    mode === 'embed' ? VoucherStatus.Edit : VoucherStatus.Closed,
  )
  const [voucherCode, setVoucherCode] = useState<string>('')
  const [rejectedVoucher, setRejectedVoucher] = useState<string>('')
  const [error, setError] = useState<string | undefined>(undefined)
  const {
    setFieldValue,
    values: { meta, isVacancyLoading, isReservationLoading, vacancy },
  } = useFormikContext<CheckoutFormData>()
  const isValidating = isVacancyLoading || isReservationLoading
  const { required } = useValidators()
  const changeVoucherCode = (value: string) => {
    setVoucherCode(value)
    setError(undefined)
  }
  const removeFromForm = (code: string) => {
    const cards = meta.cards.filter(card => card.name !== code)
    setFieldValue('meta.cards', cards)
    return cards
  }

  useEffect(() => {
    if (isValidating || !voucherCode) return

    if (vacancy?.appliedCards.find(card => card.name === voucherCode)) {
      setStatus(VoucherStatus.Applied)
    } else {
      setError(t('checkout.voucher.invalid'))
      setRejectedVoucher(voucherCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidating, vacancy?.appliedCards])

  const toggleVoucher = () => {
    /* istanbul ignore next */
    if (isValidating) return
    setStatus(status => (status === VoucherStatus.Closed ? VoucherStatus.Edit : VoucherStatus.Closed))
    changeVoucherCode('')
  }

  const applyVoucher = () => {
    if (!voucherCode) {
      setError(required(voucherCode))
      return
    }
    const cards = removeFromForm(rejectedVoucher)
    setFieldValue('meta.cards', [...cards, { name: voucherCode }])
    setFieldValue('vacancies', [])
  }

  const removeVoucher = () => {
    setStatus(VoucherStatus.Edit)
    removeFromForm(voucherCode)
    changeVoucherCode('')
    setFieldValue('vacancies', [])
  }

  if (status === VoucherStatus.Applied) {
    return (
      <div className="row space-between items-center voucher">
        <div className={cn(bem('voucher', 'applied'), 'body-12')}>
          {t('checkout.voucher.appliedNumber', { number: voucherCode })}
        </div>
        <Button variant="text" onClick={removeVoucher}>
          <div className="row items-center">
            <Icon size="medium" name="minus" />
            <span>{t('checkout.voucher.remove')}</span>
          </div>
        </Button>
      </div>
    )
  }

  return (
    <div className={cn('column', 'voucher')}>
      <div className={cn(bem('voucher', 'empty'), 'body-12')} onClick={toggleVoucher}>
        {t('checkout.voucher.empty')}
      </div>
      {status === VoucherStatus.Edit && (
        <div className={cn('row', 'pt-2')}>
          <div className="cell mr-3">
            <Input
              errorMessage={error}
              label={t('checkout.voucher.numberLabel')}
              value={voucherCode}
              onChange={changeVoucherCode}
              disabled={isValidating}
            />
          </div>
          <div className={cn('cell no-grow', bem('voucher', 'apply'))}>
            <Button onClick={applyVoucher} variant="text" loading={isValidating}>
              {t('checkout.voucher.apply')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Voucher
