import { getIn, useFormikContext } from 'formik'
import React, { Fragment, ReactElement, useMemo } from 'react'

import { IdFields } from '@enums'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import Citizenship from '@pages/Checkout/PassengerDetails/PassengerFieldset/Citizenship'
import DocumentType from '@pages/Checkout/PassengerDetails/PassengerFieldset/DocumentType'
import ExpirationDate from '@pages/Checkout/PassengerDetails/PassengerFieldset/ExpirationDate'
import IssuingLocation from '@pages/Checkout/PassengerDetails/PassengerFieldset/IssuingLocation'
import { useSettings } from '@queries/settings'

interface GovernmentIdFieldsProps {
  name: string
}

const GovernmentId = ({ name }: GovernmentIdFieldsProps): ReactElement => {
  const [{ governmentId, cpf }] = useSettings()
  const { values } = useFormikContext<CheckoutFormData>()
  const governmentIdType = getIn(values, `${name}.governmentIdType`) as GovernmentIdType

  const documentFields: Record<GovernmentIdFields, React.ReactNode> = useMemo(
    () => ({
      [IdFields.Type]: <DocumentType name={name} />,
      [IdFields.Citizenship]: <Citizenship name={name} />,
      [IdFields.Location]: <IssuingLocation name={name} />,
      [IdFields.ExpirationDate]: <ExpirationDate name={name} />,
    }),
    [name],
  )

  const fields = useMemo(() => {
    const isPassportId = governmentIdType === 'passport_id'

    if (cpf.enabled && isPassportId && !governmentId.fields.includes(IdFields.Citizenship)) {
      return [...governmentId.fields, IdFields.Citizenship]
    }

    if (cpf.enabled && !isPassportId && governmentId.fields.includes(IdFields.Citizenship)) {
      return governmentId.fields.filter(field => field !== IdFields.Citizenship)
    }

    return governmentId.fields
  }, [cpf.enabled, governmentIdType, governmentId.fields])

  return (
    <>
      {fields.map(key => (
        <Fragment key={key}>{documentFields[key]}</Fragment>
      ))}
    </>
  )
}

export default GovernmentId
