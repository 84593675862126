import { APIProvider } from '@vis.gl/react-google-maps'
import React, { ReactElement, Suspense, lazy } from 'react'
import { RouterProvider, useRouteError } from 'react-router-dom'

import ErrorPage from '@components/ErrorPage'
import config from '@config'
import { URLAppContextProvider } from '@context/app'
import amplitude from '@lib/analytics/amplitude'
import { QueryClientProvider } from '@lib/queryClient'
import Sentry from '@lib/sentry'
import CheckoutPage from '@pages/Checkout/App'
import RestrictedRouter from '@pages/Index/RestrictedRouter'
import NotFoundPage from '@pages/NotFound'
import SearchPage from '@pages/Search/App'
import SearchResultPage from '@pages/SearchResult/App'
import PopupContainerContext from '@ui/Tooltip/ContainerContext'

const BookingPage = lazy(() => import('@pages/Booking/App'))
const ReservationAgentPayment = lazy(() => import('@pages/ReservationAgentPayment/App'))
const ReservationFailedPayment = lazy(() => import('@pages/ReservationFailedPayment/App'))
const ReservationPayment = lazy(() => import('@pages/ReservationPayment/App'))
const ConfirmationPage = lazy(() => import('@pages/Confirmation/App'))
const DestinationsPage = lazy(() => import('@pages/Destinations/App'))
const FramePage = lazy(() => import('@pages/Frame/App'))
const FrameReservationPayment = lazy(() => import('@pages/Frame/ReservationPayment'))
const FrameSearchResults = lazy(() => import('@pages/Frame/SearchResult'))
const TrackTrip = lazy(() => import('@pages/TrackTrip/App'))
const TrackTripSearch = lazy(() => import('@pages/TrackTrip/Search'))
const ServicePage = lazy(() => import('@pages/Service/App'))
const StatusPage = lazy(() => import('@pages/Status/App'))
const TemplatePage = lazy(() => import('@pages/Template/App'))
const LandingPage = lazy(() => import('@pages/Landing/App'))

const RouteErrorBoundary = (): ReactElement => {
  const error = useRouteError()

  return <ErrorPage error={error as Error} code="internalError" />
}

const Root = () => {
  return (
    <URLAppContextProvider>
      <RestrictedRouter />
    </URLAppContextProvider>
  )
}

const router = Sentry.createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <RouteErrorBoundary />,
    children: [
      { index: true, element: <SearchPage /> },
      { path: '/checkout', element: <CheckoutPage /> },
      {
        path: '/frame',
        element: <FramePage />,
        children: [
          { path: 'result', element: <FrameSearchResults /> },
          { path: 'reservation/pay/:id', element: <FrameReservationPayment /> },
        ],
      },
      { path: '/result', element: <SearchResultPage /> },
      { path: '/landing', element: <LandingPage /> },
      { path: '/booking/*', element: <BookingPage /> },
      { path: '/bookings/confirmation/:bookingId', element: <ConfirmationPage /> },
      { path: '/reservation/pay/:id', element: <ReservationPayment /> },
      { path: '/agent/:id/reservation/pay', element: <ReservationAgentPayment /> },
      { path: '/reservation/pay/:id/failure', element: <ReservationFailedPayment /> },
      { path: '/status/:bookingFormId', element: <StatusPage /> },
      { path: '/destinations', element: <DestinationsPage /> },
      { path: '/service', element: <ServicePage /> },
      { path: '/d/*', element: <TemplatePage /> },
      { path: '/trackTrip/:id', element: <TrackTrip /> },
      { path: '/trackTrip', element: <TrackTripSearch /> },
      { path: '*', element: <NotFoundPage /> },
    ],
  },
])

router.subscribe(() => {
  amplitude.onInternalRedirect()
})

Sentry.init({ global: true })

const App = (): ReactElement => (
  <Suspense>
    <QueryClientProvider>
      <APIProvider apiKey={config.googleMapsPublicKey}>
        <PopupContainerContext.Provider value={{ current: document.body }}>
          <RouterProvider router={router} />
        </PopupContainerContext.Provider>
      </APIProvider>
    </QueryClientProvider>
  </Suspense>
)

export default App
