import { Field, useFormikContext } from 'formik'
import React, { ReactElement, useMemo } from 'react'

import { useTranslation } from '@lib/i18n'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { DropdownItem } from '@ui/Dropdown'
import DropdownField from '@ui/Dropdown/Field'

interface PassengerTypeProps {
  name: string
  types: Passenger.Type[]
  disabled?: boolean
  validate?: () => void
  onChange?: (type: string) => void
}

const Type = ({ name, types, disabled, validate, onChange }: PassengerTypeProps): ReactElement => {
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext<CheckoutFormData>()
  const handleChange = (type: string): void => {
    setFieldValue(`${name}.cards`, [])
    onChange?.(type)
  }

  const errorMessage = validate?.()
  const options = useMemo(
    () =>
      types.map<DropdownItem<string>>(({ id, name, minAge, maxAge }) => ({
        value: id,
        label: t('checkout.passengerDetails.passengerTypeLabel', { name, minAge, maxAge }),
      })),
    [types, t],
  )

  return (
    <Field
      component={DropdownField}
      label={t('checkout.passengerDetails.passengerType')}
      name={`${name}.type`}
      items={options}
      disabled={disabled}
      validate={validate}
      errorMessage={errorMessage}
      onChange={handleChange}
    />
  )
}

export default Type
