import React, { ReactElement } from 'react'

import Connection from '@components/Connection'
import DetailedInfo from '@components/JourneyCard/Details/DetailedInfo'
import Fares from '@components/JourneyCard/Details/Fares'
import Money from '@components/Money'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { UpdatedMediaData } from '@queries/media'
import { Button } from '@ui'
import Modal from '@ui/Modal'

import '@components/JourneyCard/Details/index.scss'

interface DetailsProps {
  connection: Connection
  opened: boolean
  onToggleDetails: () => void
  onClick: (connection: Connection, fareClassCode: string) => void
  showFares: boolean
  selectedFareClass: string | null
  displayedFareClass: string
  onFareClick: (fareClass: string) => void
  bestFare?: Fare | null
  isAmendment: boolean
  isInbound: boolean
  disableFareFilter: boolean
  media?: UpdatedMediaData[]
}

const Details = (props: DetailsProps): ReactElement => {
  const {
    connection,
    opened,
    onToggleDetails,
    onClick,
    showFares,
    selectedFareClass,
    bestFare,
    displayedFareClass,
    onFareClick,
    isAmendment,
    isInbound,
    disableFareFilter,
    media,
  } = props
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const handleProcessClick = (connection: Connection, fareClassCode: string): void => {
    onClick(connection, fareClassCode)
    onToggleDetails()
  }

  const clickBestFare = (): void => {
    const code = bestFare?.fareClass.code ?? /* istanbul ignore next */ connection.cheapestFareClassCode
    handleProcessClick(connection, code)
  }

  return (
    <>
      {!isMobile && opened && (
        <DetailedInfo connection={connection} displayedFareClass={displayedFareClass} media={media} />
      )}
      {isMobile && (
        <Modal
          opened={opened}
          onClose={onToggleDetails}
          className={bem('journey-card', 'modal', { media })}
          title={<Connection.DepartureDate connection={connection} />}
          header={
            <span className={bem('journey-card', 'modal-duration')}>
              {t('card.duration') + ': '}
              <Connection.Duration
                departureTime={connection.departureTime}
                arrivalTime={connection.arrivalTime}
                departureTimezone={connection.departureStation.timeZone}
                arrivalTimezone={connection.arrivalStation.timeZone}
              />
            </span>
          }
          footer={
            <div className={bem('journey-card', 'modal-button', { fares: showFares })}>
              {!showFares && (
                <Button onClick={clickBestFare} disabled={connection.bookedOut}>
                  <div className="row space-between">
                    <span className={bem('journey-card', 'modal-button-title')}>{t('card.trip')}</span>
                    <span className={bem('journey-card', 'modal-button-price')}>
                      <Money {...(bestFare?.price ?? connection.cheapestTotalAdultPrice)} />
                    </span>
                  </div>
                </Button>
              )}
              {showFares && (
                <Fares
                  onClick={handleProcessClick}
                  connection={connection}
                  selectedFareClass={selectedFareClass}
                  displayedFareClass={displayedFareClass}
                  onFareClick={onFareClick}
                  isAmendment={isAmendment}
                  isInbound={isInbound}
                  disableFareFilter={disableFareFilter}
                />
              )}
            </div>
          }
        >
          <DetailedInfo connection={connection} displayedFareClass={displayedFareClass} media={media} />
        </Modal>
      )}
    </>
  )
}

export default Details
