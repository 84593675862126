import React, { useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import bem from '@lib/bem'
import ScrollContext from '@pages/SearchResult/ScrollContext/scrollContext'
import { Button, Icon } from '@ui'

interface ScrollButtonProps {
  listRef: React.RefObject<HTMLDivElement>
  onClick: () => void
}

const ScrollButton = ({ listRef, onClick }: ScrollButtonProps) => {
  const [scrollButtonOffset, setScrollButtonOffset] = useState(0)
  const [scrollButtonVisible, setScrollButtonVisible] = useState<boolean>(false)
  const { scrollObservable } = useContext(ScrollContext)

  useEffect(() => {
    scrollObservable?.subscribe(event => {
      setScrollButtonVisible(event.currentTarget.scrollTop > window.innerHeight)
    })
  }, [scrollObservable])

  useEffect(() => {
    // istanbul ignore else
    if (listRef.current) {
      const { right } = listRef.current.getBoundingClientRect()
      setScrollButtonOffset(window.innerWidth > 1200 ? window.innerWidth - right - 70 : 10)
    }
  }, [listRef])

  return createPortal(
    <div
      className={bem('journey-list', 'scroll-top-button', { visible: scrollButtonVisible })}
      style={{ right: scrollButtonOffset }}
    >
      <Button round color="white" onClick={onClick}>
        <Icon name="chevron-up" size="large" />
      </Button>
    </div>,
    document.body,
  )
}

export default ScrollButton
