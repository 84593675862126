import React, { ReactElement } from 'react'

import Logo from '@components/Logo'
import { useTranslation } from '@lib/i18n'
import { useSettings } from '@queries/settings'
import { Icon } from '@ui'

import '@components/Footer/Brand/index.scss'

const Brand = (): ReactElement => {
  const { t } = useTranslation()
  const [
    {
      footer: { socials },
    },
  ] = useSettings()

  return (
    <div className="column gap-2 page-footer__brand">
      <Logo />
      <div className="body-14">{t('footer.logoDescription')}</div>
      {socials.length > 0 && (
        <div className="cell">
          <div className="row gap-2">
            {socials.map(({ url, type }) => (
              <div className="cell no-grow" key={type}>
                <a href={url} target="_blank" rel="noopener noreferrer" className="page-footer__brand-social">
                  <Icon name={type} size="large" />
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Brand
