import React, { ReactElement, useEffect } from 'react'

import { CheapestPricesResponse } from '@api/connections/cheapestPrices'
import utils from '@lib/utils'
import { useCheapestPricesLoader, CheapestPricesParams } from '@loaders/connections/cheapestPrices'

interface CheapestPricesLoaderParams {
  params: CheapestPricesParams
  onLoading?: (loading: boolean, params: CheapestPricesParams) => void
  onSuccess: (data: CheapestPricesResponse) => void
}

const CheapestPricesLoader = ({ params, onLoading, onSuccess }: CheapestPricesLoaderParams): ReactElement | null => {
  const { isLoading } = useCheapestPricesLoader(params, {
    onSuccess: data => {
      onSuccess(data)
    },
  })

  useEffect(() => {
    onLoading?.(isLoading, params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, onLoading, utils.common.hash(params)])

  return <></>
}

export default CheapestPricesLoader
