import React, { ReactElement } from 'react'

import { useTranslation } from '@lib/i18n'
import { RadioGroup } from '@ui'

interface IsReturnTripProps {
  value: boolean
  layout: Direction
  oneWayLabel?: string
  twoWayLabel?: string
  onChange: (isReturnTrip: boolean) => void
}

const IsReturnTrip = ({ value, oneWayLabel, twoWayLabel, onChange, layout }: IsReturnTripProps): ReactElement => {
  const { t } = useTranslation()

  const types = [
    { label: oneWayLabel ?? t('components.isReturnTrip.oneWay'), value: false, centered: true },
    { label: twoWayLabel ?? t('components.isReturnTrip.return'), value: true, centered: true },
  ]

  return <RadioGroup direction={layout} value={value} onChange={onChange} options={types} />
}

export default IsReturnTrip
