import { SearchFormData } from '@components/SearchForm'
import config from '@config'
import url from '@lib/url'

const DEFAULT_PASSENGERS = 1

const builders = {
  expedia: (base: string, form: SearchFormData): string => {
    const { arrivalLocation, departureDate, returnDate } = form

    return url.build([base, 'Hotel-Search'], {
      destination: arrivalLocation?.name,
      adults: DEFAULT_PASSENGERS,
      startDate: departureDate,
      endDate: returnDate ?? departureDate,
    })
  },
}

const buildURL = (partner: Partners, locale: Locale, form: SearchFormData): string => {
  const base = config.accommodation[partner][locale] ?? config.accommodation[partner].default

  return builders[partner](base, form)
}

export default {
  buildURL,
}
