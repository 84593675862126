import React, { ReactElement, ReactNode } from 'react'

import LineSkeleton from '@components/Initializer/Skeletons/common/Line'
import bem from '@lib/bem'
import { Skeleton } from '@ui'

const LandingSkeleton = (): ReactElement => {
  const card = (key: number): ReactNode => (
    <div key={key} className="column cell cell-lg-6">
      <div className="page-skeleton__section">
        <div className="cell-12">
          <Skeleton.Item height={60} />
        </div>
        <div className="p-5 cell-12">
          <Skeleton.Item height={150} />
        </div>
      </div>
      <div className={bem('landing-card', 'tear')}>
        <div className={bem('landing-card', 'tear-divider')} />
      </div>
      <div className="page-skeleton__section p-5">
        <div className="cell-12">
          <Skeleton.Item height={60} />
        </div>
      </div>
    </div>
  )

  return (
    <>
      <LineSkeleton height={495} />
      <div className="column page-skeleton__page page-skeleton__landing">
        <div className="column row-lg gap-6 mb-9">{new Array(2).fill(card).map((card, index) => card(index))}</div>
        <div className="column row-lg space-between">
          <div className="cell-lg-6 cell-12 pr-lg-4 pb-3">
            <Skeleton.Item height={75} />
          </div>
          <div className="cell-lg-6 cell-12 pl-lg-4 pb-3">
            <div className="mb-3">
              <Skeleton.Item height={24} />
            </div>
            <div>
              <Skeleton.Item height={24} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LandingSkeleton
