import React, { Fragment, ReactElement, useMemo } from 'react'

import Counter from '@components/Counter'
import Age from '@components/PassengersList/Age'
import config from '@config'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import passengersUtils from '@lib/passengers'
import utils from '@lib/utils'
import { useSettings } from '@queries/settings'
import { Divider } from '@ui'

import '@components/PassengersList/index.scss'

interface PassengersListProps {
  value: Passenger.Param[]
  availableTypes?: Passenger.Type[] | null
  onChange: (value: Passenger.Param[]) => void
  getMaxCount?: ((totalCount: number) => number) | null
  showDiscounts?: boolean
  showAge?: boolean
}

const PassengersList = ({
  value,
  onChange,
  availableTypes,
  getMaxCount,
  showDiscounts,
  showAge,
}: PassengersListProps): ReactElement => {
  const [{ paxDiscountCodes }] = useSettings()
  const { t } = useTranslation()
  const defaultTypes = useMemo(
    () => [
      {
        id: 'passengers',
        description: '',
        code: 'passengers',
        name: t('searchBar.passengers.types.passengers'),
      },
    ],
    [t],
  )
  const types = useMemo(() => availableTypes ?? (defaultTypes as Passenger.Type[]), [availableTypes, defaultTypes])

  const typesMap = useMemo(() => passengersUtils.buildTypesMap(types), [types])

  const passengerMaxAge = useMemo(() => {
    const passengerAges = Object.values(typesMap).map(({ maxAge }) => maxAge ?? 0)

    return Math.max(...passengerAges)
  }, [typesMap])

  const getNextMinAge = (age: number, fallback: number): number | undefined => {
    const types = Object.values(typesMap).filter(({ minAge }) => minAge !== age)

    return utils.array.min(types, ({ minAge }) => minAge)?.minAge ?? fallback
  }

  const getPassengerDescription = ({ minAge, maxAge, description }: Passenger.Type): string | null => {
    if (description?.length > 0) return description
    if (minAge == null && maxAge == null) return null
    if (minAge === 0) return t('passengers.under', { maxAge: getNextMinAge(minAge, maxAge) })

    return maxAge === passengerMaxAge ? t('passengers.over', { minAge }) : t('passengers.ageRange', { minAge, maxAge })
  }

  const setPassengerAmount = (passenger: Passenger.Type, operation: CounterOperation): void => {
    const { code, maxAge } = passenger
    const newPax = { type: code, pax: 1, maxAge, cards: [] }
    const currentIndex = value.findIndex(option => option.type === passenger.code)
    const updatedValue =
      operation === 'increase' ? [...value, newPax] : value.filter((_, index) => index !== currentIndex)

    onChange(updatedValue)
  }

  const setPassengerAge = (age: string, type: string, index: number): void => {
    const filtered = value.filter(pax => pax.type === type)
    filtered[index].maxAge = Number(age)

    onChange([...value.filter(pax => pax.type !== type), ...filtered])
  }

  const totalPassengersCount = value.length
  const maxPassengers = getMaxCount?.(totalPassengersCount) ?? config.maxPassengers
  const hasPaxDiscount = value.some(({ cards }) => cards?.some(({ type }) => type === 'discount_card'))
  const isLimitedDiscountFlow = showDiscounts && paxDiscountCodes.displayOn === 'search_results' && hasPaxDiscount
  const getPaxCount = (paxType: string): number => value.filter(({ type }) => type === paxType).length

  return (
    <div className="column gap-3 p-1">
      {Object.values(typesMap).map((passenger, index) => {
        const filteredPax = value.filter(({ type }) => type === passenger.code)
        const type = typesMap[passenger.code]
        const showDivider = showAge && Object.values(typesMap).length !== index + 1

        return (
          <Fragment key={passenger.id}>
            <Counter
              key={passenger.code}
              label={t(`searchBar.passengers.codes.${type.code}`)}
              description={getPassengerDescription(type)}
              value={getPaxCount(passenger.code)}
              max={isLimitedDiscountFlow ? 1 : maxPassengers}
              min={totalPassengersCount > 1 ? 0 : 1}
              onChange={(_, operation) => {
                setPassengerAmount(passenger, operation)
              }}
            />
            {showAge &&
              filteredPax.map(({ maxAge }, index) => (
                <Age
                  key={passenger.id + index}
                  label={`${t(`searchBar.passengers.codes.${type.code}`)} ${index + 1}`}
                  type={type}
                  value={String(maxAge)}
                  onChange={(age, event) => {
                    event?.stopPropagation()
                    setPassengerAge(age, passenger.code, index)
                  }}
                />
              ))}
            {showDivider && <Divider />}
          </Fragment>
        )
      })}
      {isLimitedDiscountFlow && (
        <div className={bem('passenger-counters', 'limited-discount-message')}>
          {t('searchBar.passengers.limitedDiscount.maxPassengers')}
        </div>
      )}
    </div>
  )
}

export default PassengersList
