import { PaymentAction } from '@adyen/adyen-web/types'
import { FormikConfig } from 'formik'

import * as Reservation from '@api/reservation'
import nethone from '@lib/nethone'
import passengerUtils from '@lib/passengers'
import { PaymentMethod } from '@pages/Checkout/hooks/Payment/useBookingPayment'
import { CheckoutFormData, CheckoutFormRef } from '@pages/Checkout/hooks/useInitialFormValues'
import { useCheckout } from '@stores/checkout'

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export type SubmitEventPayload = Partial<CheckoutFormData> | undefined | void
export type SubmitFormEvent<T = SubmitEventPayload> = (data: CheckoutFormData) => T | Promise<T>

export interface ConfirmPaymentParams {
  action: BookingAction
  bookingFormId: number
  redirectPath: string
  details: {
    action: PaymentAction
  }
}

export type FinishPaymentParams = Partial<ConfirmPaymentParams>

interface QuickReservationResult {
  shouldConfirm: boolean
}

export interface PaymentEvents {
  submitForm?: SubmitFormEvent
  validate?: FormikConfig<any>['validate']
  quickReservation?: (params: Reservation.CreateResponse) => QuickReservationResult
  confirmPayment?: (response: ConfirmPaymentParams) => void
}

interface BookingFlowEvents extends Required<PaymentEvents> {
  submitForm: SubmitFormEvent<CheckoutFormData>
  validate: Required<FormikConfig<CheckoutFormData>>['validate']
}

interface BookingFlowParams {
  form: CheckoutFormRef
  onSuccess: (params: ConfirmPaymentParams) => void
}

const useBookingFlow = ({ form, onSuccess }: BookingFlowParams): BookingFlowEvents => {
  const [, setCheckout, { get }] = useCheckout()
  const getMethod = (): PaymentMethod | null | undefined => get().paymentInstance

  const confirmPayment = (params: ConfirmPaymentParams): void => {
    setCheckout({ bookingFormId: params.bookingFormId })
    getMethod()?.on?.confirmPayment?.(params)

    onSuccess(params)
  }

  const submitForm: BookingFlowEvents['submitForm'] = async data => {
    await nethone.profile()
    const dataOverride = await getMethod()?.on?.submitForm?.(data)
    const passengers = passengerUtils.getBookingPassengers(data.passengers)
    const updatedData = { ...data, ...dataOverride, passengers }
    form.current?.setValues({ ...updatedData, paymentMethod: data.paymentMethod })

    return updatedData
  }

  const quickReservation: BookingFlowEvents['quickReservation'] = params => {
    return getMethod()?.on?.quickReservation?.(params) ?? { shouldConfirm: true }
  }

  const validate: BookingFlowEvents['validate'] = async data => {
    return getMethod()?.on?.validate?.(data)
  }

  return { submitForm, confirmPayment, quickReservation, validate }
}

export default useBookingFlow
