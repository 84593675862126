import { useFormikContext } from 'formik'
import { useEffect, useMemo } from 'react'

import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import useInstallments from '@pages/Checkout/hooks/useInstallments'
import { useTotalPrice } from '@pages/Checkout/hooks/usePrice'
import { useCarrierSpecificParams } from '@stores/params'

const InstallmentsLoader = (): null => {
  const { values, setFieldValue } = useFormikContext<CheckoutFormData>()
  const [{ marketingCarrierCode, retailerPartnerNumber }] = useCarrierSpecificParams()
  const price = useTotalPrice()
  const { numberOfInstallments } = values

  const { data: installments } = useInstallments({ price, marketingCarrierCode, retailerPartnerNumber })

  const installment = useMemo((): Installment.Item | null => {
    if (numberOfInstallments < 2) return null

    return installments.find(i => i.term === numberOfInstallments) ?? /* istanbul ignore next */ null
  }, [numberOfInstallments, installments])

  useEffect(() => {
    setFieldValue('installments', installments)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(installments), setFieldValue])

  useEffect(() => {
    setFieldValue('installment', installment)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(installment), setFieldValue])

  return null
}

export default InstallmentsLoader
