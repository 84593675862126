type Observer<T> = (value: T) => void

export class Observable<T> {
  __listeners: Observer<T>[] = []

  subscribe(fn: Observer<T>): () => void {
    this.__listeners.push(fn)

    /* istanbul ignore next */
    return (): void => {
      this.__listeners = this.__listeners.filter(x => x !== fn)
    }
  }

  set(arg: T): void {
    this.__listeners.forEach(fn => {
      fn(arg)
    })
  }
}
