import React, { ReactElement } from 'react'

import Money from '@components/Money'
import { AncillariesInfo } from '@lib/ancillary'
import { useTranslation } from '@lib/i18n'

interface AncillaryProps {
  priceInfo: AncillariesInfo[]
}

const Ancillaries = ({ priceInfo }: AncillaryProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <div className="column gap-2 mb-1">
      {priceInfo.map(({ price, name }) => (
        <div className="cell row space-between booking-details__info" key={name}>
          <div>
            <span>{t(`extras.ancillary.${name.toLowerCase()}.title`)}</span>
          </div>
          <div className="booking-details__amount">
            <Money fractional={price.fractional} currency={price.currency} />
          </div>
        </div>
      ))}
    </div>
  )
}

export default Ancillaries
