import React, { useCallback, useMemo, useState } from 'react'

import * as Reservation from '@api/reservation'
import { PaymentMethodStatus } from '@enums'
import { useTranslation } from '@lib/i18n'
import ReservationHoldModal from '@pages/Checkout/Form/SubmitReservation/ReservationHoldModal'
import { PaymentMethod } from '@pages/Checkout/hooks/Payment/useBookingPayment'
import PaymentLabel from '@pages/Checkout/Payment/Label'
import { Button } from '@ui'

const useHoldReservation = (): PaymentMethod => {
  const { t } = useTranslation()
  const [heldReservation, setHeldReservation] = useState<Reservation.CreateResponse | null>(null)
  const getOption = useCallback(
    () => ({
      value: 'hold_reservation',
      label: <PaymentLabel type="holdReservation" showName />,
    }),
    [],
  )

  const getPayButton = useCallback(
    () => <Button buttonType="submit">{t('checkout.holdReservationButton')}</Button>,
    [t],
  )

  const handleQuickReservation = useCallback((params: Reservation.CreateResponse) => {
    setHeldReservation(params)

    return { shouldConfirm: false }
  }, [])

  const modal = useMemo(
    () => heldReservation && <ReservationHoldModal reservationData={heldReservation} />,
    [heldReservation],
  )

  return useMemo(
    () => ({
      status: PaymentMethodStatus.Ready,
      getOption,
      getPayButton,
      extraContent: modal,
      on: {
        quickReservation: handleQuickReservation,
      },
    }),
    [getOption, getPayButton, handleQuickReservation, modal],
  )
}

export default useHoldReservation
