import { useMemo } from 'react'

import { useTranslation } from '@lib/i18n'
import { usePassengerTypes } from '@stores/passengerTypes'

const usePassengersList = (): Passenger.Type[] => {
  const [passengerTypes] = usePassengerTypes()
  const { t } = useTranslation()

  return useMemo(
    () =>
      passengerTypes.types.map<Passenger.Type>(item => ({
        ...item,
        id: Math.random().toString(),
        name: t(`searchBar.passengers.types.${item.name}`),
        description: '',
        code: item.code,
      })),
    [passengerTypes.types, t],
  )
}

export default usePassengersList
