import { useFormikContext } from 'formik'
import React, { ReactElement, useMemo } from 'react'

import Money from '@components/Money'
import currencyUtils from '@lib/currency'
import { useTranslation } from '@lib/i18n'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'
import { Skeleton } from '@ui'

import '@pages/Checkout/BookingDetails/Price/index.scss'

interface PriceProps {
  price: Money | null
}

const Price = ({ price }: PriceProps): ReactElement => {
  const { t } = useTranslation()
  const [{ reservation }] = useSettings()
  const { values } = useFormikContext<CheckoutFormData>()
  const [{ currency }] = useParams()
  const { passengers, installment, isReservationLoading, isVacancyLoading } = values

  const isLoading = reservation.enabled ? isReservationLoading : isVacancyLoading

  const pricePerPassenger = useMemo(() => {
    if (price == null) return null

    return currencyUtils
      .create(price.fractional, price.currency)
      .divide(passengers.reduce((acc, curr) => acc + curr.pax, 0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passengers.length, JSON.stringify(price)])
  const pricePerPersonPlaceholder = t('checkout.bookingDetails.perPerson', {
    price: currencyUtils.create(7600, currency).format(),
  })

  if (!isLoading && !price) return <div className="price mb-5" data-tag="price-section" />

  return (
    <div className="price mb-5" data-tag="price-section">
      <>
        {installment && (
          <div className="row space-between price__installments gap-1">
            <div className="cell basis-auto shrink">{t('checkout.bookingDetails.installmentPayments')}</div>
            <div className="cell basis-auto no-grow">
              {installment.term}
              {' x '}
              <b>
                <Money {...installment.monthly} />
              </b>
            </div>
          </div>
        )}
        <div className="row space-between price__total">
          <div className="cell basis-auto no-grow">{t('checkout.bookingDetails.total')}</div>
          <div className="cell basis-auto no-grow">
            <Skeleton.Text placeholder={<Money fractional={13800} currency={currency} />} loading={isLoading}>
              {price && <Money {...price} />}
            </Skeleton.Text>
          </div>
        </div>
        <div className="row space-between price__per-person gap-1">
          <div className="cell basis-auto shrink">{t('checkout.bookingDetails.includingFees')}</div>
          <div className="cell basis-auto no-grow">
            <Skeleton.Text placeholder={pricePerPersonPlaceholder} loading={isLoading}>
              {t('checkout.bookingDetails.perPerson', { price: pricePerPassenger?.format() })}
            </Skeleton.Text>
          </div>
        </div>
      </>
    </div>
  )
}

export default Price
