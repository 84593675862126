import cn from 'classnames'

type Modifiers = Record<string, any>

interface BemFunction {
  (block: string): string
  (block: string, modifiers: Modifiers): string
  (block: string, element: string): string
  (block: string, element: string, modifiers: Modifiers): string
}

const bem: BemFunction = (block, element: string | Modifiers = '', modifiers: Modifiers = {}): string => {
  if (typeof element === 'object') {
    modifiers = element
    element = ''
  }

  const elementClassPostfix = element.length > 0 ? `__${element}` : ''
  const blockElementClass = `${block}${elementClassPostfix}`

  const modifiersObject = Object.entries(modifiers).reduce((mem, [key, value]) => {
    return {
      ...mem,
      [`${blockElementClass}--${key}`]: value,
    }
  }, {})

  return cn(blockElementClass, modifiersObject)
}

export default bem
