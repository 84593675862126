import React, { ReactElement } from 'react'

import bem from '@lib/bem'
import image from '@lib/image'

interface CarrierLogoProps {
  name: string
  code: string
  line?: string
  linePrefix?: string
  lineDataEnabled?: boolean
}

const CarrierLogo = ({ name, code, line, linePrefix, lineDataEnabled }: CarrierLogoProps): ReactElement => {
  return (
    <div className="carriers__info row column-lg items-center cell-3 cell-md-5">
      <img src={image.getMarketingCarrierLogo(code)} className={bem('carriers', 'info-logo')} />
      <div className={bem('carriers', 'info-name')}>
        {lineDataEnabled && (
          <>
            <div>{line}</div>
            <div>{linePrefix}</div>
          </>
        )}
        {!lineDataEnabled && name}
      </div>
    </div>
  )
}

export default CarrierLogo
