import React, { ReactElement } from 'react'

import SearchForm, { SearchFormProps } from '@components/SearchForm'
import { useTranslation } from '@lib/i18n'
import { Modal } from '@ui'

import '@components/SearchModal/index.scss'

interface SearchModalProps extends SearchFormProps {
  opened: boolean
  onClose: () => void
}

const SearchModal = ({ onClose, opened, ...formProps }: SearchModalProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Modal onClose={onClose} opened={opened} title={t('searchBar.modalTitle')} className="search-modal">
      <SearchForm direction="column" {...formProps} />
    </Modal>
  )
}

export default SearchModal
