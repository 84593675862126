import { useFormikContext } from 'formik'
import React, { ReactElement, useEffect } from 'react'

import { useTranslation } from '@lib/i18n'
import { VGS } from '@lib/vgs'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import BillingAddress from '@pages/Checkout/Payment/BillingAddress'
import VGSCVCField from '@pages/Checkout/Payment/CreditCardForm/VGS/CVCField'
import { useSettings } from '@queries/settings'

interface SavedCardFormProps {
  card: CreditCard.Saved
  vgsForm?: VGS.Form | null
}

const SavedCardForm = ({ card, vgsForm }: SavedCardFormProps): ReactElement => {
  const { t } = useTranslation()
  const [{ amtrackSavedCards }] = useSettings()
  const {
    setFieldValue,
    touched: { paymentMethodData: formTouched },
  } = useFormikContext<CheckoutFormData>()

  useEffect(() => {
    setFieldValue('paymentMethodData', {
      cardType: card.type,
      cardExpiry: card.expiry,
      cardHolderName: card.holderName,
      cardNumber: card.number,
    })
  }, [card.type, card.expiry, card.holderName, card.number, setFieldValue])
  const showCvc = amtrackSavedCards.cvc && vgsForm != null

  return (
    <>
      {showCvc && <VGSCVCField touched={formTouched} form={vgsForm} name="cvv" placeholder={t('checkout.card.cvc')} />}
      <div className="cell-12">
        <BillingAddress />
      </div>
    </>
  )
}

export default SavedCardForm
