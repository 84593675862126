import React, { ReactElement, useState } from 'react'

import amplitude from '@lib/analytics/amplitude'
import bem from '@lib/bem'
import { Trans, useTranslation } from '@lib/i18n'
import ConnectionDetails from '@pages/Checkout/BookingDetails/TripDetails/ConnectionDetails'
import { useCheckout } from '@stores/checkout'
import { useParams } from '@stores/params'
import { Icon, Modal } from '@ui'

import '@pages/Checkout/BookingDetails/TripDetails/index.scss'

interface TripDetailsProps {
  fareClass: string
  carriers: MarketingCarrier[]
}

const TripDetails = ({ fareClass, carriers }: TripDetailsProps): ReactElement => {
  const { t } = useTranslation()
  const [opened, setOpened] = useState(false)
  const [{ outbound, inbound }] = useCheckout()
  const [{ express }] = useParams()

  const handleOpen = (): void => {
    setOpened(true)
    amplitude.checkout.clickTripDetails()
  }

  return (
    <>
      <div className={bem('trip-details')}>
        <div className={bem('trip-details', 'icon-container')}>
          <div className={bem('icon-container', 'icon')}>
            <Icon name="ticket-rotated" size="medium" />
          </div>
        </div>
        <div className={bem('trip-details', 'text')}>
          <Trans
            i18nKey="checkout.tripDetails.link"
            components={[<a key={0} onClick={handleOpen} />, <a key={1} onClick={handleOpen} />]}
          />
        </div>
      </div>

      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false)
        }}
        title={t('checkout.tripDetails.title')}
        fullScreen
      >
        <div className={bem('p-6')}>
          {outbound && (
            <ConnectionDetails
              connection={outbound}
              type="outbound"
              fareClass={fareClass}
              carriers={carriers}
              hideTime={express}
            />
          )}
          {inbound && (
            <ConnectionDetails
              connection={inbound}
              type="inbound"
              fareClass={fareClass}
              carriers={carriers}
              hideTime={express}
            />
          )}
        </div>
      </Modal>
    </>
  )
}

export default TripDetails
