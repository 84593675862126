import React, { ReactElement } from 'react'

import bem from '@lib/bem'
import { Icon } from '@ui'

import '@pages/Checkout/FareClassesSelection/SplitFareClasses/Navigation/Arrow.scss'

interface NavigateArrowProps {
  onClick: () => void
  direction: 'left' | 'right'
}

const NavigateArrow = ({ onClick, direction }: NavigateArrowProps): ReactElement => {
  return (
    <div className={bem('fare-class', 'navigate', { [direction]: true })}>
      <Icon name={`chevron-${direction}`} size="medium" onClick={onClick} />
    </div>
  )
}

export default NavigateArrow
