import React, { FocusEvent, KeyboardEvent, useCallback, useEffect, useState } from 'react'

import currencyUtils from '@lib/currency'
import { useParams } from '@stores/params'
import { Input } from '@ui'
import { InputElement } from '@ui/Input'

interface Props {
  value?: number
  onSubmit: (value?: number) => void
  onChange?: (value?: number) => void
  transformOnSubmit?: (value?: number) => number
  label: string
}

const PriceInput = (props: Props) => {
  const { onSubmit, label, value, onChange, transformOnSubmit } = props
  const [{ currency }] = useParams()

  const getDecimalsMultiplier = (input: string) => {
    const decimalsAmount = input.split(/[.,]/)[1]?.length ?? 0
    return 10 ** (currencyUtils.getPrecision(currency) - decimalsAmount)
  }

  const parseInput = (input: string): number | undefined => {
    const value = Number(input.match(/\d/g)?.join('')) * getDecimalsMultiplier(input)

    return isNaN(value) ? undefined : value
  }
  const stringifyInput = useCallback(
    (fractional?: number) =>
      fractional == null
        ? currencyUtils.getSymbol(currency)
        : currencyUtils.create(fractional, currency, { separator: '' }).format(),
    [currency],
  )

  const [inputValue, setInputValue] = useState<string>(() => stringifyInput(value))

  const submitValue = (e: FocusEvent<InputElement> | KeyboardEvent<InputElement>) => {
    const value = parseInput(e.currentTarget.value)
    const transformedValue = transformOnSubmit?.(value) ?? value
    onSubmit(transformedValue)
    setInputValue(stringifyInput(transformedValue))
  }

  useEffect(() => {
    setInputValue(stringifyInput(value))
  }, [stringifyInput, value])

  const onInputChange = (value: string) => {
    const allowedCharacters = value.match(/[\d.,]/g)?.join('') ?? ''
    setInputValue(`${currencyUtils.getSymbol(currency)}${allowedCharacters}`)
    onChange && onChange(parseInput(value))
  }

  return (
    <Input value={inputValue} onChange={onInputChange} onBlur={submitValue} onPressEnter={submitValue} label={label} />
  )
}

export default PriceInput
