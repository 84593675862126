import React, { ReactElement, ReactNode, useState } from 'react'

import amplitude from '@lib/analytics/amplitude'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { Modal } from '@ui'

import '@pages/Checkout/BookingDetails/CarrierLink/index.scss'

interface CarrierModalProps {
  carrier: MarketingCarrier
}

type RenderCarrierField = 'legalName' | 'address' | 'commercialRegisterNumber'

const CARRIER_FIELDS = ['legalName', 'address', 'commercialRegisterNumber'] as const

const CarrierLink = ({ carrier }: CarrierModalProps): ReactElement => {
  const { t } = useTranslation()
  const [opened, setOpened] = useState<boolean>(false)
  const { tradeName } = carrier

  const handleOpen = (): void => {
    setOpened(true)
    amplitude.checkout.clickCarrierDetails()
  }

  const renderCarrierInfoItem = (field: RenderCarrierField): ReactNode =>
    carrier[field] ? (
      <div className="cell" key={field}>
        <div className="row gap-1">
          <div className={bem('carrier-modal', 'label')}>{t(`checkout.carrierModal.${field}`)}</div>
          <div>{carrier[field]}</div>
        </div>
      </div>
    ) : null

  return (
    <>
      <a onClick={handleOpen}>{carrier.tradeName}</a>
      <Modal
        maxWidth="md"
        opened={opened}
        onClose={() => {
          setOpened(false)
        }}
        title={tradeName}
      >
        <div className="carrier-modal">
          <div className="column gap-2">{CARRIER_FIELDS.map(renderCarrierInfoItem)}</div>
        </div>
      </Modal>
    </>
  )
}

export default CarrierLink
