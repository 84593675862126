import CardElement from '@adyen/adyen-web/components/Card'
import { Field, useFormikContext } from 'formik'
import React, { ReactElement, useEffect, useRef } from 'react'

import Loader from '@components/Loader'
import useValidators from '@hooks/useValidators'
import { useTranslation } from '@lib/i18n'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import BillingAddress from '@pages/Checkout/Payment/BillingAddress'
import InstallmentSelector from '@pages/Checkout/Payment/CreditCardForm/Adyen/InstallmentSelector'
import { useSettings } from '@queries/settings'
import InputField from '@ui/Input/Field'

import '@pages/Checkout/Payment/CreditCardForm/Adyen/index.scss'

interface AdyenCardFormProps {
  loading: boolean
  card?: CardElement
}

const AdyenCardForm = ({ loading, card }: AdyenCardFormProps): ReactElement => {
  const { t } = useTranslation()
  const cardSecureFieldsRef = useRef(null)
  const [{ cpf, billingAddress }] = useSettings()
  const {
    values: { passengers },
  } = useFormikContext<CheckoutFormData>()
  const { required } = useValidators()
  const isCpfVisible =
    cpf.enabled &&
    passengers.find(({ nationality, governmentIdType }) => nationality === 'BR' || governmentIdType !== 'passport_id')

  useEffect(() => {
    /* istanbul ignore else */
    if (!loading && cardSecureFieldsRef.current != null && card != null) {
      card.mount(cardSecureFieldsRef.current)

      return () => {
        card.unmount()
      }
    }
  }, [card, loading])

  return (
    <Loader loading={loading}>
      <div className="row credit-card-form">
        <div className="cell-7 cell-sm-12">
          <div className="column gap-3">
            <div className="cell-12">
              <InstallmentSelector />
            </div>
            <div className="cell-12">
              <div ref={cardSecureFieldsRef} />
            </div>
            <div className="cell-12">
              <Field
                validate={required}
                component={InputField}
                name="holderName"
                label={t('checkout.card.holder')}
                required
              />
            </div>
            {isCpfVisible && (
              <div className="cell-12">
                <Field validate={required} component={InputField} name="cpf" label={t('checkout.card.cpf')} required />
              </div>
            )}
            {billingAddress.enabled && <BillingAddress />}
          </div>
        </div>
      </div>
    </Loader>
  )
}

export default AdyenCardForm
