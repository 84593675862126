import { FieldProps } from 'formik'
import React, { ReactElement } from 'react'

import { Dropdown } from '@ui'
import { DropdownProps } from '@ui/Dropdown'

type DropdownFieldProps<T> = FieldProps & DropdownProps<T>

const DropdownField = <TValue extends string>(props: DropdownFieldProps<TValue>): ReactElement => {
  const {
    field,
    form: { setFieldValue, getFieldMeta, submitCount, setFieldTouched },
    onChange,
    errorMessage,
    ...rest
  } = props
  const { touched, error: fieldError } = getFieldMeta(field.name)
  const submitted = submitCount > 0

  return (
    <Dropdown
      {...rest}
      {...field}
      onChange={value => {
        setFieldTouched(field.name, true, false)
        setFieldValue(field.name, value, true)
        onChange?.(value)
      }}
      errorMessage={touched || submitted ? errorMessage ?? fieldError : null}
    />
  )
}

export default DropdownField
