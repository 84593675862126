import React, { ReactElement, useState } from 'react'

import Collection from '@components/MediaCollection'

interface FareClassMediaProps {
  media: string[]
}

const FareClassMedia = ({ media }: FareClassMediaProps): ReactElement => {
  const [opened, setOpened] = useState<boolean>(false)

  return (
    <div
      onClick={e => {
        e.stopPropagation()
        setOpened(true)
      }}
    >
      <Collection media={media} opened={opened} onClick={() => setOpened(false)} />
    </div>
  )
}

export default FareClassMedia
