import React, { ReactElement } from 'react'

import config from '@config'
import NavigateArrow from '@pages/Checkout/FareClassesSelection/SplitFareClasses/Navigation/Arrow'

interface NavigationProps {
  currentIndex: number
  maxIndex: number
  onNavigate: (index: number) => void
}

const Navigation = ({ currentIndex, maxIndex, onNavigate }: NavigationProps): ReactElement => {
  const handleNavigation = (type: 'forward' | 'back'): void => {
    if (type === 'forward' && currentIndex + config.fareSplitViewLength <= maxIndex) {
      onNavigate(currentIndex + config.fareSplitViewLength)
    }
    if (type === 'back' && currentIndex - config.fareSplitViewLength >= 0) {
      onNavigate(currentIndex - config.fareSplitViewLength)
    }
  }

  return (
    <>
      {currentIndex + config.fareSplitViewLength < maxIndex && (
        <NavigateArrow
          onClick={() => {
            handleNavigation('forward')
          }}
          direction="right"
        />
      )}
      {currentIndex > 0 && (
        <NavigateArrow
          onClick={() => {
            handleNavigation('back')
          }}
          direction="left"
        />
      )}
    </>
  )
}

export default Navigation
