import React, { ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { ListChildComponentProps } from 'react-window'

import ErrorBoundary from '@components/ErrorBoundary'
import bem from '@lib/bem'

export interface ItemData<T = any> {
  setRowHeight: (index: number, height: number) => void
  items: T[]
  renderElement: (item: T) => ReactNode
}

type ItemProps = ListChildComponentProps<ItemData>

const Item = ({ style, index, data }: ItemProps): ReactElement => {
  const { setRowHeight, items, renderElement } = data
  const rowRef = useRef<HTMLDivElement | null>(null)
  const [isClicked] = useState<boolean>(false)

  const updateRowHeight = useCallback(() => {
    if (!rowRef.current?.children[0]) return
    setRowHeight(index, rowRef.current.children[0].getBoundingClientRect().height)
  }, [setRowHeight, index])

  useEffect(() => {
    updateRowHeight()
  }, [isClicked, index, setRowHeight, updateRowHeight])

  return (
    <div
      className={bem('virtual-list', 'item')}
      style={style}
      ref={rowRef}
      onClick={() => requestAnimationFrame(updateRowHeight)}
    >
      <ErrorBoundary fallback={() => <></>}>{renderElement(items[index])}</ErrorBoundary>
    </div>
  )
}

export default Item
