import { useMemo } from 'react'

import { SearchFormData } from '@components/SearchForm'
import { useLocationName } from '@loaders/locations'
import { ParamsStore } from '@stores/params'

export type SearchParams = Omit<SearchFormData, 'departureLocation' | 'arrivalLocation'> &
  Pick<ParamsStore, 'departureLocation' | 'arrivalLocation' | 'locale'>

const useSearchFormInitial = (params: SearchParams): SearchFormData => {
  const { data: departureLocation } = useLocationName({ locale: params.locale, location: params.departureLocation })
  const { data: arrivalLocation } = useLocationName({ locale: params.locale, location: params.arrivalLocation })

  return useMemo(
    () => ({
      ...params,
      departureLocation,
      arrivalLocation,
    }),
    [arrivalLocation, departureLocation, params],
  )
}

export default useSearchFormInitial
