import { RefObject, useCallback, useEffect } from 'react'

const useClickOutside = (elementRef: RefObject<HTMLElement> | RefObject<HTMLElement>[], callback: () => void): void => {
  const handleClickOutside = useCallback(
    (event: MouseEvent): void => {
      const target = event.composedPath()[0] as HTMLElement
      const elements = Array.isArray(elementRef) ? elementRef : [elementRef]
      /* istanbul ignore next: ignoring ref.current = null case (it's actually impossible) */
      const inside = elements.some(({ current }) => current?.contains(target)) ?? false

      if (!inside) callback()
    },
    [callback, elementRef],
  )

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [callback, handleClickOutside])
}

export default useClickOutside
