/* istanbul ignore next */
const metaPixelScriptTemplate = (containerId: string | number): string => `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', ${containerId});
  fbq('track', 'PageView');`

/* istanbul ignore next */
const init = ({ container_id }: Analytics.Settings['meta_pixel']): void => {
  if (!container_id) return
  const metaPixelScript = document.createElement('script')
  metaPixelScript.innerHTML = metaPixelScriptTemplate(container_id)

  document.head.appendChild(metaPixelScript)
}

export default {
  init,
}
