import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'

import qs from '@lib/queryString'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

interface LogoLinkProps {
  children: ReactElement
}

const LogoLink = ({ children }: LogoLinkProps): ReactElement => {
  const [{ logo }] = useSettings()
  const [{ retailerPartnerNumber, marketingCarrierCode }] = useParams()

  const { redirectionUrl } = logo

  if (redirectionUrl) return <a href={redirectionUrl}>{children}</a>

  return (
    <Link to={{ pathname: '/', search: qs.stringify({ retailerPartnerNumber, marketingCarrierCode }) }}>
      {children}
    </Link>
  )
}

export default LogoLink
