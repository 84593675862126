import React, { ReactElement } from 'react'

import SkeletonFilter from '@components/ConnectionFilters/Skeleton'
import LineSkeleton from '@components/Initializer/Skeletons/common/Line'
import SearchBarSkeleton from '@components/Initializer/Skeletons/common/SearchBar'
import SkeletonCard from '@components/JourneyCard/Skeleton'
import useIsMobile from '@hooks/useIsMobile'
import { Skeleton } from '@ui'

const ResultSkeleton = (): ReactElement => {
  const isMobile = useIsMobile()

  const mobileFilters = (
    <div className="row space-between mb-5">
      <div className="cell-4">
        <Skeleton.Item height={24} />
      </div>
      <div className="cell-2">
        <Skeleton.Item height={24} />
      </div>
    </div>
  )

  return (
    <>
      <LineSkeleton height={135} />
      <div className="column page-skeleton__page page-skeleton__result">
        {!isMobile && <SearchBarSkeleton />}
        <div className="row-lg column">
          {!isMobile && (
            <div className="column mr-6">
              <SkeletonFilter />
            </div>
          )}
          <div className="cell">
            {isMobile && mobileFilters}
            <SkeletonCard />
          </div>
        </div>
      </div>
    </>
  )
}

export default ResultSkeleton
