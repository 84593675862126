import React, { ReactElement, ReactNode, useEffect, useState } from 'react'

import PageSkeleton from '@components/Initializer/Skeletons'
import i18n from '@lib/i18n'
import { Skeleton } from '@ui'

export interface LocaleProps {
  locale: Locale
  children: ReactNode
  params: {
    retailerPartnerNumber?: RetailerNumber
    marketingCarrierCode?: CarrierCode
  }
  loading?: boolean
}

const Initializer = ({ locale, children, params, loading }: LocaleProps): ReactElement => {
  const [busy, setBusy] = useState<boolean>(true)
  const { retailerPartnerNumber, marketingCarrierCode } = params

  useEffect(() => {
    if (i18n.isInitialized()) {
      void i18n.changeLanguage(locale)
    }
  }, [locale])

  useEffect(() => {
    if (loading || !busy) return

    const initLocalization = async (): Promise<void> => {
      await i18n.init(locale, { retailerPartnerNumber, marketingCarrierCode })
      setBusy(false)
    }

    void initLocalization()
  }, [locale, loading, busy, retailerPartnerNumber, marketingCarrierCode])

  return (
    <Skeleton.List Skeleton={PageSkeleton} amount={1} loading={busy || !!loading}>
      <>{children}</>
    </Skeleton.List>
  )
}

export default Initializer
