import React from 'react'

import MenuLink from '@components/Header/Link'
import { useTranslation } from '@lib/i18n'
import { useSettings } from '@queries/settings'
import { Icon } from '@ui'

import '@components/Footer/Menu/index.scss'

const FooterMenu = () => {
  const { t } = useTranslation()
  const [
    {
      footer: { menu },
    },
  ] = useSettings()

  return (
    <div className="row gap-5 footer-menu">
      {menu.map((items, column) => (
        <div className="cell cell-sm-6 footer-menu__column" key={column}>
          <div className="column gap-6">
            {items.map((item, row) => {
              const translationPath = `menu.${column}.${row}`
              const title = t(`footer.${translationPath}.title`, { defaultValue: '' })

              return (
                <div className="cell" key={row}>
                  {title && (
                    <div className="row items-center gap-2 mb-2">
                      {item.icon && (
                        <div className="cell no-grow">
                          <div className="footer-menu__icon">
                            <Icon name={item.icon} size="medium" />
                          </div>
                        </div>
                      )}
                      <h4 className="mb-0">{title}</h4>
                    </div>
                  )}
                  <div className="column gap-2 body-14">
                    {item.urls.map((link, index) => (
                      <MenuLink
                        key={index + link}
                        keyPrefix="footer"
                        url={link}
                        translationKey={`${translationPath}.urls.${index}`}
                        className="footer-menu__link"
                      />
                    ))}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      ))}
    </div>
  )
}

export default FooterMenu
