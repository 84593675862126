import React from 'react'

import StationFilter from '@lib/connections/filters/components/StationFilter'
import { FilterConfig, FilterFunction, FilterRenderProps } from '@lib/connections/filters/types'

const filter: FilterFunction = (connection, value) => value.includes(connection.arrivalStation.id)

const arrivalStationsFilter: FilterConfig = {
  filter,
  title: 'arrivalStations',
  Component: (props: FilterRenderProps<string[]>) => <StationFilter {...props} direction="arrival" />,
}

export default arrivalStationsFilter
