import makeApi from '@lib/api'

interface NameEntry {
  languageId: number
  languageCode: Locale
  name: string
}

export interface SuggestionEntry {
  type: Location.Type
  code: string
  names: NameEntry[]
  countryCode: string
  subdivisionCode: string | null
  childrenLocations?: SuggestionEntry[]
}

export interface SuggestionsResponse {
  locations: SuggestionEntry[]
}

export interface SuggestionsRequest {
  q: string
  carriers?: string[]
  departureLocation?: string
  departureLocationType?: string
  limitTopLevel?: number
  limitNested?: number
}

export default {
  load: makeApi.get<SuggestionsRequest, SuggestionsResponse>('/locations'),
}
