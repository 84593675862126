import React, { ReactElement } from 'react'

import bem from '@lib/bem'
import utils from '@lib/utils'
import Icon from '@ui/Icon'

interface FareLabelProps {
  fareClassName: string
}

const FareLabel = ({ fareClassName }: FareLabelProps): ReactElement => {
  return (
    <>
      <span className={bem('carriers', 'fares-class-info')}>
        <span className={bem('carriers', 'fares-class-icon')}>
          <Icon name="seat" size="medium" />
        </span>
        <span className={bem('carriers', 'fares-class-title')}>{utils.string.capitalize(fareClassName)}</span>
      </span>
    </>
  )
}

export default FareLabel
