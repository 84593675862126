/* istanbul ignore next */
const hotjarScriptTemplate = (containerId: string | number): string =>
  `(function (h, o, t, j, a, r) {
    h.hj = h.hj || function () {
      (h.hj.q = h.hj.q || []).push(arguments)
    }
    h._hjSettings = {
      hjid: ${containerId},
      hjsv: 6,
    }
    a = o.getElementsByTagName('head')[0]
    r = o.createElement('script')
    r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')`

/* istanbul ignore next */
const init = ({ container_id }: Analytics.Settings['hotjar']): void => {
  if (!container_id) return
  const hotjarScript = document.createElement('script')
  hotjarScript.innerHTML = hotjarScriptTemplate(container_id)

  document.head.appendChild(hotjarScript)
}

export default {
  init,
}
