import React, { ReactElement, ReactNode } from 'react'

import '@ui/Skeleton/Text/index.scss'

export interface SkeletonTextProps {
  placeholder: ReactNode
  children: ReactNode
  loading: boolean
}

const Text = ({ loading, children, placeholder }: SkeletonTextProps): ReactElement => {
  if (!loading) return <>{children}</>

  return <div className="ui-skeleton-text">{placeholder}</div>
}

export default Text
