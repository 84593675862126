import React, { ReactElement } from 'react'

import Body, { PopularDirectionParams } from '@pages/Search/PopularDirectionCard/Body'
import Header from '@pages/Search/PopularDirectionCard/Header'
import { Card } from '@ui'

interface PopularDirectionCardProps {
  connection: PopularConnection
  onClick: (params: PopularDirectionParams) => void
}

const PopularDirectionCard = ({ connection, onClick }: PopularDirectionCardProps): ReactElement => {
  return <Card body={<Body connection={connection} onClick={onClick} />} header={<Header connection={connection} />} />
}

export default PopularDirectionCard
