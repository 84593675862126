import React, { ReactElement } from 'react'

import { Skeleton } from '@ui'

const PaymentOptionSkeleton = (): ReactElement => {
  return (
    <div className="cell-6">
      <Skeleton.Item height={32} />
    </div>
  )
}

export default PaymentOptionSkeleton
