import React, { ReactElement, ReactNode } from 'react'

import bem from '@lib/bem'

import '@ui/Card/index.scss'

interface CardProps {
  header?: ReactNode
  body: ReactNode
  footer?: ReactNode
  details?: ReactNode
  rightSide?: ReactNode
  leftSide?: ReactNode
  disabled?: boolean
  disabledLabel?: string
  opened?: boolean
  dataTag?: string
}

const Card = ({
  opened,
  header,
  body,
  footer,
  details,
  rightSide,
  leftSide,
  disabled,
  disabledLabel,
  dataTag,
  ...props
}: CardProps & React.HTMLAttributes<HTMLDivElement>): ReactElement => {
  return (
    <div {...props} className={bem('ui-card', { opened, disabled })} data-tag={dataTag}>
      <div className={bem('ui-card', 'main', { opened, sided: rightSide })}>
        {leftSide}
        <div className={bem('ui-card', 'main-content')}>
          {Boolean(header) && <div className={bem('ui-card', 'header')}>{header}</div>}
          <div className={bem('ui-card', 'body')}>{body}</div>
          {Boolean(footer) && <div className={bem('ui-card', 'footer')}>{footer}</div>}
        </div>
        {rightSide}
      </div>

      {disabled && (
        <div className={bem('ui-card__overlay', { disabled })}>
          <div>{disabledLabel}</div>
        </div>
      )}
      {details}
    </div>
  )
}

export default Card
