import { VacancyAvailableResponse, VacancyData } from '@api/vacancy'
import utils from '@lib/utils'
import { UpdatedFareClass } from '@pages/Checkout/FareClassesSelection'

export interface DefaultVacancy {
  price: Money
  vacant: boolean
  fareClass: string
  travelClass?: string
}

export type Vacancy = DefaultVacancy & VacancyData

const getUpdatedVacancies = (vacancies: Vacancy[], fareClassList: UpdatedFareClass[]): DefaultVacancy[] =>
  fareClassList.map(fare => {
    const vacancy = vacancies.find(({ fareClass }) => fareClass === fare.code)
    const defaultVacancy = { vacant: true, price: fare.price, fareClass: fare.code }

    return vacancy ?? defaultVacancy
  })

const getPrice = (fareClassList: UpdatedFareClass[], fareClass: string | string[]): Money | undefined => {
  const fareClasses = Array.isArray(fareClass) ? fareClass : [fareClass]
  const updatedVacancies = getUpdatedVacancies([], fareClassList)
  const availableVacancies = updatedVacancies.filter(item => item.vacant && fareClasses.includes(item.fareClass))

  return utils.array.min(availableVacancies, vacancy => vacancy.price.fractional)?.price
}

const getVacant = (vacancies: Vacancy[], fareClass: string | string[]): boolean => {
  const fareClasses = Array.isArray(fareClass) ? fareClass : [fareClass]
  const filteredVacancies = fareClasses.map(code => vacancies.find(v => v.fareClass === code)?.vacant ?? true)

  return filteredVacancies.some(item => item)
}

const getFareByTravelClass = (vacancies: DefaultVacancy[], faresList: FareClassMap.Entity[]): DefaultVacancy[] => {
  return vacancies.map(item => {
    const fare = faresList.find(el => el.fares.map(({ fareClassCode }) => fareClassCode).includes(item.fareClass))

    return { ...item, travelClass: fare?.class }
  })
}

const getSortedVacancies = (
  vacancies: Vacancy[],
  fareClassList: UpdatedFareClass[],
  faresList: FareClassMap.Entity[],
): DefaultVacancy[] => {
  const updatedVacancies = getUpdatedVacancies(vacancies, fareClassList)

  return getFareByTravelClass(updatedVacancies, faresList)
    .filter(({ vacant }) => vacant)
    .sort((a, b) => a.price.fractional - b.price.fractional)
}

const calculatePrice = (totalPrice: Money | null, currentPrice: Money, selectedPrice?: Money): Money => {
  const { fractional: total = 0 } = { ...totalPrice }
  const { fractional: selected = 0 } = { ...selectedPrice }
  const { fractional: current, currency } = currentPrice

  return { fractional: selected + current - total, currency }
}

const getDiscount = ({ price, originalPrice }: VacancyAvailableResponse): number => originalPrice - price.fractional

const vacancyUtils = {
  getPrice,
  getVacant,
  getSortedVacancies,
  calculatePrice,
  getDiscount,
}

export default vacancyUtils
