import React, { ReactElement, useEffect } from 'react'

import amplitude from '@lib/analytics/amplitude'
import { ApiError } from '@lib/api'
import { AssertionError } from '@lib/assert'
import { ErrorMessage } from '@ui'

interface ErrorPageProps {
  error: Error
  code?: ErrorCode
}

const ErrorPage = ({ error, code }: ErrorPageProps): ReactElement => {
  useEffect(() => {
    const errorData = amplitude.error.buildParams(error)

    amplitude.error.viewPage(errorData)
  }, [error])

  if (error instanceof ApiError) return <ErrorMessage code={error.code} />
  /* istanbul ignore else */
  if (error instanceof AssertionError) return <ErrorMessage code="paramsInvalid" />

  /* istanbul ignore next */
  return <ErrorMessage code={code} fallbackMessage={code ? null : error.message} />
}

export default ErrorPage
