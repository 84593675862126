import React, { ReactElement, useMemo } from 'react'

import CheckboxFilter from '@lib/connections/filters/components/CheckboxFilter'
import { FilterRenderProps } from '@lib/connections/filters/types'
import filterUtils from '@lib/filter'
import utils from '@lib/utils'
import { Icon } from '@ui'

interface Props extends FilterRenderProps {
  direction: 'departure' | 'arrival'
}

const StationFilter = ({ data, value, onChange, direction, setVisibility }: Props): ReactElement | null => {
  const options = useMemo(() => {
    const key = direction === 'departure' ? 'departureStation' : 'arrivalStation'
    const stations = utils.array.uniqueBy(
      data.map(connection => connection[key]),
      'id',
    )

    return stations.map(station => ({
      value: station.id,
      label: (
        <div className="row items-center gap-1">
          <a href={filterUtils.buildStationMapURL(station)} rel="noreferrer" target="_blank">
            <Icon name="map-pin" size="medium" />
          </a>
          <span>{station.name}</span>
        </div>
      ),
    }))
  }, [data, direction])

  return <CheckboxFilter options={options} value={value} onChange={onChange} setVisibility={setVisibility} />
}

export default StationFilter
