import { useFormikContext } from 'formik'
import React, { ReactElement } from 'react'

import bem from '@lib/bem'
import fare from '@lib/fare'
import ContactDetails from '@pages/Checkout/express/ContactDetails'
import Message from '@pages/Checkout/express/Message'
import Route from '@pages/Checkout/express/Route'
import TicketDetails from '@pages/Checkout/express/TicketDetails'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useCheckout } from '@stores/checkout'
import { Skeleton } from '@ui'

const ExpressFields = (): ReactElement => {
  const [{ outbound }] = useCheckout()
  const { values } = useFormikContext<CheckoutFormData>()
  const isReturn = fare.getFareByCode(values.fareClass, outbound)?.fareClass.journeyType === 'open_return'
  const isLoading = !outbound

  return (
    <>
      <div className="cell">
        <Skeleton.List Skeleton={() => <Skeleton.Item height={100} />} loading={isLoading} amount={1}>
          <Route connection={outbound} isReturn={isReturn} />
        </Skeleton.List>
      </div>
      {outbound && (
        <div className="cell">
          <div className={bem('checkout', 'message')}>
            <Message isReturn={isReturn} outbound={outbound} />
          </div>
        </div>
      )}
      <div className="cell">
        <div className={bem('checkout', 'section')}>
          <TicketDetails />
        </div>
      </div>
      <div className="cell">
        <div className={bem('checkout', 'section')}>
          <ContactDetails />
        </div>
      </div>
    </>
  )
}

export default ExpressFields
