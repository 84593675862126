import React, { ReactElement, useState } from 'react'
import { UseQueryResult } from 'react-query'

import { DiscountCardsResponse } from '@api/discountCards'
import PassengersTabs from '@components/PassengersDropdown/PassengersTabs'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { Button, Modal } from '@ui'

import '@components/PassengersDropdown/PassengersModal/index.scss'

interface PassengersModalProps {
  opened: boolean
  onClose: () => void
  value: Passenger.Param[]
  onChange: (value: Passenger.Param[]) => void
  availableTypes?: Passenger.Type[] | null
  getMaxCount?: ((totalCount: number) => number) | null
  discounts?: UseQueryResult<DiscountCardsResponse>
  showDiscounts?: boolean
  showAge?: boolean
}

const PassengersModal = (props: PassengersModalProps): ReactElement => {
  const { t } = useTranslation()
  const { opened, onClose, value, onChange, availableTypes, getMaxCount, discounts, showDiscounts, showAge } = props
  const [passengersState, setPassengersState] = useState<Passenger.Param[]>(value)

  const handleModalClose = (): void => {
    setPassengersState(value)
    onClose()
  }

  const submitPassengers = (): void => {
    onChange(passengersState)
    onClose()
  }

  return (
    <Modal
      fullScreen
      className={bem('passenger-dropdown', 'modal')}
      opened={opened}
      onClose={handleModalClose}
      title={t('searchBar.passengers.label')}
      footer={<Button onClick={submitPassengers}>{t('searchBar.passengers.confirm')}</Button>}
    >
      <PassengersTabs
        value={passengersState}
        onChange={setPassengersState}
        availableTypes={availableTypes}
        getMaxCount={getMaxCount}
        discounts={discounts}
        showDiscounts={showDiscounts}
        showAge={showAge}
      />
    </Modal>
  )
}

export default PassengersModal
