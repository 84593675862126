import cn from 'classnames'
import React, { ReactElement } from 'react'
import Highlighter from 'react-highlight-words'

import bem from '@lib/bem'
import locationUtils from '@lib/location'
import { Icon } from '@ui'

const optionClassName = (active: boolean): string => bem('location-autocomplete', 'item', { active })

const getIconName = (value: LocationType): string => {
  switch (value) {
    case 'city':
      return 'city'
    default:
      return 'map-pin'
  }
}

interface OptionProps {
  item: Location.Suggestion
  highlight: string
  active: boolean
}

const Option = ({ item, highlight, active }: OptionProps): ReactElement => {
  const text = locationUtils.makeDisplayName(item)

  return (
    <div className={cn(optionClassName(active), 'row', 'items-center')}>
      <Icon name={getIconName(item.type)} size="medium" />
      <Highlighter
        highlightStyle={{ fontWeight: 600, background: 'none' }}
        searchWords={[highlight]}
        textToHighlight={text}
        autoEscape
      />
    </div>
  )
}

export default Option
