import { Field, useFormikContext } from 'formik'
import React, { ReactElement, useMemo } from 'react'

import CountryField from '@components/CountryField'
import Loader from '@components/Loader'
import StateField from '@components/StateField'
import useValidators from '@hooks/useValidators'
import { useTranslation } from '@lib/i18n'
import { useBillingAddressLoadLoader } from '@loaders/billingAddress'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'
import InputField from '@ui/Input/Field'

const COUNTRIES_WITH_STATES = ['US', 'CA']

const Index = (): ReactElement => {
  const { t } = useTranslation()
  const [{ billingAddress }] = useSettings()
  const [{ retailerPartnerNumber }] = useParams()
  const { required, zipcode, combine } = useValidators()
  const { values, setFieldValue } = useFormikContext<CheckoutFormData>()
  const { countryCode } = values
  const hasState = useMemo(() => COUNTRIES_WITH_STATES.includes(String(countryCode)), [countryCode])

  const { isLoading } = useBillingAddressLoadLoader(
    {
      retailerPartnerNumber: String(retailerPartnerNumber),
      enabled: billingAddress.enabled,
    },
    {
      onSuccess: ({ street, city, postalCode, country, state }) => {
        setFieldValue('streetAndNumber', street)
        setFieldValue('zipCode', postalCode)
        setFieldValue('countryCode', country)
        setFieldValue('state', state)
        setFieldValue('city', city)
      },
    },
  )

  return (
    <Loader loading={isLoading}>
      <div className="column gap-3">
        <strong className="cell-12">{t('checkout.billing.title')}</strong>
        <div className="cell-12">
          <Field
            validate={required}
            component={InputField}
            name="streetAndNumber"
            label={t('checkout.billing.street')}
            required
          />
        </div>
        <div className="cell-12">
          <Field validate={required} component={InputField} name="city" label={t('checkout.billing.city')} required />
        </div>
        <div className="cell-12">
          <CountryField validate={required} name="countryCode" label={t('checkout.billing.country')} required />
        </div>
        {hasState && (
          <div className="cell-12">
            <StateField
              validate={required}
              countryCode={values.countryCode}
              name="state"
              label={t('checkout.billing.state')}
            />
          </div>
        )}
        <div className="cell-12">
          <Field
            validate={combine([required, zipcode(countryCode)])}
            component={InputField}
            name="zipCode"
            label={t('checkout.billing.zipCode')}
            required
          />
        </div>
      </div>
    </Loader>
  )
}

export default Index
