import { buildUrl } from '@lib/api'

const getMarketingCarrierLogo = (code: string): string => {
  return buildUrl(
    {
      type: 'marketingCarriers/logo',
      old: `/carriers/${code}/logo`,
      new: `/marketing_carriers/${code}/logo`,
    },
    {},
    {},
  )
}

export default {
  getMarketingCarrierLogo,
}
