import React, { ReactElement } from 'react'

import { MediaCarousel, Modal } from '@ui'
import Item from '@ui/MediaCarousel/Item'

import '@components/MediaCollection/index.scss'

interface CollectionProps {
  media: string[]
  opened: boolean
  onClick: () => void
}

const Collection = ({ media, opened, onClick }: CollectionProps): ReactElement => {
  const handleClick = (e?: React.MouseEvent<HTMLButtonElement | HTMLDivElement>): void => {
    e?.stopPropagation()
    onClick()
  }

  return (
    <>
      <div className="collection row">
        <div className="collection-content row center items-center">
          {media[0] && <Item url={media[0]} config={{ video: { controls: false } }} />}
          <div className="collection-content-overlay row center items-center">
            {media.length > 1 && <span className="collection-content-count">{`+${media.length - 1}`}</span>}
          </div>
        </div>
      </div>
      <Modal className="collection-modal" opened={opened} onClose={handleClick} title>
        <MediaCarousel media={media} />
      </Modal>
    </>
  )
}

export default Collection
