import React, { ComponentType, ReactElement, useRef, useState } from 'react'

import MenuItem from '@components/Header/Item'
import ManageBookingButton from '@components/Header/ManageBookingButton'
import TrackMyTripButton from '@components/Header/TrackMyTripButton'
import CurrencyPage from '@components/SidebarMenu/pages/Currency'
import LocalePage from '@components/SidebarMenu/pages/Locale'
import bem from '@lib/bem'
import currencyUtils from '@lib/currency'
import { useTranslation } from '@lib/i18n'
import localeUtils from '@lib/locale'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'
import { Divider, Icon } from '@ui'

import '@components/SidebarMenu/index.scss'

type SidebarPage = 'locale' | 'currency'
interface ClosablePage {
  onClose: () => void
}

const getPageComponent = (page: SidebarPage | null): ComponentType<ClosablePage> | null => {
  switch (page) {
    case 'locale':
      return LocalePage
    case 'currency':
      return CurrencyPage
    default:
      return null
  }
}

interface SidebarMenuProps {
  onClose: () => void
  opened: boolean
  showCurrency: boolean
  showLocale: boolean
  showManageBookingsLink: boolean
}

const SidebarMenu = ({
  opened,
  onClose,
  showCurrency,
  showLocale,
  showManageBookingsLink,
}: SidebarMenuProps): ReactElement => {
  const [page, setPage] = useState<SidebarPage | null>(null)
  const [{ currency, locale, mode }] = useParams()
  const [showPage, setShowPage] = useState<boolean>(!!page)
  const { t } = useTranslation()
  const pageRef = useRef<HTMLDivElement>(null)
  const PageComponent = getPageComponent(page)
  const [{ menu, trackTrip }] = useSettings()

  const localeFlag = localeUtils.getFlagImage(locale)
  const currencySymbol = currencyUtils.getSymbol(currency)

  const closePage = (): void => {
    setShowPage(false)
    /* istanbul ignore next */
    pageRef.current?.addEventListener(
      'animationend',
      () => {
        setPage(null)
      },
      { once: true },
    )
  }

  const openPage = (name: SidebarPage): void => {
    setShowPage(true)
    setPage(name)
  }

  const classNames = bem('sidebar-menu', { opened, closed: !opened })
  const rootClassNames = bem('sidebar-menu', 'root', { hidden: showPage })
  const pageClassNames = bem('sidebar-menu', 'page', { hidden: !showPage })

  return (
    <div className={classNames} id="sidebar">
      <div className={rootClassNames}>
        <div className="sidebar-menu__close-button">
          <Icon name="cross" size="large" onClick={onClose} />
        </div>
        {mode === 'page' && (
          <>
            {showLocale && (
              <div
                className="sidebar-menu__item"
                onClick={() => {
                  openPage('locale')
                }}
              >
                <div className="item__left">{t('header.localeSelectLabel')}</div>
                <div className="item__right">
                  <img src={localeFlag} alt={locale} />
                  <Icon name="chevron-right" size="medium" />
                </div>
              </div>
            )}
            {showCurrency && (
              <div
                className="sidebar-menu__item"
                onClick={() => {
                  openPage('currency')
                }}
              >
                <div className="item__left">{t('header.currencySelectLabel')}</div>
                <div className="item__right">
                  <span className="right__text">{currencySymbol}</span>
                  <Icon name="chevron-right" size="medium" />
                </div>
              </div>
            )}
            <Divider />
            <div className="sidebar-menu__controls column gap-5 p-2 pl-4 re-4">
              {trackTrip.enabled && opened && <TrackMyTripButton />}
              {menu.items.map(item => (
                <div className="cell header-menu" key={item.translationKey}>
                  <MenuItem {...item} onLinkClick={onClose} level={0} />
                </div>
              ))}
              {showManageBookingsLink && <ManageBookingButton />}
            </div>
          </>
        )}
      </div>
      <div className={pageClassNames} ref={pageRef}>
        {PageComponent && <PageComponent onClose={closePage} />}
      </div>
    </div>
  )
}

export default SidebarMenu
