import date from '@lib/date'
import fareFeature from '@lib/fareFeature'
import { t } from '@lib/i18n'

export const getRefundableMessage = (fareFeatures: FareFeature): string | null => {
  switch (true) {
    case fareFeature.isFullyRefundable(fareFeatures):
      return t('checkout.tripDetails.refundable.full')
    case fareFeature.isPartiallyRefundable(fareFeatures):
      return t('checkout.tripDetails.refundable.partial')
    case fareFeature.isNotRefundable(fareFeatures):
      return t('checkout.tripDetails.refundable.not')
    case fareFeature.isVoucherRefund(fareFeatures):
      return t('checkout.tripDetails.refundable.voucher')
    case fareFeature.isAmendable(fareFeatures):
      return t('checkout.tripDetails.amendable.full')
    case fareFeature.isNonAmendable(fareFeatures):
      return t('checkout.tripDetails.amendable.not')
  }

  /* istanbul ignore next */
  return null
}

export const getAvailableMessage = (fareClass?: FareClass, type?: ConnectionType): string | null => {
  const rule = fareClass?.ticketValidityRules?.find(r => r.ruleType === type)

  if (rule == null) return null
  if (!rule.duration) return null

  const duration = date.formatDuration({ [rule.durationType]: rule.duration })

  return t('checkout.tripDetails.available', { duration })
}

export const getValidity = (carriers: MarketingCarrier[], fareClass?: string, type?: ConnectionType): string | null => {
  const messages = carriers.map(carrier => {
    const fare = carrier.fareClasses.find(item => item.code === fareClass)

    return getAvailableMessage(fare, type)
  })

  return [...new Set(messages)].length === 1 ? messages[0] : null
}
