import { useFormikContext } from 'formik'
import { useEffect } from 'react'

import nethone from '@lib/nethone'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useSettings } from '@queries/settings'

const NethoneLoader = (): null => {
  const [
    {
      nethone: { enabled },
    },
  ] = useSettings()
  const {
    values: { paymentAttemptReference },
  } = useFormikContext<CheckoutFormData>()

  useEffect(() => {
    if (enabled) void nethone.init(paymentAttemptReference)
  }, [enabled, paymentAttemptReference])

  return null
}

export default NethoneLoader
