import { createContext, RefObject, UIEvent } from 'react'

import { Observable } from '@lib/observable'

interface ScrollContext {
  scrollObservable?: Observable<UIEvent>
  scrollRef?: RefObject<HTMLElement>
}

const ScrollContext = createContext<ScrollContext>({})

export default ScrollContext
