import { useMemo } from 'react'

export interface FormBlockData {
  theme: PageBlocks.FormTheme
  attachments: File[]

  ticketNumber: string | null
  tripDate: Date | null
  tripTime: string | null

  lostObject: string | null
  lostBrand: string | null
  lostModel?: string | null
  lostColor: string | null

  firstName: string | null
  lastName: string | null
  email: string | null
  phone: string | null

  termAccepted: boolean
  message: string | null
}

export const useInitialFormValues = (themes: PageBlocks.FormTheme[]): FormBlockData => {
  return useMemo(
    () => ({
      theme: themes[0],
      attachments: [],
      lostObject: null,
      lostBrand: null,
      lostModel: null,
      lostColor: null,
      ticketNumber: null,
      tripDate: null,
      tripTime: null,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      termAccepted: false,
      message: null,
    }),
    [themes],
  )
}
