import React, { ReactElement } from 'react'

import { Skeleton } from '@ui'

const SearchBarSkeleton = (): ReactElement => {
  return (
    <div className="page-skeleton__section page-skeleton__search-bar column space-between">
      <div className="cell-lg-2 mb-3">
        <Skeleton.Item height={24} />
      </div>
      <div className="row space-between">
        <div className="cell cell-lg-5">
          <Skeleton.Item height={56} />
        </div>
        <div className="cell-lg-2">
          <Skeleton.Item height={56} />
        </div>
      </div>
    </div>
  )
}

export default SearchBarSkeleton
