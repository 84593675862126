import cn from 'classnames'
import React, { ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from 'react'

import bem from '@lib/bem'
import Icon from '@ui/Icon'

export type Variant = 'card' | 'flat'

import '@ui/Accordion/index.scss'

interface AccordionProps {
  title: ReactNode
  children: ReactNode
  divider?: boolean
  opened?: boolean
  variant?: Variant
}

const Accordion = (props: AccordionProps): ReactElement => {
  const { title, children, divider = true, opened, variant = 'flat' } = props
  const [open, setOpen] = useState(opened)
  const contentWrapper = useRef<HTMLDivElement>(null)
  const content = useRef<HTMLDivElement>()
  const onContentLoaded = (element: HTMLDivElement): void => {
    content.current = element
    /* istanbul ignore next: standard null check */
    if (!contentWrapper.current) return

    if (element != null) {
      contentWrapper.current.style.minHeight = `${element.offsetHeight}px`
      observer.observe(content.current)
    } else {
      contentWrapper.current.style.minHeight = '0px'
    }
  }

  const observer = useMemo(
    () =>
      new ResizeObserver(entries => {
        /* istanbul ignore next: standard null check */
        if (!contentWrapper.current) return

        const height = (entries[0].target as HTMLElement).offsetHeight

        contentWrapper.current.style.height = `${height}px`
        contentWrapper.current.style.minHeight = `${height}px`
      }),
    [],
  )

  useEffect(() => {
    return () => {
      observer.disconnect()
    }
  }, [observer])

  useEffect(() => {
    const transitionCallback = () => {
      /* istanbul ignore next: standard null check */
      if (!contentWrapper.current) return
      contentWrapper.current.style.flexBasis = content.current ? 'auto' : /* istanbul ignore next */ '0px'
    }

    contentWrapper.current?.addEventListener('transitionend', transitionCallback)
  }, [])

  return (
    <div className={cn('column', bem('ui-accordion', { [variant]: true }))}>
      <div
        className="cell ui-accordion__header"
        onClick={() => {
          setOpen(open => !open)
        }}
      >
        <div className="row">
          <h4 className="cell mb-0">{title}</h4>
          <div className={cn('cell no-grow ui-accordion__expand', { open })}>
            <Icon name="chevron-down" size="medium" />
          </div>
        </div>
      </div>
      <div className="cell ui-accordion__content-wrapper" ref={contentWrapper}>
        {open && (
          <div className="ui-accordion__content" ref={onContentLoaded}>
            {children}
          </div>
        )}
      </div>
      {divider && variant === 'flat' && <hr />}
    </div>
  )
}

export default Accordion
