import React, { ReactElement, useEffect, useMemo } from 'react'

import InlineFilters, { InlineFiltersProps } from '@components/ConnectionFilters/InlineFilters'
import { useTranslation } from '@lib/i18n'
import { useConnectionFilters } from '@stores/connectionFilters'
import { Modal, Button } from '@ui'

export interface ModalFiltersProps extends InlineFiltersProps {
  foundConnectionsCount: number
  opened: boolean
  onClose: () => void
}

const ModalFilters = (props: ModalFiltersProps): ReactElement => {
  const { t } = useTranslation()
  const { connections, foundConnectionsCount, tripDirection, opened, onClose, options } = props
  const [filters, setFilters, { reset: resetFilters }] = useConnectionFilters()

  // On component unmount
  useEffect(() => onClose, [onClose])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const originalFilters = useMemo(() => filters, [opened])

  const closeWithStateRestoration = (): void => {
    setFilters(originalFilters)
    onClose()
  }

  const buttons = useMemo(() => {
    return (
      <div className="connection-filters__buttons row gap-2">
        <div className="cell-5">
          <Button variant="outline" color="secondary" onClick={() => resetFilters()}>
            {t('journeyList.filters.clearAllFilters')}
          </Button>
        </div>
        <div className="cell-7">
          <Button variant="filled" color="primary" onClick={onClose}>
            {`${t('journeyList.filters.showResults')}: ${foundConnectionsCount}`}
          </Button>
        </div>
      </div>
    )
  }, [foundConnectionsCount, onClose, resetFilters, t])

  return (
    <Modal
      onClose={closeWithStateRestoration}
      opened={opened}
      title={t('journeyList.filters.title')}
      fullScreen
      footer={buttons}
    >
      <InlineFilters tripDirection={tripDirection} connections={connections} options={options} />
    </Modal>
  )
}

export default ModalFilters
