import { CardsRequest, CardsResponse, cards } from '@api/retailers'
import { makeLoader } from '@lib/loader'

interface UseSavedCardsHook extends CardsRequest {
  enabled: boolean
}

export const useSavedCards = makeLoader<UseSavedCardsHook, CardsResponse>({
  key: 'saved-cards',
  enabled: ({ enabled }) => enabled,
  loader: async params => await cards.load(params),
})
