import { UpdatedMediaData } from '@queries/media'

export type SegmentedData = Pick<Connection, 'segments'>

const getCarrierCodes = (connection: SegmentedData | null): string[] =>
  connection?.segments.map(({ marketingCarrier }) => marketingCarrier.code) ?? []

const getCarriers = (connection: SegmentedData | null, carriers: MarketingCarrier[]): MarketingCarrier[] =>
  carriers.filter(c => [...new Set(getCarrierCodes(connection))].includes(c.code))

const getCarrierMedia = (media: UpdatedMediaData[], carrierCodes: string[]): UpdatedMediaData[] =>
  media.filter(item => carrierCodes.includes(item.marketingCarrierCode))

export default {
  getCarrierCodes,
  getCarriers,
  getCarrierMedia,
}
