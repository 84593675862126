/* istanbul ignore next */
const init = ({ script }: Analytics.Settings['abtasty']): void => {
  if (!script) return
  const scriptElement = document.createElement('script')

  scriptElement.src = script

  document.head.appendChild(scriptElement)
}

export default {
  init,
}
