export class AssertionError extends Error {}

const check = (condition: boolean, message: string): void => {
  if (!condition) throw new AssertionError(message)
}

const assert = {
  notNull: (value: any, message: string): void => check(value != null, message),
  positive: (value: any, message: string): void => check(value > 0, message),
  includes: <T>(value: T, array: T[], message: string): void => check(array.includes(value), message),
}

export default assert
