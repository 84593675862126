import fareClassAPI, { FareClassesRequest, FareClassesResponse } from '@api/fareClasses'
import { makeLoader } from '@lib/loader'

interface UseFareClassesLoaderProps {
  params: FareClassesRequest
  enabled: boolean
}

export const useFareClassesLoader = makeLoader<UseFareClassesLoaderProps, FareClassesResponse>({
  key: 'fareClasses',
  enabled: ({ params, enabled }) => !!params.marketingCarrierCode && !!enabled,
  loader: async ({ params }) => await fareClassAPI.load(params),
})
