import makeApi from '@lib/api'

export type SeatsResponse = Seat.Data[]

export interface SeatsRequest {
  marketingCarrierCode: string
  arrivalStation?: string
  departureStation?: string
  departureTime: string | null
  arrivalTime: string | null
  locale: string
  currency: string
  extraFields?: string
}

export default {
  load: makeApi.get<SeatsRequest, SeatsResponse>({ type: 'seats', old: 'bookings/seats', new: '/seats' }),
}
