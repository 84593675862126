import React, { ReactElement } from 'react'

import safeBrowsing from '@images/common/googleSafeBrowsing.svg'
import securePurchase from '@images/common/securePurchase.svg'
import bem from '@lib/bem'

const Security = (): ReactElement => {
  return (
    <div className="row gap-4 page-footer__security">
      <img src={safeBrowsing} alt="safe browsing" className={bem('security', 'icon')} />
      <img src={securePurchase} alt="secure purchase" className={bem('security', 'icon')} />
    </div>
  )
}

export default Security
