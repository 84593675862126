import React, { ReactElement } from 'react'

import Connection from '@components/Connection'
import bem from '@lib/bem'

import '@components/TripTimePreview/index.scss'

interface LineData {
  line?: string
  linePrefix?: string
}

interface BodyProps {
  lineData?: LineData
  fareSelection: boolean
  departureTime: string
  arrivalTime: string
  departureStation: string
  arrivalStation: string
  departureTimezone?: string
  arrivalTimezone?: string
}

const TripTimePreview = (props: BodyProps): ReactElement => {
  const {
    lineData,
    fareSelection,
    departureTime,
    arrivalTime,
    departureStation,
    arrivalStation,
    departureTimezone,
    arrivalTimezone,
  } = props

  return (
    <div className={bem('journey-card', 'body')}>
      <div className={bem('journey-card', 'body-wrapper', { fares: fareSelection })}>
        <div className="row space-between items-center">
          <span className={bem('journey-card', 'body-time')}>
            <Connection.DepartureTime date={departureTime} />
          </span>
          <span className={bem('journey-card', 'body-duration')}>
            <div className={bem('journey-card', 'body-time-decoration', { fares: fareSelection })} />
            <div className={bem('journey-card', 'body-duration-time')}>
              <Connection.Duration
                departureTime={departureTime}
                arrivalTime={arrivalTime}
                departureTimezone={departureTimezone}
                arrivalTimezone={arrivalTimezone}
              />
            </div>
            <div className={bem('journey-card', 'body-time-decoration', { fares: fareSelection })} />
          </span>
        </div>
        <div className={bem('journey-card', 'body-location')} data-tag="departure-station">
          {departureStation}
        </div>
      </div>
      <div className="column">
        <span className="row">
          <span className={bem('journey-card', 'body-time')}>
            <Connection.ArrivalTime date={arrivalTime} />
          </span>
          <span className={bem('journey-card', 'body-time-difference')}>
            <Connection.DayShift departureTime={departureTime} arrivalTime={arrivalTime} />
          </span>
        </span>
        <div className={bem('journey-card', 'body-location')} data-tag="arrival-station">
          {arrivalStation}
        </div>
      </div>
      {lineData && (
        <div className={bem('journey-card', 'body-line')}>
          <div>{lineData.line}</div>
          <div>{lineData.linePrefix}</div>
        </div>
      )}
    </div>
  )
}

export default TripTimePreview
