import cn from 'classnames'
import React from 'react'

import bem from '@lib/bem'
import { Icon } from '@ui'

import '@components/BackButton/index.scss'

interface BackButtonProps {
  title: string
  className?: string
  onClick?: () => void
}

const BackButton = ({ title, className, onClick }: BackButtonProps) => {
  const handleNavigate = (): void => {
    onClick?.()

    if (history.length === 1 && document.referrer) {
      window.location.href = document.referrer
    } else {
      history.back()
    }
  }

  return (
    <button onClick={handleNavigate} className={cn('back-button', className)}>
      <Icon name="back-arrow" size="small" />
      <span className={bem('back-button', 'title')}>{title}</span>
    </button>
  )
}

export default BackButton
