import React, { ReactNode, useMemo } from 'react'

import BlockSection from '@components/Blocks/common/BlockSection'
import { useTranslation } from '@lib/i18n'
import { Accordion } from '@ui'

interface Item {
  title: string
  description: string
  content: ReactNode
}

interface AccordionTranslations {
  items: Item[]
}

type Props = PageBlocks.BlockComponentProps<PageBlocks.AccordionBlock>

const AccordionBlock = (props: Props) => {
  const { t } = useTranslation()
  const { translationKey, accordionVariant, ...rest } = props
  const data = useMemo<AccordionTranslations>(
    () =>
      t(`customBlocks.${translationKey}`, {
        returnObjects: true,
        postProcess: ['parseHtml'],
        parseHtmlOptions: {
          keyPattern: /\.content/,
        },
      }),
    [t, translationKey],
  )
  const renderAccordionTitle = ({ title, description }: Item) => (
    <div className="column">
      <h4>{title}</h4>
      <div className="body-14">{description}</div>
    </div>
  )

  return (
    <BlockSection {...rest}>
      <div className="column gap-5">
        {data.items.map((item, index) => (
          <div className="cell" key={index}>
            <Accordion title={renderAccordionTitle(item)} variant={accordionVariant}>
              {item.content}
            </Accordion>
          </div>
        ))}
      </div>
    </BlockSection>
  )
}

export default AccordionBlock
