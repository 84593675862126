import { Field } from 'formik'
import React, { ReactElement } from 'react'

import useValidators from '@hooks/useValidators'
import { useTranslation } from '@lib/i18n'
import InputField from '@ui/Input/Field'

interface PassengerNameProps {
  name: string
}

const Name = ({ name }: PassengerNameProps): ReactElement => {
  const { t } = useTranslation()
  const { required } = useValidators()

  return (
    <>
      <Field
        component={InputField}
        label={t('checkout.passengerDetails.firstName')}
        name={`${name}.firstName`}
        validate={required}
        required
      />
      <Field
        component={InputField}
        label={t('checkout.passengerDetails.lastName')}
        name={`${name}.lastName`}
        validate={required}
        required
      />
    </>
  )
}

export default Name
