import makeApi, { ResponsePostprocessing } from '@lib/api'

export interface CarrierRequest {
  currency: Currency
  locale: Locale
}

export interface CarrierConfigRequest {
  discounts?: string[]
}

export type CarrierResponse = MarketingCarrier

interface SettingSwitch {
  enabled: boolean
}

interface CarrierLogoSetting {
  url: string
}

interface CarrierCPFSetting {
  perPassenger?: boolean
}

export interface CarrierConfigResponse {
  googleWallet: SettingSwitch
  boardingByQrCode: SettingSwitch
  phone: SettingSwitch
  birthday: SettingSwitch
  governmentId: SettingSwitch
  logo: CarrierLogoSetting
  cpf: CarrierCPFSetting
}

const config: ResponsePostprocessing = {
  convertValues: {
    bookingFee: 'Price',
    cancellationFee: 'Price',
  },
}

export default {
  load: async (code: string, params: CarrierRequest) => {
    const callApi = makeApi.get<CarrierRequest, CarrierResponse>(
      { type: 'marketingCarriers/find', new: `/marketing_carriers/${code}` },
      {
        responsePostprocessing: config,
      },
    )

    return await callApi(params)
  },
  config: async (code: string, params: CarrierConfigRequest) =>
    makeApi.get<CarrierConfigRequest, CarrierConfigResponse>(`/carrier/${code}/configuration`)(params),
}
