import { isSameDay, subHours, parse, isAfter } from 'date-fns'
import React, { ReactElement, useMemo } from 'react'

import useSearchParams from '@hooks/useSearchParams'
import date from '@lib/date'
import { useTranslation } from '@lib/i18n'
import { useParams } from '@stores/params'
import { Button } from '@ui'

import '@components/LoadConnectionsButton/index.scss'

interface LoadConnectionsButtonProps {
  loading: boolean
}

const CONNECTIONS_PERIOD_HOURS = 2

const LoadConnectionsButton = ({ loading }: LoadConnectionsButtonProps): ReactElement => {
  const { t } = useTranslation()
  const [{ departureDate }] = useParams()
  const [queryParams, setSearchParams] = useSearchParams()

  const reachedStartOfDay = useMemo(() => {
    const isSameDate = isSameDay(date.parse(departureDate), date.today())
    const current = parse(queryParams.departureTime, 'HH:mm', new Date())

    return isSameDate ? isAfter(date.today(), current) : queryParams.departureTime === date.startOfDay()
  }, [departureDate, queryParams.departureTime])

  const text = reachedStartOfDay ? t('loadConnections.noMoreEarlierTrips') : t('loadConnections.loadEarlierResults')

  const extendDepartureTime = (): void => {
    const currentDate = parse(queryParams.departureTime, 'HH:mm', new Date())
    const newDate = subHours(currentDate, CONNECTIONS_PERIOD_HOURS)
    const departureTime = isSameDay(currentDate, newDate) ? date.formatTime(newDate) : date.startOfDay()

    setSearchParams(current => ({ ...current, departureTime }))
  }

  return (
    <div className="load-connections-button mb-3 w-100">
      <Button variant="outline" onClick={extendDepartureTime} disabled={loading || reachedStartOfDay}>
        {text}
      </Button>
    </div>
  )
}

export default LoadConnectionsButton
