import React, { useMemo } from 'react'

import CheckboxFilter from '@lib/connections/filters/components/CheckboxFilter'
import { FilterConfig, FilterFunction, FilterRenderProps } from '@lib/connections/filters/types'
import utils from '@lib/utils'
import { CheckboxOption } from '@ui/CheckboxGroup'

const getCarriers = (connection: Connection[]): MarketingCarrier[] =>
  connection.flatMap(c => c.segments.map(({ marketingCarrier }) => marketingCarrier))

const filter: FilterFunction = (connection, value) => {
  const carriers = utils.array.uniqueBy(getCarriers([connection]), 'id')

  return carriers.some(({ code }) => value.includes(code))
}
const getCarrierOption = ({ code, tradeName }: MarketingCarrier): CheckboxOption<string> => ({
  value: code,
  label: tradeName,
})

const FilterComponent = ({ value, onChange, data, setVisibility }: FilterRenderProps) => {
  const options = useMemo(() => utils.array.uniqueBy(getCarriers(data), 'id').map(getCarrierOption), [data])

  return <CheckboxFilter value={value} onChange={onChange} options={options} setVisibility={setVisibility} />
}

const carriersFilter: FilterConfig = {
  filter,
  title: 'carriers',
  Component: FilterComponent,
}

export default carriersFilter
