import React, { useMemo } from 'react'

import PayWithCalculator from '@pages/Checkout/hooks/Payment/Common/components/PayWithCalculator'
import useDelayedPayment from '@pages/Checkout/hooks/Payment/Common/useDelayedPayment'
import { PaymentMethod } from '@pages/Checkout/hooks/Payment/useBookingPayment'
import { useSettings } from '@queries/settings'

const useCash = (): PaymentMethod => {
  const [{ changeCalculator }] = useSettings()
  const delayedProps = useDelayedPayment({ type: 'cash' })

  const getPayButton = useMemo(
    () => (changeCalculator.enabled ? () => <PayWithCalculator /> : undefined),
    [changeCalculator.enabled],
  )

  return useMemo(() => ({ ...delayedProps, getPayButton }), [delayedProps, getPayButton])
}

export default useCash
