import { createStore, useStore, UseStoreHook } from '@lib/store'

export interface ConnectionsStore {
  outbounds: Connection[]
  inbounds: Connection[]
}

const store = createStore<ConnectionsStore>({
  inbounds: [],
  outbounds: [],
})

export const useConnections = (): UseStoreHook<ConnectionsStore> => useStore(store)

export default store
