import React, { ReactElement } from 'react'

import { useTranslation } from '@lib/i18n'
import { Button } from '@ui'

interface FooterProps {
  onClick: () => void
  onClose: () => void
}

const Footer = ({ onClick, onClose }: FooterProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <div className="extras__card-modal-buttons row gap-3">
      <Button variant="outline" color="secondary" onClick={onClose}>
        {t('extras.ancillary.cancel')}
      </Button>
      <Button onClick={onClick}>{t('extras.ancillary.apply')}</Button>
    </div>
  )
}

export default Footer
