import React, { ReactElement } from 'react'

import { Skeleton } from '@ui'

const TripBreakdownSkeleton = (): ReactElement => {
  return (
    <div className="column">
      <div className="cell-12 pb-3">
        <Skeleton.Item height={32} width={72} />
      </div>
      <div className="cell-12">
        <Skeleton.Item height={116} />
      </div>
    </div>
  )
}

export default TripBreakdownSkeleton
