import React, { ReactElement } from 'react'

import bem from '@lib/bem'
import { Trans } from '@lib/i18n'
import { Icon } from '@ui'

import '@components/JourneyList/Emission/Details.scss'

interface DetailsProps {
  typicalEmission: number
  currentEmission: number
  savingPercentage: number
  onClick?: () => void
}

const Details = ({ typicalEmission, currentEmission, savingPercentage, onClick }: DetailsProps): ReactElement => {
  const enhance = <span className={bem('journey-list', 'emission', { enhance: true })} />

  return (
    <div className="column journey-list__emission-details">
      <div className="row space-between journey-list__emission-details-header">
        <span>
          <Trans i18nKey="journeyList.emission.header" components={{ enhance, sub: <sub />, b: <b /> }} />
        </span>
        {onClick && (
          <span>
            <Icon name="cross" size="large" onClick={onClick} />
          </span>
        )}
      </div>
      <div className="column gap-1 journey-list__emission-details-body">
        <div className="row space-between">
          <span>
            <Trans i18nKey="journeyList.emission.typicalTrip" />
          </span>
          <span>
            <Trans i18nKey="journeyList.emission.value" values={{ value: typicalEmission }} />
          </span>
        </div>
        <div className="row space-between">
          <span>
            <Trans i18nKey="journeyList.emission.thisTrip" />
          </span>
          <span>
            <Trans i18nKey="journeyList.emission.value" values={{ value: currentEmission }} />
          </span>
        </div>
        <hr />
        <div className="row space-between">
          <span>
            <b>
              <Trans
                i18nKey="journeyList.emission.savings"
                values={{ percentage: `${savingPercentage}%` }}
                components={{ enhance }}
              />
            </b>
          </span>
          <span>
            <b>
              <Trans i18nKey="journeyList.emission.value" values={{ value: `-${typicalEmission - currentEmission}` }} />
            </b>
          </span>
        </div>
      </div>
      <div className="row journey-list__emission-details-footer">
        <Trans i18nKey="journeyList.emission.info" />
      </div>
    </div>
  )
}

export default Details
