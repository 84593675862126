import React, { useMemo } from 'react'

import { FilterConfig, FilterFunction, FilterRenderProps } from '@lib/connections/filters/types'
import { useTranslation } from '@lib/i18n'
import CheckboxGroup from '@ui/CheckboxGroup'

const filter: FilterFunction = (connection, value) => {
  /* istanbul ignore else: unaccessible */
  if (value[0] === 'directOnly') {
    return connection.segments.length <= 1
  } else {
    return true
  }
}

const FilterComponent = ({ value, onChange }: FilterRenderProps) => {
  const { t } = useTranslation()
  const options = useMemo(() => [{ value: 'directOnly', label: t('journeyList.filters.directOnly') }], [t])

  return <CheckboxGroup value={value} onChange={onChange} options={options} />
}

const directionFilter: FilterConfig = {
  filter,
  Component: FilterComponent,
}

export default directionFilter
