import React, { ReactElement } from 'react'

import dateUtils from '@lib/date'

export interface TimeProps {
  date: Date
}

const Time = ({ date }: TimeProps): ReactElement => {
  const string = dateUtils.formatTime(date)

  return <>{string}</>
}

export default Time
