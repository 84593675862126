import { Form, Formik } from 'formik'
import React, { ReactElement, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import amendmentUtils from '@lib/amendment'
import bem from '@lib/bem'
import bookingUtils from '@lib/booking'
import { useBookingByIdLoader } from '@loaders/booking'
import Details from '@pages/Checkout/Amendment/Details'
import Fields from '@pages/Checkout/Amendment/Fields'
import CarrierLoader from '@pages/Checkout/Form/CarrierLoader'
import VacancyLoader from '@pages/Checkout/Form/VacancyLoader'
import useCarriers from '@pages/Checkout/hooks/useCarriers'
import { CheckoutFormData, useInitialFormValues } from '@pages/Checkout/hooks/useInitialFormValues'
import { useParams } from '@stores/params'

import '@pages/Checkout/Form/index.scss'

export interface AmendmentFormProps {
  booking: Booking.Data | null
  isBookingLoading: boolean
}

const FormContent = ({ booking, isBookingLoading }: AmendmentFormProps): ReactElement => {
  const { codes, handleSuccess, handleLoading, isLoading: isCarriersLoading, carriers } = useCarriers()

  const carrierLoaders = useMemo(
    () =>
      codes.map(item => (
        <CarrierLoader key={item} marketingCarrierCode={item} onLoading={handleLoading} onSuccess={handleSuccess} />
      )),
    [codes, handleLoading, handleSuccess],
  )

  return (
    <>
      {carrierLoaders}
      <VacancyLoader />
      <div className="checkout-form">
        <div className="row gap-4 start column-sm">
          <div className="cell-8 cell-sm-12">
            <div className="column gap-4">
              <Fields />
            </div>
          </div>
          <div className="cell-4 cell-sm-12">
            <div className="column gap-4 checkout-form__trip-details">
              <div className="cell">
                <div className={bem('checkout', 'section')}>
                  <Details
                    isCarrierLoading={isCarriersLoading}
                    isBookingLoading={isBookingLoading}
                    carriers={carriers}
                    totalPrice={booking?.booking.totalPrice}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const AmendmentForm = (): ReactElement => {
  const initialValues = useInitialFormValues()
  const [params] = useParams()
  const { search } = useLocation()
  const navigate = useNavigate()

  const { data, isLoading } = useBookingByIdLoader({
    bookingId: params.bookingId as string,
    params: { locale: params.locale, retailerPartnerNumber: params.retailerPartnerNumber },
  })

  const passengers = useMemo(
    () => bookingUtils.getSegmentPassengers(data?.outboundConnection).map(p => ({ ...p, pax: 1 })),
    [data],
  )

  const submitForm = (formData: CheckoutFormData): void => {
    amendmentUtils.setSessionStorage(params.bookingId as string, { ...formData, passengers })
    data && navigate(`/booking/${data.booking.id}/payment${search}`)
  }

  return (
    <Formik initialValues={initialValues} onSubmit={submitForm}>
      <Form>
        <FormContent booking={data} isBookingLoading={isLoading} />
      </Form>
    </Formik>
  )
}

export default AmendmentForm
