import cn from 'classnames'
import React, { MouseEventHandler, ReactElement } from 'react'

import '@ui/Icon/index.scss'

interface Props {
  name: string
  size: 'small' | 'medium' | 'large'
  className?: string
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void
}

const Icon = ({ name, size, className, onClick, ...rest }: Props): ReactElement => {
  return (
    <span
      className={cn('font-icon', `font-icon-${name}`, className, size)}
      onClick={onClick as MouseEventHandler}
      {...rest}
    >
      <span className={cn('font-icon__content')} translate="no" data-nosnippet="true">
        {name}
      </span>
    </span>
  )
}

export default Icon
