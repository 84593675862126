import React, { ReactElement } from 'react'

import Item from '@components/TripInfo/TripConditions/Item'
import fare from '@lib/fare'
import utils from '@lib/utils'
import { getRefundableMessage } from '@pages/Checkout/BookingDetails/TripDetails/ConnectionDetails/utils'

import '@components/TripInfo/TripConditions/index.scss'

interface TripConditionsProps {
  fareFeatures: FareFeature[]
  description: boolean
  validity?: string | null
}

const TripConditions = ({ fareFeatures, validity, description }: TripConditionsProps): ReactElement => {
  return (
    <>
      <div className="row gap-2 wrap trip-conditions">
        {utils.array.uniqueBy(fareFeatures, 'code').map(item => (
          <Item key={item.code} icon={fare.getFareFeatureIcon(item.code)} title={getRefundableMessage(item)} />
        ))}
        {validity && <Item icon="clock" title={validity} />}
      </div>
      {description &&
        fareFeatures.map(({ description, code }) => (
          <p key={code} className="trip-conditions__description cell-lg-6">
            {description}
          </p>
        ))}
    </>
  )
}

export default TripConditions
