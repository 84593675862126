import React, { FocusEvent, ReactElement, ReactNode } from 'react'

import bem from '@lib/bem'

import '@ui/Checkbox/index.scss'

export interface CheckboxProps {
  children: ReactNode
  checked: boolean
  onChange?: () => void
  onBlur?: (e: FocusEvent) => void
  disabled?: boolean
  error?: boolean
}

const Checkbox = ({ children, onChange, checked, disabled, error, onBlur }: CheckboxProps): ReactElement => (
  <label className={bem('ui-checkbox', { disabled, error })}>
    <input
      className="ui-checkbox__input"
      type="checkbox"
      onChange={onChange}
      disabled={disabled}
      checked={checked}
      onBlur={onBlur}
    />
    <span className="ui-checkbox__label">{children}</span>
  </label>
)

export default Checkbox
