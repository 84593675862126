import React, { createContext, useContext, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import resolve from '@lib/resolve'

interface AppContext {
  retailerPartnerNumber: RetailerNumber
  marketingCarrierCode: CarrierCode | null
}

const AppContext = createContext<AppContext>({
  retailerPartnerNumber: 0,
  marketingCarrierCode: null,
})

type AppContextProviderProps = {
  children: React.ReactNode
  retailerPartnerNumber: RetailerNumber
  marketingCarrierCode?: CarrierCode | null
}

export const AppContextProvider = ({ children, ...value }: AppContextProviderProps) => {
  const context = {
    retailerPartnerNumber: value.retailerPartnerNumber,
    marketingCarrierCode: value.marketingCarrierCode ?? null,
  }
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>
}

export const URLAppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [searchParams] = useSearchParams()

  const rawRetailerPartnerNumber = searchParams.get('retailerPartnerNumber') ?? null
  const marketingCarrierCode = searchParams.get('marketingCarrierCode') ?? null

  const retailerPartnerNumber = useMemo(() => {
    const rpn = Number(rawRetailerPartnerNumber)

    if (rpn > 0) return rpn

    return resolve.retailerPartnerNumber() ?? 0
  }, [rawRetailerPartnerNumber])

  const context = { retailerPartnerNumber, marketingCarrierCode }

  return <AppContextProvider {...context}>{children}</AppContextProvider>
}

export const useAppContext = () => useContext(AppContext)
