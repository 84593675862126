import React, { ReactElement } from 'react'

import { VGS } from '@lib/vgs'
import VGSField from '@pages/Checkout/Payment/CreditCardForm/VGS/Field'

interface ExpDateFieldProps {
  form: VGS.Form
  name: string
  placeholder: string
  touched?: boolean
}

const ExpDateField = ({ form, name, placeholder, touched }: ExpDateFieldProps): ReactElement => {
  return (
    <VGSField
      id="vgs-exp"
      form={form}
      touched={touched}
      options={{
        name,
        placeholder,
        type: 'card-expiration-date',
        validations: ['required', 'validCardExpirationDate'],
        tokenization: { format: 'NUM_LENGTH_PRESERVING', storage: 'PERSISTENT' },
        serializers: [
          { name: 'replace', options: { old: ' ', new: '' } },
          { name: 'replace', options: { old: '/', new: '' } },
        ],
      }}
    />
  )
}

export default ExpDateField
