import React, { useMemo } from 'react'

import BlockSection from '@components/Blocks/common/BlockSection'
import FAQTabs, { FAQTabsProps } from '@components/FAQTabs'
import { useTranslation } from '@lib/i18n'

const FAQBlock = ({ translationKey, ...rest }: PageBlocks.FAQBlock['data']) => {
  const { t } = useTranslation()
  const faqData = useMemo<FAQTabsProps>(
    () =>
      t(`customBlocks.${translationKey}`, {
        returnObjects: true,
        postProcess: ['parseHtml'],
        parseHtmlOptions: {
          keyPattern: /\.\d\.content/,
        },
      }),
    [t, translationKey],
  )

  return (
    <BlockSection {...rest}>
      <div className="row items-center center">
        <FAQTabs {...faqData} />
      </div>
    </BlockSection>
  )
}

export default FAQBlock
