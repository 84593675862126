import React, { ReactElement } from 'react'

import TripInfo from '@components/TripInfo'
import bem from '@lib/bem'
import { UpdatedMediaData } from '@queries/media'

import '@components/JourneyCard/Details/DetailedInfo/index.scss'

interface DetailedInfoProps {
  connection: Connection
  displayedFareClass: string
  media?: UpdatedMediaData[]
}

const DetailedInfo = ({ connection, displayedFareClass, media }: DetailedInfoProps): ReactElement => {
  return (
    <div className={bem('journey-card', 'subfooter')} data-tag="journey-card-details">
      <TripInfo connection={connection} fareClass={displayedFareClass} media={media} />
    </div>
  )
}

export default DetailedInfo
