import { createStore, useStore, UseStoreHook } from '@lib/store'

interface ExchangeRatesStore {
  rates: ExchangeRate[]
}

const store = createStore<ExchangeRatesStore>({ rates: [] })

export const useExchangeRates = (): UseStoreHook<ExchangeRatesStore> => useStore(store)

export default store
