import cn from 'classnames'
import { addMonths, endOfMonth, isAfter, isBefore, startOfMonth, subMonths } from 'date-fns'
import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react'

import { Button, Calendar, Icon } from '@ui'

import '@ui/DatePicker/MenuDesktop/index.scss'

export interface DatePickerMenuDesktopProps {
  value: Date | null
  initialValue?: Date
  onChange: (value: Date) => void
  minDate: Date
  maxDate: Date
  onClose: () => void
  getTileText?: (date: Date) => ReactNode
  isTileDisabled?: (date: Date) => boolean
  prices?: PriceCalendar
  isPriceLoading?: boolean
}

const DatePickerMenuDesktop = ({
  initialValue,
  value,
  onChange,
  onClose,
  minDate,
  maxDate,
  ...calendarProps
}: DatePickerMenuDesktopProps): ReactElement | null => {
  const popupRef = useRef<HTMLDivElement>(null)
  const [calendarDate, setCalendarDate] = useState(new Date())

  useEffect(() => {
    setCalendarDate(value ?? initialValue ?? new Date())
  }, [value, initialValue, setCalendarDate])

  const nextPage = (): void => {
    setCalendarDate(date => addMonths(date, 2))
  }
  const previousPage = (): void => {
    setCalendarDate(date => subMonths(date, 2))
  }

  const onDateSelect = (date: Date): void => {
    onChange(date)
    onClose()
  }

  const renderCalendarHeader = (date: Date, title: string, isPrevious?: boolean): ReactNode => {
    const isNavigationDisabled = isPrevious ? isBefore(startOfMonth(date), minDate) : isAfter(endOfMonth(date), maxDate)
    const onClick = isPrevious ? previousPage : nextPage

    return (
      <div className="ui-date-picker-popup__calendar-header">
        <div className={cn('ui-date-picker-popup__navigation-button', { right: !isPrevious })}>
          <Button variant="outline" round onClick={onClick} disabled={isNavigationDisabled}>
            <Icon name={isPrevious ? 'chevron-left' : 'chevron-right'} size="large" />
          </Button>
        </div>
        {title}
      </div>
    )
  }

  return (
    <div ref={popupRef} className="ui-date-picker-popup">
      <Calendar
        calendarDate={calendarDate}
        selectedDate={value}
        onDateChange={onDateSelect}
        renderHeader={(...props) => renderCalendarHeader(...props, true)}
        {...calendarProps}
      />
      <div className="ui-date-picker-popup__divider" />
      <Calendar
        calendarDate={addMonths(calendarDate, 1)}
        selectedDate={value}
        onDateChange={onDateSelect}
        renderHeader={renderCalendarHeader}
        {...calendarProps}
      />
    </div>
  )
}

export default DatePickerMenuDesktop
