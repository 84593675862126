import { PaymentAction } from '@adyen/adyen-web/types'

import makeApi from '@lib/api'

export interface Request {
  paymentDetails: {
    details: {
      threeDSResult: string
    }
  }
}

export interface Response {
  action: BookingAction
  bookingFormId: number
  redirectPath: string
  bookingStatus: string
  details: {
    action: PaymentAction
  }
}

export const finalize = async (bookingFormId: number, body: Request) => {
  const callApi = makeApi.post<{}, Request, Response>({
    type: 'reservations',
    old: `/booking_forms/${bookingFormId}/finalization`,
    new: `/reservations/${bookingFormId}/details`,
  })

  return await callApi({ body, params: {} })
}
