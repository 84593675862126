import React, { ReactElement, useState } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import fareUtils from '@lib/fare'
import Icon from '@ui/Icon'

interface FareFeaturesProps {
  fareFeatures: FareFeature[]
  media?: boolean
}

const FareFeatures = ({ fareFeatures, media }: FareFeaturesProps): ReactElement => {
  const isMobile = useIsMobile()
  const [hide, setHide] = useState<boolean>(false)

  const handleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    const scrollLeft = e.currentTarget.scrollLeft
    const scrollWidth = e.currentTarget.scrollWidth
    const clientWidth = e.currentTarget.clientWidth
    const gap = 100

    setHide(scrollLeft + clientWidth - (hide ? gap / 2 : 0) > scrollWidth - gap)
  }

  return (
    <div
      className={bem('carriers', 'fares-feature-wrapper', { media })}
      onScroll={fareFeatures.length > 4 ? handleScroll : undefined}
      data-tag="fare-features-list"
    >
      {fareFeatures.map(item => (
        <div key={item.id} className="carriers__fares-feature cell-lg-3" data-tag="fare-feature">
          <Icon name={fareUtils.getFareFeatureIcon(item.code)} size="large" />
          <span className={bem('carriers', 'fares-description')}>{item.name}</span>
        </div>
      ))}
      {fareFeatures.length > 4 && isMobile && <div className={bem('carriers', 'fares-blur', { hide })} />}
    </div>
  )
}

export default FareFeatures
