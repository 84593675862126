import { eachHourOfInterval, endOfDay, isFuture, startOfDay, subMonths } from 'date-fns'
import { Field } from 'formik'
import React from 'react'

import useIsMobile from '@hooks/useIsMobile'
import useValidators from '@hooks/useValidators'
import date from '@lib/date'
import { useTranslation } from '@lib/i18n'
import DatePickerField from '@ui/DatePicker/Field'
import DropdownField from '@ui/Dropdown/Field'
import InputField from '@ui/Input/Field'

const dayInterval = { start: startOfDay(new Date()), end: endOfDay(new Date()) }
const hoursInterval = eachHourOfInterval(dayInterval)

const timeOptions = hoursInterval.map(hour => ({
  value: date.formatTime(hour),
  selectedLabel: date.formatTime(hour),
}))

const TicketInformation = () => {
  const { t } = useTranslation()
  const { required } = useValidators()
  const isMobile = useIsMobile()
  /* istanbul ignore next: cover in https://distribusion.atlassian.net/browse/OWL-2608 */
  return (
    <div className="row gap-3 wrap">
      <div className="cell-6 cell-sm-12">
        <Field
          component={InputField}
          label={t('customBlocksCommon.form.ticketNumber')}
          name="ticketNumber"
          validate={required}
          required
        />
      </div>
      <div className="cell-6 cell-sm-12">
        <Field
          component={DatePickerField}
          name="tripDate"
          label={t('customBlocksCommon.form.tripDate')}
          validate={required}
          minDate={subMonths(new Date(), 3)}
          maxDate={new Date()}
          isTileDisabled={(date: Date) => isFuture(date)}
          required
        />
      </div>
      {!isMobile && <div className="cell-6" />}
      <div className="cell-6 cell-sm-12">
        <Field
          component={DropdownField}
          label={t('customBlocksCommon.form.tripTime')}
          name="tripTime"
          items={timeOptions}
          validate={required}
          required
        />
      </div>
    </div>
  )
}

export default TicketInformation
