import { useFormikContext } from 'formik'
import { useEffect, useMemo, useState } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import amplitude from '@lib/analytics/amplitude'
import ancillaryUtils from '@lib/ancillary'
import passengerUtils from '@lib/passengers'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'

interface UseAncillariesProps {
  name: KnownAncillary
  outbound: Connection | null
  inbound: Connection | null
}

interface UseAncillariesHook {
  opened: boolean
  list: Ancillary.Item[]
  selected: Ancillary.Item[]
  initialValue: Ancillary.Item[]
  maxCount: number
  handleChange: (isIncrease: boolean, index: number, ancillary: Ancillary.Item, code?: string) => void
  handleToggle: () => void
  handleCancel: () => void
  handleSelect: (ancillary: Ancillary.Item) => void
}

const OFFLOADING = 'OFFLOADING'
const METRO = 'METROTEN'

const useAncillaries = ({ name, outbound, inbound }: UseAncillariesProps): UseAncillariesHook => {
  const isMobile = useIsMobile()
  const [initialAncillary, setInitialAncillary] = useState<Ancillary.Item[]>([])
  const [opened, setOpened] = useState<boolean>(!isMobile)
  const { values, setFieldValue, initialValues } = useFormikContext<CheckoutFormData>()
  const { vacancy, ancillaries, passengers } = values

  const segmentsCount = [outbound, inbound].reduce((acc, curr) => (curr ? acc + curr.segments.length : acc), 0)
  const selected = ancillaries[name]
  const initialValue = useMemo(() => initialValues.ancillaries[name], [initialValues.ancillaries, name])

  const maxCount = useMemo(
    () => ancillaryUtils.getMaxCount(passengers, passengerUtils.getPassengerCode(['infant'])),
    [passengers],
  )

  useEffect(() => {
    opened && setInitialAncillary(selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened])

  useEffect(() => {
    if (name !== 'VEHICLE') return

    const vehicle = [...ancillaries.VEHICLE]

    if (ancillaryUtils.isOffloadingOver(vehicle)) {
      vehicle.splice(
        vehicle.findIndex(item => item.code === OFFLOADING),
        1,
      )
      setFieldValue('ancillaries.VEHICLE', vehicle)
    }
  }, [ancillaries, name, setFieldValue])

  const handleChange = (isIncrease: boolean, count: number, ancillary: Ancillary.Item): void => {
    const array = [...selected]
    const currentIndex = array.find(({ passengerIndex }) => passengerIndex === count)?.passengerIndex ?? 0
    amplitude.checkout.changeAncillary(ancillary.name, count)

    if (isIncrease) {
      setFieldValue(`ancillaries.${name}`, [...array, { ...ancillary, passengerIndex: count + currentIndex }])
    } else {
      array.splice(
        selected.findIndex(item => item.code === ancillary.code),
        1,
      )
      setFieldValue(`ancillaries.${name}`, array)
    }
  }

  const handleSelect = (ancillary: Ancillary.Item): void => {
    const index = selected.findIndex(item => item.segmentIndex === ancillary.segmentIndex)

    selected.splice(index, 1, ancillary)
    setFieldValue(`ancillaries.${name}`, selected)
  }

  const handleToggle = (): void => {
    setOpened(!opened)
  }

  const handleCancel = (): void => {
    setFieldValue(`ancillaries.${name}`, initialAncillary)
    setOpened(false)
  }

  const list = useMemo(() => {
    const ancillaries = ancillaryUtils.getByCategory(name, vacancy?.ancillaries)
    const filteredAncillaries = ancillaryUtils.filterBySegment(ancillaries, segmentsCount)

    return filteredAncillaries
      .sort(
        (a, b) =>
          Number(a.code === OFFLOADING) - Number(b.code === OFFLOADING) ||
          Number(a.code === METRO) - Number(b.code === METRO),
      )
      .reduce((mem: Ancillary.Item[], curr) => {
        const index = mem.findIndex(item => item.code === curr.code)
        const multiplier = ancillaryUtils.getPriceMultiplier(curr.category, Number(!!inbound) + 1)
        const allSegmentPrice = { fractional: curr.price.fractional * multiplier, currency: curr.price.currency }
        const initialPrice = { fractional: curr.price.fractional, currency: curr.price.currency }

        if (curr.isAllSegments) return [...mem, { ...curr, price: allSegmentPrice, initialPrice }]
        if (index < 0) return [...mem, { ...curr, initialPrice }]

        const price = { fractional: mem[index].price.fractional + curr.price.fractional, currency: curr.price.currency }

        mem.splice(index, 1, { ...mem[index], price, initialPrice })

        return mem
      }, [])
  }, [vacancy, name, inbound, segmentsCount])

  return {
    opened,
    handleChange,
    handleToggle,
    handleCancel,
    handleSelect,
    list,
    selected,
    maxCount,
    initialValue,
  }
}

export default useAncillaries
