import React, { useRef } from 'react'

import utils from '@lib/utils'

interface Bounds {
  min: number
  max: number
}

interface Props {
  value: number
  sliderBounds: Bounds
  thumbBounds: Bounds
  onChange: (value: number) => void
  sliderRef: React.RefObject<HTMLDivElement>
}

type ThumbEvent = TouchEvent | MouseEvent

const isTouchEvent = (e: ThumbEvent): e is TouchEvent => {
  return 'changedTouches' in e
}

const getFingerPosition = (e: ThumbEvent): number => {
  return isTouchEvent(e) ? e.changedTouches[0].clientX : e.clientX
}

const Thumb = (props: Props) => {
  const { value, sliderBounds, thumbBounds, sliderRef, onChange } = props
  const thumbRef = useRef<HTMLDivElement>(null)

  const onThumbMove = (e: ThumbEvent) => {
    const { left, width } = sliderRef.current!.getBoundingClientRect()
    const x = getFingerPosition(e)
    const percent = (x - left) / width
    const value = sliderBounds.min + (sliderBounds.max - sliderBounds.min) * percent
    const clampedValue = utils.number.clamp(value, thumbBounds.min, thumbBounds.max)

    onChange(clampedValue)
  }

  const onThumbUp = () => {
    thumbRef.current?.blur()
    document.removeEventListener('mousemove', onThumbMove)
    document.removeEventListener('mouseup', onThumbUp)
    document.removeEventListener('touchmove', onThumbMove)
    document.removeEventListener('touchend', onThumbUp)
  }

  const onMouseDown = (e: React.MouseEvent) => {
    e.preventDefault()
    thumbRef.current?.focus()
    document.addEventListener('mousemove', onThumbMove)
    document.addEventListener('mouseup', onThumbUp)
  }

  const onTouchStart = (e: React.TouchEvent) => {
    e.preventDefault()
    thumbRef.current?.focus()
    document.addEventListener('touchmove', onThumbMove)
    document.addEventListener('touchend', onThumbUp)
  }

  return (
    <div
      ref={thumbRef}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      tabIndex={0}
      className="ui-slider__thumb"
      style={{ left: `${value}%` }}
    />
  )
}

export default Thumb
