import { differenceInMinutes } from 'date-fns'
import { useMemo, useState } from 'react'

import date from '@lib/date'
import { TimerConfig } from '@pages/Checkout/Timer'
import { useCheckout } from '@stores/checkout'

interface ExpressTimer {
  timer?: TimerConfig | null
  expired: boolean
  period: number | null
}

export const useExpressTimer = (): ExpressTimer => {
  const [expired, setExpired] = useState(false)
  const [{ outbound }] = useCheckout()

  const timer: TimerConfig | null = useMemo(
    () =>
      outbound && {
        onExpire: () => {
          setExpired(true)
        },
        expiryTimestamp: date.parse(outbound?.departureTime, 'UTC'),
      },
    [outbound],
  )
  const period = useMemo(() => {
    return timer?.expiryTimestamp
      ? differenceInMinutes(timer.expiryTimestamp, new Date())
      : /* istanbul ignore next */ null
  }, [timer?.expiryTimestamp])
  const isValidPeriod = period != null && period < 60 && period > 0

  return {
    timer: isValidPeriod ? timer : null,
    expired,
    period,
  }
}
