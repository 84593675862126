import React, { ReactElement, ReactNode } from 'react'

import utils from '@lib/utils'

import '@ui/Carousel/index.scss'

export type CarouselNavigationDirection = 'back' | 'forward'

interface CarouselProps<T> {
  renderItem: (itemConfig: T, selected: boolean, onClick: () => void) => ReactNode
  itemsConfig: T[]
  selectedItemIndex: number
  viewportLength: number
  onChange: (itemConfig: T, direction: CarouselNavigationDirection) => void
}

const Carousel = <T,>({
  renderItem,
  itemsConfig,
  selectedItemIndex,
  viewportLength,
  onChange,
}: CarouselProps<T>): ReactElement => {
  const length = Math.min(viewportLength, itemsConfig.length)
  const itemsForRender = utils.array.sliceMiddle(itemsConfig, length, selectedItemIndex)

  const onClick = (itemConfig: T, index: number): void => {
    /* istanbul ignore else */
    if (index < selectedItemIndex) {
      onChange(itemConfig, 'back')
    } else if (index > selectedItemIndex) {
      onChange(itemConfig, 'forward')
    }
  }

  return (
    <div className="carousel">
      <div className="carousel__list">
        {itemsForRender.map(config => {
          const absoluteIndex = itemsConfig.indexOf(config)

          return renderItem(config, absoluteIndex === selectedItemIndex, () => {
            onClick(config, absoluteIndex)
          })
        })}
      </div>
    </div>
  )
}

export default Carousel
