const buildBookingFee = (amount: Money): Fee => ({
  id: 'BOOKING',
  code: 'BOOKING',
  name: 'Booking Fee',
  component: 'booking',
  amount,
})

const feeUtils = {
  buildBookingFee,
}

export default feeUtils
