import makeApi from '@lib/api'

export type MediaResponse = Media.Data

export interface MediaRequest {
  marketingCarrierCode: string | null
}

export default {
  load: makeApi.get<MediaRequest, MediaResponse>('/media'),
}
