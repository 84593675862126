import React, { ReactElement, ReactNode } from 'react'

import LineSkeleton from '@components/Initializer/Skeletons/common/Line'
import SearchBarSkeleton from '@components/Initializer/Skeletons/common/SearchBar'
import { Skeleton } from '@ui'

const SearchSkeleton = (): ReactElement => {
  const card = (key: number): ReactNode => (
    <div key={key} className="cell-lg-3 cell-12">
      <div className="page-skeleton__section">
        <div className="cell-12 mb-9">
          <Skeleton.Item height={190} />
        </div>
        <div className="cell-12 p-3">
          <Skeleton.Item height={54} />
        </div>
      </div>
    </div>
  )

  return (
    <>
      <LineSkeleton height={375} />
      <div className="column page-skeleton__page page-skeleton__search">
        <SearchBarSkeleton />
        <div className="column row-lg gap-5">{new Array(4).fill(card).map((card, index) => card(index))}</div>
      </div>
    </>
  )
}

export default SearchSkeleton
