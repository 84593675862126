import React, { ReactElement, Suspense } from 'react'

import SearchResultsLayout from '@pages/SearchResult/Layout'

import '@pages/SearchResult/App.scss'

const App = (): ReactElement => {
  return (
    <Suspense>
      <SearchResultsLayout header footer trips />
    </Suspense>
  )
}

export default App
