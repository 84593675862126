import React, { Fragment, ReactElement } from 'react'

import { PaymentMethodsSetting } from '@api/settings'
import bem from '@lib/bem'
import { useSettings } from '@queries/settings'

import '@components/Footer/PaymentBrands/index.scss'

const brandsOrderMap: Record<string, keyof PaymentMethodsSetting> = {
  me: 'creditCard',
  mc: 'creditCard',
  visa: 'creditCard',
  googlePay: 'googlePay',
  applePay: 'applePay',
  pix: 'pix',
  paypal: 'paypal',
  americanExpress: 'creditCard',
}

interface BrandIconProps {
  brand: string
}

const BrandIcon = ({ brand }: BrandIconProps): ReactElement => (
  <div className="cell no-grow">
    <img
      alt={brand}
      className={bem('payment-brand', 'icon', { [brand]: true })}
      src={require(`@images/paymentBrands/footer/${brand}.svg`)}
    />
  </div>
)

const PaymentBrands = (): ReactElement => {
  const [{ paymentMethods }] = useSettings()

  return (
    <div className="row gap-3 wrap">
      {Object.entries(brandsOrderMap).map(([brand, method]) =>
        paymentMethods[method] ? <BrandIcon brand={brand} key={brand} /> : <Fragment key={brand} />,
      )}
    </div>
  )
}

export default PaymentBrands
