import { useFormikContext } from 'formik'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import config from '@config'
import useIsMobile from '@hooks/useIsMobile'
import { useTranslation } from '@lib/i18n'
import vacancy from '@lib/vacancy'
import { UpdatedFareClass } from '@pages/Checkout/FareClassesSelection'
import FareClassSkeleton from '@pages/Checkout/FareClassesSelection/FareClassSkeleton'
import Card from '@pages/Checkout/FareClassesSelection/SplitFareClasses/Card'
import Navigation from '@pages/Checkout/FareClassesSelection/SplitFareClasses/Navigation'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useParams } from '@stores/params'
import { Skeleton } from '@ui'

interface FareClassListProps {
  faresList: FareClassMap.Entity[]
  fareClassList: UpdatedFareClass[]
  travelClass?: string
  isLoading: boolean
  isReturnTrip?: boolean
  selectedFare?: Fare
}

const FareClassList = (props: FareClassListProps): ReactElement => {
  const { faresList, fareClassList, isLoading, travelClass, isReturnTrip, selectedFare } = props
  const [{ currency }] = useParams()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const {
    values: { isVacancyLoading, vacancies, returnTravelClass, price: totalPrice },
    setFieldValue,
  } = useFormikContext<CheckoutFormData>()
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const fareFormName = isReturnTrip ? 'returnFareClass' : 'fareClass'
  const travelFormName = isReturnTrip ? 'returnTravelClass' : 'travelClass'
  const emptyPrice = { fractional: 0, currency }

  const sorted = useMemo(
    () =>
      faresList
        .map(item => ({
          ...item,
          price: vacancy.getPrice(
            fareClassList,
            item.fares.map(({ fareClassCode }) => fareClassCode),
          ),
          vacant: vacancy.getVacant(
            vacancies,
            item.fares
              .map((curr, _, self) => {
                if (!returnTravelClass) return curr.fareClassCode

                return self.map(s => curr.fareClassCode + s.fareClassCode)
              })
              .flat(),
          ),
        }))
        .sort((a, b) => Number(a.price?.fractional) - Number(b.price?.fractional)),
    [returnTravelClass, fareClassList, faresList, vacancies],
  )

  const list = useMemo(
    () => (isMobile ? sorted : sorted.slice(currentIndex, currentIndex + config.fareSplitViewLength)),
    [currentIndex, sorted, isMobile],
  )

  useEffect(() => {
    const index = sorted.findIndex(item => item.class === travelClass) / config.fareSplitViewLength

    if (index < 0) setCurrentIndex(0)
    else setCurrentIndex(Math.floor(index) * config.fareSplitViewLength)
  }, [sorted, isVacancyLoading, travelClass, vacancies.length])

  const selectTravelClass = (travelClass: string): void => {
    const sortedVacancies = vacancy
      .getSortedVacancies(vacancies, fareClassList, faresList)
      .filter(item => item.travelClass === travelClass)[0]

    setFieldValue(fareFormName, sortedVacancies.fareClass)
    setFieldValue(travelFormName, travelClass)
    setFieldValue('seats', null)
  }

  return (
    <Skeleton.List Skeleton={FareClassSkeleton} amount={2} loading={isLoading}>
      <div className="fare-class__class-section row gap-3">
        {list.map(({ price, vacant, ...item }) => {
          const classKey = item.class.toLowerCase()
          const description: string[] = t(`checkout.travel_class.${classKey}.description`, {
            returnObjects: true,
            defaultValue: [],
          })

          return (
            <div key={item.class} className="cell-11 cell-lg-4">
              <Card
                title={t(`checkout.travel_class.${classKey}.title`, { defaultValue: classKey })}
                selected={travelClass === item.class}
                onClick={() => {
                  selectTravelClass(item.class)
                }}
                description={description}
                vacant={vacant}
                price={
                  travelClass === item.class || !price
                    ? emptyPrice
                    : vacancy.calculatePrice(totalPrice, price, selectedFare?.price)
                }
              />
            </div>
          )
        })}
        {!isMobile && (
          <Navigation currentIndex={currentIndex} maxIndex={faresList.length} onNavigate={setCurrentIndex} />
        )}
      </div>
    </Skeleton.List>
  )
}

export default FareClassList
