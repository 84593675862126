import cn from 'classnames'
import React, { ReactElement } from 'react'

import '@ui/Skeleton/Item/index.scss'

export interface SkeletonProps {
  className?: string
  width?: number | string
  height?: number
}

const Item = ({ width, height, className }: SkeletonProps): ReactElement => (
  <div className={cn('ui-skeleton', className)} style={{ width, height }} />
)

export default Item
