/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */

import i18n from 'i18next'
import backend, { HttpBackendOptions } from 'i18next-http-backend'
import React from 'react'
import { useTranslation, initReactI18next } from 'react-i18next'
import { TransProps } from 'react-i18next/TransWithoutContext'

import TransFromHtml from '@components/TransFromHtml'
import config from '@config'
import url from '@lib/url'
import utils from '@lib/utils'

interface I18nQueryParams {
  retailerPartnerNumber?: RetailerNumber | null
  marketingCarrierCode?: CarrierCode | null
}

const buildTranslationsURL = (path: string, params: I18nQueryParams): string => {
  const query = utils.object.compact({
    retailer_partner_number: params.retailerPartnerNumber,
    marketing_carrier_code: params.marketingCarrierCode,
  })

  return url.build([config.api.translationsUrl, path], query)
}

interface HtmlParserOptions {
  parseHtmlOptions: {
    keyPattern?: RegExp
    components?: TransProps<any>['components']
  }
}

const init = async (locale: Locale, params: I18nQueryParams): Promise<void> => {
  await i18n
    .use(initReactI18next)
    .use(backend)
    .use({
      type: 'postProcessor',
      name: 'parseHtml',
      process: (value: string, key: string, options: HtmlParserOptions) => {
        const { parseHtmlOptions: { keyPattern, components } = {}, ...rest } = options
        return keyPattern && keyPattern.test(key) ? (
          <TransFromHtml i18nKey={key} values={rest} components={components} />
        ) : (
          value
        )
      },
    })
    .init<HttpBackendOptions>({
      lng: locale,
      returnNull: false,
      fallbackLng: config.fallback.locale.default,
      load: 'currentOnly',
      nonExplicitSupportedLngs: true,
      interpolation: {
        escapeValue: false,
      },
      react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
        hashTransKey: /* istanbul ignore next */ () => {
          throw new Error('i18nKey is not set for Trans component')
        },
      },
      backend: {
        reloadInterval: false,
        crossDomain: true,
        loadPath: buildTranslationsURL('/translations/whitelabel/{{lng}}', params),
      },
    })
}

const changeLanguage = async (locale: Locale): Promise<void> => {
  await i18n.changeLanguage(locale)
}

const getLanguage = (): Locale => (i18n.language ?? config.fallback.locale.default) as Locale

const isInitialized = (): boolean => i18n.isInitialized

export default {
  init,
  changeLanguage,
  getLanguage,
  isInitialized,
}

export { useTranslation }

export { Trans } from 'react-i18next'
export { t } from 'i18next'
export type { TFunction } from 'i18next'
