import paramsStore from '@stores/params'

const MAX_SEATS_LEFT_SHOW = 9

const showSeatsLeft = (number: number): boolean => {
  const { mode } = paramsStore.get()

  if (number === 0) return false
  if (mode === 'embed') return true

  return number <= MAX_SEATS_LEFT_SHOW
}

const seatsUtils = {
  showSeatsLeft,
}

export default seatsUtils
