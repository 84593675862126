import React, { ReactElement } from 'react'

import { useTranslation } from '@lib/i18n'

interface LabelProps {
  type: ConnectionType
  label?: string
}

const Label = ({ type, label }: LabelProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <span>
      <span>{`${t(`extras.seats.${type}`)}: `}</span>
      <span>{label}</span>
    </span>
  )
}

export default Label
