import cn from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import bem from '@lib/bem'
import utils from '@lib/utils'
import Item from '@ui/MediaCarousel/Item'
import NavigateState from '@ui/MediaCarousel/NavigateState'

import '@ui/MediaCarousel/Mobile/index.scss'

interface MediaCarouselProps {
  media: string[]
  className?: string
  onMediaChange?: (index: number) => void
}

interface StylesState {
  height: number
  opacity: number
}

const activeStyles: StylesState = {
  height: 100,
  opacity: 1,
}

const secondaryStyles: StylesState = {
  height: 85,
  opacity: 0.5,
}

const interpolate = (progress: number, from: number, to: number): number => from + (to - from) * progress
const interpolateField = (progress: number, field: keyof StylesState): number =>
  interpolate(progress, activeStyles[field], secondaryStyles[field])

interface Styles {
  height: string
  opacity: string
}

interface Position {
  x: number
  dragPosition: number | null
}

const gap = 8
const mediaSwapLimit = 50

const MediaCarouselMobile = ({ media, className, onMediaChange }: MediaCarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const mediaRef = useRef<HTMLDivElement>(null)
  const dimensionsRef = useRef<DOMRect>(new DOMRect())
  const dragStateRef = useRef<Position>({ x: 0, dragPosition: 0 })

  const changeIndex = (index: number) => {
    setCurrentIndex(index)
    onMediaChange?.(index)
  }

  const getStyles = useCallback(
    (progress: number): Styles => ({
      height: `${interpolateField(progress, 'height')}%`,
      opacity: interpolateField(progress, 'opacity').toString(),
    }),
    [],
  )

  const updateMediaStyles = useCallback(
    (element: HTMLElement) => {
      const { x, width } = element.getBoundingClientRect()
      const progress = Math.min(Math.abs(x - dimensionsRef.current.x) / width, 1) || 0
      const { height, opacity } = getStyles(progress)

      element.style.height = height
      element.style.opacity = opacity
    },
    [getStyles],
  )

  const updateMedia = useCallback(
    (media: HTMLElement) => {
      for (const child of media.children) {
        updateMediaStyles(child as HTMLElement)
      }
    },
    [updateMediaStyles],
  )

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      const rect = mediaRef.current?.getBoundingClientRect()
      // istanbul ignore else
      if (mediaRef.current != null && rect != null && rect.height > 0) {
        dimensionsRef.current = rect
        mediaRef.current.style.height = `${dimensionsRef.current.height}px`
        updateMedia(mediaRef.current)
        observer.disconnect()
      }
    })

    observer.observe(mediaRef.current as HTMLElement)
    // istanbul ignore next
    return () => observer.disconnect()
  }, [updateMediaStyles, updateMedia])

  const dragStart = (event: React.TouchEvent) => {
    dragStateRef.current.dragPosition = event.touches[0].clientX
  }

  const dragMove = (event: React.TouchEvent) => {
    const { x, dragPosition } = dragStateRef.current

    /* istanbul ignore else: ref checks shouldn't be false in real case scenarios */
    if (dragPosition != null && mediaRef.current) {
      const { clientX } = event.touches[0]
      const offset = x + clientX - dragPosition
      const scrollLimit = dimensionsRef.current.width - mediaRef.current.scrollWidth

      mediaRef.current.style.left = `${utils.number.clamp(offset, scrollLimit, 0)}px`
      updateMedia(mediaRef.current)
    }
  }

  const scrollTo = (
    getDistance: (progress: number) => number,
    onEnd: () => void,
    duration: number,
    startTime: number = 0,
  ) => {
    requestAnimationFrame(delta => {
      if (startTime !== 0) {
        const progress = (delta - startTime) / duration
        const distance = getDistance(Math.min(progress, 1) || /* istanbul ignore next */ 0)
        /* istanbul ignore else: ref checks shouldn't be false in real case scenarios */
        if (mediaRef.current) {
          mediaRef.current.style.left = `${distance}px`
          updateMedia(mediaRef.current)
        }

        if (progress < 1) {
          scrollTo(getDistance, onEnd, duration, startTime)
        } else {
          dragStateRef.current.x = distance
          onEnd()
        }
      } else {
        scrollTo(getDistance, onEnd, duration, delta)
      }
    })
  }

  const getOffsetFromIndex = (index: number) => index * dimensionsRef.current.width + index * gap
  const focusOnMedia = (index: number) => {
    const getDistance = (progress: number) => interpolate(progress, dragStateRef.current.x, -getOffsetFromIndex(index))
    const onEnd = () => changeIndex(index)

    scrollTo(getDistance, onEnd, 250)
  }

  const dragEnd = (event: React.TouchEvent) => {
    /* istanbul ignore else: ref checks shouldn't be false in real case scenarios */
    if (mediaRef.current && dragStateRef.current.dragPosition != null) {
      const dragDistance = dragStateRef.current.dragPosition - event.changedTouches[0].clientX
      const indexChange = Math.abs(dragDistance) < mediaSwapLimit ? 0 : Math.sign(dragDistance)

      dragStateRef.current.dragPosition = null
      dragStateRef.current.x = mediaRef.current.getBoundingClientRect().x - dimensionsRef.current.x

      focusOnMedia(utils.number.clamp(currentIndex + indexChange, 0, media.length - 1))
    }
  }

  return (
    <div className={cn('column', className, bem('media-carousel', { mobile: true }))}>
      <div
        className={bem('media-carousel', 'media')}
        ref={mediaRef}
        onTouchStart={dragStart}
        onTouchMove={dragMove}
        onTouchEnd={dragEnd}
      >
        {media.map(url => (
          <Item key={url} url={url} />
        ))}
      </div>
      <NavigateState media={media} currentIndex={currentIndex} />
    </div>
  )
}

export default MediaCarouselMobile
