import React, { ReactElement, ReactNode } from 'react'

import bem from '@lib/bem'
import { Icon } from '@ui'

import '@components/RouteBreakdown/Item/index.scss'

export interface ItemProps {
  left?: ReactNode
  icon?: string
  right?: ReactNode
  type?: string
}

const Item = ({ left, icon, right, type }: ItemProps): ReactElement => {
  return (
    <div className={bem('rb-item', type ? { [type]: true } : {})}>
      <div className={bem('rb-item', 'left')}>{left}</div>
      <div className={bem('rb-item', 'middle')}>
        {icon && <Icon name={icon} size="medium" className={bem('rb-item', 'icon')} />}
      </div>
      <div className={bem('rb-item', 'line')} />
      <div className={bem('rb-item', 'right')}>{right}</div>
    </div>
  )
}

export default Item
