import React, { ForwardedRef, ReactElement, forwardRef } from 'react'

import Connection from '@components/Connection'
import Money from '@components/Money'
import bem from '@lib/bem'
import connectionUtils from '@lib/connection'
import { useTranslation } from '@lib/i18n'
import { Icon } from '@ui'

import '@components/OutboundTrip/index.scss'

interface OutboundTripProps {
  opened: boolean
  connection: Connection
  fareClassCode: string
  onClick: () => void
  seatsPrice: Money | null
  isAmendment: boolean
}

const OutboundTrip = (
  { connection, onClick, opened, fareClassCode, seatsPrice, isAmendment }: OutboundTripProps,
  ref: ForwardedRef<HTMLDivElement>,
): ReactElement => {
  const { t } = useTranslation()

  const fare = connection.fares.find(fare => fare.fareClass.code === fareClassCode) as Fare
  const price = seatsPrice ?? connectionUtils.getPrice(connection, fare)

  return (
    <div ref={ref} className={bem('outbound', { opened, closed: !opened })} onClick={onClick} data-tag="outbound">
      <div className="outbound__header mb-1">
        <span className={bem('outbound', 'icon')}>
          <Icon name="checkout" size="medium" />
        </span>
        <span className={bem('outbound', 'title')}>{t('journeyList.selectedOutbound')}</span>
      </div>
      <div className={bem('outbound', 'date-range')}>
        <Connection.DepartureDateTime connection={connection} />
        {' - '}
        <Connection.ArrivalDateTime connection={connection} />
      </div>
      <div className="outbound__description row-lg space-between items-center">
        <div className={bem('outbound', 'description-station')}>
          <span className="cell-sm-5">{connection.departureStation.name}</span>
          <Icon name="arrow-right" size="small" />
          <span className="cell-sm-5">{connection.arrivalStation.name}</span>
        </div>
        <div className={bem('outbound', 'description-price')}>
          <Money {...price} signed={isAmendment} />
        </div>
      </div>
    </div>
  )
}

export default forwardRef(OutboundTrip)
