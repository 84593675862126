import React, { ReactElement } from 'react'

import bem from '@lib/bem'
import Item from '@ui/MediaCarousel/Item'

import '@components/JourneyCard/Media/Preview.scss'

interface PreviewProps {
  media: string[]
  onClick: () => void
}

const Preview = ({ media, onClick }: PreviewProps): ReactElement => (
  <div className="journey-card__preview-media column center">
    <div className="journey-card__preview-media-container" onClick={onClick}>
      {media.map((url, index) => (
        <Item
          key={url}
          url={url}
          className={bem('journey-card', 'preview-media-content', { [index]: true, centered: media.length === 1 })}
          config={{ video: { controls: false } }}
        />
      ))}
      <div className="journey-card__preview-media-overlay" />
    </div>
  </div>
)

export default Preview
