import React, { ReactElement } from 'react'

import paymentErrorIcon from '@images/checkout/paymentError.svg'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { Button, Icon, Modal } from '@ui'

import '@pages/Checkout/Payment/ErrorModal/index.scss'

interface PaymentErrorModalProps {
  opened: boolean
  onClose: () => void
  onRetry?: () => void
}

const PaymentErrorModal = ({ onClose, opened, onRetry }: PaymentErrorModalProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Modal opened={opened} onClose={onClose} className="payment-error">
      <div className="column gap-3 items-center payment-error__content">
        <button onClick={onClose} className={bem('payment-error', 'close-button')}>
          <Icon name="cross" size="large" />
        </button>
        <img src={paymentErrorIcon} className={bem('payment-error', 'icon')} />
        <h3 className={bem('payment-error', 'title')}>{t('checkout.paymentError.title')}</h3>
        <div>{t('checkout.paymentError.description')}</div>
        {onRetry && <Button onClick={onRetry}>{t('checkout.paymentError.retry')}</Button>}
      </div>
    </Modal>
  )
}

export default PaymentErrorModal
