import { KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react'

interface UseArrowControlsResult<T> {
  activeItem?: T | null
  onKeyDown: (e: KeyboardEvent) => void
  resetActiveItem: () => void
}

const useArrowControls = <T>(
  items: T[],
  onSelect: (item: T) => void,
  selectedItem?: T | null,
): UseArrowControlsResult<T> => {
  const [activeIndex, setActiveIndex] = useState<number | null>()
  const activeItem = useMemo(() => (activeIndex != null ? items[activeIndex] : null), [activeIndex, items])

  const selectedIndex = useMemo(
    () => (selectedItem != null ? items.indexOf(selectedItem) : null),
    [items, selectedItem],
  )

  const resetActiveItem = useCallback(() => {
    setActiveIndex(selectedIndex)
  }, [selectedIndex])

  useEffect(() => {
    resetActiveItem()
  }, [resetActiveItem])

  const toPreviousItem = (): void => {
    const newIndex = activeIndex != null ? activeIndex - 1 : items.length - 1
    setActiveIndex(newIndex >= 0 ? newIndex : items.length - 1)
  }

  const toNextItem = (): void => {
    const newIndex = activeIndex != null ? activeIndex + 1 : 0
    setActiveIndex(newIndex < items.length ? newIndex : 0)
  }

  const selectActiveItem = (): void => {
    activeItem != null && onSelect(activeItem)
  }

  const keyHandlersMap: Record<string, () => void> = {
    ArrowUp: toPreviousItem,
    ArrowDown: toNextItem,
    Enter: selectActiveItem,
  }

  const onKeyDown = (e: KeyboardEvent): void => {
    if (keyHandlersMap[e.key] != null) {
      e.preventDefault()

      keyHandlersMap[e.key]()
    }
  }

  return { activeItem, onKeyDown, resetActiveItem }
}

export default useArrowControls
