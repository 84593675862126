import { FormBlockData } from '@components/Blocks/Form/useInitialFormValues'
import makeApi from '@lib/api'

export interface FeedbackRequestData
  extends Omit<Partial<FormBlockData>, 'theme' | 'termAccepted' | 'attachments' | 'tripDate'> {
  theme: string
  targetEmail: string
  tripDate?: string | null
}

const feedbackApi = {
  send: async (data: FeedbackRequestData) => {
    await makeApi.post('/feedback')({ body: data, params: {} })
  },
}

export default feedbackApi
