import React, { ReactElement, useMemo } from 'react'

import Amenity from '@components/JourneyCard/Footer/Amenity'
import bem from '@lib/bem'
import connectionUtils from '@lib/connection'
import fareUtils from '@lib/fare'
import { useTranslation } from '@lib/i18n'
import { Icon } from '@ui'

interface AmenitiesProps {
  connection: Connection
  onToggleDetails: () => void
  isHeaderHidden?: boolean
}

const Amenities = ({ connection, onToggleDetails, isHeaderHidden }: AmenitiesProps): ReactElement => {
  const { t } = useTranslation()
  const maxFeatures = 4
  const segmentsCount = connection.segments.filter(segment => !connectionUtils.isSelfTransfer(segment)).length
  const tripDirection =
    segmentsCount === 1 ? t('card.directTrip') : t('card.numberOfTransfers', { count: segmentsCount - 1 })
  const detailsTitle = isHeaderHidden ? tripDirection : t('card.details')

  const displayedFareFeatures = useMemo(
    () => fareUtils.getFareFeaturesByAmenities(connection).slice(0, maxFeatures),
    [connection],
  )
  const hiddenFareFeatures = useMemo(
    () => fareUtils.getFareFeaturesByAmenities(connection).slice(maxFeatures),
    [connection],
  )

  return (
    <div className={bem('journey-card', 'footer-details-wrapper')}>
      <div className={bem('journey-card', 'footer-details', { transfers: isHeaderHidden })} onClick={onToggleDetails}>
        <span className={bem('journey-card', 'footer-details-title')}>{detailsTitle}</span>
        <Icon name="chevron-down" size="medium" />
      </div>
      <div className={bem('journey-card', 'footer-amenities')}>
        {displayedFareFeatures.map(item => (
          <Amenity key={item.id} fareFeature={item} />
        ))}
        {hiddenFareFeatures.length > 0 && (
          <span onClick={onToggleDetails} className={bem('journey-card', 'footer-amenities-count')}>
            {`+${hiddenFareFeatures.length}`}
          </span>
        )}
      </div>
    </div>
  )
}

export default Amenities
