import { useQueries } from '@tanstack/react-query'
import { useMemo } from 'react'

import mediaAPI from '@api/media'
import { ApiError } from '@lib/api'
import carrierUtils from '@lib/carrier'

interface UseMediaQueriesProps {
  connections: Connection[]
  enabled: boolean
}

export interface UpdatedMediaData extends Media.Data {
  marketingCarrierCode: string
}

interface Meta {
  isLoading: boolean
  error: ApiError | null | undefined
}

type UseMediaQueriesHook = [UpdatedMediaData[], Meta]

const useMediaQueries = ({ connections, enabled }: UseMediaQueriesProps): UseMediaQueriesHook => {
  const carriers = useMemo(() => [...new Set(connections.map(carrierUtils.getCarrierCodes).flat())], [connections])

  const combinedQuery = useQueries({
    queries: carriers.map(marketingCarrierCode => ({
      queryKey: ['media', marketingCarrierCode],
      queryFn: () => mediaAPI.load({ marketingCarrierCode }).then(res => ({ ...res, marketingCarrierCode })),
      enabled: enabled,
      staleTime: Infinity,
    })),
    combine: results => ({
      data: results.map(({ data }) => data).filter(Boolean),
      isLoading: results.some(({ isLoading }) => isLoading),
      error: results.find(Boolean)?.error,
    }),
  })

  const meta = {
    isLoading: combinedQuery.isLoading,
    error: combinedQuery.error as ApiError,
  }

  return [combinedQuery.data as UpdatedMediaData[], meta]
}

export default useMediaQueries
