import React, { useMemo } from 'react'

import { FilterConfig, FilterFunction, FilterRenderProps } from '@lib/connections/filters/types'
import { useTranslation } from '@lib/i18n'
import CheckboxGroup from '@ui/CheckboxGroup'

const ACELA_PREFIX = 'Acela'

const filter: FilterFunction = (connection, value) => {
  if (value.length === 2) return true

  if (value.includes('conventional')) return connection.segments.some(item => item.linePrefix !== ACELA_PREFIX)

  return connection.segments.some(item => item.linePrefix === ACELA_PREFIX)
}

const FilterComponent = ({ value, onChange }: FilterRenderProps) => {
  const { t } = useTranslation()
  const options = useMemo(
    () => [
      { value: 'acela', label: t('journeyList.filters.acela') },
      { value: 'conventional', label: t('journeyList.filters.conventional') },
    ],
    [t],
  )

  return <CheckboxGroup value={value} onChange={onChange} options={options} />
}

const trainTypeFilter: FilterConfig = {
  filter,
  title: 'trainType',
  Component: FilterComponent,
}

export default trainTypeFilter
