const calculate = (price: Money, ratio: number, term: number): Installment.Item => {
  if (ratio > 0) {
    const rate = (1 + ratio) ** term
    const numerator = price.fractional * ratio * rate
    const denominator = rate - 1
    const monthly = { fractional: Math.round(numerator / denominator), currency: price.currency }
    const total = { fractional: monthly.fractional * term, currency: price.currency }

    return { term, monthly, total }
  }

  const monthly = { fractional: Math.round(price.fractional / term), currency: price.currency }

  return { term, monthly, total: price }
}

const buildOneTimePayment = (price: Money): Installment.Item => ({ term: 1, monthly: price, total: price })

const buildFromRates = (price: Money, rates: Installment.Rate[]): Installment.Item[] => {
  if (rates.length === 0) return []

  const installments = [buildOneTimePayment(price)]

  rates.forEach(rate => {
    for (let i = rate.periodBeginningInMonths; i <= rate.periodEndInMonths; i++) {
      const ratio = rate.interestRate / 100
      const installment = calculate(price, ratio, i)

      installments.push(installment)
    }
  })

  return installments
}

const installmentsUtils = {
  buildFromRates,
}

export default installmentsUtils
