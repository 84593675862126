import date from '@lib/date'
import paramsUtils from '@lib/params'
import url from '@lib/url'
import utils from '@lib/utils'

export interface NormalURLParams {
  arrivalStationCode: StationCode
  arrivalTime: Date
  currency: Currency
  departureStationCode: StationCode
  departureTime: Date
  locale: Locale
  marketingCarrierCode: CarrierCode
  pax?: number
  retailerPartnerNumber: RetailerNumber
  retailerBookingNumber?: string | null
  returnArrivalTime: Date | null
  deviceId?: string
}

export default (params: NormalURLParams): string => {
  const parameters = utils.object.compact({
    ...paramsUtils.getPersistentUrlParams(params),
    departureDate: date.formatDate(params.departureTime),
    departureStation: params.departureStationCode,
    arrivalStation: params.arrivalStationCode,
    pax: params.pax,
    currency: params.currency,
    returnDate: params.returnArrivalTime ? date.formatDate(params.returnArrivalTime) : null,
    deviceId: params.deviceId,
  })

  return url.build([window.location.origin, 'result'], parameters)
}
