import React, { ReactElement } from 'react'

import Counter from '@components/Counter'

interface ControlsProps {
  maxCount: number
  value: number
  ancillary: Ancillary.Item
  onChange: (isIncrease: boolean, count: number, ancillary: Ancillary.Item) => void
}

const Controls = ({ ancillary, maxCount, onChange, value }: ControlsProps): ReactElement => (
  <div className="row items-center space-between">
    <Counter
      min={0}
      max={maxCount}
      value={value}
      onChange={count => {
        onChange(count > value, count, ancillary)
      }}
    />
  </div>
)

export default Controls
