import React, { ReactElement } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { useAppContext } from '@context/app'
import useNotFoundPath from '@hooks/useNotFoundPath'
import amplitude from '@lib/analytics/amplitude'
import { AssertionError } from '@lib/assert'
import IndexPage from '@pages/Index/Page'

const REQUIRED_RPN_PAGES = ['/result', '/', '/booking/login', '/destinations']
const isValidRetailerNumber = (retailerPartnerNumber: RetailerNumber): boolean => retailerPartnerNumber > 0

const RestrictedRouter = (): ReactElement => {
  const { retailerPartnerNumber } = useAppContext()
  const { pathname } = useLocation()
  const notFoundPath = useNotFoundPath()

  if (REQUIRED_RPN_PAGES.includes(pathname) && !isValidRetailerNumber(retailerPartnerNumber)) {
    const error = amplitude.error.buildParams(new AssertionError('retailerPartnerNumber'))

    return <Navigate to={notFoundPath} state={{ error }} />
  }

  return <IndexPage />
}

export default RestrictedRouter
