import makeApi from '@lib/api'

export interface CountriesRequest {
  locale: Locale
}

export interface StatesRequest extends CountriesRequest {
  countryCode: string
}

export type CountriesResponse = Country[]

export default {
  load: makeApi.get<CountriesRequest, CountriesResponse>('/countries'),
  states: async ({ countryCode, ...params }: StatesRequest) =>
    await makeApi.get<CountriesRequest, CountriesResponse>(`/countries/${countryCode}/states`)(params),
}
