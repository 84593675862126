import React, { ReactElement, useState } from 'react'

import useSearchForm from '@components/SearchBar/useSearchForm'
import { SearchFormData } from '@components/SearchForm'
import SearchModal from '@components/SearchModal'
import Station from '@components/Station'
import bem from '@lib/bem'
import date from '@lib/date'
import { useLocationName } from '@loaders/locations'
import { useConnectionCalendar } from '@stores/connectionCalendar'
import { useParams } from '@stores/params'
import { Icon } from '@ui'

import '@components/TripPreview/index.scss'

const TripPreview = (): ReactElement => {
  const [params] = useParams()
  const [searchModalOpened, setSearchModalOpened] = useState<boolean>(false)
  const { settings, submit, initialValues } = useSearchForm()
  const [{ outboundDate, inboundDate }] = useConnectionCalendar()

  const { data: departureLocation } = useLocationName({
    location: initialValues.departureLocation,
    locale: params.locale,
  })

  const { data: arrivalLocation } = useLocationName({
    location: initialValues.arrivalLocation,
    locale: params.locale,
  })

  const handleSubmit = (values: SearchFormData): void => {
    submit(values)
    setSearchModalOpened(false)
  }

  const departureInfo = departureLocation?.name ?? initialValues.departureLocation?.code
  const arrivalInfo = arrivalLocation?.name ?? initialValues.arrivalLocation?.code

  return (
    <>
      <div
        className="trip-preview"
        onClick={() => {
          setSearchModalOpened(true)
        }}
      >
        <div className={bem('trip-preview', 'station')}>
          <div className={bem('trip-preview', 'station-info')}>{departureInfo}</div>
          <Icon name="return" size="medium" />
          <div className={bem('trip-preview', 'station-info')}>{arrivalInfo}</div>
        </div>
        <div className="trip-preview__date row space-between">
          <span className={bem('trip-preview', 'date-range')}>
            <span>
              <Station.Date date={outboundDate ?? date.parse(params.departureDate)} />
            </span>
            {params.returnDate && (
              <span>
                {' - '}
                <Station.Date date={inboundDate ?? date.parse(params.returnDate)} />
              </span>
            )}
          </span>
          <Icon name="edit" size="medium" />
        </div>
      </div>
      <SearchModal
        settings={settings}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        opened={searchModalOpened}
        onClose={() => {
          setSearchModalOpened(false)
        }}
      />
    </>
  )
}

export default TripPreview
