import makeApi from '@lib/api'

export interface AnalyticsSettingsRequest {
  retailerPartnerNumber: number | null
}

export type AnalyticsSettingsResponse = Analytics.Settings

export default {
  load: makeApi.get<AnalyticsSettingsRequest, AnalyticsSettingsResponse>(`/analytics_settings`),
}
