import React from 'react'

import StationFilter from '@lib/connections/filters/components/StationFilter'
import { FilterConfig, FilterFunction } from '@lib/connections/filters/types'

const filter: FilterFunction = (connection, value) => value.includes(connection.departureStation.id)

const departureStationsFilter: FilterConfig = {
  filter,
  title: 'departureStations',
  Component: props => <StationFilter {...props} direction="departure" />,
}

export default departureStationsFilter
