import cn from 'classnames'
import { Field, Form, FormikProvider, useFormik } from 'formik'
import React, { FunctionComponent, useMemo, useState } from 'react'

import BlockSection from '@components/Blocks/common/BlockSection'
import SubmitModal from '@components/Blocks/common/SubmitModal'
import AdditionalInformation from '@components/Blocks/Form/Fields/Additional'
import GeneralInformation from '@components/Blocks/Form/Fields/General'
import LostItemInformation from '@components/Blocks/Form/Fields/LostItem'
import TicketInformation from '@components/Blocks/Form/Fields/Ticket'
import { FormBlockData, useInitialFormValues } from '@components/Blocks/Form/useInitialFormValues'
import bem from '@lib/bem'
import date from '@lib/date'
import { useTranslation } from '@lib/i18n'
import { useCarrierLoader } from '@loaders/carrier'
import { useFeedbackMutation } from '@loaders/feedback'
import { useParams } from '@stores/params'
import { Button, Icon } from '@ui'
import DropdownField from '@ui/Dropdown/Field'

import '@components/Blocks/Form/index.scss'

const themesConfig: Record<PageBlocks.FormTheme, FunctionComponent[]> = {
  lost: [TicketInformation, LostItemInformation],
  general: [],
  claims: [TicketInformation],
}

type Props = PageBlocks.BlockComponentProps<PageBlocks.FormBlock>

const FormBlock = ({ themes, translationKey, showCarrierInformation, backgroundStyle }: Props) => {
  const { t } = useTranslation()
  const [activeModal, setActiveModal] = useState<'success' | 'error' | null>(null)
  const [{ marketingCarrierCode, currency, locale }] = useParams()
  const { title, description } = t<any, PageBlocks.CardTranslation>(`customBlocks.${translationKey}.header`, {
    returnObjects: true,
    defaultValue: {},
    postProcess: ['parseHtml'],
    parseHtmlOptions: {
      keyPattern: /\.description/,
    },
  })
  const feedback = useFeedbackMutation()
  const carrierLoader = useCarrierLoader({
    marketingCarrierCode,
    params: { currency, locale },
  })
  const onSubmit = async (data: Partial<FormBlockData>) => {
    const validatedData = data as FormBlockData
    try {
      /* istanbul ignore next */
      if (!carrierLoader.data?.customerServiceEmail) return setActiveModal('error')
      await feedback.mutateAsync({
        ...validatedData,
        tripDate: validatedData.tripDate && date.formatDate(validatedData.tripDate),
        targetEmail: carrierLoader.data?.customerServiceEmail,
        theme: t(`customBlocksCommon.emailTheme.${validatedData.theme}`),
      })
      setActiveModal('success')
    } catch (e) {
      setActiveModal('error')
    }
  }

  const initialValues = useInitialFormValues(themes)
  const formik = useFormik<FormBlockData>({ initialValues, onSubmit })

  const { values } = formik
  const themeFields = useMemo(() => themesConfig[values.theme], [values.theme])
  const themeOptions = useMemo(
    () => themes.map(value => ({ value, label: t(`customBlocksCommon.form.theme.${value}`) })),
    [t, themes],
  )

  const header = (
    <>
      {title && <h2 className="cell mb-3 form-block__title">{title}</h2>}
      {description && <div className="body-16 mb-5 form-block__description">{description}</div>}
    </>
  )

  return (
    <BlockSection background={{ fill: 'white' }} appearance="flat">
      {activeModal != null && (
        <SubmitModal blockType="form" opened onClose={() => setActiveModal(null)} type={activeModal} />
      )}
      <FormikProvider value={formik}>
        <Form
          className={cn(
            'row center gap-8 column-sm',
            bem('form-block', {
              'with-carrier': showCarrierInformation,
              'two-tone': backgroundStyle === 'twoTone' && showCarrierInformation,
            }),
          )}
        >
          <>
            {showCarrierInformation && (
              <div className="cell-6 cell-sm-12 form-block__carrier-info">
                <div className="column gap-3">
                  {header}
                  {carrierLoader.data?.phone && (
                    <div className="row gap-2 items-center">
                      <div className="cell no-grow form-block__carrier-info-icon">
                        <Icon name="phone" size="large" />
                      </div>
                      <div className="cell">{carrierLoader.data.phone}</div>
                    </div>
                  )}
                  {carrierLoader.data?.email && (
                    <div className="row gap-2 items-center form-block__carrier-email">
                      <div className="cell no-grow form-block__carrier-info-icon">
                        <Icon name="mail" size="large" />
                      </div>
                      <div className="cell">{carrierLoader.data.email}</div>
                    </div>
                  )}
                  {carrierLoader.data?.address && (
                    <div className="row gap-2 items-center">
                      <div className="cell no-grow form-block__carrier-info-icon">
                        <Icon name="map-pin" size="large" />
                      </div>
                      <div className="cell">{carrierLoader.data.address}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="cell-6 cell-sm-12">
              <div className="column gap-4 items-center">
                {!showCarrierInformation && header}
                <div className="cell-12 stretch">
                  <Field
                    component={DropdownField}
                    label={t('customBlocksCommon.form.themeLabel')}
                    name="theme"
                    items={themeOptions}
                    required
                  />
                </div>
                <div className="cell-12 stretch">
                  <GeneralInformation />
                </div>
                {themeFields.map((Component, index) => (
                  <div className="cell-12 stretch" key={index}>
                    <Component />
                  </div>
                ))}
                <div className="cell-12 stretch">
                  <AdditionalInformation terms={carrierLoader.data?.terms ?? ''} />
                </div>
                <div className="cell no-grow form-block__submit">
                  <Button loading={feedback.isLoading} buttonType="submit">
                    {t('customBlocksCommon.form.submit')}
                  </Button>
                </div>
              </div>
            </div>
          </>
        </Form>
      </FormikProvider>
    </BlockSection>
  )
}

export default FormBlock
