import { SuggestionsResponse } from '@api/suggestion'
import helpers from '@lib/analytics/amplitude/helpers'
import utils from '@lib/utils'

const { sendEvent } = helpers

export interface SuggestionResponseProps {
  queryString: string
  requestStartTime: number
  fieldType: Location.Direction
  response: SuggestionsResponse
  suggestions: Location.Suggestion[]
}

const response = ({
  queryString,
  response,
  suggestions,
  fieldType,
  requestStartTime,
}: SuggestionResponseProps): void => {
  const requestProcessingTime = Math.round(performance.now() - requestStartTime)
  const size = utils.array.flatten(suggestions, ({ children }) => children).length

  sendEvent('suggest-api-return', {
    queryString,
    size,
    stringSize: queryString.length,
    fieldType,
    requestProcessingTime,
    object: JSON.stringify(response),
  })
}

export interface SuggestSelectProps {
  position: number
  stringSize: number
  station: Location.Item
  fieldType: Location.Direction
}

const select = (props: SuggestSelectProps): void => {
  sendEvent('suggest-select', props)
}

export default {
  response,
  select,
}
