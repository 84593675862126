import { useEffect, useState } from 'react'

import utils from '@lib/utils'

const useDebounced = <T>(value: T, delay: number): T => {
  const [debounced, setDebounced] = useState<T>(value)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounced(value)
    }, delay)

    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [utils.common.hash(value), delay])

  return debounced
}

export default useDebounced
