import React, { useMemo } from 'react'

import CheckboxFilter from '@lib/connections/filters/components/CheckboxFilter'
import { FilterConfig, FilterFunction, FilterRenderProps } from '@lib/connections/filters/types'
import fareUtils from '@lib/fare'
import { IconText } from '@ui'
import { CheckboxOption } from '@ui/CheckboxGroup'

const filter: FilterFunction = (connection, value) => {
  const fareFeatures = fareUtils.getUniqueFareFeatures([connection], { fareClassCode: null })

  return value.every(code => fareFeatures.map(fareFeature => fareFeature.code).includes(code))
}

const getFareFeaturesOption = (fareFeature: FareFeature): CheckboxOption<string> => ({
  value: fareFeature.code,
  label: (
    <IconText
      className="ui-icon-text--alternative"
      label={fareFeature.name}
      iconName={fareUtils.getFareFeatureIcon(fareFeature.code)}
    />
  ),
})

const FilterComponent = ({ value, onChange, data, setVisibility }: FilterRenderProps) => {
  const options = useMemo(
    () => fareUtils.getUniqueFareFeatures(data, { fareClassCode: null }).map(getFareFeaturesOption),
    [data],
  )

  return <CheckboxFilter value={value} onChange={onChange} options={options} setVisibility={setVisibility} />
}

const servicesFilter: FilterConfig = {
  filter,
  title: 'services',
  Component: FilterComponent,
}

export default servicesFilter
