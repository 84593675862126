import { useFormikContext } from 'formik'
import React, { ReactElement } from 'react'

const ScrollToError = (): ReactElement => {
  const { isSubmitting } = useFormikContext()

  const element = document.querySelector('[class*="error"]')

  isSubmitting && element?.scrollIntoView({ block: 'center' })

  return <></>
}

export default ScrollToError
