import React, { ReactElement, useState } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import trackIcon from '@images/common/track.svg'
import { useTranslation } from '@lib/i18n'
import { Button, Icon, Modal } from '@ui'

const TrackMyTripButton = (): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const [opened, setOpened] = useState(false)
  const closeModal = (): void => {
    setOpened(false)
  }

  return (
    <div className="track-trip">
      <div className="track-trip__button">
        <Button
          variant="text"
          color={isMobile ? undefined : 'backgroundText'}
          onClick={() => {
            setOpened(true)
          }}
        >
          {t('header.trackMyTrip')}
        </Button>
      </div>
      <Modal onClose={closeModal} opened={opened}>
        <div className="column items-center track-trip-modal">
          <img src={trackIcon} alt="track a trip" className="mb-4 track-trip-modal__image" />
          <button onClick={closeModal} className="track-trip-modal__close ui-modal__close-button">
            <Icon name="cross" size="large" data-tag="modal-close" />
          </button>
          <h3 className="mb-2">{t('header.trackMyTripModal.title')}</h3>
          <div className="body-16 mb-4">{t('header.trackMyTripModal.description')}</div>
          <div className="track-trip-modal__confirm">
            <Button onClick={closeModal}>{t('header.trackMyTripModal.confirm')}</Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default TrackMyTripButton
