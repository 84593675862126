import { useLocation } from 'react-router-dom'

import { useSettings } from '@queries/settings'

const useDistribusionBranding = () => {
  const { pathname } = useLocation()
  const [{ distribusionBranding }] = useSettings()
  const strippedPath = pathname.replace(/^\//, '')

  return distribusionBranding.enabled && distribusionBranding.pages.some(page => strippedPath.startsWith(page))
}

export default useDistribusionBranding
