import countriesApi, { CountriesResponse, StatesRequest } from '@api/countries'
import { makeLoader } from '@lib/loader'

interface CountriesLoaderParams {
  locale: Locale
  countryCode?: string | null
}

export const useStates = makeLoader<CountriesLoaderParams, CountriesResponse>({
  key: 'states',
  enabled: ({ countryCode }) => countryCode != null,
  loader: async params => await countriesApi.states(params as StatesRequest),
})
