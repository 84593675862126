import { AmendmentConfirmRequest, AmendmentCreateRequest } from '@api/amendments'
import { BookingPassenger, BookingResponse } from '@api/booking'
import config from '@config'
import ancillaryUtils from '@lib/ancillary'
import bookingUtils from '@lib/booking'
import checkoutUtils from '@lib/checkout'
import dateUtils from '@lib/date'
import passengersUtils from '@lib/passengers'
import utils from '@lib/utils'
import { BookingFormData } from '@pages/Booking/hooks/useInitialFormValues'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { PassengerData } from '@stores/checkout'
import { ParamsStore } from '@stores/params'

export type AmendmentTypeKeys = 'tripDetails' | 'passengers' | 'fareClass' | 'seats' | 'ancillaries' | 'general'

type AmendmentType = Record<AmendmentTypeKeys, Amendment.TypeIdentifier[]>

export type SessionStorageData = Pick<BookingFormData, 'passengers' | 'seats' | 'fareClass' | 'ancillaries'>

const amendmentTypes: AmendmentType = {
  tripDetails: ['date_time_amendment', 'origin_destination_amendment'],
  passengers: ['passenger_information_amendment'],
  fareClass: ['fare_class_amendment'],
  seats: ['seats_amendment'],
  ancillaries: ['ancillaries_amendment'],
  general: ['general_amendment'],
}

const getTypeIdentifier = (type: AmendmentTypeKeys): string[] => amendmentTypes[type]

const filterRule = (rules: Amendment.Rule[], type: AmendmentTypeKeys): Amendment.Rule[] =>
  rules.filter(rule => getTypeIdentifier(type).includes(rule.ruleTypeIdentifier))

const getAvailability = (rules: Amendment.Rule[] | undefined, type: AmendmentTypeKeys): boolean => {
  if (!rules) return false

  const general = filterRule(rules, 'general').some(({ allowed }) => allowed)

  return general || filterRule(rules, type).some(({ allowed }) => allowed)
}

const getFee = (rules: Amendment.Rule[], type: AmendmentTypeKeys): Money | null =>
  rules.find(rule => getTypeIdentifier(type).includes(rule.ruleTypeIdentifier))?.fee ??
  /* istanbul ignore next: if we use the function fee should be presented */ null

const setSessionStorage = (id: string, data: Partial<BookingFormData>): void => {
  const { passengers, seats, fareClass, ancillaries } = data

  sessionStorage.setItem(id, JSON.stringify({ passengers, seats, fareClass, ancillaries }))
}

const paramsDate = (date: string | null, time: string | null): string | null => {
  const parsed = dateUtils.parseUTCDateTime(date, time, 'UTC')

  return parsed && dateUtils.formatDateISO(parsed)
}

const getConnectionData = (current: string | null, amend?: string | null): string | null => {
  if (!amend) return null

  return utils.string.isSimilar(String(current), amend) ? null : amend
}

const getPassengerData = (
  booking: Booking.Passengers,
  stored: PassengerData,
  key: keyof Booking.Passengers & keyof PassengerData,
): string | null => (utils.string.isSimilar(String(booking[key]), String(stored[key])) ? null : (stored[key] as string))

const filterPassengers = (booking: Booking.Passengers[], stored: BookingPassenger[]): Partial<BookingPassenger>[] =>
  stored.reduce<Partial<BookingPassenger>[]>((mem, curr, index) => {
    const entries = Object.entries(curr).reduce<any[]>((mem, [key, value]) => {
      if (key === 'id') return [...mem, [key, value]]
      if (key === 'pax' || booking[index][key as keyof Booking.Passengers] === value) return mem

      return [...mem, [key, value]]
    }, [])

    return entries.length === 1 ? mem : [...mem, Object.fromEntries(entries)]
  }, [])

const formatDate = (date?: string): string | null => {
  if (!date) return null

  return dateUtils.formatDateISO(dateUtils.parse(date, 'UTC'))
}

const buildCreateParams = (
  booking: BookingResponse,
  params: ParamsStore,
  data: SessionStorageData,
): AmendmentCreateRequest => {
  const {
    booking: { id, fareClass },
    outboundConnection,
    inboundConnection,
  } = booking
  const { departureStation, arrivalStation, departureTime, arrivalTime } = outboundConnection
  const { departureTime: inboundDepartureTime, arrivalTime: inboundArrivalTime } = { ...inboundConnection }
  const { passengers: storedPassengers, ancillaries } = data

  const passengers = bookingUtils.getSegmentPassengers(outboundConnection)
  const bookingPassenger = passengers[0]
  const storedPassenger = storedPassengers[0] ?? passengers[0]
  const assigned = checkoutUtils.assignSeatsToPassengers({
    passengers: passengersUtils.filterPassengers(storedPassengers),
    data: data as CheckoutFormData,
    seatAncillaries: true,
  })

  return utils.object.compact({
    bookingId: id,
    retailerPartnerNumber: params.retailerPartnerNumber,
    fareClass: getConnectionData(fareClass.code, data.fareClass),
    departureStationCode: getConnectionData(departureStation.code, params.departureLocation?.code),
    arrivalStationCode: getConnectionData(arrivalStation.code, params.arrivalLocation?.code),
    departureTime: getConnectionData(formatDate(departureTime), paramsDate(params.departureDate, params.departureTime)),
    arrivalTime: getConnectionData(formatDate(arrivalTime), paramsDate(params.arrivalDate, params.arrivalTime)),
    passengers: filterPassengers(passengers, assigned).length ? filterPassengers(passengers, assigned) : null,
    returnDepartureTime: getConnectionData(
      formatDate(inboundDepartureTime),
      paramsDate(params.returnDepartureDate, params.returnDepartureTime),
    ),
    returnArrivalTime: getConnectionData(
      formatDate(inboundArrivalTime),
      paramsDate(params.returnArrivalDate, params.returnArrivalTime),
    ),
    ancillaries: ancillaryUtils.getBookingAncillariesParams(ancillaries),
    firstName: getPassengerData(bookingPassenger, storedPassenger, 'firstName'),
    lastName: getPassengerData(bookingPassenger, storedPassenger, 'lastName'),
    governmentId: getPassengerData(bookingPassenger, storedPassenger, 'governmentId'),
    governmentIdType: getPassengerData(bookingPassenger, storedPassenger, 'governmentIdType'),
    governmentIdIssuingCountry: storedPassenger.governmentIdIssuingCountry,
    governmentIdIssuingState: storedPassenger.governmentIdIssuingState,
    governmentIdValidityExpiration: storedPassenger.governmentIdValidityExpiration,
    gender: storedPassenger.gender,
  })
}

const buildConfirmParams = (data: CheckoutFormData): AmendmentConfirmRequest =>
  utils.object.compact<AmendmentConfirmRequest>({
    paymentMethod: data.paymentMethod,
    paymentProvider: data.paymentProvider,
    description: config.adyen.description,
    termsAccepted: data.termsAndPrivacy,
    cardHolderName: data.holderName,
    numberOfInstallments: data.numberOfInstallments,
    cpf: data.cpf,
    cardData: data.cardData,
    paymentMethodData: data.paymentMethod === 'pix' ? { type: 'pix' } : data.paymentMethodData,
    browserInfo: data.browserInfo,
    streetAndNumber: data.streetAndNumber,
    city: data.city,
    countryCode: data.countryCode,
    state: data.state,
    zipCode: data.zipCode,
    firstName: data.passengers[0].firstName,
    lastName: data.passengers[0].lastName,
    deviceFingerprint: data.deviceFingerprint,
  })

const getInitialStorage = (): SessionStorageData => ({
  passengers: [],
  fareClass: '',
  seats: null,
  ancillaries: ancillaryUtils.getInitialFormData(),
})

export default {
  getAvailability,
  getFee,
  buildCreateParams,
  setSessionStorage,
  buildConfirmParams,
  getInitialStorage,
}
