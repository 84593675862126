import { useFormikContext } from 'formik'
import React, { useState } from 'react'

import ChangeCalculator from '@components/ChangeCalculator'
import { useTranslation } from '@lib/i18n'
import DefaultPayButton from '@pages/Checkout/BookingDetails/SubmitSection/PayButton/Default'
import { useTotalPrice } from '@pages/Checkout/hooks/usePrice'
import useRunFormValidation from '@pages/Checkout/hooks/useRunFormValidation'
import { Modal } from '@ui'

const PayWithCalculator = () => {
  const { t } = useTranslation()
  const { submitForm } = useFormikContext()
  const price = useTotalPrice()
  const [calculatorOpened, setCalculatorOpened] = useState(false)
  const validateForm = useRunFormValidation()
  const openCalculator = async () => {
    try {
      await validateForm()
      setCalculatorOpened(true)
    } catch (e) {}
  }

  return (
    <>
      <Modal
        maxWidth="sm"
        title={t('checkout.cash.calculatorWindow.title')}
        opened={calculatorOpened}
        onClose={() => setCalculatorOpened(false)}
      >
        <div className="px-6 pt-5 pb-3">
          {price && <ChangeCalculator total={price.fractional} onSubmit={submitForm} />}
        </div>
      </Modal>
      <DefaultPayButton onClick={openCalculator} buttonType="button" />
    </>
  )
}

export default PayWithCalculator
