import {
  flush as flushAmplitude,
  getDeviceId as getDeviceIdAmplitude,
  identify as amplitudeIdentify,
  Identify,
  init as initAmplitude,
} from '@amplitude/analytics-browser'
import { IdentifyOperation } from '@amplitude/analytics-types'

import checkout from '@lib/analytics/amplitude/checkout'
import common from '@lib/analytics/amplitude/common'
import confirmation from '@lib/analytics/amplitude/confirmation'
import error from '@lib/analytics/amplitude/error'
import helpers from '@lib/analytics/amplitude/helpers'
import landing from '@lib/analytics/amplitude/landing'
import results from '@lib/analytics/amplitude/results'
import suggestions from '@lib/analytics/amplitude/suggestions'
import paramsUtils from '@lib/params'
import urlUtils from '@lib/url'
import utils from '@lib/utils'

const { sendEvent } = helpers

interface ValueChangeEvent<T> {
  from: T
  to: T
}

const getIdentifyEvent = (search: string): Identify => {
  const event = new Identify()
  const utmParams = paramsUtils.getUtmParams(search)

  Object.entries(utmParams).forEach(([key, value]) => {
    event.set(key, value)
  })

  return event
}

const identify = (event: Identify, deviceId: string): void => {
  amplitudeIdentify(event)
  localStorage.setItem(deviceId, JSON.stringify(event.getUserProperties()[IdentifyOperation.SET]))
}

const init = ({ container_id }: Analytics.IdConfig): void => {
  // istanbul ignore next
  if (!container_id) return

  initAmplitude(container_id.toString(), undefined, {
    transport: 'beacon',
    defaultTracking: {
      attribution: true,
      pageViews: false,
      sessions: false,
      fileDownloads: false,
      formInteractions: false,
    },
  }).promise.then(() => {
    if (document.referrer) {
      const deviceId = getDeviceIdAmplitude()
      const event = getIdentifyEvent(window.location.search)
      const userProperties = event.getUserProperties()[IdentifyOperation.SET]

      // istanbul ignore else
      if (userProperties && deviceId) {
        const storage = localStorage.getItem(deviceId)

        const isSameDomain = urlUtils.getDomain(document.referrer) === urlUtils.getDomain(window.location.origin)
        const isSameEvent = storage && utils.object.isEqual(JSON.parse(storage), userProperties)

        isSameDomain && !isSameEvent && identify(event, deviceId)
      }
    }
  })

  addEventListener('beforeunload', () => {
    flushAmplitude()
  })
}
const getDeviceId = (): string | undefined => getDeviceIdAmplitude()
const changeLocale = (props: ValueChangeEvent<Locale>): void => {
  sendEvent('change-language', props)
}
const changeCurrency = (props: ValueChangeEvent<Currency>): void => {
  sendEvent('change-currency', props)
}

interface PopularDirectionProps {
  departureLocation: Location.NamedItem
  arrivalLocation: Location.NamedItem
  position: number
}

const clickPopularDirection = ({ departureLocation, arrivalLocation, position }: PopularDirectionProps): void => {
  sendEvent('click-popular-directions', {
    departure: { type: departureLocation.type, code: departureLocation.code },
    arrival: { type: arrivalLocation.type, code: arrivalLocation.code },
    route: `${departureLocation.name} - ${arrivalLocation.name}`,
    position,
  })
}

const viewSearchPage = (): void => {
  sendEvent('view-homepage', {}, { measurePerformance: 'page' })
}

const viewWidget = (): void => {
  sendEvent('view-widget', {}, { measurePerformance: 'widget' })
}

const clickDownloadTicket = (): void => {
  sendEvent('click-download-ticket')
}

const clickWhatsappButton = (): void => {
  sendEvent('whatsapp-click')
}

const onInternalRedirect = (): void => {
  performance.mark('redirect')
}

export default {
  init,
  changeLocale,
  changeCurrency,
  viewSearchPage,
  viewWidget,
  clickPopularDirection,
  landing,
  suggestions,
  checkout,
  confirmation,
  error,
  results,
  common,
  getDeviceId,
  clickDownloadTicket,
  onInternalRedirect,
  clickWhatsappButton,
}
