import React, { ReactElement } from 'react'
import { TransProps } from 'react-i18next'

import { Trans } from '@lib/i18n'

type Props = TransProps<any> & {
  i18nKey: string
}

const htmlElementsMapping: Props['components'] = {
  h1: <h1 />,
  h2: <h2 />,
  h3: <h3 />,
  h4: <h4 />,
  p: <p />,
  a: <a />,
  li: <li />,
  ul: <ul />,
  u: <u />,
  b: <b />,
  img: <img />,
  em: <em />,
  s: <s />,
  ol: <ol />,
}

const TransFromHtml = ({ components, ...props }: Props): ReactElement => {
  return <Trans components={{ ...htmlElementsMapping, ...components }} {...props} />
}

export default TransFromHtml
