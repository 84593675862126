import { useFormikContext } from 'formik'
import React, { ReactElement } from 'react'

import paymentInProcessIcon from '@images/checkout/paymentInProcess.svg'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { Modal } from '@ui'

import '@pages/Checkout/Payment/InProgressModal/index.scss'

interface PaymentInProgressModalProps {
  opened: boolean
}

const RESERVATION_MESSAGE_METHODS = ['hold_reservation', 'cash', 'terminal', 'invoice']

const PaymentInProgressModal = ({ opened }: PaymentInProgressModalProps): ReactElement => {
  const { t } = useTranslation()
  const context = useFormikContext<CheckoutFormData>()
  const paymentMethod = context?.values?.paymentMethod

  const isReservationInProgress = paymentMethod != null && RESERVATION_MESSAGE_METHODS.includes(paymentMethod)
  const translationScope = isReservationInProgress ? 'checkout.reservationInProcess' : 'checkout.paymentInProcess'

  return (
    <Modal opened={opened} className="payment-in-progress">
      <div className="column gap-3 items-center">
        <img src={paymentInProcessIcon} className={bem('payment-in-progress', 'icon')} />
        <h3 className={bem('payment-in-progress', 'title')}>{t(`${translationScope}.title`)}</h3>
        <div>{t(`${translationScope}.description`)}</div>
      </div>
    </Modal>
  )
}

export default PaymentInProgressModal
