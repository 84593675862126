import { t } from 'i18next'
import React, { ReactElement } from 'react'

import { Button } from '@ui'
import { ButtonProps } from '@ui/Button'

interface DefaultPayButtonProps extends Partial<ButtonProps> {
  confirm?: boolean
}

const DefaultPayButton = ({ confirm, ...rest }: DefaultPayButtonProps): ReactElement => (
  <Button buttonType="submit" {...rest}>
    {t(`checkout.${confirm ? 'confirmButton' : 'payButton'}`)}
  </Button>
)

export default DefaultPayButton
