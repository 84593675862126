import React, { ReactElement } from 'react'

import dateUtils from '@lib/date'

export interface DateProps {
  date: Date
}

const Date = ({ date }: DateProps): ReactElement => {
  const string = dateUtils.format(date, 'EEE, dd MMM')

  return <>{string}</>
}

export default Date
