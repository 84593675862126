import cn from 'classnames'
import React, { ReactElement } from 'react'

import amplitude from '@lib/analytics/amplitude'
import { Icon } from '@ui'

import '@components/WhatsappButton/index.scss'

interface WhatsappButtonProps {
  phoneNumber: string
}

const WhatsappButton = ({ phoneNumber }: WhatsappButtonProps): ReactElement => {
  return (
    <a
      data-tag="whatsapp-button"
      href={`https://api.whatsapp.com/send?phone=${phoneNumber}`}
      className={cn('whatsapp-button')}
      target="_blank"
      rel="noreferrer"
      onClick={amplitude.clickWhatsappButton}
    >
      <Icon name="whatsapp" size="large" />
    </a>
  )
}

export default WhatsappButton
