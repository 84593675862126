import { useMemo } from 'react'
import { NavigateOptions, useSearchParams as useURLSearchParams } from 'react-router-dom'

import qs from '@lib/queryString'
import utils from '@lib/utils'

type SearchParams = Record<string, any>

type ParamsSetter = (current: SearchParams) => SearchParams

type SetParamsFunction = (arg: SearchParams | ParamsSetter, options?: NavigateOptions) => void

type UseSearchParamsHook = [SearchParams, SetParamsFunction]

const useSearchParams = (): UseSearchParamsHook => {
  const [urlParams, setUrlParams] = useURLSearchParams()

  const params = useMemo(() => qs.parse(Object.fromEntries([...urlParams.entries()])), [urlParams])
  const setParams: SetParamsFunction = (arg, options?) => {
    const values = arg instanceof Function ? arg(params) : arg

    if (utils.object.isSimilar(params, values)) return

    setUrlParams(new URLSearchParams(qs.stringify(values)), options)
  }

  return [params, setParams]
}

export default useSearchParams
