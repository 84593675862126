import cn from 'classnames'
import React, { MouseEvent, ReactElement, ReactNode, useCallback, useMemo, useRef, useState } from 'react'

import useClickOutside from '@hooks/useClickOutside'
import Popper, { PopperProps } from '@ui/Popper'

import '@ui/Tooltip/index.scss'

type PopperInheritedProps = Omit<PopperProps, 'onRendered' | 'anchorElement' | 'children' | 'className'>
interface TooltipProps extends PopperInheritedProps {
  action?: 'click' | 'hover' | 'focus'
  content: ReactNode | null
  children: ReactNode
  disabled?: boolean
  className?: string
  popperClassName?: string
  onOpenChanged?: (isOpened: boolean) => void
  [key: string]: unknown
}

const Tooltip = (props: TooltipProps): ReactElement => {
  const {
    children,
    action = 'click',
    content,
    disabled,
    popperClassName,
    className,
    onOpenChanged,
    // Popper props. Maybe we should move them under a separate property later?
    opened,
    position,
    anchorWidth,
    fullWidth,
    maxWidth,
    maxHeight,
    ...rest
  } = props
  const [isShown, setIsShown] = useState<boolean>(false)
  const isPopupVisible = opened ?? isShown

  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const hideTimeout = useRef<ReturnType<typeof setTimeout>>()

  const handleShownChanged = useCallback(
    (isOpened: boolean): void => {
      if (disabled) return
      clearTimeout(hideTimeout.current)

      if (isOpened) {
        setIsShown(isOpened)
        onOpenChanged?.(isOpened)
      } else {
        hideTimeout.current = setTimeout(() => {
          setIsShown(isOpened)
          onOpenChanged?.(isOpened)
        }, 50)
      }
    },
    [disabled, onOpenChanged],
  )

  const elements = useMemo(
    () => [{ current: anchorElement }, { current: popperElement }],
    [anchorElement, popperElement],
  )
  const hidePopper = useCallback(() => handleShownChanged(false), [handleShownChanged])
  useClickOutside(elements, hidePopper)

  const toggleTooltip = (): void => handleShownChanged(!isPopupVisible)
  const openTooltip = (): void => handleShownChanged(true)

  const handleShowOnMouseEnter = (): void => {
    handleShownChanged(true)
  }

  const handleCloseOnMouseLeave = (event: MouseEvent): void => {
    if (event.relatedTarget === anchorElement || event.relatedTarget === popperElement) return
    hidePopper()
  }

  if (content == null) return <>{children}</>

  const onReferenceClick = (): void => {
    if (action === 'focus') openTooltip()
    if (action === 'click') toggleTooltip()
  }

  return (
    <>
      <div
        ref={setAnchorElement}
        className={cn('ui-tooltip', className)}
        onMouseEnter={action === 'hover' ? handleShowOnMouseEnter : undefined}
        onMouseLeave={action === 'hover' ? handleCloseOnMouseLeave : undefined}
        onClick={onReferenceClick}
        onFocus={action === 'focus' ? openTooltip : undefined}
        {...rest}
      >
        {children}
      </div>
      <Popper
        opened={isPopupVisible && !disabled}
        position={position}
        className={popperClassName}
        anchorWidth={anchorWidth}
        onRendered={setPopperElement}
        anchorElement={anchorElement}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        onMouseEnter={action === 'hover' ? handleShowOnMouseEnter : undefined}
        onMouseLeave={action === 'hover' ? handleCloseOnMouseLeave : undefined}
      >
        {content}
      </Popper>
    </>
  )
}

export default Tooltip
