import arrivalStationsFilter from '@lib/connections/filters/arrivalStations'
import carriersFilter from '@lib/connections/filters/carriers'
import departureStationsFilter from '@lib/connections/filters/departureStations'
import departureTimeFilter from '@lib/connections/filters/departureTime'
import directionFilter from '@lib/connections/filters/direction'
import fareClassFilter from '@lib/connections/filters/fareClass'
import priceFilter from '@lib/connections/filters/price'
import servicesFilter from '@lib/connections/filters/services'
import trainTypeFilter from '@lib/connections/filters/trainType'
import transportModeFilter from '@lib/connections/filters/transportMode'
import { FilterConfig, FilterType } from '@lib/connections/filters/types'

const CONNECTION_FILTERS: Record<FilterType, FilterConfig<any>> = {
  arrivalStations: arrivalStationsFilter,
  departureStations: departureStationsFilter,
  departureTime: departureTimeFilter,
  direction: directionFilter,
  fareClass: fareClassFilter,
  services: servicesFilter,
  trainType: trainTypeFilter,
  price: priceFilter,
  marketingCarriers: carriersFilter,
  transportMode: transportModeFilter,
}

export default CONNECTION_FILTERS
