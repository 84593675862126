import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'

import useIsMobile from '@hooks/useIsMobile'
import { useTranslation } from '@lib/i18n'
import url from '@lib/url'
import { useParams } from '@stores/params'
import { Button } from '@ui'

const ManageBookingButton = (): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const [{ retailerPartnerNumber, marketingCarrierCode }] = useParams()
  const loginPath = url.build(['/', 'booking', 'login'], { retailerPartnerNumber, marketingCarrierCode })

  return (
    <div className="manage-bookings">
      <Link to={loginPath}>
        <Button variant="text" color={isMobile ? undefined : 'backgroundText'}>
          {t('header.manageBookings')}
        </Button>
      </Link>
    </div>
  )
}

export default ManageBookingButton
