import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import config from '@config'
import { AssertionError } from '@lib/assert'

interface Params {
  global: boolean
}

const IGNORE_PATTERNS = [
  /variable: fbq/, // External ingtegration with Facebook which we don't control
  /fbq is not defined/, // External ingtegration with Facebook which we don't control
]

/* istanbul ignore next */
const init = ({ global }: Params) => {
  if (!config.sentry.enabled) return

  const integrations = [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
    Sentry.globalHandlersIntegration({
      onerror: global,
      onunhandledrejection: global,
    }),
  ]

  Sentry.init({
    dsn: config.sentry.dsn,
    environment: config.env,
    release: config.release,
    integrations,
    enableTracing: false,
    tracePropagationTargets: [],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend: (event, { originalException }) => {
      if (originalException instanceof AssertionError) return null
      return event
    },
    ignoreErrors: [
      'top.GLOBALS', // Random plugins/extensions
      ...IGNORE_PATTERNS,
    ],
  })
}

const sentry = {
  init,
  createBrowserRouter: Sentry.wrapCreateBrowserRouter(createBrowserRouter),
  ErrorBoundary: Sentry.ErrorBoundary,
  captureException: Sentry.captureException,
}

export default sentry
