import { useCallback, useMemo, useState } from 'react'

import { useCheckout } from '@stores/checkout'

interface UseCarriersHook {
  handleSuccess: (carrier: MarketingCarrier) => void
  handleLoading: (code: string, loading: boolean) => void
  isLoading: boolean
  codes: string[]
  carriers: MarketingCarrier[]
}

const useCarriers = (): UseCarriersHook => {
  const [carriersLoading, setCarriersLoading] = useState<Record<string, boolean>>({})
  const [carriers, setCarriers] = useState<MarketingCarrier[]>([])
  const [{ outbound, inbound }] = useCheckout()

  /* istanbul ignore next: due to checkout's store data could be null by the interface we need to handle them for ts */
  const segments = useMemo(() => [...(outbound?.segments ?? []), ...(inbound?.segments ?? [])], [inbound, outbound])
  /* istanbul ignore next: due to checkout's store data could be null by the interface we need to handle them for ts */
  const codes = useMemo(
    () => [...new Set(segments.map(({ marketingCarrier }) => marketingCarrier.code))] ?? [],
    [segments],
  )

  const isLoading = Object.entries(carriersLoading).some(([_, values]) => values)

  const handleLoading = useCallback((code: string, loading: boolean): void => {
    setCarriersLoading(prevState => ({ ...prevState, [code]: loading }))
  }, [])

  const handleSuccess = useCallback((carrier: MarketingCarrier): void => {
    setCarriers(prevState => [...prevState.filter(({ code }) => carrier.code !== code), carrier])
  }, [])

  return {
    handleSuccess,
    handleLoading,
    isLoading,
    codes,
    carriers,
  }
}

export default useCarriers
