import React from 'react'

import useIsMobile from '@hooks/useIsMobile'
import Desktop from '@ui/MediaCarousel/Desktop'
import Mobile from '@ui/MediaCarousel/Mobile'

interface ImageCarouselProps {
  media: string[]
  className?: string
  onMediaChange?: (index: number) => void
}

const MediaCarousel = (props: ImageCarouselProps) => {
  const isMobile = useIsMobile()

  return isMobile ? <Mobile {...props} /> : <Desktop {...props} />
}

export default MediaCarousel
