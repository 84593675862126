import cn from 'classnames'
import React, { MouseEvent, ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import { Dropdown } from '@ui'

import '@components/PassengersList/Age.scss'

interface AgeProps {
  label: string
  type: Passenger.Type
  value: string | null
  onChange: (age: string, event?: MouseEvent) => void
}

const LIST_GAP = 90

const Age = ({ label, onChange, type, value }: AgeProps): ReactElement => {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  const [shifted, setShifted] = useState<boolean>(false)
  const isMobile = useIsMobile()
  const items = new Array(type.maxAge - type.minAge + 1).fill(1).map((_, index) => ({
    label: t('searchBar.passengers.ageList', { age: type.minAge + index }),
    value: String(type.minAge + index),
  }))

  const observer = useMemo(
    () =>
      new ResizeObserver(entries => {
        const child = ref.current?.getBoundingClientRect().bottom
        const parent = entries[0].contentRect.bottom
        /* istanbul ignore else */
        if (parent && child) setShifted(parent + LIST_GAP < child)
      }),
    [],
  )

  useEffect(() => {
    !isMobile && ref.current?.offsetParent && observer.observe(ref.current.offsetParent)

    return () => {
      observer.disconnect()
    }
  }, [isMobile, observer])

  return (
    <div ref={ref} className="passenger-list__age row space-between">
      <span className="cell-5">{label}</span>
      <Dropdown
        className={cn(bem('passenger-list', 'age-dropdown', { shifted }), 'cell-5')}
        label={t('searchBar.passengers.selectAge')}
        items={items}
        value={value}
        onChange={onChange}
        checkedMark
      />
    </div>
  )
}

export default Age
