import React, { useMemo } from 'react'

import CheckboxFilter from '@lib/connections/filters/components/CheckboxFilter'
import { FilterConfig, FilterFunction, FilterRenderProps } from '@lib/connections/filters/types'
import fareUtils from '@lib/fare'
import { CheckboxOption } from '@ui/CheckboxGroup'

const filter: FilterFunction = (connection, value) => {
  const fareClassNames = fareUtils.getUniqueFareClassNames([connection], { fareClassCode: null })

  return fareClassNames.some(name => value.includes(name))
}
const getFareClassOption = (fareClassName: string): CheckboxOption<string> => ({
  value: fareClassName,
  label: fareClassName,
})

const FilterComponent = ({ value, onChange, data, setVisibility }: FilterRenderProps) => {
  const options = useMemo(
    () => fareUtils.getUniqueFareClassNames(data, { fareClassCode: null }).map(getFareClassOption),
    [data],
  )

  return <CheckboxFilter value={value} onChange={onChange} options={options} setVisibility={setVisibility} />
}

const fareClassFilter: FilterConfig = {
  filter,
  title: 'fareClass',
  Component: FilterComponent,
}

export default fareClassFilter
