import makeApi from '@lib/api'

export interface FareClassesRequest {
  marketingCarrierCode: CarrierCode
}

export type FareClassesResponse = FareClassMap.Entity[]

export default {
  load: async (params: FareClassesRequest): Promise<FareClassesResponse> => {
    const callApi = makeApi.get<{}, FareClassesResponse>(
      `/marketing_carriers/${params.marketingCarrierCode}/fare_classes`,
    )

    return await callApi({})
  },
}
