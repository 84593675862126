import helpers from '@lib/analytics/amplitude/helpers'
import { ApiError } from '@lib/api'
import { AssertionError } from '@lib/assert'

const { sendEvent } = helpers

export interface ViewErrorPageEvent {
  error: any
  reason: string
}

export class DataNotFoundError extends Error {}
export class PageNotFoundError extends Error {}

/* istanbul ignore next */
const buildParams = (error: Error): ViewErrorPageEvent => {
  if (error instanceof ApiError) {
    const { code, status, data, path } = error

    return { reason: 'API error', error: { code, status, data, path } }
  }
  if (error instanceof AssertionError) return { reason: 'Invalid params', error: { data: error.message } }
  if (error instanceof DataNotFoundError) return { reason: 'Data not found', error: null }
  if (error instanceof PageNotFoundError) return { reason: 'Page not found', error: null }

  return { reason: 'Unknown error', error: { data: error.message } }
}

const viewPage = (params: ViewErrorPageEvent): void => {
  sendEvent('view-error-page', params)
}

export default {
  viewPage,
  buildParams,
}
