import { useMemo } from 'react'

import usePassengersList from '@hooks/usePassengersList'
import passengersUtils from '@lib/passengers'
import { ConnectionsLoaderParams, PassengersTypeParams } from '@loaders/connections'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

const compactCards = (passenger: PassengersTypeParams) => ({
  ...passenger,
  cards: passengersUtils.getPaxCards(passenger.cards),
})

const useConnectionsSearchParams = (): ConnectionsLoaderParams => {
  const [params] = useParams()
  const passengerList = usePassengersList()
  const [{ seatSelection, passengerTypesList }] = useSettings()

  const passengers = useMemo(() => {
    if (!passengerTypesList.enabled || !params.passengers) return null

    return passengersUtils.getPassengerTypeParams(params.passengers, passengerList).map(compactCards)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.passengers, passengerList, passengerTypesList.enabled, JSON.stringify(params.passengers)])

  return useMemo(
    () => ({
      departureLocation: params.departureLocation,
      arrivalLocation: params.arrivalLocation,
      returnDate: params.returnDate,
      departureDate: params.departureDate,
      pax: seatSelection.enabledOnSearchResults ? 1 : params.pax,
      passengers,
      locale: params.locale,
      currency: params.currency,
      departureTime: params.departureTime,
      departureEndTime: params.departureEndTime,
      retailerPartnerNumber: params.retailerPartnerNumber,
      marketingCarrierCode: params.marketingCarrierCode,
      cards: params.cards,
      bookingId: params.bookingId,
    }),
    [
      params.arrivalLocation,
      params.bookingId,
      params.cards,
      params.currency,
      params.departureDate,
      params.departureEndTime,
      params.departureLocation,
      params.departureTime,
      params.locale,
      params.marketingCarrierCode,
      params.pax,
      params.retailerPartnerNumber,
      params.returnDate,
      passengers,
      seatSelection.enabledOnSearchResults,
    ],
  )
}

export default useConnectionsSearchParams
