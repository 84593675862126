import { StationData } from '@api/stations'
import { Filter } from '@enums'
import { ConnectionFiltersStore } from '@stores/connectionFilters'

const filterKeys: Record<Filter, keyof ConnectionFiltersStore> = {
  [Filter.DirectOnly]: 'direction',
  [Filter.DepartureTime]: 'departureTime',
  [Filter.FareClass]: 'fareClass',
  [Filter.Services]: 'services',
  [Filter.DepartureStation]: 'departureStations',
  [Filter.ArrivalStation]: 'arrivalStations',
  [Filter.TrainType]: 'trainType',
  [Filter.Price]: 'price',
  [Filter.Carriers]: 'marketingCarriers',
}

const getFilterKey = (filter: Filter): keyof ConnectionFiltersStore => filterKeys[filter]

const buildStationMapURL = (station: Station | StationData): string => {
  const { latitude, longitude, name } = station

  if (latitude && longitude) return `https://maps.google.com/?q=${latitude},${longitude}`

  return `https://maps.google.com/?q=${name}`
}

export default {
  getFilterKey,
  buildStationMapURL,
}
