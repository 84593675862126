import { markupFee, priceWithOutFee, totalPrice } from '@lib/analytics/gtm/confirmation/helpers'

export interface PurchaseEvent {
  ecommerce: Ecommerce
  event: string
}

enum SegmentFields {
  RetailerPartnerNumber = 'dimension7',
  FareClassCode = 'dimension8',
}

interface Ecommerce {
  currencyCode: string
  name: string
  purchase: PurchaseInfo
}

interface PurchaseInfo {
  actionField: {
    action: string
    id: string
    affiliation: number
    revenue: number
    tax: number
    option: string
  }
  products: BookingSegment[]
}

interface BookingSegment {
  id: string
  name: string
  price: number
  brand: string
  [SegmentFields.RetailerPartnerNumber]: number
  [SegmentFields.FareClassCode]: string
  category: string
  variant: string
  quantity: number
}

const EVENT_NAME = 'eec.purchase'

export const buildPurchaseEvent = (bookingData: Booking.Confirmation, currency: Currency): PurchaseEvent => {
  const { booking } = bookingData

  const ecommerce = {
    name: `${booking.departureStation.city.name}-${booking.arrivalStation.city.name}`,
    purchase: {
      actionField: buildPurchaseInfo(booking),
      products: buildSegmentsInfo(booking),
    },
    currencyCode: currency,
  }

  return {
    ecommerce,
    event: EVENT_NAME,
  }
}

const buildPurchaseInfo = (booking: Booking.Info): PurchaseInfo['actionField'] => {
  return {
    action: 'purchase',
    id: booking.marketingCarrierBookingNumber,
    affiliation: booking.retailerPartnerNumber,
    revenue: totalPrice(booking) / 100,
    tax: markupFee(booking) / 100,
    option: booking.paymentMethod,
  }
}

const buildSegmentsInfo = (booking: Booking.Info): BookingSegment[] => {
  return booking.segments.map((segment, index) => {
    const segmentId = `${segment.departureStation.name}-${segment.arrivalStation.name}`
    const segmentName = `${segment.departureStation.code}-${segment.arrivalStation.code}`
    const segmentPrice = priceWithOutFee(booking) / booking.pax / booking.segments.length / 100

    return {
      id: segmentId,
      name: segmentName,
      price: segmentPrice,
      brand: segment.marketingCarrier.code,
      [SegmentFields.RetailerPartnerNumber]: booking.retailerPartnerNumber,
      [SegmentFields.FareClassCode]: booking.fareClass.code,
      category: booking.segments.length > 1 ? 'round' : /* istanbul ignore next */ 'single',
      variant: index === 0 ? 'outbound' : /* istanbul ignore next */ 'inbound',
      quantity: booking.pax,
    }
  })
}
