import cn from 'classnames'
import React, { ReactElement, useEffect } from 'react'
import { Trans } from 'react-i18next'

import Blocks from '@components/Blocks'
import Banner from '@components/Blocks/Banner'
import PageWrapper from '@components/PageWrapper'
import Section from '@components/Section'
import useIsMobile from '@hooks/useIsMobile'
import amplitude from '@lib/analytics/amplitude'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import utils from '@lib/utils'
import { usePopularConnections } from '@loaders/popularConnections'
import SearchBar from '@pages/Search/Bar'
import PopularDirections from '@pages/Search/PopularDirections'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

import '@pages/Search/index.scss'

const SearchPage = (): ReactElement => {
  const [
    {
      searchBar: { layout },
      backgroundImage,
      customElements,
      colors: { backgroundText, headerText },
    },
  ] = useSettings()
  const [params] = useParams()
  const { retailerPartnerNumber, locale, currency, marketingCarrierCode } = params
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { data: popularConnections } = usePopularConnections({
    currency,
    locale,
    marketingCarrierCode,
    retailerPartnerNumber,
  })
  const connections = popularConnections?.connections ?? []

  useEffect(() => {
    amplitude.viewSearchPage()
  }, [])

  const pageTitle = t(`header.${isMobile ? 'titleMobile' : 'title'}`)
  const pageSubTitle = <Trans t={t} i18nKey="header.subtitle" />

  const bgImageUrl = backgroundImage.enabled ? backgroundImage.url : null
  const headerImage = bgImageUrl && !isMobile ? `url(${bgImageUrl})` : undefined
  const customBlocks = utils.array.findBy(customElements.pages, 'location', 'search')?.blocks
  const showSearchBarOffset = !pageTitle && !t('header.subtitle') && layout !== 'column' && headerImage
  const banner = customBlocks?.find(block => block.type === 'banner') as PageBlocks.BannerBlock | undefined

  return (
    <PageWrapper banner={banner ? <Banner {...banner.data} /> : null}>
      <div className="search-page">
        <div
          id="bg-line"
          style={{ backgroundImage: headerImage }}
          className={cn(bem('background'), { 'header-offset': backgroundImage.headerOffset, banner: !!banner })}
        />
        <Section
          appearance="flat"
          background={{ fill: 'transparent' }}
          className={cn('mb-5', bem('search-page', 'form'))}
        >
          <>
            {showSearchBarOffset && <div className="background-offset" />}
            <SearchBar
              layout={layout}
              title={pageTitle}
              subTitle={pageSubTitle}
              textColor={headerImage ? headerText : backgroundText}
            />
          </>
        </Section>
        {!!connections.length && (
          <Section appearance="flat" contentClassName={bem('search-page', 'connections')}>
            <PopularDirections connections={connections} />
          </Section>
        )}
        {customElements.enabled && customBlocks && <Blocks blocks={customBlocks} />}
      </div>
    </PageWrapper>
  )
}

export default SearchPage
