import React, { ReactElement } from 'react'

import { Skeleton } from '@ui'

import '@pages/Checkout/FareClassesSelection/FareClassSkeleton/index.scss'

const FareClassSkeleton = (): ReactElement => {
  return (
    <div className="fare-class__skeleton column">
      <div className="cell-6 pb-2">
        <Skeleton.Item height={24} />
      </div>
      <div className="cell-12 pb-1">
        <Skeleton.Item height={48} />
      </div>
      <div className="cell-4 pb-2">
        <Skeleton.Item height={16} />
      </div>
      <div className="cell-2">
        <Skeleton.Item height={24} />
      </div>
    </div>
  )
}

export default FareClassSkeleton
