import config from '@config'

const isMatches = (string: string, target: string): boolean => new RegExp(`${string}`).test(target)

const getDomain = (): string => location.hostname

const retailerPartnerNumber = (): number | null => {
  const domain = getDomain()

  const entry = Object.entries(config.resolve.retailerPartnerNumber).find(([key]) => isMatches(key, domain))
  const [_, number] = entry ?? [0, null]

  return number
}

export default {
  retailerPartnerNumber,
}
