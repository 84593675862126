import { createStore, useStore, UseStoreHook } from '@lib/store'
import { ConnectionsStore } from '@stores/connections'

export interface ConnectionCalendarStore {
  selectedDate: Date | null
  outboundDate: Date | null
  inboundDate: Date | null
  calendar: Record<string, ConnectionsStore>
  cheapestPrices: Record<string, Record<ConnectionType, Money>>
}

const store = createStore<ConnectionCalendarStore>({
  selectedDate: null,
  outboundDate: null,
  inboundDate: null,
  calendar: {},
  cheapestPrices: {},
})

export const useConnectionCalendar = (): UseStoreHook<ConnectionCalendarStore> => useStore(store)

export default store
