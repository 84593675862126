import cn from 'classnames'
import React, { ReactElement } from 'react'

import { Skeleton } from '@ui'

interface DetailsSkeletonProps {
  className?: string
}

const DetailsSkeleton = ({ className }: DetailsSkeletonProps): ReactElement => {
  return (
    <div className="column">
      <div className={cn('cell-12', className)}>
        <Skeleton.Item height={32} />
      </div>
    </div>
  )
}

export default DetailsSkeleton
